<template>
    <div><br>
        <Breadcrumbs main="Resume Details" title="Resume Details" />
        <div class="container-fluid">
            <div class="user-profile">
                <div class="row">
                    <div class="col-sm-12">
                        <br /><br />
                        <div class="card hovercard text-center">
                            <div class="user-image">
                                <div class="avatar">
                                    <img v-if="this.image_name" class="mr-3 rounded-circle" :src="this.image_name"
                                        style="height:100;width:100;" alt="" />

                                    <img v-else class="mr-3 rounded-circle" src="../../assets/images/user/user.png"
                                        style="height:100;width:100;" alt="" />
                                </div>
                                <h6 class="mb-0"><b>{{ name }}</b></h6>
                            </div>
                            <br><br>
                            <div class="card m-top ">
                                <div class="col-sm-12 p-0 ">
                                    <b-tabs content-class="mt-3 " pills card align="center"
                                        active-nav-item-class="font-weight-bold text-uppercase"
                                        active-tab-class=" font-weight-bold">

                                        <b-tab active>
                                            <template v-slot:title>
                                                <feather type="user"></feather> Personal Details
                                            </template>
                                            <b-card-text>
                                                <div class="col-sm-12 p-0">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-envelope-o"></i>
                                                                            Email </h6>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{ this.email }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-md-1">

                                                                </div> -->

                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-file"></i>
                                                                            Resume </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <a :href="this.file_name" target="_blank" download>
                                                                            <b-button id="default-primary" class="btn-pill"
                                                                                variant="outline-primary">
                                                                                <feather type="download"></feather>
                                                                                Download
                                                                            </b-button>
                                                                        </a> &nbsp;

                                                                      
                                                                        <b-button id="default-primary" class="btn-pill"
                                                                            variant="outline-primary" @click="openModal">
                                                                            <feather type="eye"></feather>
                                                                            View
                                                                        </b-button>
                                                                       
                                                                    </div>
                                                                </div>
                                                                <b-modal v-model="modalOpen" title="Resume" @hidden="closeModal" size="lg"> 
                                                                    <iframe :src="'https://docs.google.com/viewer?url='+this.file_name+'&embedded=true'" frameborder="0" style="width: 100%; height: 500px;"></iframe>
                                                                </b-modal>
                                                               
                                                            </div><br>
                                                            <div class="row">
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-phone"></i>
                                                                            Phone </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{
                                                                            this.phone
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-phone"></i>
                                                                            Alternate
                                                                            Phone </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{
                                                                            this.alt_phone
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-md-1">

                                                                </div> -->
                                                              
                                                            </div><br>
                                                            <div class="row">
                                                          
                                                                <!-- <div class="col-1">

                                                                </div> -->
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-calendar"></i>
                                                                            Date of
                                                                            birth </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{
                                                                            this.dob
                                                                        }} </span>&nbsp; ({{ age }} years old)
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-user"></i>
                                                                            Gender </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <div class="ali-med">
                                                                            <span v-if="this.gender == 'F'">
                                                                                Female
                                                                            </span>
                                                                            <span v-if="this.gender == 'M'">
                                                                                Male
                                                                            </span>
                                                                            <span v-if="this.gender == 'O'">
                                                                                Other
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div><br>
                                                            <div class="row">
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-heart"></i>
                                                                            Marital Status </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <div class="ali-med">
                                                                            <span v-if="this.marital_status == 'M'">
                                                                                Married
                                                                            </span>
                                                                            <span v-if="this.marital_status == 'S'">
                                                                                Single
                                                                            </span>
                                                                            <span v-if="this.marital_status == 'D'">
                                                                                Divorced
                                                                            </span>
                                                                            <span v-if="this.marital_status == 'W'">
                                                                                Widowed
                                                                            </span>
                                                                            <span v-if="this.marital_status == 'O'">
                                                                                Other
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-1">

                                                                </div> -->
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-home"></i>
                                                                            Current Address </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{
                                                                            this.current_address
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                            </div><br>
                                                            <div class="row">
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-map-marker"></i>
                                                                            Current State
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{
                                                                            this.current_state
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-1">

                                                                </div> -->
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-map-marker"></i>
                                                                            Current Country
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{
                                                                            this.current_country
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                            </div><br>
                                                            <div class="row">
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-building"></i>
                                                                            Current Pin </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{
                                                                            this.current_pin
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-1">

                                                                </div> -->
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-home"></i>
                                                                            Permanent Address
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{
                                                                            this.permanent_address
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                            </div><br>
                                                            <div class="row">
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-map-marker"></i>
                                                                            Permanent State
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{
                                                                            this.permanent_state
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-1">

                                                                </div> -->
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-map-marker"></i>
                                                                            Permanent Country
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{
                                                                            this.permanent_country
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                            </div><br>
                                                            <div class="row">
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-building"></i>
                                                                            Permanent Pin
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{
                                                                            this.permanent_pin
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-1">

                                                                </div> -->
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-money"></i>
                                                                            Current Salary
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{
                                                                            this.current_salary
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                            </div><br>
                                                            <div class="row">
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-map-marker"></i>
                                                                            Current city
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{
                                                                            this.cur_city
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-1">

                                                                </div> -->
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-map-marker"></i>
                                                                            Permenent city
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left" v-if="notice_period_in_days">
                                                                        <span class="ali-med">{{
                                                                            per_city
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                            </div><br>
                                                            <div class="row">
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-money"></i>
                                                                            Expected Salary
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{
                                                                            this.expected_salary
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-1">

                                                                </div> -->
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-file"></i>
                                                                            Notice Period
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left" v-if="notice_period_in_days">
                                                                        <span class="ali-med">{{
                                                                            notice_period_in_days
                                                                        }} days</span>
                                                                    </div>
                                                                </div>
                                                            </div><br>
                                                            <div class="row">
                                                                <div class="col-md-2 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <h6 class="ali-large"><i class="fa fa-comment"></i>
                                                                            Notes </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 marg-top">
                                                                    <div class="ttl-info text-left">
                                                                        <span class="ali-med">{{
                                                                            this.notes
                                                                        }}</span>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-1">

                                                                </div> -->
                                                            </div><br>

                                                        </div>
                                                    </div>
                                                </div>
                                            </b-card-text>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <feather type="book"></feather> Academic Details
                                            </template>
                                            <b-card-text>
                                                <div class="col-sm-12 p-0">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <center>
                                                                    <h6 class="mb-0"><b>Academic Details</b></h6> <br>
                                                                </center>
                                                                <div class="table-responsive datatable-vue">
                                                                    <b-table show-empty stacked="md"
                                                                        :items="academic_detail" :fields="tablefields">
                                                                    </b-table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-card-text>
                                        </b-tab>
                                        <b-tab>
                                            <template v-slot:title>
                                                <feather type="briefcase"></feather> Work Experience
                                            </template>
                                            <b-card-text>
                                                <div class="col-sm-12 p-0">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <center>
                                                                    <h6 class="mb-0"><b>Total Work Experience: </b> {{ tot_exp_year }} Years and {{ tot_exp_month }} Months</h6> <br>
                                                                    
                                                                </center>
                                                                
                                                                <div class="table-responsive datatable-vue">
                                                                    <b-table show-empty stacked="md"
                                                                        :items="work_exp_details" :fields="tablefields1">

                                                                    </b-table>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-card-text>
                                        </b-tab>
                                        <b-tab>
                                            <template v-slot:title>
                                                <feather type="check-square"></feather> Applied Positions
                                            </template>
                                            <b-card-text>
                                                <div class="col-sm-12 p-0">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <center>
                                                                    <h6 class="mb-0"><b>Applied Positions</b></h6> <br>
                                                                </center>

                                                                <div class="table-responsive datatable-vue">
                                                                   
                                                                    <b-table show-empty stacked="md"
                                                                        :items="applied_vacancies" :fields="tablefields2">
                                                                        <template #cell(keyword_wise_mark)="row">

                                                                        <table v-if="row.item.resume_status == 'Resume Under Review.'">
                                                                            <tr>
                                                                                <td>keyword</td>
                                                                                <td>mark</td>
                                                                                <td>time</td>
                                                                            </tr>
                                                                            <tr v-for="(intm,index) in row.item.keyword_wise_mark">
                                                                                <td>{{ intm.keyword }}</td>
                                                                              
                                                                                <td>{{ intm.marks }}</td>
                                                                                  <td>{{ intm.times }}</td>
                                                                           
                                                                            </tr>
                                                                        </table>
                                                                    </template>

                                                                        <template #cell(actions)="row">
                                                                            <span>
                                                                                <router-link
                                                                                    :to="'/home/resume-transaction-view/' + row.item.id">
                                                                                    <feather type="eye"></feather>
                                                                                </router-link>
                                                                            </span>
                                                                        </template>

                                                                    </b-table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-card-text>
                                        </b-tab>
                                        <b-tab>
                                            <template v-slot:title>
                                                <feather type="plus-square"></feather> Openings
                                            </template>
                                            <b-card-text>
                                                <div class="col-sm-12 p-0">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <center>
                                                                    <h6 class="mb-0"><b>Openings</b></h6> <br>
                                                                </center>
                                                                <div class="table-responsive datatable-vue">
                                                                    <b-table show-empty stacked="md"
                                                                        :items="openings" :fields="tablefields3">

                                                                        <template #cell(actions)="row">
                                                                            <span>
                                                                                <a href="javascript:void(0)" class="action-btn btn-trash" @click="applyVacancy(row.item.vacancy_id)">
                                                                                    <feather type="check-square" title="Apply Job"></feather>
                                                                                </a>
                                                                            </span>
                                                                            <span>
                                                                                <router-link
                                                                                    :to="'/home/vacancy-view/' + row.item.vacancy_id">
                                                                                    <feather type="eye" title="Job Description"></feather>
                                                                                </router-link>
                                                                            </span>
                                                                        </template>



                                                                    </b-table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-card-text>
                                        </b-tab>
                                    </b-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script>

import Service from '@/services/Service.js';
import ServiceApi from "@/services/ServiceApi.js";
import moment from 'moment'
import Swal from "sweetalert2";

export default {
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            dob: '',
            age: '',
            alt_phone: '',
            gender: '',
            marital_status: '',
            image_name: '',
            current_address: '',
            current_state: '',
            current_country: '',
            current_pin: '',
            permanent_address: '',
            permanent_state: '',
            permanent_country: '',
            permanent_pin: '',
            file_name: '',
            current_salary: '',
            expected_salary: '',
            notice_period_in_days: '',
            notes: '',
            cur_city: '',
            per_city: '',
            academic_detail: [],
            work_exp_details: [],
            applied_vacancies: [],
            openings: [],
            tot_exp_year: null,
            tot_exp_month: null,
            items: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            filter: null,
            pageOptions: [10, 25, 50],
            modalOpen: false,
            tablefields: [
                { key: "institute", label: "Institute", sortable: true },
                { key: "course", label: "Course", sortable: true },
                { key: "joining_year", label: "Joining Year", sortable: true },
                { key: "pass_out_year", label: "Pass Out Year", sortable: true },
            ],
            tablefields1: [
                { key: "organization", label: "Organization", sortable: true },
                { key: "designation", label: "Designation", sortable: true },
                { key: "start_date", label: "Start Date", sortable: true },
                { key: "end_date", label: "End Date", sortable: true },
            ],
            tablefields2: [
                { key: "title", label: "Job Title", sortable: true },
                { key: "resume_status", label: "Current status", sortable: true },
                { key: "keyword_wise_mark", label: "Keywords", sortable: false },
                { key: "actions", label: "Actions", sortable: true, },
            ],
            tablefields3: [
                { key: "title", label: "Job Title", sortable: true },
                { key: "department", label: "Department", sortable: true },
                { key: "end_date", label: "Last Date", sortable: true },
                { key: "actions", label: "Actions", sortable: true, },
            ],
        };
    },

    mounted() {
        this.retrieveResumeDetails();
        this.getVacancyDetails();
    },
    methods: {
        openModal() {
        this.modalOpen = true;
        },
        closeModal() {
        this.modalOpen = false;
        },
        async retrieveResumeDetails() {
            const ReqData = {
                id: this.$route.params.ids,
            };
            const response = await Service.GetResumesViewById(ReqData);
            if (response.code == "200") {
                this.name = response.data.name;
                this.email = response.data.email;
                this.phone = response.data.phone;
                this.alt_phone = response.data.alt_phone;
                this.gender = response.data.gender;
                this.dob = response.data.dob;
                this.marital_status = response.data.marital_status;
                this.image_name = response.data.image_name;
                this.current_address = response.data.current_address;
                this.current_state = response.data.current_state;
                this.current_country = response.data.current_country;
                this.current_pin = response.data.current_pin;
                this.permanent_address = response.data.permanent_address;
                this.permanent_state = response.data.permanent_state;
                this.permanent_country = response.data.permanent_country;
                this.permanent_pin = response.data.permanent_pin;
                this.file_name = response.data.file_name;
                this.current_salary = response.data.current_salary;
                this.expected_salary = response.data.expected_salary;
                this.notice_period_in_days = response.data.notice_period_in_days;
                this.notes = response.data.notes;
                this.academic_detail = response.data.academic_detail;
                this.work_exp_details = response.data.work_exp_details;
                this.applied_vacancies = response.data.applied_vacancies;
                this.tot_exp_year = response.data.total_exp_year;
                this.tot_exp_month = response.data.total_exp_month;
                this.age = response.data.age;
                this.cur_city = response.data.cur_city;
                this.per_city = response.data.per_city;
            }
        },
        async getVacancyDetails() {
			const data = {
				id : this.$route.params.ids,
			}
			const response = await ServiceApi.GetNewOpenings(data);
			if (response.code == '200') {
				this.openings = response.data;
			}
			
			else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
			}

		},

        applyVacancy(id) {
            
            Swal.fire({
                title: "Are you sure?",
                text: "Are you sure you  to apply this Job!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Sure!",
            }).then((result) => {
                if (result.isConfirmed) {
                    const credentials = {
                        vacancy_id:id,
                        resume_id:this.$route.params.ids,
                    };
                    var done = this.applyJobVacancy(credentials);

                    if(done){
                        Swal.fire("Thank  You!", "for applying this job.", "success")
                        window.location.reload()
                       
                    }
                }
            });
       
    },
    async applyJobVacancy(credentials){
        const response = await ServiceApi.ApplyJobVacancy(credentials);
        if (response.code == "200") {
            return true;
        }
        else if (response.code == "400") {
            this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
            return false;
        }
        else if (response.code == "403") {
            this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
            localStorage.removeItem('loginDetails')
            localStorage.removeItem('menuData')
            this.$router.push('/');
        }else{
            return false;
        }
    },
    }
};



</script>
<style>
.product-color li {
    display: inline-block;
    border-radius: 100%;
    height: 31px;
    width: 41px;
    margin: -5px 2px;
}

.hovercard .info .social-media ul li {
    padding-top: 3px;
    margin-right: 10px;
}

.m-top {
    margin-top: 35px;
}

.wid-tab {
    width: 18% !important;
}

.ali-large {
    font-size: 14px !important;
}

.ali-med {
    font-size: medium !important;
}

.marg-top {
    margin-top: auto !important;
}
</style>