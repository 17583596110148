<template>
  <router-link to="/home">
    <div v-if="data.company.logo_name">
      <img
      class="img-fluid for-light"
      :src="data.company.logo_name"
      alt=""
    /><img
      class="img-fluid for-dark"
      :src="data.company.logo_name"
      alt=""
    />

    </div>
    <div v-else>
      <img
      class="img-fluid for-light"
      src="../../assets/images/logo/login.png"
      alt=""
    /><img
      class="img-fluid for-dark"
      src="../../assets/images/logo/login.png"
      alt=""
    />


    </div>
  </router-link>
</template>
<script>

  export default {
    name: 'Logo',
    data() {
      return {
        data:JSON.parse(localStorage.getItem('loginDetails')),
      };
    },
  };
</script>
