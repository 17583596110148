<template>
    <div><br>
        <Breadcrumbs main="Activities" title="Manage Activities" />

        <div class="container-fluid">
            <div class="row">

                <div class="col-sm-12 p-0">

                    <div class="card card-p">
                        <div class="card-body">
                            <b-row>
                                <b-col xl="8"></b-col>
                                <b-col xl="3">
                                    <v-select v-model="to_users" label="name" placeholder="Users" :options="users"
                                        :multiple="false" />
                                </b-col>
                                <b-col xl="1" class="load-style">
                                    <b-button id="default-primary" v-if="!isLoading" variant="primary"
                                        @click="retrieveAllActivities()">
                                        View
                                    </b-button>
                                    <div class="loader-box " v-else>
                                        <div class="loader-15"></div>
                                    </div>
                                </b-col>


                            </b-row>
                            <hr><br>
                            <b-row>
                                <b-col xl="6">
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select ">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">

                                </b-table>
                            </div>
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'

export default {
    components: {
        vSelect
    },
    data() {
        return {
            tablefields: [
                { key: 'date', label: 'Date Time', sortable: true, },
                // { key: 'company_name', label: 'Company', sortable: true, },
                { key: 'text', label: 'Notification', sortable: true, },
                // { key: 'actions', label: 'Actions', sortable: true, },
            ],
            items: [],
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            users: [],
            to_users: null,
            isLoading: false,
            pageOptions: [10, 25, 50],
            loginData: JSON.parse(localStorage.getItem('loginDetails')),
        };
    },
    computed: {

        statusVariant() {
            const statusColor = {
                1: "light-success",
                0: "light-danger",
            };

            return (status) => statusColor[status];
        },

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        }
    },
    created() {
       
        this.retrieveEmployees();

    },
    methods: {
        async retrieveEmployees() {
            const response = await Service.GetEmployeesForReport();
            if (response.code == "200") {
                this.users = response.data

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        
        async retrieveAllActivities() {
            this.isLoading = true;
            // console.log(this.to_users);
            if (this.to_users == null) {
                this.isLoading = false;
                this.$toasted.show('Select Employee', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                var rqData = {
                    user: this.to_users.id
                }
                const response = await Service.GetAllActivities(rqData);
                if (response.code == "200") {
                    this.isLoading = false;
                    // console.log(response.data);
                    this.items = []
                    this.items = response.data;
                   
                    this.totalRows = this.items.length;
                } else if (response.code == "403") {
                    this.isLoading = false;
                    this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                    localStorage.removeItem('loginDetails')
                    localStorage.removeItem('menuData')
                    this.$router.push('/');
                } else {
                    this.isLoading = false;
                    this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                }
            }
        },
       
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

    }
};
</script>
<style>
.per-page {
    padding: 18px 1px;
}

.red-marg {
    margin-top: 0rem !important;
}
</style>