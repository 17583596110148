<template>
    <div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="user-profile">
                <div class="row">
                    <!-- user profile first-style start-->
                    <div class="col-sm-12">

                        <br /><br />

                        <div class="card hovercard text-center"><br>
                            <div>
                                <h5> Resume status view</h5>
                            </div>

                            <div class="info">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-flag"></i> Name </h6>
                                            <span>
                                                {{ this.name }}
                                            </span>
                                        </div>
                                    </div>


                                    <div class="col-md-2">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-flag"></i> Priority </h6>
                                            <span>
                                                {{ this.priority }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-flag"></i> Assign status </h6>
                                            <span>
                                                {{ this.assign_status_name }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-flag"></i> Applicant Mail status </h6>
                                            <span>
                                                {{ this.mail_status_name }}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-flag"></i> Recruiter Mail status </h6>
                                            <span>
                                                {{ this.recruiter_mail_status_name }}
                                            </span>
                                        </div>
                                    </div>

                                    


                                </div>

                                <hr>
                                <div class="row">

                                    <div class="col-md-3">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-flag"></i> Description </h6>
                                            <span>
                                                {{ this.description }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3" v-if="this.mail_subject">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-flag"></i> Applicant Mail Subject </h6>
                                            <span>
                                                {{ this.mail_subject }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3" v-if="this.recruiter_mail_subject">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-flag"></i> Reruiter Mail Subject </h6>
                                            <span>
                                                {{ this.recruiter_mail_subject }}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-md-3" v-if="this.mail_content">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-list-alt"></i> Applicant Mail content</h6>
                                            <p :style="'margin: 5px;'" v-html="this.mail_content"></p>
                                        </div>
                                    </div>
                                    <div class="col-md-3" v-if="this.recruiter_mail_content">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-list-alt"></i> Reruiter Mail content</h6>
                                            <p :style="'margin: 5px;'" v-html="this.recruiter_mail_content"></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ServiceApi from '@/services/ServiceApi.js';
import vSelect from 'vue-select'

export default {

    components: {
        vSelect,
    },

    data() {
        return {
            name: '',
            description: '',
            priority: '',
            priority_name: '',
            assign_status: '',
            mail_status: '',
            mail_content: '',
            recruiter_mail_status: '',
            recruiter_mail_content: '',
            recruiter_mail_status_name:'',
            mail_content: '',
            mail_subject: '',
            recruiter_mail_subject:'',
            assign_status_name: '',
            mail_status_name: '',
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            items: [],

        };
    },
    props: ['taskId', 'heading'],
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-warning",
                2: "light-info",
                3: "light-success",
                4: "light-info",
                5: "light-info",
            };

            return (status) => statusColor[status];
        },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        },

    },

    mounted() {
        this.getResumeStatusById();

    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        async getResumeStatusById() {
            const ReqData = {
                id: this.$route.params.id,
            };
            const response = await ServiceApi.GetResumeStatusById(ReqData);
            if (response.code == '200') {
                this.name = response.data.name;
                this.description = response.data.description;
                this.priority = response.data.priority;
                this.mail_content = response.data.mail_content;
                this.mail_subject = response.data.mail_subject;
                this.recruiter_mail_subject = response.data.recruiter_mail_subject;
                this.recruiter_mail_content = response.data.recruiter_mail_content;
                if (response.data.assign_status == 1) {
                    this.assign_status_name = 'YES';

                }
                else {
                    this.assign_status_name = 'NO';
                }
                this.assign_status = { value: response.data.assign_status, text: this.assign_status_name };

                if (response.data.mail_status == 1) {
                    this.mail_status_name = 'YES';

                }
                else {
                    this.mail_status_name = 'NO';
                }
                this.mail_status = { value: response.data.mail_status, text: this.mail_status_name };


                if (response.data.recruiter_mail_status == 1) {
                    this.recruiter_mail_status_name = 'YES';

                }
                else {
                    this.recruiter_mail_status_name = 'NO';
                }
                this.recruiter_mail_status = { value: response.data.recruiter_mail_status, text: this.recruiter_mail_status_name };



            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }

        },


    }
};

</script>
<style>
.p-dot {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

ul {
    padding-left: 0px;
    list-style-type: unset;
    margin-bottom: 0;
}</style>