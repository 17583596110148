<template>
    <div>
        <Breadcrumbs main="Departments" title="Edit Department" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_name">Name</label>
                                        <b-form-input type="text" id="c_form_name" v-model="nameValue"
                                            :state="c_form_result.name" placeholder="Name"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.name">
                                        </b-form-valid-feedback>
                                    </div>
                                    <!-- <div class="col-md-6 mb-3">
                                        <label for="c_form_company">Company</label>

                                        <v-select
                                            v-model="CompanyValue"
                                            label="name"
                                            :options="Companies"
                                           
                                        />

                                    </div> -->

                                </div>
                            
                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from '@/services/ServiceApi.js';
import vSelect from 'vue-select'

export default {
    components: {
      vSelect,
   
    },
    data() {
        return {
          
            nameValue:null,
            // CompanyValue:null,
            // Companies:[],
            success:false,
            c_form: {
                name: '',
                //company: ''
               
            },
            c_form_result: {
                name: null,
                //company: null
              
            },


        };
    },
    computed: {
   

    },
    mounted() {
        // Set the initial number of items
        //this.GetCompanies();
        this.getDepartmentById()
        
    },
    methods: {

        onCustomStyleSubmit(evt) {
            evt.preventDefault();

            if (this.nameValue !== '') {
                this.success = true;
                this.c_form_result.name = true;
            } else {
                this.c_form_result.name = false;
                this.success = false;
                this.$toasted.show(' Please enter Department ', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });

            }

            // if (this.CompanyValue !== null) {
            //     this.success = true;
               
            // } else {
            //  this.$toasted.show('Company is mandatory field', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
            //  this.success = false;
               
            // }

            this.saveDepartment();
          
        },

        saveDepartment() {

            if (this.success == true) {

                const dept_data = {
                    id: this.$route.params.id,
                    name: this.nameValue,
                    //company:this.CompanyValue.company_id
                }
                
                this.updateDepartment(dept_data)
            }
        },
        // async GetCompanies() {
        //   const response = await Service.GetCompanies();
        //     if (response.code =='200') {

        //       this.Companies = response.data;

        //     }
        // },
        async updateDepartment(dept_data) {
            const response = await ServiceApi.UpdateDepartment(dept_data);
            if (response.code == '200') {
                this.$toasted.show(' Department updated successfully ', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/department');
            }
            else if (response.code == '400') {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getDepartmentById(){
            const ReqData = {
                 id: this.$route.params.id,
            };
            const response = await ServiceApi.GetDepartmentById(ReqData);

            if (response.code == '200') {

                this.nameValue = response.data.name;
                //this.CompanyValue = { name: response.data.company, company_id: response.data.company_id };
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }


        }
      

    }
};
</script>
<style lang="scss">
@import "vue-select/dist/vue-select.css";
</style>