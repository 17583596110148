<template>
    <!-- <v-app> -->
    <div>
        <Breadcrumbs main="Generate Attendance" title="Generate Attendance" />
       
     
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <b-col xl="3">
                                   
                                    <!-- <b-form-input type="month" value="2015-01" v-model="month"></b-form-input> -->
                                    <datepicker :format="customFormatter"  input-class="datepicker-here form-control digits"
                                        v-model="date" :disabled-dates="state.disabledDates" ></datepicker>
                                        <div
                             v-if="pinvaliddate"
                              class="feedbacksd"
                            >
                              date is required
                            </div>
                                </b-col>
                                <b-col xl="3">
                                    <v-select v-model="to_users" label="name" placeholder="Users" :options="users"
                                        :multiple="false" />
                                        <div
                             v-if="pinvaliduser"
                              class="feedbacksd"
                            >
                              user is required
                            </div>
                      
                                </b-col>
                                <b-col xl="3" class="load-style">
                                    <b-button id="default-primary" variant="primary" @click="Generate()">
                                        Generate
                                    </b-button>
                                   
                                </b-col>
                            </b-row>
                        </div>

                    </div>

                </div>

            </div>
        </div>



    </div>
    <!-- </v-app> -->
</template>

<script>
import ServiceApi from '@/services/ServiceApi.js';
import Service from '@/services/Service.js';
import Swal from "sweetalert2";
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'


export default {
    components: {
        vSelect,
        Datepicker
    },

    data() {
        return {
            datalayout: JSON.parse(localStorage.getItem('loginDetails')),
            data:this.$store.state.PinVery.token,
            // data:'byg0hJfFQDVf3ygBRxXWtb6u-nqEBW70tGV2Yy7t6YHrPKOccu730X3yEiJRYwtJ',
            pinvaliduser:null,
            pinvaliddate:null,
            users:[],
            to_users:null,
            date:null,
            state:{ disabledDates: {from: new Date()}}
        };
    },
    computed: {

       
    },
    mounted() {
        if (this.data == null) {
            this.$router.push('/home/verify-passcode');
        }
        this.retrieveEmployees();
    },
    methods: {
        Generate(){
            if (this.to_users !=null && this.date !=null) {
                this.pinvaliduser = false;
                this.pinvaliddate = false;
               var req = {
                    date:moment(this.date).format('Y-MM-DD'),
                    id:this.to_users.id
                }
                this.generateAttendance(req);
            }
            if(this.to_users ==null){
                this.pinvaliduser = true;
            }else{
                this.pinvaliduser = false;
            }
            if(this.date ==null){
                this.pinvaliddate = true;
            }else{
                this.pinvaliddate = false;
            }

        },
        customFormatter(date) {
            return moment(date).format('Y-MM-DD');
        },
        async retrieveEmployees() {
            const response = await Service.GetEmployeesForReport();
            if (response.code == "200") {
                this.users = response.data

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async generateAttendance(req) {
            const response = await ServiceApi.generateAttendanceApi(req);
            if (response.code == "200") {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }else if (response.code == "400") {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
        },

    }
};
</script>
<style>
.feedbacksd {
    display: block !important;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
}

</style>