<template>
    <div>
        <!-- <Breadcrumbs main="Leave" title="Apply Leave" /> -->
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onFormSubmit">
                                <div class="form-row">
                                    <div class="row">
                                        <!-- <div class="col-md-10">
                                            <label for="c_form_departments">Employee</label>
                                            <v-select
                                                v-model="employee"
                                                label="name"
                                                :options="employees"
                                            />
                                        </div> -->
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-label">Start date</label>
                                                <datepicker :format="customFormatter"
                                                    input-class="datepicker-here form-control digits"
                                                    :open-date="new Date()"
                                                    :disabledDates="disabledDates"
                                                    v-model="start_date" @input="hideFirstSession"></datepicker>
                                                <!-- <b-form-input type="date" value="2018-01-01" v-model="start_date"></b-form-input> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="hideSession1">
                                            <div class="form-group">
                                                <label class="form-label">Session</label>
                                                <!-- <b-form-input type="number"  v-model="duration"></b-form-input> -->
                                                <v-select v-model="session1" label="name" :options="sessions" />
                                            </div>
                                        </div>
                                        <div class="col-md-4"></div>

                                    </div>
                                    <!-- <div class="col-md-6">
                                        <label class="form-label">Reason</label>
                                        <textarea class="form-control" rows="4" placeholder="Enter leave reason" v-model="leave_reason">
                                        </textarea>
                                    </div> -->
                                </div>

                                <div class="form-row">
                                    <div class="row">



                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-label">End date</label>
                                                <datepicker :format="customFormatter"
                                                    input-class="datepicker-here form-control digits"
                                                    :open-date="new Date()"
                                                    :disabledDates="disabledDates"
                                                    v-model="end_date" @input="hideFirstSession"></datepicker>
                                                <!-- <b-form-input type="date" value="2018-01-01" v-model="end_date"></b-form-input> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-label">Session</label>
                                                <!-- <b-form-input type="number"  v-model="duration"></b-form-input> -->
                                                <v-select v-model="session2" label="name" :options="sessions" />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6">
                                        <label class="form-label">Reason</label>
                                        <textarea class="form-control" rows="4" placeholder="Enter leave reason" v-model="leave_reason">
                                        </textarea>
                                    </div> -->
                                </div>
                                <div class="form-row">

                                    <div class="col-md-4 mb-3">
                                        <label for="c_form">Leave Type</label>
                                        <v-select v-model="leaveType" label="name" :options="leave_types"
                                            v-on:input="setname()" />
                                    </div>
                                    <div class="col-md-4" v-if="leave_name == 'Sick Leave'">
                                        <label for="file">File</label>
                                        <!-- <input type="file" :state="supported_file_state" @change="onFileChange"
                                            placeholder="Choose file..." drop-placeholder="Drop file here..." /> -->

                                        <b-form-file v-model="file" :state="supported_file_state"
                                            placeholder="Choose file..." drop-placeholder="Drop file here..."
                                            type="file" @change="onFileChange"></b-form-file>

                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <label class="form-label">Reason</label>
                                        <textarea class="form-control" rows="4" placeholder="Enter leave reason"
                                            v-model="leave_reason">
                                        </textarea>
                                    </div>
                                </div>
                                <b-button v-if="loader == false" type="submit" variant="primary">Submit</b-button>

                                <div v-if="loader == true" class="loader-box">
                                    <div class="loader-15"></div>
                                </div>

                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from "@/services/ServiceApi.js";
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'
import S3 from "aws-s3";
import Swal from "sweetalert2";

export default {

    components: {
        vSelect,
        Datepicker,
    },

    data() {
        return {
            disabledDates: {
            to: new Date(Date.now() - 8640000)
            },
            loader: false,
            hideSession1: true,
            success: false,
            file: null,
            file_name: null,
            leave_name: null,
            leave_reason: null,
            start_date: null,
            end_date: null,
            leaveType: null,
            leave_types: [],
            holidays: [],
            holidays_arr: [],
            duration: null,
            sessions: [
            ],
            session1: null,
            session2: null,
            emp_ref_no: null,
            order_arr: [],
        };
    },
    computed: {
        supported_file_state() {
            if (this.file === null) {
                return false;
            } else {
                return true;
            }
        },
    },
    props: ['childVal', 'callTest'],
    mounted() {
        this.getLeaveTypes();
        this.getSessions();
        this.getAwsS3Config();
        this.getUserById();
        this.getHolidays();

    },
    methods: {
        hideFirstSession() {
            if (this.start_date != null && this.end_date != null) {
                if (this.customFormatter(this.start_date) == this.customFormatter(this.end_date)) {
                    this.hideSession1 = false;
                    this.session1 = null
                }else{
                    this.hideSession1 = true;
                }
            }
        },
        async getAwsS3Config() {
            const response = await Service.getAwsS3Configs();
            if (response.code == "200") {
                this.s3 = response.data;
            }
        },
        customFormatter(date) {
            return moment(date).format('Y-MM-DD');
        },

        onFormSubmit(evt) {
            evt.preventDefault();

            if ((this.leave_reason == null) || (this.start_date == null) || (this.end_date == null) || (this.leaveType == null)) {

                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else if (this.customFormatter(this.end_date) < this.customFormatter(this.start_date)) {

                this.$toasted.show(' From Date should be less than To Date ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {

                var from_date = this.customFormatter(this.start_date);
                var to_date = this.customFormatter(this.end_date);


                if (from_date === to_date) {
                    if (this.session1 && this.session2) {
                        if (this.session1.id == this.session2.id) {
                            this.applyLeave();
                        } else {
                            this.$toasted.show("Can't select different session for 1 day", { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                        }
                    }
                    else {
                        this.applyLeave();
                    }
                } else {
                    var larger = Math.max(...this.order_arr)
                    var smaller = Math.min(...this.order_arr)
                    if (this.session1 && this.session2) {
                        if ((this.session1.order == larger) && (this.session2.order == smaller)) {
                            this.applyLeave();
                        } else {
                            this.$toasted.show(' Please select correct sessions 111', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                        }
                    } else {
                        if (this.session1) {
                            if (this.session1.order != larger) {
                                this.$toasted.show(' Please select correct sessions 222', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                            } else {
                                this.applyLeave();
                            }
                        } else if (this.session2) {
                            if (this.session2.order != smaller) {
                                this.$toasted.show(' Please select correct sessions 33', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                            } else {
                                this.applyLeave();
                            }
                        } else {
                            this.applyLeave();
                        }

                    }
                }
            }


        },

        onFileChange(e) {

            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }

            let file = files[0];
            const now = Date.now();
            const config = {
                bucketName: this.s3.bucketName,
                dirName: "leave_docs" /* optional */,
                region: this.s3.region,
                accessKeyId: this.s3.accessKeyId,
                secretAccessKey: this.s3.secretAccessKey,
                s3Url: "https://bktqaptive.s3.amazonaws.com" /* optional */,
            };

            const S3Client = new S3(config);
            S3Client.uploadFile(file, this.emp_ref_no+now).then((response) => {
                this.file = response.location;
            });

        },

        async getUserById() {
            const ReqData = {
                id: this.$route.params.id,
            };

            const response = await ServiceApi.GetUserById(ReqData);

            if (response.code == "200") {
                this.emp_ref_no = response.data.name;
            }
        },
        applyLeave() {

            // this.holidays.forEach(dates => {
            //     var day = moment(dates.date)
            //     this.holidays_arr.push(day.format('MM/DD/YYYY'));

            // });

            // var date1 = new Date(this.customFormatter(this.start_date));
            // var date2 = new Date(this.customFormatter(this.end_date));

            // var Difference_In_Time = date2.getTime() - date1.getTime();

            // var getDaysBetweenDates = function (startDate, endDate) {
            //     var now = startDate.clone(), dates = [];

            //     while (now.isSameOrBefore(endDate)) {
            //         dates.push(now.format('MM/DD/YYYY'));
            //         now.add(1, 'days');
            //     }
            //     return dates;
            // };

            // var startDate = moment(date1);
            // var endDate = moment(date2);

            // var dateList = getDaysBetweenDates(startDate, endDate);

            // var date_arr1 = []
            // var date_arrr = []

            // dateList.forEach(dates => {
            //     let date = new Date(dates);
            //     let day = date.toLocaleString('en-us', { weekday: 'long' });
            //     if ((day != 'Saturday') && (day != 'Sunday')) {
            //         date_arr1.push(dates);
            //     }

            // });
            // date_arrr = date_arr1.filter(val => !this.holidays_arr.includes(val));

            // var result_obj = this.getDateDetails(dateList)
            // var result_obj2 = this.getDateDetails(date_arrr)

            const leave_data = {
                employee_id: this.$route.params.id,
                reason: this.leave_reason,
                session1: this.session1 ? this.session1.id : '',
                session2: this.session2 ? this.session2.id : '',
                //no_of_days: result_obj.diff_in_days,
                type: this.leaveType.id,
                from_date: this.customFormatter(this.start_date),
                to_date: this.customFormatter(this.end_date),
                //days_without_holiday: result_obj2.date_arr_length,
                file: this.file,
                sandwichConf: false
                //month_array: result_obj.month_arr,
                //month_array_without: result_obj2.month_arr,
            }


            this.addLeave(leave_data);

        },
        getDateDetails(dateList) {
            var date1 = new Date(this.customFormatter(this.start_date));
            var date2 = new Date(this.customFormatter(this.end_date));
            var Difference_In_Time = date2.getTime() - date1.getTime();

            var month_arr = [];
            var month;
            var year;
            var x = 0;
            var result_arr = [];
            dateList.forEach(dates => {
                month = Number(dates.split('/')[0]);
                year = dates.split('/')[2]
                // month_arr.push(month);
                month_arr.push({ month_val: month, year_val: year });

                if (x != dates.split('/')[0]) {
                    result_arr.push(dates.split('/')[0]);
                    x = dates.split('/')[0];
                } else {

                }
            });

            var month_array = []

            month_arr.sort();

            var current = null;
            var cnt = 0;
            for (var i = 0; i < month_arr.length; i++) {
                if (month_arr[i].month_val != current) {

                    if (cnt > 0) {
                        month_array.push({ month: current, value: cnt, year: month_arr[0].year_val });
                    }
                    current = month_arr[i].month_val;
                    cnt = 1;
                } else {
                    cnt++;
                }
            }
            if (cnt > 0) {
                month_array.push({ month: current, value: cnt, year: month_arr[month_arr.length - 1].year_val });
            }

            var date_arr = []
            dateList.forEach(dates => {
                let date = new Date(dates);
                let day = date.toLocaleString('en-us', { weekday: 'long' });
                if ((day != 'Saturday') && (day != 'Sunday')) {
                    date_arr.push(day);
                }

            });

            if (this.session1 && this.session2) {
                var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24));
                var date_arr_length = date_arr.length - 1;
                if (result_arr.length == 2) {
                    month_array[0].value = month_array[0].value - .5;
                    month_array[1].value = month_array[1].value - .5;
                } else if (result_arr.length == 1) {
                    month_array[0].value = month_array[0].value - 1;
                }
            } else if (this.session1 || this.session2) {
                var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24)) + .5;
                var date_arr_length = date_arr.length - .5;
                if (this.session1) {
                    month_array[0].value = month_array[0].value - .5;
                } else if (this.session2) {
                    if (result_arr.length == 2) {
                        month_array[1].value = month_array[1].value - .5;
                    } else if (result_arr.length == 1) {
                        month_array[0].value = month_array[0].value - .5;
                    }
                }
            } else {
                var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24)) + 1;
                var date_arr_length = date_arr.length;
            }
            var obj = { 'date_arr_length': date_arr_length, 'diff_in_days': Difference_In_Days, 'month_arr': month_array };
            return obj;
        },
        async addLeave(leave_data) {
            this.loader = true;
            const response = await Service.AddLeave(leave_data);
            if (response.code === 200) {
                this.loader = false;
                this.$toasted.show(' Leave applied successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                // this.$router.push('/home/employee-view/' + this.$route.params.id);
                this.childVal = 0;
                this.$emit('update:childVal', this.childVal);
                this.$emit('callTest');
            } else if (response.code === 206) {

                Swal.fire({
                    title: "Proceed with sandwich leave?",
                    text: "This will affect the leave count! The Extra leave will be " + response.data,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Proceed!",

                }).then( async (result) => {
                    if (result.isConfirmed) {
                        leave_data.sandwichConf = true
                        const response = await Service.AddLeave(leave_data);
                        if (response.code === 200) {
                            this.loader = false;
                            this.$toasted.show(' Leave applied successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                            // this.$router.push('/home/employee-view/' + this.$route.params.id);
                            this.childVal = 0;
                            this.$emit('update:childVal', this.childVal);
                            this.$emit('callTest');
                        }
                    }
                });
                this.loader = false;
                this.childVal = 0;
                this.$emit('update:childVal', this.childVal);
                this.$emit('callTest');

            } else if (response.code === 201) {
                this.loader = false;
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 3000 });
            }
        },
        async getLeaveTypes() {
            const ReqData = {
                id: this.$route.params.id,
            };

            const response = await Service.GetLeaveTypes(ReqData);
            if (response.code == '200') {

                this.leave_types = response.data;
            }
        },
        async getHolidays() {
            const response = await ServiceApi.GetHolidays();
            if (response.code == '200') {

                var date_obj = {};
                var holiday_arr = [];

                response.data.forEach(element => {

                    date_obj = {

                        date: element.date,
                    }
                    holiday_arr.push(date_obj);

                });
                this.holidays = holiday_arr;

            }
        },
        async setname() {
            this.leave_name = this.leaveType.name;
        },
        async getSessions() {
            const response = await ServiceApi.GetSessions();
            if (response.code == '200') {
                var sess_obj = {};
                var sess_arr = [];

                var i = 1;
                response.data.forEach(element => {

                    sess_obj = {
                        id: element.id,
                        name: element.name,
                        order: i,
                    }
                    this.order_arr.push(i);
                    sess_arr.push(sess_obj);
                    i++;
                });
                this.sessions = sess_arr;
            }
        }
    }
};
</script>

<style>
.loader-box {
    height: 50px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;
}
</style>
