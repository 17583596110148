<template>
  <li class="mega-menu outside">
  </li>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'BonusUI',
    data() {
      return {
        openbonusUI: false,
      };
    },
    computed: {
      ...mapState({
        megamenuItems: (state) => state.menu.megamenu,
      }),
    },
    methods: {
      openmegamenu() {
        this.openbonusUI = !this.openbonusUI;
      },
      closeBonusUI() {
        this.openbonusUI = false;
      },
      setNavActive(item) {
        this.$store.dispatch('menu/setBonusNavActive', item);
      },
    },
  };
</script>
