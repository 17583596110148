<template>
    <div>
        <Breadcrumbs main="Resume Status" title="Edit Resume Status" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="name">Status Name</label>
                                        <b-form-input type="text" id="name" v-model="name" :state="c_form_result.name"
                                            placeholder="Name"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.name">
                                        </b-form-valid-feedback>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="priority">Priority</label>
                                        <b-form-input type="number" id="priority" v-model="priority"
                                            :state="c_form_result.priority" placeholder="priority"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.priority">
                                        </b-form-valid-feedback>

                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_assign_status">Assign status</label>
                                        <v-select v-model="assign_status" label="text" :options="types" />

                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="description">Description</label>
                                        <textarea class="form-control" rows="1" placeholder="Enter Description"
                                            v-model="description">
                                        </textarea>
                                        <b-form-valid-feedback :state="c_form.description">
                                        </b-form-valid-feedback>
                                    </div>


                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_assign_status">Applicant Mail status</label>
                                        <v-select v-model="mail_status" label="text" :options="types" />
                                    </div>


                                    <div class="col-md-6 mb-3" v-if="assign_status.value == '1'">
                                        <label for="c_form_assign_status">Recruiter Mail status</label>
                                        <v-select v-model="recruiter_mail_status" label="text" :options="types" />
                                    </div>

                                    <div class="col-md-6 mb-3" v-if="assign_status.value == '2'">
                                        <div>
                                            <label for="c_form_assign_status">Interview Date</label>
                                            <v-select v-model="interview_status" label="text" :options="types" />
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <div v-if="this.mail_status.value == 1">

                                            <label for="mail_subject">Mail Subject</label>
                                            <b-form-input type="text" id="mail_subject" v-model="mail_subject"
                                                :state="c_form_result.mail_subject"
                                                placeholder="Mail Subject"></b-form-input>
                                            <b-form-valid-feedback :state="c_form_result.mail_subject">
                                            </b-form-valid-feedback>
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <div v-if="this.recruiter_mail_status.value == 1 && assign_status.value == '1'">

                                            <label for="recruiter_mail_subject">Recruiter Mail Subject</label>
                                            <b-form-input type="text" id="recruiter_mail_subject"
                                                v-model="recruiter_mail_subject"
                                                :state="c_form_result.recruiter_mail_subject"
                                                placeholder="Recruiter Mail Subject"></b-form-input>
                                            <b-form-valid-feedback :state="c_form_result.recruiter_mail_subject">
                                            </b-form-valid-feedback>
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <div v-if="this.mail_status.value == 1">
                                            <label for="c_form_assign_status">Applicant Mail content</label>
                                            <vue-editor v-model="mail_content"></vue-editor>
                                        </div>

                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <div v-if="this.recruiter_mail_status.value == 1 && assign_status.value == '1'">
                                            <label for="c_form_assign_status">Recruiter Mail content</label>
                                            <vue-editor v-model="recruiter_mail_content"></vue-editor>
                                        </div>

                                    </div>

                                </div>

                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from '@/services/ServiceApi.js';
import vSelect from 'vue-select'
import { VueEditor } from 'vue2-editor';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'

export default {

    components: {
        vSelect,
        VueEditor,
    },

    data() {
        return {
            success: false,
            name: '',
            description: '',
            priority: '',
            assign_status: '',
            mail_status: '',
            mail_content: '',
            recruiter_mail_status: '',
            interview_status: null,
            recruiter_mail_content: '',
            mail_subject: '',
            recruiter_mail_subject: '',
            c_form: {
                name: '',
                description: '',
                priority: '',
                assign_status: '',
                mail_status: '',
                mail_content: '',
                recruiter_mail_status: '',
                recruiter_mail_content: '',
                mail_subject: '',
                recruiter_mail_subject: '',
            },
            c_form_result: {
                name: null,
                description: null,
                priority: null,
                assign_status: null,
                mail_status: null,
                mail_content: null,
                recruiter_mail_status: null,
                recruiter_mail_content: null,
                mail_subject: null,
                recruiter_mail_subject: null
            },
            types: [

                { value: "1", text: "YES" },
                { value: "2", text: "NO" },
            ],

        };
    },
    computed: {

    },
    mounted() {
        this.getResumeStatusById();

    },
    methods: {
        async getResumeStatusById() {
            const ReqData = {
                id: this.$route.params.id,
            };
            const response = await ServiceApi.GetResumeStatusById(ReqData);
            if (response.code == '200') {
                this.name = response.data.name;
                this.description = response.data.description;
                this.priority = response.data.priority;
                this.mail_content = response.data.mail_content;
                this.recruiter_mail_content = response.data.recruiter_mail_content;
                this.mail_subject = response.data.mail_subject;
                this.recruiter_mail_subject = response.data.recruiter_mail_subject;
                if (response.data.assign_status == 1) {
                    this.assign_status_name = 'YES';
                }
                else {
                    this.assign_status_name = 'NO';
                }
                this.assign_status = { value: response.data.assign_status, text: this.assign_status_name };

                if (response.data.mail_status == 1) {
                    this.mail_status_name = 'YES';
                }
                else {
                    this.mail_status_name = 'NO';
                }
                this.mail_status = { value: response.data.mail_status, text: this.mail_status_name };

                if (response.data.recruiter_mail_status == 1) {
                    // this.recruiter_mail_status_name = 'YES';
                    this.recruiter_mail_status = { value: "1", text: "YES" }
                }
                else {
                    // this.recruiter_mail_status_name = 'NO';
                    this.recruiter_mail_status = { value: "2", text: "NO" }
                }
                // this.recruiter_mail_status = { value: response.data.recruiter_mail_status, text: this.recruiter_mail_status_name };

                if (response.data.inter_status == 1) {
                    this.interview_status = { value: "1", text: "YES" }

                }
                else {
                    this.interview_status = { value: "2", text: "No" }
                }
                // this.recruiter_mail_status = { value: response.data.recruiter_mail_status, text: this.recruiter_mail_status_name };

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }

        },

        onCustomStyleSubmit(evt) {
            evt.preventDefault();

            if (this.name == '') {
                this.success = false;
                this.$toasted.show(' Please enter status name', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }

            else if (this.priority == '') {
                this.success = false;
                this.$toasted.show(' Please enter priority', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.priority < 0) {
                this.success = false;
                this.$toasted.show(' Please choose positive numbers', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.description == '') {
                this.success = false;
                this.$toasted.show(' Please enter description', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.assign_status.value == '') {
                this.success = false;
                this.$toasted.show(' Please select assign status', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.mail_status.value == '') {
                this.success = false;
                this.$toasted.show(' Please select applicant mail status', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.mail_status.value == 1 && this.mail_content == '') {

                this.success = false;
                this.$toasted.show(' Please enter applicant mail content', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.assign_status.value == "1") {

                if (this.recruiter_mail_status == '') {
                    this.success = false;
                    this.$toasted.show(' Please select recruiter mail status', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                }
                else if (this.recruiter_mail_status.value == '') {
                    this.success = false;
                    this.$toasted.show(' Please select recruiter mail status', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                }
                else if (this.recruiter_mail_status.value == 1 && this.recruiter_mail_content == '') {

                    this.success = false;
                    this.$toasted.show(' Please enter recruiter mail content', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                }
                // else if (this.recruiter_mail_subject == '') {

                //     this.success = false;
                //     this.$toasted.show(' Please enter recruiter mail subject', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                // }
                else {
                    this.success = true;
                }
            }
            else if (this.assign_status.value == "1" && this.interview_status == '') {

                this.success = false;
                this.$toasted.show(' Please enter Interview status', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else {
                this.success = true;
            }

            this.saveResumeStatus();
        },
        saveResumeStatus() {

            if (this.success == true) {
                const status_data = {
                    id: this.$route.params.id,
                    name: this.name,
                    description: this.description,
                    priority: this.priority,
                    mail_status: this.mail_status.value,
                    inter_status: this.assign_status.value == '1' ? '1': this.interview_status.value,
                    mail_content: this.mail_content,
                    recruiter_mail_status: this.assign_status.value == '1' ? this.recruiter_mail_status.value : '',
                    recruiter_mail_content: this.assign_status.value == '1' ? this.recruiter_mail_content : '',
                    mail_subject: this.mail_subject,
                    recruiter_mail_subject: this.assign_status.value == '1' ? this.recruiter_mail_subject : '',
                    assign_status: this.assign_status.value,
                }
                this.updateResumeStatus(status_data)
                // console.log(status_data);
            }
        },
        async updateResumeStatus(status_data) {
            const response = await ServiceApi.UpdateResumeStatus(status_data);
            if (response.code == '200') {
                this.$toasted.show(' Resume status updated successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/resume_status');
            }
            else if (response.code == '400') {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },

    }
};
</script>
