<template>
    <div><br>
        <Breadcrumbs main="Report" title="Employee List" />
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <b-col xl="3">
                                    <datepicker :minimumView="'month'" input-class="datepicker-here form-control digits"
                                        v-model="date"></datepicker>
                                </b-col>
                                <b-col xl="3">
                                    <v-select v-model="to_users" label="name" placeholder="Users" :options="users" />
                                </b-col>
                                <b-col xl="3" class="load-style">
                                    <b-button id="default-primary" v-if="!isLoading" variant="primary" @click="Generate()">
                                        Generate
                                    </b-button>
                                    <div class="loader-box " v-else>
                                        <div class="loader-15"></div>
                                    </div>
                                </b-col>
                            </b-row>


                        </div>

                    </div>

                    <div class="card">
                        <div class="card-body">


                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                </b-table>
                            </div>
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>

                        </div>

                    </div>
                </div>

            </div>
        </div>


    </div>
</template>
  
<script>
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'
import Service from '@/services/Service.js';
export default {
    name: 'app',
    components: {
        Datepicker,
        vSelect
    },
    data() {
        return {
            date: moment('01-' + moment().format('MMM-Y')).format('Y-MM-DD'),
            tablefields: [
                { key: 'name', label: 'Name', sortable: true, },
                { key: 'date', label: 'Date', sortable: true, },
                { key: 'time', label: 'Time', sortable: true, },
                { key: 'reason', label: 'Reason', sortable: true, },
            ],

            items: [],
            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            users: [],
            to_users: null,
            isLoading: false,
        };
    },
    mounted() {
        this.isLoading = true;
        var req = {

            id: this.to_users,
            date: moment('01-' + moment().format('MMM-Y')).format('Y-MM-DD')

        }
        this.retrieveEmployees();
        this.getLateComers(req);
    },
    methods: {
        customFormatter(date) {
            // return moment(date).startOf('month');
        },
        async retrieveEmployees() {
            const response = await Service.GetEmployees();
            if (response.code == "200") {
                this.users = response.data

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        Generate() {
            
            // this.isLoading = true;
            var req = {

                id: this.to_users ? this.to_users.id : null,
                date: moment(this.date).format('Y-MM-DD')

            }
            this.getLateComers(req)
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getLateComers(req) {
            // alert(1)
            const response = await Service.getLateComersApi(req);
            if (response.code == '200') {
                this.isLoading = false;
                this.items = response.data;
                this.totalRows = response.data.length;
            } else if (response.code == "403") {
                this.isLoading = false;
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
            // this.isLoading = false;
        },
    }

}
</script>
  
<style lang="scss" scoped>
    .load-style .loader-box  {

height: 38px;
text-align: left !important;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: left !important;
-ms-flex-align: left !important;
align-items: left !important;
vertical-align: left !important;
-webkit-box-pack: left !important;
-ms-flex-pack: left !important;
justify-content: left !important;
-webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;

}
</style>
  