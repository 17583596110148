<template>
    <div>
        <div v-if="!visible">
            <div class="chat-scroll">
                <div class="chat-box">
                    <div>

                        <div class="">

                            <div class="people-list " id="people-list">
                                <ul class="list" :style="`max-height: none;`">
                                    <div class="clearfix chat-lists" v-for="(intimation, index) in activeIntimations"
                                        :key="intimation.id" @click="setActiveuser(intimation.id), visible = true"
                                        :class="{ 'selected': intimation.selected }">
                                        <li>
                                            <div>
                                                <img class="rounded-circle user-image" src="@/assets/group.png" alt="" />

                                                <div class="about">
                                                    <div class="name name-chat">{{ intimation.subject }} <span v-if="intimation.newMsg" style="width: 20px; height: 50px;" class=" chat-notification">
                                                        {{ intimation.newMsg }}
                                                    </span></div>
                                                    <!-- <div class="status">{{ intimation.text }}</div> -->
                                                </div>
                                                <!-- <p>
                                                                        <span class="font-danger away rounded-circle">
                                                                            {{ intimation.newMsg ? intimation.newMsg : '' }}
                                                                        </span>
                                                                    </p> -->
                                            </div>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div v-if="visible">
            <div class="chat-box">
                <Chat :participants="participants" :myself="myself" :messages="messages" :chat-title="chatTitle"
                    :placeholder="placeholder" :colors="colors" :border-style="borderStyle"
                    :hide-close-button="hideCloseButton" :close-button-icon-size="closeButtonIconSize"
                    :submit-icon-size="submitIconSize" :send-images="false"
                    :load-more-messages="toLoad.length > 0 ? loadMoreMessages : null" :async-mode="asyncMode"
                    :scroll-bottom="scrollBottom" :display-header="true"
                    :profile-picture-config="profilePictureConfig" :timestamp-config="timestampConfig"
                    :link-options="linkOptions"
                     @onMessageSubmit="onMessageSubmit"
                    @onType="onType" @onClose="onClose" />
            </div>
        </div>

        <b-modal id="modal-6" v-model="modalShow" hide-footer>
            <div class="row">
                <div class="col-12">
                    <form>
                        <label class="col-form-label" for="message-text">Subject:</label>
                        <input class="form-control" type="text" placeholder="Subject" v-model="subject">
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <form>
                        <label class="form-label">Members</label>
                        <v-select v-model="to_users" label="name" :options="users" :multiple="true" />
                    </form>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-12">
                    <form>
                        <label class="col-form-label" for="message-text">Body:</label>
                        <b-form-textarea id="textarea" v-model="text" placeholder="Enter Description"
                            rows="3"></b-form-textarea>
                    </form>
                </div>
            </div> -->
            <div class="row">
                <div class="col-6">
                    <b-button class="mt-3 " variant="danger" @click="hideModal">Close</b-button>
                </div>
                <div class="col-6">
                    <b-button class="mt-3 float-right" variant="primary" @click="handleSubmit()">Save
                        Intimation</b-button>
                </div>
            </div>
        </b-modal>
</div>
</template>
<script>
import Service from '@/services/Service.js';
import { Chat } from 'vue-quick-chat';
import 'vue-quick-chat/dist/vue-quick-chat.css';
import vSelect from 'vue-select'
import S3 from "aws-s3";

export default {
    components: {
        Chat,
        vSelect,
    },
    data() {
        return {
            NewMsg: [],
            file: null,
            img_url: null,
            img: null,
            modalShow: false,
            to_users: [],
            subject: '',
            text: '',
            // submitImageIconSize: 25,
            visible: false,
            intimation_id: null,
            activeIntimations: [],
            users: [],
            participants: [],
            myself: {'id': null, 'name': 'You'},
            messages: [],
            chatTitle: 'My chat title',
            placeholder: 'send your message',
            colors: {
                header: {
                    bg: '#7366ff',
                    text: '#fff'
                },
                message: {
                    myself: {
                        bg: '#fff',
                        text: '#bdb8b8'
                    },
                    others: {
                        bg: '#7366ff',
                        text: '#fff'
                    },
                    messagesDisplay: {
                        bg: '#f7f3f3'
                    }
                },
                submitIcon: '#b91010',
                submitImageIcon: '#b91010',
            },
            borderStyle: {
                topLeft: "10px",
                topRight: "10px",
                bottomLeft: "10px",
                bottomRight: "10px",
            },
            hideCloseButton: false,
            submitIconSize: 25,
            closeButtonIconSize: "20px",
            asyncMode: false,
            toLoad: [],
            scrollBottom: {
                messageSent: true,
                messageReceived: true,
            },
            displayHeader: true,
            profilePictureConfig: {
                others: true,
                myself: true,
                styles: {
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%'
                }
            },
            timestampConfig: {
                format: 'HH:mm',
                relative: false
            },

            linkOptions: {
                myself: {
                    className: 'myLinkClass',
                    events: {
                        click: function (e) {
                            // alert('Link clicked!');
                        },
                        mouseover: function (e) {
                            // alert('Link hovered!');
                        }
                    },
                    format: function (value, type) {
                        if (type === 'url' && value.length > 50) {
                            value = value.slice(0, 50) + '…';
                        }
                        return value;
                    }
                },
                others: {
                    className: 'othersLinkClass',
                    events: {
                        click: function (e) {
                            // alert('Link clicked!');
                        },
                        mouseover: function (e) {
                            // alert('Link hovered!');
                        }
                    },
                    format: function (value, type) {
                        if (type === 'url' && value.length > 50) {
                            value = value.slice(0, 50) + '…';
                        }
                        return value;
                    }
                }
            }
        }
    },
    created() {
        this.getAwsS3Config();
        this.retrieveEmployees();
        this.getFewMsg();
        this.intervalId = setInterval(() => {
            this.retrieveEmployees();
            // this.getFewMsg();
        }, 15000);
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
    methods: {
        async getAwsS3Config() {
            const response = await Service.getAwsS3Configs();
            if (response.code == "200") {
                this.s3 = response.data;
            }
        },
        onImageSelected(files, message) {
            // let src = 'https://149364066.v2.pressablecdn.com/wp-content/uploads/2017/03/vue.jpg'

            // this.messages.push(message);
            // this.sendImages(files.file,message);

            /**
             * This timeout simulates a requisition that uploads the image file to the server.
             * It's up to you implement the request and deal with the response in order to
             * update the message status and the message URL
             */
            // setTimeout((res) => {
            //     message.uploaded = true
            //     message.src = res.src
            // }, 3000, { src });
        },
        sendImages(img, message) {
            if (!img) {
                return;
            }
            const now = Date.now();

            let file = img;

            this.photo_url = URL.createObjectURL(file);
            const config = {
                bucketName: this.s3.bucketName,
                dirName: "user_photos" /* optional */,
                region: this.s3.region,
                accessKeyId: this.s3.accessKeyId,
                secretAccessKey: this.s3.secretAccessKey,
                s3Url: "https://bktqaptive.s3.amazonaws.com" /* optional */,
            };

            const S3Client = new S3(config);

            S3Client.uploadFile(file, now).then((response) => {

                this.file = response.location;

            });
            setTimeout(() => {
                this.sendMsg(this.file, message);
            }, 3000);

        },
        hideModal() {
            this.modalShow = false;
        },
        async handleSubmit() {
            if ((this.subject == '') || (this.to_users.length == 0)) {
                this.$toasted.show('Please enter required fields', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                this.modalShow = false;
                const ReqData = {
                    subject: this.subject,
                    users: this.to_users,
                    body: this.text,
                };
                const response = await Service.AddMessage(ReqData);
                this.subject = '';
                this.to_users = [];
                this.text = '';
                if (response.code == '200') {
                    // this.retrieveEmployees();
                    this.getIntimations();
                }
            }
        },
        async retrieveEmployees() {
            const response = await Service.GetEmployeeMsg();
            if (response.code == "200") {
                this.users = response.data
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getIntimations() {
            const response = await Service.GetIntimations();
            if (response.code == "200") {
                var newIntimation = response.data;
                newIntimation.forEach(element => {
                    this.NewMsg.forEach(element2 => {
                        if (element.id == element2.intimation.id) {
                            element.newMsg = element2.chat.length;
                        }
                    });
                });

                this.activeIntimations = newIntimation;
                this.setActiveuser(this.intimation_id);
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async setActiveuser(id) {
            const req = {
                inti_id: id,
            }
            const response = await Service.GetIntimationsById(req);
            if (response.code == "200") {
                if (Object.keys(response.data).length > 0) {
                    this.participants = response.data.mem_without;
                    this.messages = response.data.chat;
                    // this.toLoad = response.data.chat;
                    this.chatTitle = response.data.curr_inti.sub;

                }
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
            this.intimation_id = id;
        },
        onType: function (event) {
            //here you can set any behavior
        },
        loadMoreMessages(resolve) {
            setTimeout(() => {
                resolve(this.toLoad); //We end the loading state and add the messages
                //Make sure the loaded messages are also added to our local messages copy or they will be lost
                this.messages.unshift(...this.toLoad);
                this.toLoad = [];
            }, 1000);
        },
        onMessageSubmit: function (message) {
            this.sendMsg(message.content, message);
        },
        async sendMsg(msg, message) {
            const req_data = {
                inti_id: this.intimation_id,
                msg: msg,
            }
            const response = await Service.sendMessage(req_data);
            if (response.code == "200") {
                message.uploaded = true;
                // message.src = res.msg;
                this.setActiveuser(response.data);
            }
        },
        async getFewMsg() {
            const response = await Service.GetMessages();
            if (response.code == "200") {
                this.NewMsg = response.data;
                this.getIntimations()
            }
        },
        onClose() {
            this.visible = false;
        },
    }
}
</script>
<style>
.quick-chat-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    background: #f0eeee;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    overflow: hidden;
    height: 328px;
}

.chat-scroll {
    height: 306px;
    overflow: auto;
}
.chat-notification
{
    background-color: green;
    width: 21px !important;
    padding: 2px 6px;
    height: 21px;
    text-align: center;
    border-radius: 50%;
    color: #fff !important;
    margin-left: 7px;
}
</style>