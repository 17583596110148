<template>
    <div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="title">Project Title</label>
                                        <b-form-input type="text" id="title" v-model="title" placeholder="Project Title">
                                        </b-form-input>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="description">Project Description</label>
                                        <b-form-textarea type="text" id="description" v-model="description" placeholder="Project Description">
                                        </b-form-textarea>
                                    </div>
                                    <!-- <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Description</label>
                                           
                                         
                                            <vue-editor v-model="descrip"></vue-editor>

                                        </div>
                                    </div>
                                </div> -->
                                </div>
                                <div class="row">
                                    <div class="col" >
                                        <b-button type="submit" variant="primary">Submit</b-button>
                                    </div>
                                    <!-- <div  class="loader-box">
                                        <div class="loader-15"></div>
                                    </div> -->
                                </div>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import moment from "moment";
import { VueEditor } from 'vue2-editor';
export default {

    components: {
        vSelect,
        VueEditor
    },

    data() {
        return {
            loader: false,
            success: false,
            // startEndStatus: [
            //     { value: '1', text: 'Pending' },
            //     { value: '2', text: 'Start' },
            // ],
            title: '',
            description: '',
            project_id: null,
            // loginData: JSON.parse(localStorage.getItem('loginDetails')),
        };
    },
    props: ['phaseId'],
    computed: {
    },
    // created() {
    //     var head = 3;
    //     // this.heading = head;
    //     this.$emit('update:heading', head);
    //     this.getTasks();
    //     this.getUsers();
    // },
    methods: {
        onCustomStyleSubmit(evt) {
            evt.preventDefault();
            if (this.title == '' || this.description == null) {
                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                this.savePhase();
            }
        },
        savePhase() {
            const phase_data = {
                title: this.title,
                description: this.description,
                projectId: this.$route.params.id,
            };
            this.addPhase(phase_data)
        },
        async addPhase(phase_data) {
            this.loader = true;
            const response = await Service.AddPhase(phase_data);
            if (response.code == '200') {
                this.loader = false;
                this.$toasted.show(' Phases added successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });           
                this.$emit('update:phaseId', 4);
            } else if (response.code == "400") {
                this.loader = false;
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
        },
        // async getTasks() {
        //     const response = await Service.GetTaskType();
        //     if (response.code == '200') {
        //         this.taskType = response.data;
        //     }
        // },
        // async getUsers() {
        //     var pro_id = this.$route.params.id;
        //     const reqData = {
        //         id: pro_id,
        //     }
        //     const response = await Service.GetProjectDetails(reqData);
        //     if (response.code == '200') {
        //         this.pManager = this.loginData.designation.level.key;
        //         this.currUser = this.loginData.id;
        //         if (this.pManager == 'MANAGER') {
        //             this.employees = response.data.all.members;
        //         } else {
        //             response.data.all.allMem.forEach(element => {
        //                 element.mem.forEach(elem => {
        //                     if ((elem.user_id == this.currUser) && (elem.role == 1)) {
        //                         this.employees = element.mem;
        //                     }
        //                 });
        //             });
        //             if(this.employees.length == 0){
        //                 this.employees = [
        //                     { user_id: this.currUser, user: this.loginData.name },
        //                 ];
        //             }
        //         }
        //     }
        // },
    }
};
</script>
<style>
.ck-content {
    height: 150px;
}</style>