<template>
    <div>
        <!-- <Breadcrumbs main="Leave" title="Apply Leave" /> -->
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onFormSubmit">
                                <div class="form-row">
                                    <div class="row">

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label class="form-label">Start date and time</label>
                                                <!-- <datetime format="YYYY-MM-DD H:i:s" v-model='start_date'></datetime> -->
                                                <br>
                                                <date-picker v-model="start_date"  valueType="format" type="datetime"></date-picker>
                                                <!-- <datepicker :format="customFormatter"
                                                    input-class="datepicker-here form-control digits"
                                                    v-model="start_date">
                                                </datepicker> -->
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label class="form-label">End date and time</label>
                                                <!-- <datetime format="YYYY-MM-DD H:i:s" v-model='end_date'></datetime> -->
                                                <br>
                                                <date-picker v-model="end_date"  valueType="format" type="datetime"></date-picker>
                                                <!-- <datepicker :format="customFormatter"
                                                    input-class="datepicker-here form-control digits"
                                                    v-model="end_date">
                                                </datepicker> -->

                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                            <label for="form-label">Project</label>
                                            <v-select v-model="project" label="project_name" :options="empProjects"
                                                @input="getTasks()" />
                                        </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                            <label for="form-label">Task</label>
                                            <v-select v-model="task" label="name" :options="empTasks" />
                                        </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-row">
                                    <div class="col-md-6">
                                       
                                    </div>
                                    <div class="col-md-6">
                                      
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <label class="form-label">Reason</label>
                                        <textarea class="form-control" rows="4" placeholder="Enter reason" v-model="reason">
                                        </textarea>
                                    </div>
                                </div><br>
                                <b-button v-if="loader == false" type="submit" variant="primary">Submit</b-button>

                                <div v-if="loader == true" class="loader-box">
                                    <div class="loader-15"></div>
                                </div>

                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from "@/services/ServiceApi.js";
import vSelect from 'vue-select'
import Datetime from 'vuejs-datetimepicker';
import moment from 'moment'
import S3 from "aws-s3";

export default {

    components: {
        vSelect,
        Datetime,
    },

    data() {
        return {
            disabledDates: {
                to: new Date(Date.now() - 8640000)
            },
            loader: false,
            success: false,
            file: null,
            file_name: null,
            reason: null,
            start_date: null,
            end_date: null,
            holidays: [],
            holidays_arr: [],
            duration: null,
            sessions: [
            ],
            session1: null,
            session2: null,
            emp_ref_no: null,
            order_arr: [],
            empProjects: [],
            project: null,
            empTasks: [],
            task: null,
        };
    },
    computed: {
        supported_file_state() {
            if (this.file === null) {
                return false;
            } else {
                return true;
            }
        },
    },
    props: ['childVal2', 'callTest'],
    mounted() {
        this.getUserById();
        this.getProjects()
    },
    methods: {
        async getProjects() {
            var req = {
                id: this.$route.params.id
            }
            const response = await ServiceApi.GetProjectsByUser(req);
            if (response.code == "200") {
                console.log(response.data);
                response.data.forEach(element => {
                    if (element.status === 1){
                        this.empProjects.push(element)
                    }
                });
                // this.empProjects = response.data;
            }
        },
        async getTasks() {
            var req = {
                project: this.project.id,
                user: this.$route.params.id
            }
            const response = await Service.getTasksByProjects(req);
            if (response.code == "200") {
                this.empTasks = response.data;
            }
        },
        // customFormatter(date) {
        //     return moment(date).format('Y-MM-DD HH:MM:SS');
        // },

        onFormSubmit(evt) {
            evt.preventDefault();

            if ((this.reason == null) || (this.start_date == null) || (this.end_date == null) || (this.project == null) || (this.task == null)) {
                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else if (this.end_date.split(' ')[0] != this.start_date.split(' ')[0]) {
                this.$toasted.show(' From Date and To Date should be same ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else if (this.end_date.split(' ')[1] <= this.start_date.split(' ')[1]) {
                this.$toasted.show(' End time should be greater than start time ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                this.addOvertime();

            }

        },
        async getUserById() {
            const ReqData = {
                id: this.$route.params.id,
            };

            const response = await ServiceApi.GetUserById(ReqData);

            if (response.code == "200") {
                this.emp_ref_no = response.data.name;
            }
        },

        async addOvertime() {

            this.loader = true;

            const leave_data = {
                employee_id: this.$route.params.id,
                reason: this.reason,
                from_date: this.start_date,
                to_date: this.end_date,
                project: this.project ? this.project.id : null,
                task: this.task.id,
                type: 2
            }
            // console.log(leave_data);
            const response = await ServiceApi.AddOnsite(leave_data);
            if (response.code == '200') {
                this.loader = false;
                this.$toasted.show(' Request applied successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                // this.$router.push('/home/employee-view/' + this.$route.params.id);
                this.childVal2 = 0;
                this.$emit('update:childVal2', this.childVal2);
                this.$emit('callTest');
            } else if (response.code == '201') {
                this.loader = false;
                this.$toasted.show(response.message + ' ' + response.data, { theme: 'outline', position: 'top-right', type: 'error', duration: 3000 });
            }

        },

    }
};
</script>

<style>
.loader-box {
    height: 50px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;
}

</style>
