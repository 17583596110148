<template>
  <div>
    <Breadcrumbs main="Applied Vacancies" title="Edit Resume Details" />
    <div class="container-fluid">
      <div class="email-wrap bookmark-wrap">
        <div class="row">
          <div class="col-xl-3 box-col-6">
            <div class="email-left-aside">
              <div class="card">
                <div class="card-body">
                  <div class="email-app-sidebar left-bookmark">
                    <div class="media" style="flex-direction: column;">
                      <!-- <div class="media-size-email"><img class="mr-3 rounded-circle" src="../../assets/images/user/user.png" alt=""></div> -->
                      <div class="media-size-email">

                        <img v-if="image_name" class="mr-3 rounded-circle" :src="image_name"
                          style="height:80px;width:80px;" alt="" />

                        <img v-else class="mr-3 rounded-circle" src="../../assets/images/user/user.png"
                          style="height:80px;width:80px;" alt="" />

                      </div>
                      <div class="media-body">
                        <p>{{ email }}</p>
                        <!-- <h6 class="f-w-600">{{ data.name }}</h6> -->
                        <!-- <p>{{ data.emp_ref_no }}</p>
                        <p>{{ data.email }}</p>
                        <p>{{ data.phone }}</p> -->
                      </div>

                    </div>
                    <hr />
                    <ul class="nav main-menu" role="tablist">
                      <li>
                        <a id="pills-personal-tab" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-personal" aria-selected="true"><span class="title"
                            v-on:click="say('pills_personal')">
                            Personal Details</span></a>
                      </li>

                      <li>
                        <a class="show" id="pills-academic-tab" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-academic" aria-selected="false" @click="changeComp"><span class="title"
                            v-on:click="say('pills_academic')">
                            Academic Details</span></a>
                      </li>
                      <li>
                        <a class="show" id="pills-work-tab" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-work" aria-selected="false" @click="changeComp"><span class="title"
                            v-on:click="say('pills_work')">
                            Work Experience Details</span></a>
                      </li>
                      <!-- <li>
                        <a class="show" id="pills-applied-tab" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-applied" aria-selected="false" @click="changeComp"><span class="title"
                            v-on:click="say('pills_applied')">
                            Applied Vacancy Details</span></a>
                      </li> -->
                      <li>
                        <hr />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-9 col-md-12 box-col-12">
            <div class="email-right-aside bookmark-tabcontent">
              <div class="card email-body radius-left">
                <div class="pl-0">
                  <div class="tab-content">
                    <div class="tab-pane fade" v-bind:class="activeclass === 'pills_personal' ? 'active show' : ''
                      " id="pills-personal" role="tabpanel" aria-labelledby="pills-personal-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0">Edit Personal Details</h5>
                        </div>

                        <div class="card">
                          <div class="card-body">
                            <div class="">

                              <!---->
                              <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Name <span style="color:red">*</span></label>
                                    <b-form-input type="text" id="name" v-model="name" placeholder="Name"></b-form-input>
                                    <b-form-valid-feedback>
                                    </b-form-valid-feedback>
                                  </div>

                                  <div class="col-md-4 mb-3">
                                    <div class="form-group">
                                      <label class="form-label">Phone <span style="color:red">*</span></label>
                                      <VuePhoneNumberInput v-model="phone" default-country-code="IN"
                                        @update="onUpdatePhoneNumber" />
                                    </div>

                                  </div>

                                  <div class="col-md-4 mb-3">
                                    <div class="form-group">
                                      <label class="form-label">Alternative phone</label>
                                      <VuePhoneNumberInput v-model="alt_phone" default-country-code="IN"
                                        @update="onUpdateAltPhoneNumber" />
                                    </div>
                                  </div>

                                </div>
                                <div class="form-row">
                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Date of birth <span style="color:red">*</span></label>
                                    <datepicker :format="customFormatter"
                                      input-class="datepicker-here form-control digits" v-model="dob" :disabled-dates="isDateDisabled">
                                    </datepicker>
                                  </div>

                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Gender <span style="color:red">*</span></label>
                                    <b-form-select v-model="gender" label="gender" :options="genders">
                                    </b-form-select>
                                    <b-form-valid-feedback>
                                    </b-form-valid-feedback>
                                  </div>

                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Marital Status <span style="color:red">*</span></label>
                                    <b-form-select v-model="marital_status" label="marital_status"
                                      :options="marital_statuses">
                                    </b-form-select>
                                    <b-form-valid-feedback>
                                    </b-form-valid-feedback>
                                  </div>

                                </div>

                                <div class="form-row">
                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Current Address</label>
                                    <b-form-textarea rows="1" v-model="current_address"></b-form-textarea>
                                  </div>

                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Current State</label>
                                    <b-form-input type="text" id="current_state" v-model="current_state"
                                      placeholder="Current State"></b-form-input>
                                    <b-form-valid-feedback>
                                    </b-form-valid-feedback>
                                  </div>
                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Current country</label>
                                    <b-form-input type="text" id="current_country" v-model="current_country"
                                      placeholder="Current country"></b-form-input>
                                    <b-form-valid-feedback>
                                    </b-form-valid-feedback>
                                  </div>

                                </div>
                                <div class="form-row">
                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Current pin</label>
                                    <b-form-input type="number" id="current_pin" v-model="current_pin"
                                      placeholder="Current pin"></b-form-input>
                                    <b-form-valid-feedback>
                                    </b-form-valid-feedback>
                                  </div>

                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Current City</label>
                                    <b-form-input type="text" id="current_pin" v-model="cur_city"
                                      placeholder="Current City"></b-form-input>
                                    <b-form-valid-feedback>
                                    </b-form-valid-feedback>
                                  </div>

                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Permanent Address</label>
                                    <b-form-textarea rows="1" v-model="permanent_address"></b-form-textarea>
                                  </div>
                                  

                                </div>
                                <div class="form-row">
                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Permanent State</label>
                                    <b-form-input type="text" id="permanent_state" v-model="permanent_state"
                                      placeholder="Permanent State"></b-form-input>
                                    <b-form-valid-feedback>
                                    </b-form-valid-feedback>
                                  </div>
                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Permanent Country</label>
                                    <b-form-input type="text" id="permanent_country" v-model="permanent_country"
                                      placeholder="Permanent Country"></b-form-input>
                                    <b-form-valid-feedback>
                                    </b-form-valid-feedback>
                                  </div>

                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Permanent Pin</label>
                                    <b-form-input type="number" id="permanent_pin" v-model="permanent_pin"
                                      placeholder="Permanent Pin"></b-form-input>
                                    <b-form-valid-feedback>
                                    </b-form-valid-feedback>
                                  </div>

                                </div>

                                <div class="form-row">
                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Permanent City</label>
                                    <b-form-input type="text" id="current_salary" v-model="per_city"
                                      placeholder="Permanent City"></b-form-input>
                                    <b-form-valid-feedback>
                                    </b-form-valid-feedback>
                                  </div>
                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Current Salary</label>
                                    <b-form-input type="text" id="current_salary" v-model="current_salary"
                                      placeholder="Current Salary"></b-form-input>
                                    <b-form-valid-feedback>
                                    </b-form-valid-feedback>
                                  </div>
                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Expected Salary</label>
                                    <b-form-input type="text" id="expected_salary" v-model="expected_salary"
                                      placeholder="Expected Salary"></b-form-input>
                                    <b-form-valid-feedback>
                                    </b-form-valid-feedback>
                                  </div>
                                  
                                </div>
                                <div class="form-row">
                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Notice period in Days</label>
                                    <b-form-input type="number" id="notice_period_in_days" v-model="notice_period_in_days"
                                      placeholder="Notice period in Days"></b-form-input>
                                    <b-form-valid-feedback>
                                    </b-form-valid-feedback>
                                  </div>
                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Is Negotiable</label>
                                    <v-select v-model="is_negotiable" label="text" :options="types" />

                                  </div>
                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Are you willing to Relocate?</label>
                                    <v-select v-model="re_location" label="text" :options="types" />

                                  </div>
                                 

                                </div>
                                <div class="form-row">
                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Resume Upload</label>
                                    <input type="file" @change="onFileChange" accept="application/pdf"/>
                                    <div>
                                      <a v-if="file_name" :href="this.file_name" target="_blank" download>
                                        <feather type="download"></feather>
                                      </a>
                                    </div>
                                  </div>

                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">Photo Upload</label>
                                    <input type="file" @change="onImageChange" accept="image/*" />
                                    <div id="preview">
                                      <img v-if="preview_image" :src="preview_image" />
                                    </div>
                                  </div>
                                  <div class="col-md-4 mb-3">
                                    <label for="c_form_name">notes</label>

                                    <b-form-textarea rows="1" v-model="notes" placeholder="Enter Notes"></b-form-textarea>

                                  </div>
                                </div>
                                <b-button type="submit" variant="primary">Submit</b-button>
                              </b-form>
                              <!---->


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="fade tab-pane" v-bind:class="activeclass === 'pills_academic' ? 'active show' : ''
                      " id="pills-academic" role="tabpanel" aria-labelledby="pills-academic-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0">Academic Details</h5>
                        </div>

                        <div class="card">
                          <div class="card-body">

                            <b-row>
                              <b-col xl="6">

                                <span>

                                  <a class="show" id="pills-academic-add-tab" data-toggle="pill" href="javascript:void(0)"
                                    role="tab" aria-controls="pills-academic-add" aria-selected="false"
                                    @click="changeComp"><span class="title" v-on:click="say('pills_academic_add')">
                                      <b-button id="default-primary" variant="primary">Add Academics</b-button>
                                    </span></a>

                                </span>

                              </b-col>
                              <b-col xl="6">

                              </b-col>
                            </b-row>
                            <br />

                            <b-row>
                              <b-col xl="6">
                                <b-input-group class="datatable-btn">
                                  <b-form-input v-model="filter" placeholder="Search"></b-form-input>
                                  <b-input-group-append>
                                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>

                              <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                  <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">

                              <b-table show-empty stacked="md" :items="items_academics" :fields="tablefields_academics"
                                :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">

                                <template #cell(action)="row">

                                  <span>

                                    <a class="show" id="pills-academic-edit-tab" data-toggle="pill"
                                      href="javascript:void(0)" role="tab" aria-controls="pills-academic-edit"
                                      aria-selected="false" @click="changeComp"><span class="title"
                                        v-on:click="say('pills_academic_edit')">
                                        <feather type="edit" title="Edit Academic"
                                          @click="editAcademic(row.item.academic_id)"></feather>
                                      </span></a>

                                  </span>

                                  <span v-if="row.item.status == 1">
                                    <feather type="thumbs-down" @click="deleteAcademic(row.item)"></feather>
                                  </span>
                                  <span v-else>
                                    <feather type="thumbs-up" @click="deleteAcademic(row.item)"></feather>
                                  </span>


                                </template>

                              </b-table>


                            </div>
                            <b-col md="6" class="my-1 p-0">
                              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                class="my-0"></b-pagination>
                            </b-col>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="fade tab-pane" v-bind:class="activeclass === 'pills_academic_edit' ? 'active show' : ''
                      " id="pills-academic-edit" role="tabpanel" aria-labelledby="pills-academic-edit-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0">Edit Academic Details</h5>
                        </div>

                        <div class="card">
                          <div class="card-body">

                            <br />
                            <b-form class="needs-validation" @submit="onEditAcademicSubmit">
                              <div class="form-row">
                                <div class="col-md-4 mb-3">
                                  <label for="name">Institute Name</label>
                                  <b-form-input type="text" id="institute_edit" v-model="institute_edit"
                                    placeholder="Institute Name"></b-form-input>
                                </div>

                                <div class="col-md-4 mb-3">
                                  <label for="priority">Course</label>
                                  <b-form-input type="text" id="course_edit" v-model="course_edit"
                                    placeholder="course"></b-form-input>

                                </div>
                                <div class="col-md-4 mb-3">
                                </div>
                                <div class="col-md-4 mb-3">
                                  <label for="priority">Joining year</label>
                                  <b-form-select v-model="joining_year_edit" :options="yearOptions"
                                    required></b-form-select>

                                </div>
                                <div class="col-md-4 mb-3">
                                  <label for="priority">Pass out year</label>
                                  <b-form-select v-model="pass_out_year_edit" :options="yearOptions"
                                    required></b-form-select>

                                </div>

                              </div>

                              <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>


                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="fade tab-pane" v-bind:class="activeclass === 'pills_academic_add' ? 'active show' : ''
                      " id="pills-academic-add" role="tabpanel" aria-labelledby="pills-academic-add-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0">Add Academic Details</h5>
                        </div>

                        <div class="card">
                          <div class="card-body">

                            <br />

                            <b-form class="needs-validation" @submit="onAddAcademicSubmit">
                              <div class="form-row">
                                <div class="col-md-4 mb-3">
                                  <label for="name">Institute Name</label>
                                  <b-form-input type="text" id="institute" v-model="institute"
                                    placeholder="Institute Name"></b-form-input>
                                </div>

                                <div class="col-md-4 mb-3">
                                  <label for="priority">Course</label>
                                  <b-form-input type="text" id="course" v-model="course"
                                    placeholder="course"></b-form-input>

                                </div>
                                <div class="col-md-4 mb-3">
                                </div>
                                <div class="col-md-4 mb-3">
                                  <label for="priority">Joining year</label>
                                  <b-form-select v-model="joining_year" :options="yearOptions" required></b-form-select>

                                </div>
                                <div class="col-md-4 mb-3">
                                  <label for="priority">Pass out year</label>
                                  <b-form-select v-model="pass_out_year" :options="yearOptions" required></b-form-select>

                                </div>

                              </div>

                              <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="fade tab-pane" v-bind:class="activeclass === 'pills_work' ? 'active show' : ''
                      " id="pills-work" role="tabpanel" aria-labelledby="pills-work-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0">Work Experience Details</h5>
                        </div>

                        <div class="card">
                          <div class="card-body">

                            <b-row>
                              <b-col xl="6">

                                <span>

                                  <a class="show" id="pills-work-add-tab" data-toggle="pill" href="javascript:void(0)"
                                    role="tab" aria-controls="pills-work-add" aria-selected="false"
                                    @click="changeComp"><span class="title" v-on:click="say('pills_work_add')">
                                      <b-button id="default-primary" variant="primary">Add Experience</b-button>
                                    </span></a>

                                </span>

                              </b-col>
                              <b-col xl="6">

                              </b-col>
                            </b-row>
                            <br />

                            <b-row>
                              <b-col xl="6">
                                <b-input-group class="datatable-btn">
                                  <b-form-input v-model="filter" placeholder="Search"></b-form-input>
                                  <b-input-group-append>
                                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>

                              <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                  <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">

                              <b-table show-empty stacked="md" :items="items_works" :fields="tablefields_works"
                                :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">

                                <template #cell(action)="row">

                                  <span>

                                    <a class="show" id="pills-work-edit-tab" data-toggle="pill" href="javascript:void(0)"
                                      role="tab" aria-controls="pills-work-edit" aria-selected="false"
                                      @click="changeComp"><span class="title" v-on:click="say('pills_work_edit')">
                                        <feather type="edit" title="Edit Experience" @click="editWork(row.item.work_id)">
                                        </feather>
                                      </span></a>

                                  </span>

                                  <span v-if="row.item.status == 1">
                                    <feather type="thumbs-down" @click="deleteWork(row.item)"></feather>
                                  </span>
                                  <span v-else>
                                    <feather type="thumbs-up" @click="deleteWork(row.item)"></feather>
                                  </span>


                                </template>

                              </b-table>


                            </div>
                            <b-col md="6" class="my-1 p-0">
                              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                class="my-0"></b-pagination>
                            </b-col>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="fade tab-pane" v-bind:class="activeclass === 'pills_work_edit' ? 'active show' : ''
                      " id="pills-work-edit" role="tabpanel" aria-labelledby="pills-work-edit-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0">Edit Work Experience Details</h5>
                        </div>

                        <div class="card">
                          <div class="card-body">

                            <br />
                            <b-form class="needs-validation" @submit="onEditWorkSubmit">
                              <div class="form-row">
                                <div class="col-md-4 mb-3">
                                  <label for="name">Organization Name</label>
                                  <b-form-input type="text" id="organization" v-model="organization_edit"
                                    placeholder="organization"></b-form-input>
                                </div>

                                <div class="col-md-4 mb-3">
                                  <label for="priority">Designation</label>
                                  <b-form-input type="text" id="designation" v-model="designation_edit"
                                    placeholder="designation"></b-form-input>

                                </div>
                                <div class="col-md-4 mb-3">
                                </div>
                                <div class="col-md-4 mb-3">
                                  <label for="priority">From Date</label>
                                  <datepicker :format="customFormatter" input-class="datepicker-here form-control digits"
                                    v-model="start_date_edit" :disabled-dates="isDateDisable">
                                  </datepicker>

                                </div>
                                <div class="col-md-4 mb-3">
                                  <label for="priority">To Date</label>
                                  <datepicker :format="customFormatter" input-class="datepicker-here form-control digits"
                                    v-model="end_date_edit" :disabled-dates="isDateDisable">
                                  </datepicker>

                                </div>

                              </div>

                              <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>


                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="fade tab-pane" v-bind:class="activeclass === 'pills_work_add' ? 'active show' : ''
                      " id="pills-work-add" role="tabpanel" aria-labelledby="pills-work-add-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0">Add Work Experience Details</h5>
                        </div>

                        <div class="card">
                          <div class="card-body">

                            <br />

                            <b-form class="needs-validation" @submit="onAddWorkSubmit">
                              <div class="form-row">
                                <div class="col-md-4 mb-3">
                                  <label for="name">Organization Name</label>
                                  <b-form-input type="text" id="organization" v-model="organization"
                                    placeholder="organization"></b-form-input>
                                </div>

                                <div class="col-md-4 mb-3">
                                  <label for="priority">Designation</label>
                                  <b-form-input type="text" id="designation" v-model="designation"
                                    placeholder="designation"></b-form-input>

                                </div>
                                <div class="col-md-4 mb-3">
                                </div>
                                <div class="col-md-4 mb-3">
                                  <label for="priority">From Date</label>
                                  <datepicker :format="customFormatter" input-class="datepicker-here form-control digits"
                                    v-model="start_date" :disabled-dates="isDateDisable">
                                  </datepicker>

                                </div>
                                <div class="col-md-4 mb-3">
                                  <label for="priority">To Date</label>
                                  <datepicker :format="customFormatter" input-class="datepicker-here form-control digits"
                                    v-model="end_date" :disabled-dates="isDateDisable">
                                  </datepicker>

                                </div>

                              </div>

                              <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>

                          </div>
                        </div>
                      </div>
                    </div>

                    <!--tab div end-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Service from "@/services/Service.js";
import ServiceApi from "@/services/ServiceApi.js";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import S3 from "aws-s3";
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';
import VuePhoneNumberInput from 'vue-phone-number-input';

export default {
  name: "bookmark",
  components: {
    VuePhoneNumberInput,
    vSelect,
    Datepicker
  },
  data() {
    return {
      modal2Show: false,
      isLoading: true,
      emid: this.$route.params.id,
      success: false,
      success_academic: false,
      success_academic_edit: false,
      success_work: false,
      success_work_edit: false,
      email: '',
      gender: null,
      cur_city: '',
      per_city: '',
      employee_status: null,
      wage_calculation_type: null,
      liststyle: false,
      data: [],
      leave_data: [],
      academics: [],
      activeclass: "pills_personal",
      favourite: [],
      genders: [
        { value: 'M', text: 'Male' },
        { value: 'F', text: 'Female' },
        { value: 'O', text: 'Other' },
      ],
      marital_statuses: [
        { value: 'S', text: 'Single' },
        { value: 'M', text: 'Married' },
        { value: 'W', text: 'Widowed' },
        { value: 'D', text: 'Divorced' },
        { value: 'O', text: 'Other' },
      ],
      tablefields_academics: [
        { key: "institute", label: "Institute", sortable: true },
        { key: "course", label: "Course", sortable: true },
        { key: "joining_year", label: "Joining Year", sortable: true },
        { key: "pass_out_year", label: "Pass Out Year", sortable: true },
        { key: "action", label: "Actions", sortable: true },
      ],
      items_academics: [],
      types: [

        { value: "1", text: "YES" },
        { value: "2", text: "NO" },
      ],
      tablefields_works: [
        { key: "organization", label: "Organization", sortable: true },
        { key: "designation", label: "Designation", sortable: true },
        { key: "start_date", label: "Start Date", sortable: true },
        { key: "end_date", label: "End Date", sortable: true },
        { key: "action", label: "Action", sortable: true },

      ],
      items_works: [],
      items_vacancies: [],
      tablefields_vacancies: [
        { key: "title", label: "Job Title", sortable: true },
        { key: "actions", label: "Actions", sortable: true, },
      ],
      totalRows2: null,

      name: '',
      email: '',
      phone: '',
      dob: '',
      alt_phone: '',
      gender: '',
      marital_status: '',
      image_name: '',
      is_negotiable: '',
      current_address: '',
      current_state: '',
      current_country: '',
      current_pin: '',
      permanent_address: '',
      permanent_state: '',
      permanent_country: '',
      permanent_pin: '',
      file_name: '',
      current_salary: '',
      expected_salary: '',
      notice_period_in_days: '',
      re_location:'',
      notes: '',
      academics: [],
      works: [],
      academic_details: [],
      work_exp_details: [],
      applied_vacancies: [],
      is_negotiable_name: '',
      re_location_name:'',
      file: null,
      file_image: null,
      preview_image: null,
      institute: '',
      course: '',
      joining_year: '',
      pass_out_year: '',

      institute_edit: '',
      course_edit: '',
      joining_year_edit: '',
      pass_out_year_edit: '',
      yearOptions: [],

      organization: '',
      designation: '',
      start_date: '',
      end_date: '',

      organization_edit: '',
      designation_edit: '',
      start_date_edit: '',
      end_date_edit: '',

      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],
      curr_day: '',

      c_form: {
        count: "",
      },
      c_form_result: {
        count: null,
      },
      month: "",
      result_arr: [],
      heading: [],
      modalShow: false,
      activity: [],
      loginData: JSON.parse(localStorage.getItem('loginDetails')),
      changeChild: 0,
      changeChild2: 0,
      curr_user_id: null,
    };
  },

  computed: {
    isDateDisabled(date) {
        const currentDate = new Date();
        const maxDate = new Date(
        currentDate.getFullYear() - 15,
        currentDate.getMonth(),
        currentDate.getDate()
        );
        return {
        from: maxDate, // disable all dates before the maxDate
        };
    },
    isDateDisable(date) {
        return {
            from: new Date(), // disable all dates after today
        };
    },

    statusVariant() {
      const statusColor = {
        1: "light-warning",
        2: "light-warning",
        3: "light-success",
        4: "light-danger",
        5: "light-danger",
      };

      return (status) => statusColor[status];
    },
    statusVariant2() {
      const statusColor = {
        1: "light-warning",
        2: "light-success",
      };

      return (status) => statusColor[status];
    },
    statusVariant3() {
      const statusColor = {
        1: "light-danger",
        2: "light-warning",
        3: "light-success",
      };

      return (status) => statusColor[status];
    },

    sortOptions() {
      // Create an options list from our fields
      return this.tablefields_leaves
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {

    this.retrieveResumeDetails();
    this.getAwsS3Config();
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= 1900; year--) {
      this.yearOptions.push({ value: year, text: year.toString() });
    }

  },
  methods: {
    onUpdatePhoneNumber(payload) {
      this.PhoneNumberValue = payload.formattedNumber;
    },
    onUpdateAltPhoneNumber(payload) {
      this.AltNumberValue = payload.formattedNumber;
    },

    customFormatter(date) {
      return moment(date).format('Y-MM-DD');
    },
    async getAwsS3Config() {
      const response = await Service.getAwsS3Configs();
      if (response.code == "200") {
        this.s3 = response.data;
      }
    },

    onFileChange(e) {

      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }
      const now = Date.now();

      let file = files[0];
      if (file.type != 'application/pdf') {
        this.$toasted.show(' Please upload file with pdf format', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

      }
      else {
        this.file_name = URL.createObjectURL(file);
        const config = {
          bucketName: this.s3.bucketName,
          dirName: "resume_docs" /* optional */,
          region: this.s3.region,
          accessKeyId: this.s3.accessKeyId,
          secretAccessKey: this.s3.secretAccessKey,
          s3Url: "https://bktqaptive.s3.amazonaws.com" /* optional */,
        };

        const S3Client = new S3(config);

        S3Client.uploadFile(file, now).then((response) => {
          this.file = response.location;
        });

      }

    },

    onImageChange(e) {

      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }
      const now = Date.now();

      let file = files[0];

      if (!file.type.startsWith('image/')) {
        this.$toasted.show('Please upload an image file', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
        return;
      }

      this.preview_image = URL.createObjectURL(file);

      const config = {
        bucketName: this.s3.bucketName,
        dirName: "resume_photos" /* optional */,
        region: this.s3.region,
        accessKeyId: this.s3.accessKeyId,
        secretAccessKey: this.s3.secretAccessKey,
        s3Url: "https://bktqaptive.s3.amazonaws.com" /* optional */,
      };

      const S3Client = new S3(config);

      S3Client.uploadFile(file, now).then((response) => {
        this.file_image = response.location;
      });

    },
    onCustomStyleSubmit(evt) {
      evt.preventDefault();

      if (this.name == '') {
        this.success = false;
        this.$toasted.show(' Please enter name', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }

      else if (this.phone == '') {
        this.success = false;
        this.$toasted.show(' Please enter phone', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }

      else if (this.dob == '') {
        this.success = false;
        this.$toasted.show(' Please enter date of birth', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }
      else if (this.gender == '') {
        this.success = false;
        this.$toasted.show(' Please select gender', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }
      else if (this.marital_status == '') {
        this.success = false;
        this.$toasted.show(' Please select marital status', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }

      else {
        this.success = true;
      }

      this.saveResumeDetails();
    },
    saveResumeDetails() {

      if (this.success == true) {

        var dob = null;

        if (this.dob != null) {
          dob = this.customFormatter(this.dob);
        }

        if (this.file != null) {

          var file_name = this.file;

        }
        else {
          var file_name = this.file_name;
        }

        if (this.file_image != null) {

          var image_name = this.file_image;

        }
        else {
          var image_name = this.image_name;
        }

        if (this.phone == undefined || this.phone == "" || this.phone == null) {
          var phone = "";
        }
        else {
          var phone = this.phone;
        }

        if (this.alt_phone == undefined || this.alt_phone == "" || this.alt_phone == null) {
          var alt_phone = "";
        }
        else {
          var alt_phone = this.alt_phone;
        }

        const resume_data = {
          resume_id: this.$route.params.ids,
          name: this.name,
          phone: phone,
          alt_phone: alt_phone,
          gender: this.gender,
          marital_status: this.marital_status,
          image_name: image_name,
          file_name: file_name,
          notes: this.notes,
          current_address: this.current_address,
          current_pin: this.current_pin,
          current_state: this.current_state,
          current_country: this.current_country,
          cur_city: this.cur_city,
          permanent_address: this.permanent_address,
          permanent_pin: this.permanent_pin,
          permanent_state: this.permanent_state,
          permanent_country: this.permanent_country,
          per_city: this.per_city,
          expected_salary: this.expected_salary,
          current_salary: this.current_salary,
          notice_period_in_days: this.notice_period_in_days,
          is_negotiable: this.is_negotiable.value,
          re_location: this.re_location.value,
          dob: dob,
        }

        this.updateResume(resume_data)
      }
    },
    async updateResume(resume_data) {
      const response = await ServiceApi.UpdateResume(resume_data);
      if (response.code == '200') {
        this.$toasted.show(' Resume Details updated successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        //this.$router.push('/home/applied_vacancies');
        window.location.reload()
      }
      else if (response.code == '400') {
        this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },

    viewResume(resume_id) {
      this.retrieveResumeDetails(resume_id);

    },

    async retrieveResumeDetails() {
      const ReqData = {
        id: this.$route.params.ids,
      };
      const response = await Service.GetResumesViewById(ReqData);

      if (response.code == "200") {

        if (response.data.is_negotiable == 1) {
          this.is_negotiable_name = 'YES';
          this.is_negotiable = { value: response.data.is_negotiable, text: this.is_negotiable_name };
        }
        else if (response.data.is_negotiable == 2) {
          this.is_negotiable_name = 'NO';
          this.is_negotiable = { value: response.data.is_negotiable, text: this.is_negotiable_name };
        }

        if (response.data.re_location == 1) {
          this.re_location_name = 'YES';
          this.re_location = { value: response.data.re_location, text: this.re_location_name };
        }
        else if (response.data.re_location == 2) {
          this.re_location_name = 'NO';
          this.re_location = { value: response.data.re_location, text: this.re_location_name };
        }

        this.name = response.data.name;
        this.email = response.data.email;
        this.phone = response.data.phone;
        this.alt_phone = response.data.alt_phone;
        this.gender = response.data.gender;
        this.dob = response.data.dob;
        this.marital_status = response.data.marital_status;
        this.image_name = response.data.image_name;
        this.current_address = response.data.current_address;
        this.current_state = response.data.current_state;
        this.current_country = response.data.current_country;
        this.current_pin = response.data.current_pin;
        this.cur_city = response.data.cur_city;
        this.permanent_address = response.data.permanent_address;
        this.permanent_state = response.data.permanent_state;
        this.permanent_country = response.data.permanent_country;
        this.permanent_pin = response.data.permanent_pin;
        this.per_city = response.data.per_city;
        this.file_name = response.data.file_name;
        this.current_salary = response.data.current_salary;
        this.expected_salary = response.data.expected_salary;
        this.notice_period_in_days = response.data.notice_period_in_days;
        this.notes = response.data.notes;
        this.items_academics = response.data.academic_detail;
        this.items_works = response.data.work_exp_details;
        this.items_vacancies = response.data.applied_vacancies;
      }
    },
    editAcademic(academic_id) {
      this.getAcademicDetailsById(academic_id);
    },

    async getAcademicDetailsById(academic_id) {
      const ReqData = {
        id: academic_id,
      };
      const response = await ServiceApi.GetResumeAcademicsById(ReqData);

      if (response.code == "200") {
        this.academic_id = academic_id;
        this.institute_edit = response.data.institute;
        this.course_edit = response.data.course;
        this.joining_year_edit = response.data.joining_year;
        this.pass_out_year_edit = response.data.pass_out_year;

      }
    },
    onAddAcademicSubmit(evt) {
      evt.preventDefault();

      if (this.institute == '') {
        this.success_academic = false;
        this.$toasted.show(' Please enter institute', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }

      else if (this.course == '') {
        this.success_academic = false;
        this.$toasted.show(' Please enter course', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }

      else if (this.joining_year == '') {
        this.success_academic = false;
        this.$toasted.show(' Please enter joining year', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }
      else if (this.pass_out_year == '') {
        this.success_academic = false;
        this.$toasted.show(' Please select pass out year', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }
      else if (this.joining_year > this.pass_out_year) {
					this.success_academic = false;
          this.$toasted.show('Pass out year should be greater than Joining Year', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

      }
      else {
        this.success_academic = true;
      }

      this.saveAcademicDetails();
    },

    saveAcademicDetails() {

      if (this.success_academic == true) {

        const acad_data = {
          resume_id: this.$route.params.ids,
          institute: this.institute,
          course: this.course,
          joining_year: this.joining_year,
          pass_out_year: this.pass_out_year,
        }

        this.addAcademicDetails(acad_data)
      }
    },
    async addAcademicDetails(acad_data) {
      const response = await ServiceApi.AddAcademicDetails(acad_data);
      if (response.code == '200') {
        this.$toasted.show(' Resume academic added successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        //this.$router.push('/home/applied_vacancies');
        window.location.reload()
      }
      else if (response.code == '400') {
        this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },

    onEditAcademicSubmit(evt) {
      evt.preventDefault();

      if (this.institute_edit == '') {
        this.success_academic_edit = false;
        this.$toasted.show(' Please enter institute', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }

      else if (this.course_edit == '') {
        this.success_academic_edit = false;
        this.$toasted.show(' Please enter course', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }

      else if (this.joining_year_edit == '') {
        this.success_academic_edit = false;
        this.$toasted.show(' Please enter joining year', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }
      else if (this.pass_out_year_edit == '') {
        this.success_academic_edit = false;
        this.$toasted.show(' Please select pass out year', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }
      else if (this.joining_year_edit > this.pass_out_year_edit) {
					this.success_academic_edit = false;
          this.$toasted.show('Pass out year should be greater than Joining Year', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

      }
      else {
        this.success_academic_edit = true;
      }

      this.saveAcademicEditDetails();
    },

    saveAcademicEditDetails() {

      if (this.success_academic_edit == true) {

        const acad_data = {
          id: this.academic_id,
          resume_id: this.$route.params.ids,
          institute: this.institute_edit,
          course: this.course_edit,
          joining_year: this.joining_year_edit,
          pass_out_year: this.pass_out_year_edit,
        }

        this.updateAcademicDetails(acad_data)
      }
    },
    async updateAcademicDetails(acad_data) {
      const response = await ServiceApi.UpdateAcademicDetails(acad_data);
      if (response.code == '200') {
        this.$toasted.show(' Resume academic updated successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        //this.$router.push('/home/applied_vacancies');
        window.location.reload()
      }
      else if (response.code == '400') {
        this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },

    deleteAcademic(data) {
      if (data.status == 1) {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to disable this Resume Academics!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, disable!",
        }).then((result) => {
          if (result.isConfirmed) {
            const credentials = {
              status: data.status,
              id: data.academic_id,
            };
            var done = this.enableOrDisableResumeAcademic(credentials);
            if (done) {
              Swal.fire("Disabled!", "Resume Academic has been disabled.", "success")
              // window.location.reload()
              this.retrieveResumeDetails();
            }
          }
        });
      } else if (data.status == 0) {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to enable this Resume Academic!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, enable!",
        }).then((result) => {
          if (result.isConfirmed) {
            const credentials = {
              status: data.status,
              id: data.academic_id,
            };
            var done = this.enableOrDisableResumeAcademic(credentials);
            if (done) {
              Swal.fire("Disabled!", "Resume Academic has been enabled.", "success")
              // window.location.reload()
              this.retrieveResumeDetails();
            }
          }
        });
      }
    },
    async enableOrDisableResumeAcademic(credentials) {
      const response = await ServiceApi.EnableOrDisableResumeAcademic(credentials);
      if (response.code == "200") {
        return true;
      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      } else {
        return false;
      }
    },

    editWork(work_id) {
      this.getWorkDetailsById(work_id);
    },

    async getWorkDetailsById(work_id) {
      const ReqData = {
        id: work_id,
      };
      const response = await ServiceApi.GetResumeWorksById(ReqData);

      if (response.code == "200") {
        this.work_id = work_id;
        this.organization_edit = response.data.organization;
        this.designation_edit = response.data.designation;
        this.start_date_edit = response.data.start_date;
        this.end_date_edit = response.data.end_date;

      }
    },
    onAddWorkSubmit(evt) {
      evt.preventDefault();

      if (this.organization == '') {
        this.success_work = false;
        this.$toasted.show(' Please enter organization', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }

      else if (this.designation == '') {
        this.success_work = false;
        this.$toasted.show(' Please enter designation', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }

      else if (this.start_date == '') {
        this.success_work = false;
        this.$toasted.show(' Please enter start date ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }
      else if (this.end_date == '') {
        this.success_work = false;
        this.$toasted.show(' Please select end date', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }

      else if (this.customFormatter(this.start_date) > this.customFormatter(this.end_date)) {

					this.success_work = false;
          this.$toasted.show('End date should be greater than Start Date', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

				}

      else {
        this.success_work = true;
      }

      this.saveWorkDetails();
    },

    saveWorkDetails() {

      if (this.success_work == true) {

        var start_date = null;

        if (this.start_date != null) {
          start_date = this.customFormatter(this.start_date);
        }

        var end_date = null;

        if (this.end_date != null) {
          end_date = this.customFormatter(this.end_date);
        }

        const work_data = {
          resume_id: this.$route.params.ids,
          organization: this.organization,
          designation: this.designation,
          start_date: start_date,
          end_date: end_date,
        }

        this.addWorkDetails(work_data)
      }
    },
    async addWorkDetails(work_data) {
      const response = await ServiceApi.AddWorkDetails(work_data);
      if (response.code == '200') {
        this.$toasted.show(' Resume work experience added successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        //this.$router.push('/home/applied_vacancies');
        window.location.reload()
      }
      else if (response.code == '400') {
        this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },

    onEditWorkSubmit(evt) {
      evt.preventDefault();

      if (this.organization_edit == '') {
        this.success_work_edit = false;
        this.$toasted.show(' Please enter organization', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }

      else if (this.designation_edit == '') {
        this.success_work_edit = false;
        this.$toasted.show(' Please enter designation', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }

      else if (this.start_date_edit == '') {
        this.success_work_edit = false;
        this.$toasted.show(' Please enter start date ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }
      else if (this.end_date_edit == '') {
        this.success_work_edit = false;
        this.$toasted.show(' Please select end date', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }
      
      else if (this.customFormatter(this.start_date_edit) > this.customFormatter(this.end_date_edit)) {
					this.success_work_edit = false;
          this.$toasted.show('End date should be greater than Start Date', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

				}

      else {
        this.success_work_edit = true;
      }

      this.saveWorkEditDetails();
    },

    saveWorkEditDetails() {

      if (this.success_work_edit == true) {

        var start_date_edit = null;

        if (this.start_date_edit != null) {
          start_date_edit = this.customFormatter(this.start_date_edit);
        }

        var end_date_edit = null;

        if (this.end_date_edit != null) {
          end_date_edit = this.customFormatter(this.end_date_edit);
        }

        const work_data = {
          id: this.work_id,
          resume_id: this.$route.params.ids,
          organization: this.organization_edit,
          designation: this.designation_edit,
          start_date: start_date_edit,
          end_date: end_date_edit,
        }

        this.updateWorkDetails(work_data)
      }
    },
    async updateWorkDetails(work_data) {
      const response = await ServiceApi.UpdateWorkDetails(work_data);
      if (response.code == '200') {
        this.$toasted.show(' work experience updated successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        //this.$router.push('/home/applied_vacancies');
        window.location.reload()
      }
      else if (response.code == '400') {
        this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },

    deleteWork(data) {
      if (data.status == 1) {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to disable this work experience!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, disable!",
        }).then((result) => {
          if (result.isConfirmed) {
            const credentials = {
              status: data.status,
              id: data.work_id,
            };
            var done = this.enableOrDisableResumeWork(credentials);
            if (done) {
              Swal.fire("Disabled!", " work experience has been disabled.", "success")
              // window.location.reload()
              this.retrieveResumeDetails();
            }
          }
        });
      } else if (data.status == 0) {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to enable this work experience!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, enable!",
        }).then((result) => {
          if (result.isConfirmed) {
            const credentials = {
              status: data.status,
              id: data.work_id,
            };
            var done = this.enableOrDisableResumeWork(credentials);
            if (done) {
              Swal.fire("Disabled!", "work experience has been enabled.", "success")
              // window.location.reload()
              this.retrieveResumeDetails();
            }
          }
        });
      }
    },
    async enableOrDisableResumeWork(credentials) {
      const response = await ServiceApi.EnableOrDisableResumeWork(credentials);
      if (response.code == "200") {
        return true;
      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      } else {
        return false;
      }
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    hideModal() {
      this.modalShow = false;
    },

    say: function (message) {

      this.activeclass = message;
      // alert(this.activeclass);
    },
    changeComp() {
      this.changeChild = 0;
      this.changeChild2 = 0;
    }
  },
};
</script>
<style lang="scss">
@import "vue-select/dist/vue-select.css";

.icon-space {
  margin-top: 8px;
  margin-right: 7px;
}

.table thead th {
  border-bottom: 2px solid #c9c9c9 !important;
}

.table-bordered td,
.table-bordered th {
  border-color: #bbbbbb;
}

.mt25 {
  margin-top: 25px;
}

.user-img {
  margin-top: 0px;
  width: 50px;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px !important;
  border-radius: 15px !important;
  text-align: center !important;
  font-size: 12px !important;
  line-height: 1.42857 !important;
}

.load-style .loader-box {

  height: 38px;
  text-align: left !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: left !important;
  -ms-flex-align: left !important;
  align-items: left !important;
  vertical-align: left !important;
  -webkit-box-pack: left !important;
  -ms-flex-pack: left !important;
  justify-content: left !important;
  -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
  transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
  transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
  transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;

}

.input-tel.has-hint .input-tel__label,
.input-tel.has-value .input-tel__label {
  display: none;
}
</style>