<template>
  <div>
    <!-- page-wrapper Start-->
    <div class="container-fluid p-0">
      <div class="row m-0">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img class="img-fluid for-light" src="../assets/images/logo/login.png" alt="looginpage" />
                  <img class="img-fluid for-dark" src="../assets/images/logo/logo_dark.png" alt="looginpage" />
                </a>
              </div>
              <div class="login-main">
                <b-card no-body>
                  <b-tabs pills vertical>
                    <b-card-text>
                      <!-- <form class="theme-form"> -->
                      <h4>Verify in to account</h4>
                      <p>Enter your one time password(OTP)</p>
                      <!-- <div class="form-group"> -->
                      <label class="col-form-label">OTP</label>
                      <v-otp-input length="6" type="number" v-model="otp"></v-otp-input>
                      <!-- </div> -->
                      <div class="form-group mb-0">
                        <button v-if="loader == false" class="btn btn-primary btn-block" type="button" @click="verify">
                          verify
                        </button>
                        <div v-if="loader == true" class="loader-box">
                          <div class="loader-15"></div>

                        </div>
                      </div>


                      <!-- </form> -->
                    </b-card-text>
                  </b-tabs>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- latest jquery-->
  </div>
</template>

<script>
import Service from '@/services/Service.js';
import firebase from '../firebase.js'

export default {
  name: 'login',
  components: {
  },
  data() {
    return {
      otp: '',
      submitted: false,
      loader: false
    };
  },
  mounted: function () {
    this.loginIsValid()
  },
  methods: {
    loginIsValid: function () {

      if (localStorage.getItem("loginDetails") === null) {
        localStorage.removeItem('loginDetails')
      } else {
        this.$router.push('/home');
      }
    },
    verify: function () {
      this.submitted = true;
      if (this.email != '' && this.otp != '') {

        this.loginOtpVerify();



      } else {
        this.$toasted.show('One time password is required', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

      }
    },
    async loginOtpVerify() {
      this.loader = true;
      var phone = localStorage.getItem('phone')
      var verificationId = localStorage.getItem('verificationId')

      const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, this.otp)
      firebase.auth().signInWithCredential(credential)
        .then(userCredential => {
          this.loader = false;
          const user = userCredential.user
          return user.getIdToken()
        })
        .then(idToken => {
          const credentials = {
            phone: phone,
            token: idToken,
            type: 'WEB',
          };
          this.loader = true;
          this.verifyFireBaseOtp(credentials);
        }).catch(error => {
          if (error.code === 'auth/invalid-verification-code') {
            this.$toasted.show('Invalid OTP. Please enter a valid OTP and try again.', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
          }
          else if(error.code === 'auth/code-expired') {
            this.$router.push('/');
            this.$toasted.show(error.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

          }
          else {
            console.log(error);
          }
          this.loader = false;
        })




    },
    async verifyFireBaseOtp(credentials) {

      this.loader = true;
      const response = await Service.verifyFireBaseOtp(credentials);
      if (response.code == '200') {
        this.loader = false;
        localStorage.setItem('loginDetails', JSON.stringify(response.data))
        localStorage.removeItem('phone')
        localStorage.removeItem('verificationId')
        localStorage.setItem('menuData', JSON.stringify(response.menu_data))
        localStorage.setItem('id', JSON.stringify(response.id))
        this.$router.go('/home')
      } else {
        this.loader = false;
        this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

        // this.$toast("Default toast");
        // this.$toast.info("Info toast");
        // this.$toast.success("Success toast");
        // this.$toast.error("Error toast");
        // this.$toast.warning("Warning toast");

      }

    }
  },
};
</script>
<style>
.loader-box {
  height: 7px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
  transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
  transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
  transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;
}
</style>