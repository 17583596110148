<template>
    <div>
        <Breadcrumbs main="Leave Acknowledges" title="Leave Acknowledge List" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">


                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">
                          
                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">

                                    <template #cell(status)="rows">
                                        <b-badge :variant="statusVariant(rows.item.status)">

                                            <span v-if="rows.item.status == 1">
                                                Pending
                                            </span>
                                            <span v-else-if="rows.item.status == 2">
                                                Acknowledged
                                            </span>
                                            <span v-else-if="rows.item.status == 3">
                                                Approved
                                            </span>
                                            <span v-else-if="rows.item.status == 4">
                                                Canceled
                                            </span>
                                            <span v-else-if="rows.item.status == 5">
                                                Rejected
                                            </span>
                                        </b-badge>
                                    </template>
                                   

                                    <template #cell(actions)="rows">
                                        <span v-if="rows.item.status == 3" title="Acknowledge">
                                            <feather type="thumbs-up" @click="ackLeave(rows.item)"></feather>
                                        </span>

                                    </template>

                                </b-table>

                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>


                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import ServiceApi from '@/services/ServiceApi.js';
import Swal from "sweetalert2";

export default {
    data() {
        return {
            tablefields: [
                { key: 'emp_name', label: 'Employee', sortable: true, },
                { key: 'type', label: 'Leave Type', sortable: true, },
                { key: 'reason', label: 'Reason', sortable: true, },
                { key: 'start_date', label: 'From', sortable: true, },
                { key: 'end_date', label: 'To', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],

            items: [],
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
        };
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-warning",
                2: "light-info",
                3: "light-success",
                4: "light-danger",
                5: "light-danger",
            };
            return (status) => statusColor[status];
        },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        }
    },
    mounted() {
      
        this.retrieveLeaveAcks();
    },
    methods: {
       
        async retrieveLeaveAcks() {
            const response = await ServiceApi.GetLeaveAcks();
            if (response.code == "200") {
                this.items = response.data;
                this.totalRows = response.data.length;
            }
            else{
                this.items = "";
            }
        },
      
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
      
        ackLeave(data) {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to acknowledge this Leave!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Acknowledge!",
        }).then((result) => {
            if (result.isConfirmed) {

            const credentials = {
                employee_leave_id: data.id,
            };

            this.leaveAck(credentials);
            }
        });
        },

        async leaveAck(credentials) {
        const response = await ServiceApi.LeaveAck(credentials);
        if (response.code == "200") {
            Swal.fire("Acknowledged!", "Leave has been acknowledged.", "success");
            this.retrieveLeaveAcks();
          
        }
        else if (response.code == '400') {
            //message = response.
            this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
        }
        else if (response.code == "403") {
            this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
            localStorage.removeItem('loginDetails')
            localStorage.removeItem('menuData')
            this.$router.push('/');
        }
        },

        
    }
};
</script>