<template>
    <div>
        <Breadcrumbs main="Holidays" title="Add Holiday" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <label for="title">Name</label>
                                        <b-form-input type="text" id="name" v-model="c_form.name"
                                            :state="c_form_result.name" placeholder="Name"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.name">
                                        </b-form-valid-feedback>
                                    </div>
                                  
                                 
                                    <div class="col-md-4 mb-3">
                                        <div class="form-group">
                                            <label class="form-label"> Date</label>
                                            <datepicker :format="customFormatter"
                                                input-class="datepicker-here form-control digits"
                                                v-model="c_form.date">
                                            </datepicker>
                                        </div>
                                    </div>

                                    <div class="col-md-4 mb-3">
                                        <label for="c_form_priority">Year</label>
                                        <v-select v-model="c_form.year" label="text" :options="years" />

                                    </div>
                                 

                                </div>

                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from '@/services/ServiceApi.js';
import vSelect from 'vue-select'
import { VueEditor } from 'vue2-editor';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'

export default {

    components: {
        vSelect,
        VueEditor,
        Datepicker,
    },

    data() {
        return {
            success: false,
            years:[],
            year:null,
            c_form: {
                name: '',
                date: '',
                year: '',

            },
            c_form_result: {
                name: null,
                date: null,
                year:null,

            },
           
        };
    },
    computed: {

    },
    mounted() {
        const currentYear = new Date().getFullYear();
        const nextYear = currentYear + 5;
        for (let year = nextYear; year >= nextYear - 50; year--) {
        this.years.push(year);
        }

    },
    methods: {
        customFormatter(date) {
            return moment(date).format('Y-MM-DD');
        },

        onCustomStyleSubmit(evt) {
            evt.preventDefault();

            if (this.c_form.name == '') {
                this.success = false;
                this.$toasted.show(' Please enter Name', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }

            else if (this.c_form.date == '') {
                this.success = false;
                this.$toasted.show(' Please select Date', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.year == '') {
                this.success = false;
                this.$toasted.show(' Please select Year', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
          
            else {
                this.success = true;
            }

            this.saveHoliday();
        },
        saveHoliday() {

            if (this.success == true) {
                if (this.c_form.date != null) {
                    var date = this.customFormatter(this.c_form.date);
                }

                const holiday_data = {
                    name: this.c_form.name,
                    date: date,
                    year: this.c_form.year,
                }
                this.addHoliday(holiday_data)
            }
        },
        async addHoliday(holiday_data) {
            const response = await ServiceApi.AddHoliday(holiday_data);
            if (response.code == '200') {
                this.$toasted.show(' Holiday added successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/holidays');
            }
            else if (response.code == '400') {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },

    }
};
</script>
