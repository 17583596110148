<template>
    <div>
        <Breadcrumbs main="Home" title="Project View" />
        <div class="container-fluid">
            <div class="email-wrap bookmark-wrap">
                <div class="row">
                    <div class="col-xl-3 box-col-6">
                        <div class="email-left-aside">
                            <div class="card pro-view">
                                <div class="card-body"><span v-if="data.val_view == 1 ? getP() : ''"></span>
                                    <div class="email-app-sidebar left-bookmark">
                                        <div class="media">

                                            <div class="media-body">
                                                <h6 class="f-w-600">{{ data.name }}</h6>
                                            </div>
                                            <router-link :to="'/home/employee-view/' + id + '/1'">
                                                <button type="button" class="btn btn-success btn-circle" title="Projects"><i
                                                        class="fa fa-book"></i></button>

                                            </router-link>
                                        </div>
                                        <hr />
                                        <ul class="nav main-menu" role="tablist">
                                            <li>
                                                <a id="pills-created-tab" data-toggle="pill" href="javascript:void(0)"
                                                    role="tab" aria-controls="pills-created" aria-selected="true"><span
                                                        class="title" v-on:click="say('pills_created')">
                                                        Project Details</span></a>
                                            </li>
                                            <li>
                                                <a class="show" id="pills-phase-tab" data-toggle="pill"
                                                    href="javascript:void(0)" role="tab" aria-controls="pills-phase"
                                                    aria-selected="false" @click="phaseHead = 4"><span class="title"
                                                        v-on:click="say('pills_phase')">
                                                        Project Phase</span></a>
                                            </li>
                                            <li>
                                                <a class="show" id="pills-modules-tab" data-toggle="pill"
                                                    href="javascript:void(0)" role="tab" aria-controls="pills-modules"
                                                    aria-selected="false" @click="modulesHead = 4"><span class="title"
                                                        v-on:click="say('pills_modules')">
                                                        Module</span></a>
                                            </li>
                                            <li>
                                                <a class="show" id="pills-shared-tab" data-toggle="pill"
                                                    href="javascript:void(0)" role="tab" aria-controls="pills-shared"
                                                    aria-selected="false"
                                                    @click="data.val = '', data.val_type = '', data.val_add = ''"><span
                                                        class="title" v-on:click="say('pills_shared')">
                                                        Tasks</span></a>
                                            </li>

                                            <li>
                                                <a class="show" id="pills-sub-tab" data-toggle="pill"
                                                    href="javascript:void(0)" role="tab" aria-controls="pills-sub"
                                                    aria-selected="false" @click=" 2"><span class="title"
                                                        v-on:click="say('pills_sub')">
                                                        Sub Tasks</span></a>
                                            </li>

                                            <li
                                                v-if="(data.progress_status == 'bg-success') && (this.pManager == 'MANAGER')">
                                                <a class="show" id="pills-shared-tab" data-toggle="pill"
                                                    href="javascript:void(0)" role="tab" aria-controls="pills-shared"
                                                    aria-selected="false" @click="data.val = ''"><span class="title"
                                                        v-on:click="say('pills_wages')">
                                                        Wages</span></a>
                                                <!-- <div class="col-12">
                                                            <a @click="projectUnHold" class="btn btn-primary btn-xs">Unhold Project...
                                                            </a>
                                                        </div> -->
                                            </li>
                                            <li>
                                                <hr />
                                            </li>
                                            
                                            <li v-if="(data.progress_status == 1) && (this.pManager == 'MANAGER')">
                                                <center>
                                                    <br>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <p>
                                                                Ongoing Tasks count :
                                                                <b-badge class="badge-count" variant="warning">
                                                                    {{ data.ong_task }}
                                                                </b-badge>
                                                            </p>
                                                        </div>
                                                    </div><br>
                                                    <div class="col-12">
                                                        <a @click="projectHold" class="btn btn-primary btn-xs">Hold
                                                            Project
                                                        </a>
                                                    </div>
                                                    <div class="col-12">
                                                        <a @click="projectEnd" class="btn btn-primary btn-xs">End Project!
                                                        </a>
                                                    </div>
                                                </center>
                                            </li>
                                            <li v-else-if="(data.progress_status == 3) && (this.pManager == 'MANAGER')">
                                                <center>
                                                    <div class="col-12">
                                                        <a @click="projectUnHold" class="btn btn-primary btn-xs">Unhold
                                                            Project
                                                        </a>
                                                    </div>
                                                </center>
                                            </li>
                                            <li v-else-if="(data.progress_status == 2) && (this.pManager == 'MANAGER')">
                                                <!-- project end, no need to show buttons -->
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-9 col-md-12 box-col-12 pro2-view">
                        <div class="email-right-aside bookmark-tabcontent">
                            <div class="card email-body radius-left">
                                <div class="pl-0">
                                    <div class="tab-content">
                                        <div class="tab-pane fade" v-bind:class="activeclass === 'pills_created' ? 'active show' : ''
                                            " id="pills-created" role="tabpanel" aria-labelledby="pills-created-tab">
                                            <div class="card mb-0">
                                                <div class="card-header d-flex">
                                                    <h5 class="mb-0">Project Details</h5>
                                                </div>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div class="collection-filter-block">
                                                            <ul>
                                                                <li>
                                                                    <div class="media">
                                                                        <feather type="user" class="icon-space">
                                                                        </feather>
                                                                        <div class="media-body">
                                                                            <h6>Name : {{ data.name }}</h6>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="media">
                                                                        <feather type="droplet" class="icon-space">
                                                                        </feather>
                                                                        <div class="media-body">
                                                                            <h6>
                                                                                Description : {{ data.desc }}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="media">
                                                                        <feather type="calendar" class="icon-space">
                                                                        </feather>
                                                                        <div class="media-body">
                                                                            <h6>
                                                                                Start date :
                                                                                {{
                                                                                    data.start_date |
                                                                                    moment("DD-MM-YYYY")
                                                                                }}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="media">
                                                                        <feather type="book-open" class="icon-space">
                                                                        </feather>
                                                                        <div class="media-body">
                                                                            <h6>
                                                                                End date :
                                                                                {{
                                                                                    data.end_date | moment("DD-MM-YYYY")
                                                                                }}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </li>


                                                                <li>
                                                                    <div class="media">
                                                                        <feather type="credit-card" class="icon-space">
                                                                        </feather>
                                                                        <div class="media-body">
                                                                            <h6 v-if="data.priority == 1">
                                                                                Priority : High
                                                                            </h6>
                                                                            <h6 v-else-if="data.priority == 2">
                                                                                Priority : Medium
                                                                            </h6>
                                                                            <h6 v-else>
                                                                                Priority : Low
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </li>


                                                                <li>
                                                                    <div class="media">
                                                                        <feather type="credit-card" class="icon-space">
                                                                        </feather>
                                                                        <div class="media-body">
                                                                            <h6>
                                                                                Tasks : {{ data.task }}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="media">
                                                                        <feather type="credit-card" class="icon-space">
                                                                        </feather>
                                                                        <div class="media-body">
                                                                            <h6>
                                                                                Members count :
                                                                                {{ data.member_count }}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="media">
                                                                        <feather type="credit-card" class="icon-space">
                                                                        </feather>
                                                                        <div class="media-body">
                                                                            <h6>
                                                                                Project Manager :
                                                                                {{ data.manager }}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li v-if="data.file">
                                                                    <div class="media">
                                                                        <feather type="download">Download</feather>
                                                                        &nbsp;&nbsp;&nbsp;

                                                                        <div class="media-body">
                                                                            <h6>
                                                                                Project document :

                                                                                <a :href="data.file" target="_blank"
                                                                                    download>
                                                                                    Download
                                                                                </a>
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li v-if="data.progress_status != 'bg-primary'">
                                                                    <div class="media">
                                                                        <feather type="credit-card" class="icon-space">
                                                                        </feather>
                                                                        <div class="media-body">
                                                                            <h6>
                                                                                Duration :
                                                                                {{ time_seconds }}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <br />
                                                                <hr />
                                                                <div>
                                                                    <project-member-list v-if="data.allMem"
                                                                        :p_members='data.allMem' />
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="fade tab-pane" v-bind:class="activeclass === 'pills_shared' ? 'active show' : ''
                                            " id="pills_shared" role="tabpanel" aria-labelledby="pills_shared-tab">
                                            <div class="card mb-0">
                                                <div class="card-header d-flex">
                                                    <h5 class="mb-0">{{ (taskHead == 1) ? 'Task View' :
                                                        (taskHead == 2) ? 'Task Edit' :
                                                            (taskHead == 3) ? 'Task Add' :
                                                                (taskHead == 4) ? 'Task List' : '' }}</h5>
                                                </div>
                                                <div v-if="data.val != ''">
                                                    <task-view v-if="data.id" :taskId.sync='data'
                                                        :heading.sync='taskHead' />
                                                </div>
                                                <div v-else-if="data.val_type != ''">
                                                    <task-edit v-if="data.id" :taskEditId.sync='data'
                                                        :heading.sync='taskHead' />
                                                </div>
                                                <div v-else-if="data.val_add != ''">
                                                    <task-add v-if="data.id" :taskProjectId.sync='data'
                                                        :heading.sync='taskHead' />
                                                </div>
                                                <div v-else>
                                                    <task-list v-if="data.id" :project_id.sync='data'
                                                        :heading.sync='taskHead' />
                                                </div>

                                            </div>
                                        </div>


                                        <div class="fade tab-pane" v-bind:class="activeclass === 'pills_phase' ? 'active show' : ''
                                            " id="pills_phase" role="tabpanel" aria-labelledby="pills_phase-tab">
                                            <div class="card mb-0">
                                                <div class="card-header d-flex">
                                                    <h5 class="mb-0">{{ (phaseHead == 1) ? 'Project Phase View' :
                                                        (phaseHead == 2) ? 'Project Phase Edit' :
                                                            (phaseHead == 3) ? 'Project Phase Add' :
                                                                (phaseHead == 4) ? 'Project Phase List' : '' }}</h5>
                                                </div>
                                                <div v-if="phaseHead == 4">
                                                    <project-phase-list v-if="phaseHead" :phaseId.sync='phaseHead'
                                                        :projectPhaseId.sync='project_phase_id' />
                                                </div>
                                                <div v-else-if="phaseHead == 3">
                                                    <project-phase-add v-if="phaseHead" :phaseId.sync='phaseHead' />
                                                </div>
                                                <div v-else-if="phaseHead == 2">
                                                    <project-phase-edit v-if="phaseHead" :phaseId.sync='phaseHead'
                                                        :projectPhaseId.sync='project_phase_id' />
                                                </div>
                                                <div v-else-if="phaseHead == 1">
                                                    <project-phase-view v-if="phaseHead" :phaseId.sync='phaseHead'
                                                        :projectPhaseId.sync='project_phase_id' />
                                                </div>

                                            </div>
                                        </div>



                                        <div class="fade tab-pane" v-bind:class="activeclass === 'pills_modules' ? 'active show' : ''
                                            " id="pills_modules" role="tabpanel" aria-labelledby="pills_modules-tab">
                                            <div class="card mb-0">
                                                <div class="card-header d-flex">
                                                    <h5 class="mb-0">{{ (modulesHead == 1) ? 'Module View' :
                                                        (modulesHead == 2) ? 'Module Edit' :
                                                            (modulesHead == 3) ? 'Module Add' :
                                                                (modulesHead == 4) ? 'Module List' : '' }}</h5>
                                                </div>
                                                <div v-if="modulesHead == 4">
                                                    <modules-list v-if="modulesHead" :modulesId.sync='modulesHead'
                                                        :projectModulesId.sync='modules_id' />
                                                </div>
                                                <div v-else-if="modulesHead == 3">
                                                    <modules-add v-if="modulesHead" :modulesId.sync='modulesHead' />
                                                </div>
                                                <div v-else-if="modulesHead == 2">
                                                    <modules-edit v-if="modulesHead" :modulesId.sync='modulesHead'
                                                        :projectModulesId.sync='modules_id' />
                                                </div>
                                                <div v-else-if="modulesHead == 1">
                                                    <modules-view v-if="modulesHead" :modulesId.sync='modulesHead'
                                                        :projectModulesId.sync='modules_id' />
                                                </div>

                                            </div>
                                        </div>



                                        <div class="fade tab-pane" v-bind:class="activeclass === 'pills_sub' ? 'active show' : ''
                                            " id="pills_sub" role="tabpanel" aria-labelledby="pills_sub-tab">
                                            <div class="card mb-0">
                                                <div class="card-header d-flex">
                                                    <h5 class="mb-0">{{ (subHead == 1) ? 'Sub Task Add' :
                                                        (subHead == 2) ? 'Sub Task List' : '' }}</h5>
                                                </div>
                                                <div v-if="subHead == 2">
                                                    <sub-task-list v-if="subHead" :projectId.sync='subHead'
                                                        :projectId='project_id' />
                                                </div>
                                                <div v-else-if="subHead == 1">
                                                    <sub-task-add v-if="subHead" :projectId.sync='subHead' />
                                                </div>
                                            </div>
                                        </div>


                                        <div class="fade tab-pane" v-bind:class="activeclass === 'pills_wages' ? 'active show' : ''
                                            " id="pills_wages" role="tabpanel" aria-labelledby="pills_shared-tab">
                                            <div class="card mb-0">
                                                <div class="card-header d-flex">
                                                    <h5 class="mb-0">Wage Details</h5>
                                                </div>
                                                <project-wage v-if="data.id" :project_det='data' />
                                            </div>
                                        </div>

                                        <!--tab div end-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Service from "@/services/Service.js";
import ServiceApi from "@/services/ServiceApi.js";
import vSelect from "vue-select";
import moment from "moment";
import TaskList from "../Tasks/task-list.vue";
import TaskView from "../Tasks/task-view.vue";
import TaskEdit from "../Tasks/edit.vue";
import TaskAdd from "../Tasks/add.vue";
import ProjectPhaseList from "../Project_Phase/list.vue";
import ProjectPhaseView from "../Project_Phase/view.vue";
import ProjectPhaseAdd from "../Project_Phase/add.vue";
import ProjectPhaseEdit from "../Project_Phase/edit.vue";
import ModulesList from "../Modules/list.vue";
import ModulesView from "../Modules/view.vue";
import ModulesAdd from "../Modules/add.vue";
import ModulesEdit from "../Modules/edit.vue";
import SubTaskAdd from "../Sub_Task/add.vue";
import SubTaskList from "../Sub_Task/sub-task-list.vue";
import ProjectMemberList from "./project-member-list.vue";
import ProjectWage from "./project-wage.vue";
import Swal from "sweetalert2";

export default {
    name: "bookmark",
    components: {
        vSelect,
        TaskList,
        TaskView,
        ProjectMemberList,
        ProjectWage,
        TaskEdit,
        TaskAdd,
        ProjectPhaseList,
        ProjectPhaseAdd,
        ProjectPhaseEdit,
        ProjectPhaseView,
        ModulesList,
        ModulesView,
        ModulesAdd,
        ModulesEdit,
        SubTaskAdd,
        SubTaskList,
    },
    data() {
        return {
            taskHead: 'Tasks Details',
            project_phase_id: null,
            phaseHead: 4,
            modules_id: null,
            modulesHead: 4,
            subHead: 2,
            projectId: null,
            editShow: false,
            addShow: { 'taskId': null, 'show': false },
            success: false,
            changecount: null,
            leavecount: null,
            leave_type_name: null,
            gender: null,
            employee_status: null,
            liststyle: false,
            data: [],
            priority: null,
            pro_id: null,
            leave_data: [],
            activeclass: "pills_created",
            favourite: [],
            pManager: null,
            tablefields: [
                { key: "leave_type_name", label: "Leave Type", sortable: true },
                { key: "count", label: "Leave Balance", sortable: true },
            ],
            items: [],

            filter: null,

            c_form: {
                count: "",
            },
            c_form_result: {
                count: null,
            },
            time_seconds: null,
            loginData: JSON.parse(localStorage.getItem('loginDetails')),
            id: JSON.parse(localStorage.getItem('id')),
            project_id: this.$route.params.id,
        };
    },

    computed: {

    },
    mounted() {
        this.getProjectDetails();
        this.getLeaveCountByUser();
        this.pManager = this.loginData.designation.level.key;
    },
    methods: {
        getImgUrl(path) { },
        getP() {
            this.getProjectDetails();
            this.getLeaveCountByUser();
        },
        async getProjectDetails() {
            const ReqData = {
                id: this.$route.params.id,
            };

            const response = await Service.GetProjectDetails(ReqData);

            if (response.code == "200") {
                // console.log(response);
                this.data = response.data.all;
                this.pro_id = response.data.all.id;

                var sec_num = response.data.all.duration
                var hours = Math.floor(sec_num / 3600);
                var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
                var seconds = sec_num - (hours * 3600) - (minutes * 60);

                if (hours < 10) { hours = "0" + hours; }
                if (minutes < 10) { minutes = "0" + minutes; }
                if (seconds < 10) { seconds = "0" + seconds; }
                this.time_seconds = hours + ':' + minutes + ':' + seconds;

            }
        },

        async getLeaveCountByUser() {
            const ReqData = {
                id: this.$route.params.id,
            };

            const response = await ServiceApi.GetLeaveCountByUser(ReqData);

            if (response.code == "200") {
                this.items = response.data;
                this.totalRows = response.data.length;
            }
        },
        handleCountChange(value, leave_type_name, count) {
            if (value <= count) {
                this.success = true;
            } else {
                this.$toasted.show(
                    "Max leave count for " + leave_type_name + " is " + count,
                    {
                        theme: "outline",
                        position: "top-right",
                        type: "error",
                        duration: 2000,
                    }
                );
                this.success = false;
                this.$bvModal.show("bv-modal-example");
            }
        },
        onCustomStyleSubmit(evt) {
            evt.preventDefault();

            if (this.success == true) {
                this.saveLeaveCount();
                this.$bvModal.hide("bv-modal-example");
            }
            this.getLeaveCountByUser();
        },

        saveLeaveCount() {
            if (this.success == true) {
                const count_data = this.items;
                this.updateLeaveCount(count_data);
            }
        },

        async updateLeaveCount(count_data) {
            const response = await ServiceApi.UpdateUserLeave(count_data);
            if (response.code == "200") {
                this.$toasted.show(" Leave count updated successfully ", {
                    theme: "outline",
                    position: "top-right",
                    type: "success",
                    duration: 2000,
                });
                this.getLeaveCountByUser();
            }
        },
        projectEnd() {
            if (this.data.ong_task == 0) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to end this Project!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, End!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.end_curr_project();
                    }
                });

            } else {
                this.$toasted.show(this.data.ong_task + ' Task is not completed yet ', { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
            }
        },
        async end_curr_project() {
            const reqData = {
                project: this.$route.params.id,
            };
            const response = await Service.EndProject(reqData);
            if (response.code == "200") {
                this.$toasted.show(" Project completed ", {
                    theme: "outline",
                    position: "top-right",
                    type: "success",
                    duration: 2000,
                });
                this.getProjectDetails();
                this.getLeaveCountByUser();
            }
        },

        // new 17 
        projectHold() {
            Swal.fire({
                title: "Are you sure?",
                text: "Are you sure you want to hold this Project!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Hold!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.hold_project();
                }
            });
        },

        async hold_project() {
            const reqData = {
                project: this.$route.params.id,
            };
            const response = await Service.HoldProject(reqData);
            if (response.code == "200") {
                this.$toasted.show(" Project holded ", {
                    theme: "outline",
                    position: "top-right",
                    type: "success",
                    duration: 2000,
                });
                this.getProjectDetails();
            }
        },
        // new 17 end


        // new 18
        projectUnHold() {
            Swal.fire({
                title: "Are you sure?",
                text: "Are you sure you want to unhold this Project!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Unhold!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.unhold_project();
                }
            });
        },

        async unhold_project() {
            const reqData = {
                project: this.$route.params.id,
            };
            const response = await Service.UnHoldProject(reqData);
            if (response.code == "200") {
                this.$toasted.show(" Project unholded ", {
                    theme: "outline",
                    position: "top-right",
                    type: "success",
                    duration: 2000,
                });
                this.getProjectDetails();
            }
        },
        // new 18 end


        say: function (message) {
            this.activeclass = message;
        },
    },
};
</script>
<style lang="scss">
@import "vue-select/dist/vue-select.css";

.icon-space {
    margin-top: 8px;
    margin-right: 7px;
}

.email-wrap .email-app-sidebar .main-menu>li a {
    line-height: 39px;
    text-transform: uppercase;
    border-radius: 10px !important;
}

.badge-count {
    font-size: 100% !important;
}

.pro-view{
    width: 95% !important;
}

.pro2-view{
    padding-left: 0px !important;
}
</style>