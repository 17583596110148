<template>
</template>

<script>
  export default {
    name: 'LevelMenu',
    data() {
      return {
        openLevelmenu: false,
      };
    },
    methods: {
      openlevelmenu() {
        this.openLevelmenu = !this.openLevelmenu;
      },
    },
  };
</script>
