<template>
    <div><br>
        <Breadcrumbs main="Project" :title="projName + ' / Add Module'" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="name">Module Name</label>
                                        <b-form-input type="text" id="name" v-model="name" placeholder="Module Name">
                                        </b-form-input>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="description">Module Description</label>
                                        <b-form-textarea type="text" id="description" v-model="description"
                                            placeholder="Module Description">
                                        </b-form-textarea>
                                    </div>

                                    <b-col md="6">
                                        <label for="phases">Project Phase</label>
                                        <!-- <v-select v-model="phase" label="title" placeholder="Project Phase" /> -->
                                        <b-form-input type="text" id="name" v-model="phases.name" readonly
                                            placeholder="Project Phase">
                                        </b-form-input>
                                    </b-col>

                                    <b-col md="6">
                                        <label for="employees">Task Manager</label>
                                        <v-select v-model="selected_employees" label="name" placeholder="Task Manager"
                                            :options="employees" :multiple="true" />
                                    </b-col>


                                </div><br>
                                <div class="row">
                                    <div class="col-11">

                                    </div>
                                    <div class="col-1" v-if="loadFlag">
                                        <b-button type="submit" variant="primary">Submit</b-button>
                                    </div>
                                    <div class="col-1" v-else>
                                        <div class="loader-box ">
                                            <div class="loader-15"></div>
                                        </div>
                                    </div>
                                </div>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import moment from "moment";
import { VueEditor } from 'vue2-editor';
export default {

    components: {
        vSelect,
        VueEditor
    },

    data() {
        return {
            // success: false,
            // startEndStatus: [
            //     { value: '1', text: 'Pending' },
            //     { value: '2', text: 'Start' },
            // ],
            name: '',
            description: '',
            employees: [],
            selected_employees: [],
            phase: null,
            phases: [],
            phase_id: null,
            loadFlag: true,
            projName: null,
            // loginData: JSON.parse(localStorage.getItem('loginDetails')),
        };
    },
    computed: {
    },
    created() {
        this.getEmployees();
        this.getPhaseDetails(this.$route.params.id)
    },
    methods: {
        async getPhaseDetails(phase) {
            var reqData = {
                id: phase
            }
            const response = await Service.GetPhaseDataById(reqData);
            if (response.code == '200') {
                //   console.log(response.data);
                this.projName = response.data.proj_name;
                this.phases = response.data;

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getEmployees() {
            const response = await Service.GetEmployees();
            if (response.code == '200') {
                //   console.log(response.data);
                this.employees = response.data;

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        onCustomStyleSubmit(evt) {
            this.loadFlag = false;
            evt.preventDefault();
            if (this.name == '' || this.description == null || this.selected_employees.length == 0) {
                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                this.loadFlag = true;
            } else {
                this.saveModules();
            }
        },
        saveModules() {
            // console.log(this.selected_employees);
            const modules_data = {
                name: this.name,
                description: this.description,
                // project_id: this.$route.params.id,
                selected_employees: this.selected_employees,
                phase: this.$route.params.id,
            };
            // console.log(modules_data);
            this.addModules(modules_data)
        },
        async addModules(modules_data) {
            const response = await Service.AddModules(modules_data);
            if (response.code == '200') {
                this.loadFlag = true;
                this.$toasted.show(' Modules added successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/emp-project/' + response.data);
            } else if (response.code == "400") {
                this.loadFlag = true;
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
        },

    }
};
</script>
<style>
.ck-content {
    height: 150px;
}
.loader-box {
    height: 50px !important;
}
</style>