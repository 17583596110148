<template>
  <div>
    <Breadcrumbs main="Leave Types" title="Edit Leave Type" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <px-card :actions="false">
            <div slot="with-padding">
              <b-form class="needs-validation" @submit="onFormSubmit">
                <div class="form-row">
                  <div class="row">
                    <div class="col-md-3 mb-3">
                      <div class="form-group">
                        <label class="form-label">Leave Type</label>
                        <b-form-input type="text" v-model="leavetype_name"></b-form-input>
                      </div>
                    </div>

                    <div class="col-md-3 mb-3">
                      <div class="form-group">
                        <label class="form-label">Count</label>
                        <b-form-input type="number" v-model="count"></b-form-input>
                      </div>
                    </div>

                    <div class="col-md-2 mb-3">
                      <label for="c_form">Leave Type Key</label>
                      <v-select v-model="leave_key" label="text" :options="leave_keys" />
                    </div>

                    <div class="col-md-2 mb-3">
                      <label for="c_form">Calculation </label>
                      <v-select v-model="calc_status" label="text" :options="calculations" />
                    </div>

                    <div class="col-md-2 mb-3">
                      <label for="c_form">Validation status</label>
                      <v-select v-model="validation_status" label="text" :options="validationstatus" />
                    </div>

                  </div>
                </div>

                <h5>Leave Type Configurations</h5>
                <br>
                <div class="form-row" v-for="(item, index) in configbytype" :key="index">
                  <div class="row" v-if="((item.config_name == 'MAX-COUNT-IN-A-SEQUENCE') && show)">
                    <div class="col-md-4 mb-3">
                      <div class="form-group">
                        <label class="form-label">Config Name</label>
                        <b-form-input type="text" v-model="item.config_name" readonly></b-form-input>
                      </div>
                    </div>
                    <div class="col-md-5 mb-3">
                      <div class="form-group">
                        <label class="form-label">Description</label>
                        <textarea class="form-control" rows="1" v-model="item.description" readonly>
                        </textarea>

                      </div>

                    </div>

                    <div class="col-md-2 mb-3" v-if="item.value_type == 1">
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <b-form-input type="text" v-model="item.config_val" :key="index"
                          @change="handleConfig(item.config_val, item.config_id, 1)"
                          v-on:input="hideShow1(item, item.config_id)"></b-form-input>
                      </div>
                    </div>

                    <div class="col-md-2 mb-3" v-else-if="item.value_type == 2">
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <v-select v-model="item.config_val" label="text" :options="valueTypes"
                          @input="handleConfig(item.config_val, item.config_id, 2)" v-on:input="hideShowDoc1(item)"/>

                      </div>
                    </div>

                    <div class="col-md-2 mb-3" v-else>
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <b-form-input type="text" v-model="item.config_val" :key="index"
                          @change="handleConfig(item.config_val, item.config_id, 1)"></b-form-input>
                      </div>
                    </div>

                    <div class="row col-md-1 mb-3" v-if="item.status == 1">

                      <toggle-button @change="onChangeStatusHandler(item.config_id)"
                        :labels="{ checked: 'On', unchecked: 'Off' }" v-model="item.checkedValue"
                        style="padding: 50px 5px;" />

                    </div>
                    <div class="row col-md-1 mb-3" v-else>

                      <toggle-button @change="onChangeStatusHandler(item.config_id)"
                        :labels="{ checked: 'On', unchecked: 'Off' }" v-model="checkedValue"
                        style="padding: 50px 5px;" />

                    </div>

                  </div>

                  <div class="row" v-else-if="((item.config_name == 'MAX-DAYS-WITHOUT-DOC') && showdoc)">
                    <div class="col-md-4 mb-3">
                      <div class="form-group">
                        <label class="form-label">Config Name</label>
                        <b-form-input type="text" v-model="item.config_name" readonly></b-form-input>
                      </div>
                    </div>
                    <div class="col-md-5 mb-3">
                      <div class="form-group">
                        <label class="form-label">Description</label>
                        <textarea class="form-control" rows="1" v-model="item.description" readonly>
                        </textarea>

                      </div>

                    </div>

                    <div class="col-md-2 mb-3" v-if="item.value_type == 1">
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <b-form-input type="text" v-model="item.config_val" :key="index"
                          @change="handleConfig(item.config_val, item.config_id, 1)"
                          v-on:input="hideShow1(item)"></b-form-input>
                      </div>
                    </div>

                    <div class="col-md-2 mb-3" v-else-if="item.value_type == 2">
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <v-select v-model="item.config_val" label="text" :options="valueTypes"
                          @input="handleConfig(item.config_val, item.config_id, 2)" />

                      </div>
                    </div>

                    <div class="col-md-2 mb-3" v-else>
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <b-form-input type="text" v-model="item.config_val" :key="index"
                          @change="handleConfig(item.config_val, item.config_id, 1)"></b-form-input>
                      </div>
                    </div>

                    <div class="row col-md-1 mb-3" v-if="item.status == 1">

                      <toggle-button @change="onChangeStatusHandler(item.config_id)"
                        :labels="{ checked: 'On', unchecked: 'Off' }" v-model="item.checkedValue"
                        style="padding: 50px 5px;" />

                    </div>
                    <div class="row col-md-1 mb-3" v-else>

                      <toggle-button @change="onChangeStatusHandler(item.config_id)"
                        :labels="{ checked: 'On', unchecked: 'Off' }" v-model="checkedValue"
                        style="padding: 50px 5px;" />

                    </div>

                  </div>

                  <div class="row" v-else-if="((item.config_name != 'MAX-COUNT-IN-A-SEQUENCE') && (item.config_name != 'MAX-DAYS-WITHOUT-DOC'))">
                    <div class="col-md-4 mb-3">
                      <div class="form-group">
                        <label class="form-label">Config Name</label>
                        <b-form-input type="text" v-model="item.config_name" readonly></b-form-input>
                      </div>
                    </div>
                    <div class="col-md-5 mb-3">
                      <div class="form-group">
                        <label class="form-label">Description</label>
                        <textarea class="form-control" rows="1" v-model="item.description" readonly>
                        </textarea>

                      </div>

                    </div>

                    <div class="col-md-2 mb-3" v-if="item.value_type == 1">
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <b-form-input type="text" v-model="item.config_val" :key="index"
                          @change="handleConfig(item.config_val, item.config_id, 1)"
                          v-on:input="hideShow1(item)"></b-form-input>
                      </div>
                    </div>

                    <div class="col-md-2 mb-3" v-else-if="item.value_type == 2">
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <v-select v-model="item.config_val" label="text" :options="valueTypes"
                          @input="handleConfig(item.config_val, item.config_id, 2)" 
                          v-on:input="hideShowDoc1(item)"/>

                      </div>
                    </div>

                    <div class="col-md-2 mb-3" v-else>
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <b-form-input type="text" v-model="item.config_val" :key="index"
                          @change="handleConfig(item.config_val, item.config_id, 1)"></b-form-input>
                      </div>
                    </div>

                    <div class="row col-md-1 mb-3" v-if="item.status == 1">

                      <toggle-button @change="onChangeStatusHandler(item.config_id)"
                        :labels="{ checked: 'On', unchecked: 'Off' }" v-model="item.checkedValue"
                        style="padding: 50px 5px;" />

                    </div>
                    <div class="row col-md-1 mb-3" v-else>

                      <toggle-button @change="onChangeStatusHandler(item.config_id)"
                        :labels="{ checked: 'On', unchecked: 'Off' }" v-model="checkedValue"
                        style="padding: 50px 5px;" />

                    </div>

                  </div>

                </div>


                <div class="form-row" v-for="(item, index) in new_configs" :key="index">
                  <div class="row" v-if="((item.config_name == 'MAX-COUNT-IN-A-SEQUENCE') && show)">
                    <div class="col-md-4 mb-3">
                      <div class="form-group">
                        <label class="form-label">Config Name</label>
                        <b-form-input type="text" v-model="item.config_name" readonly></b-form-input>
                      </div>
                    </div>
                    <div class="col-md-5 mb-3">
                      <div class="form-group">
                        <label class="form-label">Description</label>
                        <textarea class="form-control" rows="1" v-model="item.description" readonly>
                        </textarea>

                        <!-- <b-form-input type="text" v-model="item.description" readonly></b-form-input> -->
                      </div>

                    </div>

                    <div class="col-md-2 mb-3" v-if="item.value_type == 1">
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <b-form-input type="text" v-model="config_value[item.config_id]" :key="index"
                          @change="handleConfigNew(item.config_id, 1)" v-on:input="hideShow(item.config_name, item.config_id)"></b-form-input>
                      </div>
                    </div>

                    <div class="col-md-2 mb-3" v-else-if="item.value_type == 2">
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <v-select v-model="config_value[item.config_id]" label="text" :options="valueTypes"
                          @input="handleConfigNew(item.config_id, 2)" />

                      </div>
                    </div>

                    <div class="col-md-2 mb-3" v-else>
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <b-form-input type="text" v-model="config_value[item.config_id]" :key="index"
                          @change="handleConfigNew(item.config_id, 1)"></b-form-input>
                      </div>
                    </div>

                    <div class="row col-md-1 mb-3">

                      <toggle-button @change="onChangeEventHandler(item.config_id)"
                        :labels="{ checked: 'On', unchecked: 'Off' }" v-model="checkedValueNew"
                        style="padding: 50px 5px;" />

                    </div>


                  </div>

                  <div class="row" v-else-if="((item.config_name == 'MAX-DAYS-WITHOUT-DOC') && showdoc)">
                    <div class="col-md-4 mb-3">
                      <div class="form-group">
                        <label class="form-label">Config Name</label>
                        <b-form-input type="text" v-model="item.config_name" readonly></b-form-input>
                      </div>
                    </div>
                    <div class="col-md-5 mb-3">
                      <div class="form-group">
                        <label class="form-label">Description</label>
                        <textarea class="form-control" rows="1" v-model="item.description" readonly>
                        </textarea>

                        <!-- <b-form-input type="text" v-model="item.description" readonly></b-form-input> -->
                      </div>

                    </div>

                    <div class="col-md-2 mb-3" v-if="item.value_type == 1">
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <b-form-input type="text" v-model="config_value[item.config_id]" :key="index"
                          @change="handleConfigNew(item.config_id, 1)" v-on:input="hideShow(item.config_name, item.config_id)"></b-form-input>
                      </div>
                    </div>

                    <div class="col-md-2 mb-3" v-else-if="item.value_type == 2">
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <v-select v-model="config_value[item.config_id]" label="text" :options="valueTypes"
                          @input="handleConfigNew(item.config_id, 2)" />

                      </div>
                    </div>

                    <div class="col-md-2 mb-3" v-else>
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <b-form-input type="text" v-model="config_value[item.config_id]" :key="index"
                          @change="handleConfigNew(item.config_id, 1)"></b-form-input>
                      </div>
                    </div>

                    <div class="row col-md-1 mb-3">

                      <toggle-button @change="onChangeEventHandler(item.config_id)"
                        :labels="{ checked: 'On', unchecked: 'Off' }" v-model="checkedValueNew"
                        style="padding: 50px 5px;" />

                    </div>


                  </div>

                  <div class="row" v-else-if="((item.config_name != 'MAX-COUNT-IN-A-SEQUENCE') && (item.config_name != 'MAX-DAYS-WITHOUT-DOC'))" >
                    <div class="col-md-4 mb-3">
                      <div class="form-group">
                        <label class="form-label">Config Name</label>
                        <b-form-input type="text" v-model="item.config_name" readonly></b-form-input>
                      </div>
                    </div>
                    <div class="col-md-5 mb-3">
                      <div class="form-group">
                        <label class="form-label">Description</label>
                        <textarea class="form-control" rows="1" v-model="item.description" readonly>
                        </textarea>

                        <!-- <b-form-input type="text" v-model="item.description" readonly></b-form-input> -->
                      </div>

                    </div>

                    <div class="col-md-2 mb-3" v-if="item.value_type == 1">
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <b-form-input type="text" v-model="config_value[item.config_id]" :key="index"
                          @change="handleConfigNew(item.config_id, 1)" v-on:input="hideShow(item.config_name, item.config_id)"></b-form-input>
                      </div>
                    </div>

                    <div class="col-md-2 mb-3" v-else-if="item.value_type == 2">
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <v-select v-model="config_value[item.config_id]" label="text" :options="valueTypes"
                          @input="handleConfigNew(item.config_id, 2)" v-on:input="hideShowDoc(item.config_name, item.config_id)" />

                      </div>
                    </div>

                    <div class="col-md-2 mb-3" v-else>
                      <div class="form-group">
                        <label class="form-label">Value</label>
                        <b-form-input type="text" v-model="config_value[item.config_id]" :key="index"
                          @change="handleConfigNew(item.config_id, 1)"></b-form-input>
                      </div>
                    </div>

                    <div class="row col-md-1 mb-3">

                      <toggle-button @change="onChangeEventHandler(item.config_id)"
                        :labels="{ checked: 'On', unchecked: 'Off' }" v-model="checkedValueNew"
                        style="padding: 50px 5px;" />

                    </div>


                  </div>
                </div>



                <b-button type="submit" variant="primary">Submit</b-button>
              </b-form>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>
<script>
import ServiceApi from "@/services/ServiceApi.js";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },

  data() {
    return {
      checkedValue: false,
      show: false,
      showdoc: false,
      max_coun_month: null,
      checkedValueNew: false,
      success: false,
      leavetype_name: null,
      leave_key: null,
      count: null,
      validation_status_name: [],
      leave_key_name: [],
      config_value: [],
      validation_status: null,
      config_array: [],
      config_array_new: [],
      val_array: [],
      val_array_new: [],
      configs: [],
      configs_new: [],
      configbytype: [],
      status_array: [],
      status_array_new: [],
      check_array: [],
      check_array_new: [],
      config_status: [],
      config_status_new: [],
      calc_status: null,
      calculations: [
        { value: "", text: "Please select Key" },
        { value: "1", text: "Yes" },
        { value: "2", text: "No" },
      ],
      leave_keys: [
        { value: "", text: "Please select Key" },
        { value: "1", text: "Paid" },
        { value: "2", text: "Un-Paid" },
      ],
      validationstatus: [
        { value: "", text: "Please select Status" },
        { value: "1", text: "YES" },
        { value: "2", text: "NO" },
      ],
      valueTypes: [
        { value: "", text: "Please select" },
        { value: "YES", text: "YES" },
        { value: "NO", text: "NO" },
      ],
      items_config: [],
      new_configs: [],
    };
  },
  computed: {},
  mounted() {
    this.retrieveLeaveConfig();
    this.getLeaveTypeById();
  },
  methods: {

    onChangeStatusHandler(config_id) {

      if (this.checkedValue === true) {

        var status = 1;

      }
      else if (this.checkedValue === false) {
        var status = 0;


      }
      else {
        var status = 1;
      }

      if (this.check_array.includes(config_id) === true) {
        const index = this.status_array.findIndex(object => {
          return object.id === config_id;
        });

        if (index !== -1) {

          this.status_array[index].value = status;

        }
      }
      else {
        this.status_array.push({ id: config_id, value: status });

      }

      this.check_array.push(config_id);


    },

    onChangeEventHandler(config_id) {

      if (this.checkedValueNew === true) {

        var status = 1;

      }
      else if (this.checkedValueNew === false) {
        var status = 0;


      }
      else {
        var status = 1;
      }

      if (this.check_array_new.includes(config_id) === true) {
        const index = this.status_array_new.findIndex(object => {
          return object.id === config_id;
        });

        if (index !== -1) {

          this.status_array_new[index].value = status;

        }
      }
      else {
        this.status_array_new.push({ id: config_id, value: status });

      }

      this.check_array_new.push(config_id);


    },

    hideShow1(name) {
      if (name.config_name == 'MAX-IN-A-MONTH') {
       
        if (name.config_val > '1') {
          this.max_coun_month = name.config_val;
          this.show = true;
          this.checkedValue = false;
        }
        else {
          this.show = false;
        }
      }
      else if (name.config_name == 'MAX-COUNT-IN-A-SEQUENCE') {
        if (name.config_val > this.max_coun_month) {

          this.$toasted.show('MAX-COUNT-IN-A-SEQUENCE should be less than MAX-IN-A-MONTH', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

          name.config_val = null;
        }
      }
    },
    hideShowDoc1(name) {
      if (name.config_name == 'NEED-SUPPORTING-DOC') {
        if (name.config_val.value == 'YES') {
          this.showdoc = true;
          this.checkedValue = false;
        }
        else {
          this.showdoc = false;
        }
      }
    },

    hideShow(name, id) {
      if (name == 'MAX-IN-A-MONTH') {
        
        if (this.config_value[id] > '1') {
          this.max_coun_month = this.config_value[id];
          this.show = true;
          this.checkedValue = false;
        }
        else {
          this.show = false;
        }
      }
      else if (name == 'MAX-COUNT-IN-A-SEQUENCE') {
        if (this.config_value[id] > this.max_coun_month) {

          this.$toasted.show('MAX-COUNT-IN-A-SEQUENCE should be less than MAX-IN-A-MONTH', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

          this.config_value[id] = null;
        }
      }
    },
    hideShowDoc(name, id) {
      if (name == 'NEED-SUPPORTING-DOC') {
        if (this.config_value[id].value == 'YES') {
          this.showdoc = true;
          this.checkedValue = false;
        }
        else {
          this.showdoc = false;
        }
      }
    },

    onFormSubmit(evt) {
      evt.preventDefault();

      if (this.count == null || (this.leavetype_name == null) || (this.leave_key == null) || (this.validation_status == null) || (this.calc_status == null)) {
        this.success = false;
        this.$toasted.show('Please enter required fields', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

      } else {
        this.success = true;
      }

      this.saveLeaveType();
    },

    handleConfig(valu, val, valuetype) {

      if (valuetype == 2) {

        if (this.val_array.includes(val) === true) {
          const index = this.config_array.findIndex(object => {
            return object.id === val;

          });

          if (index !== -1) {

            this.config_array[index].value = valu.value;
          }
        }
        else {
          this.config_array.push({ id: val, value: valu.value });
        }

        this.val_array.push(val);

      }

      else {

        if (this.val_array.includes(val) === true) {
          const index = this.config_array.findIndex(object => {
            return object.id === val;
          });

          if (index !== -1) {

            this.config_array[index].value = valu;

          }
        }
        else {
          this.config_array.push({ id: val, value: valu });

        }

        this.val_array.push(val);


      }


    },

    handleConfigNew(val, valuetype) {


      if (valuetype == 2) {

        if (this.val_array_new.includes(val) === true) {
          const index = this.config_array_new.findIndex(object => {
            return object.id === val;

          });

          if (index !== -1) {

            this.config_array_new[index].value = this.config_value[val].value;
          }
        }
        else {
          this.config_array_new.push({ id: val, value: this.config_value[val].value });
        }

        this.val_array_new.push(val);

      }

      else {

        if (this.val_array_new.includes(val) === true) {
          const index = this.config_array_new.findIndex(object => {
            return object.id === val;
          });

          if (index !== -1) {

            this.config_array_new[index].value = this.config_value[val];

          }
        }
        else {
          this.config_array_new.push({ id: val, value: this.config_value[val] });

        }

        this.val_array_new.push(val);

      }

    },

    async retrieveLeaveConfig() {
      const response = await ServiceApi.GetLeaveConfig();
      if (response.code == "200") {
        this.items_config = response.data;

        this.totalRows = response.data.length;


      }
    },

    async getLeaveTypeById() {
      const ReqData = {
        id: this.$route.params.id,
      };
      const response = await ServiceApi.GetLeaveTypeById(ReqData);

      if (response.code == '200') {
        console.log(response.data);
        this.leavetype_name = response.data[0].result1.name;
        this.count = response.data[0].result1.count;

        if (response.data[0].result1.key == 1) {

          this.key_name = 'Paid';
        }
        else if (response.data[0].result1.key == 2) {
          this.key_name = 'Un-Paid';
        }
        if (response.data[0].result1.validation_status == 1) {
          this.validation_status_name = 'Yes';
        }
        else if (response.data[0].result1.validation_status == 2) {
          this.validation_status_name = 'No';
        } 
        if(response.data[0].result1.calc_mode == 1){
          this.calc_status = 'Yes';
        }else if(response.data[0].result1.calc_mode == 2){
          this.calc_status = 'No';
        }
        this.leave_key = { value: response.data[0].result1.key, text: this.key_name };

        this.validation_status = { value: response.data[0].result1.validation_status, text: this.validation_status_name };


        this.configbytype = response.data[1].result2;

        response.data[1].result2.forEach(val => {
          if(val.config_name == 'MAX-IN-A-MONTH'){
            if(val.config_val > 1 ){
              this.show = true;
            }
          }else if(val.config_name == 'NEED-SUPPORTING-DOC'){
            if(val.config_val == 'YES' ){
              this.showdoc = true;
            }
          }
        });
        this.new_configs = response.data[2].result3;


      }


    },

    saveLeaveType() {

      if (this.success == true) {

        const leave_data = {
          id: this.$route.params.id,
          leave_type_name: this.leavetype_name,
          count: this.count,
          leave_key: this.leave_key.value,
          validation_status: this.validation_status.value,
          calculation_status: this.calc_status.value ? this.calc_status.value : '',
          configs: this.config_array,
          configs_new: this.config_array_new,

          config_status: this.status_array,
          config_status_new: this.status_array_new

        };

        this.addLeaveType(leave_data);
      }
    },
    async addLeaveType(leave_data) {
      const response = await ServiceApi.UpdateLeaveType(leave_data);
      if (response.code == "200") {
        this.$toasted.show(" Leave Type updated successfully ", {
          theme: "outline",
          position: "top-right",
          type: "success",
          duration: 2000,
        });
        this.$router.push("/home/leave-types/");
      }
      else if (response.code == '400') {
        //message = response.
        this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },



  },
};
</script>
