<template>
  <div>
    <!-- page-wrapper Start-->
    <div class="container-fluid p-0">
      <div class="row m-0">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img
                    class="img-fluid for-light"
                    src="../assets/images/logo/login.png"
                    alt="looginpage"
                  />
                  <img
                    class="img-fluid for-dark"
                    src="../assets/images/logo/logo_dark.png"
                    alt="looginpage"
                  />
                </a>
              </div>
              <div class="login-main">
                <b-card no-body style="padding: 35px 0px 0px 0px;">
                  <b-tabs pills vertical>
                      <b-card-text>
                        <!-- <form class="theme-form"> -->
                          <h4>Sign in to account</h4>
                          <p>Enter your Email or Phone Number</p>
                          <div class="form-group">
                            <label class="col-form-label"></label>
                            <input
                              v-model="email"
                              class="form-control"
                              type="email"
                              required=""
                              placeholder="Enter your email"
                              :class="{
                                'is-invalid': submitted && !email,
                              }"
                            />
                            <div
                              v-show="submitted && !email"
                              class="invalid-feedback"
                            >
                              Email is required
                            </div>
                          </div>
                          <p class="text-center"><b>OR</b></p>
                          <div class="form-group">
                            <input
                              v-model="phone"
                              class="form-control"
                              type="number"
                              id="phone"
                              required=""
                              placeholder="Enter your Phone"
                              :class="{
                                'is-invalid': submitted && !phone,
                              }"
                            />
                            <div
                              v-show="submitted && !phone"
                              class="invalid-feedback"
                            >
                              Phone is required
                            </div>
                          </div>
                          <div class="form-group mb-0">
                            <button v-if="loader == false"
                              class="btn btn-primary btn-block"
                              type="button"
                              @click="signUp"
                            >
                              Login
                            </button>
                          
                            <div v-if="loader == true" class="loader-box">
                              <div class="loader-15"></div>
                        
                      </div>
                          </div>
                          
    
                        <!-- </form> -->
                      </b-card-text>
                      <div id="recaptcha-container"></div>
                  </b-tabs>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- latest jquery-->
  </div>
</template>

<script>
import Service from '@/services/Service.js';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import firebase from '../firebase.js'
import location_erorr from './location_error.vue'

  export default {
    name: 'login',
    data() {
      return {
        email: '',
        phone: '',
        submitted: false,
        loader:false,
        appVerifier:null,
        location:null,
      };
    },
    mounted: function () {
      
      this. loginIsValid()
      this.getLocation();
      this.requestNotificationPermission();
      const input = document.querySelector('#phone');
		this.iti = intlTelInput(input, {
			initialCountry: 'in', // Set initial country to Qatar
			separateDialCode: true,
			utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
		});
    localStorage.removeItem('verificationId'),
    this.appVerifier =  new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'invisible'
        })
    },
    methods: {
      requestNotificationPermission() {
      if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log('Notifications Enabled');
          }else{
            console.log('Not Enabled');
          }
        });
      }
    },
      getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.handleLocationSuccess,
          this.handleLocationError
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    },
    handleLocationSuccess(position) {
      this.location = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
      console.log('Location:', this.location);
    },
    handleLocationError(error) {
      console.error('Error getting location:', error);
    },
     async checkphoneNumber(){
        this.loader = true;
        var rq = {
        phone: this.iti.getNumber()
        }
        const response = await Service.checkphoneNumber(rq);

        if (response.code == '200') {
        this.sendVerificationCode();
          this.loader = false;
          localStorage.setItem('phone', this.iti.getNumber())


      }else if (response.code == '400') {
        this.loader = false;
        this.$toasted.show(response.message, { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
        
      }
      

      },
      sendVerificationCode() {
      const phoneNumber = this.iti.getNumber();
      this.phoneNumber = this.iti.getNumber();
      this.loader = true;
      firebase.auth().signInWithPhoneNumber(phoneNumber, this.appVerifier)
        .then(confirmationResult => {
          this.verificationId = confirmationResult.verificationId
          localStorage.setItem('verificationId', confirmationResult.verificationId)
          this.$router.push('/auth/otp');
         
        })
        .catch(error => {
          this.loader = false;
          console.error(error)
        })
    },
      loginIsValid:function(){
               
               if (localStorage.getItem("loginDetails") === null) {
                 localStorage.removeItem('loginDetails')
              }else{
                 this.$router.push('/home');
              }
          },
      signUp: function() {
        this.submitted = true;

       

        if (this.location == null) {
          this.$toast.error(location_erorr, {
          position: "top-right",
          timeout: 2000,
          });
          this.submitted = false;
          
        }else{

        


        if (this.email != '') {

          this.login();

          
          this.submitted = false;
        }else if (this.phone != '') {

          this.submitted = false;


          this.checkphoneNumber();
          // this.sendVerificationCode();

          
        }else{
          this.$toasted.show('Email or Phone is required', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });

        }
      }
      },
      async login() {

        const credentials = {
            email: this.email
          };
          this.loader = true;
          const response = await Service.login(credentials);
          if (response.code =='200') {
            this.loader = false;
                localStorage.setItem('email', response.data.email)
                localStorage.removeItem('lastworkingtime')
                localStorage.removeItem('lastbuttonstatus')
                localStorage.removeItem('PunchingDetailLogin')
                localStorage.removeItem('PunchingDetail')
                localStorage.removeItem('config')
                localStorage.removeItem('id')
                this.$router.push('/auth/login-otp');

              }else{
                this.loader = false;
                this.$toasted.show(response.message, { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });

              }

      }
    },
  };
</script>
<style>
.loader-box {
    height: 7px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;
}
.iti {
    position: relative;
    display: block !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.login-card .login-main .card {
  padding: 35px 0px 0px 0px !important; 
}
</style>