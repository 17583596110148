<template>
    <div>
        <Breadcrumbs main="Leave" title="Leave List" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">


                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">

                            <b-row>
                              <b-col xl="12">
                                <router-link :to="'/home/leave-add'">
                                  <b-button v-if="datalayout.designation.level.key == 'HR'" id="default-primary" variant="primary">Apply Leave
                                  </b-button>
                                </router-link>
                              </b-col>
                            </b-row>
                            <br />

                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(start_date)="rows">
                                        <span>
                                            {{ rows.item.start_date.split("T")[0] }}
                                        </span>
                                    </template>
                                    <template #cell(end_date)="rows">
                                        <span>
                                            {{ rows.item.end_date.split("T")[0] }}
                                        </span>
                                    </template>
                                    <template #cell(status)="rows">
                                        <b-badge :variant="statusVariant(rows.item.status)">

                                            <span v-if="rows.item.status == 1">
                                                Pending
                                            </span>
                                            <span v-else-if="rows.item.status == 2">
                                                Acknowledged
                                            </span>
                                            <span v-else-if="rows.item.status == 3">
                                                Approved
                                            </span>
                                            <span v-else-if="rows.item.status == 4">
                                                Canceled
                                            </span>
                                            <span v-else-if="rows.item.status == 5">
                                                Rejected
                                            </span>
                                        </b-badge>
                                    </template>
                                    <template #cell(actions)="row">

                                        <span>
                                            <router-link :to="'/home/leave-view/' + row.item.id">
                                                <feather type="eye"></feather>
                                            </router-link>
                                        </span>
                                        <!-- <span>
                                            <router-link :to="'/home/leave-edit/' + row.item.id">
                                                <feather type="edit"></feather>
                                            </router-link>
                                        </span> -->
                                        <span v-if="row.item.status != 4 && row.item.status != 5">

                                            <span v-if="row.item.type == 'sick'"
                                                @click="$bvModal.show('bv-modal-document')"
                                                v-on:click="getId(row.item.id)">

                                                <feather type="file" title="Edit Document"></feather>

                                            </span>
                                        </span>
                                      
                                    </template>



                                </b-table>

                                <b-modal id="bv-modal-document" centered size="lg" hide-footer>
                                    <template #modal-title>
                                        Upload Medical document
                                    </template>
                                    <div class="modal-body">
                                        <b-form class="needs-validation">


                                            <div class="form-row">
                                                <div class="col-md-3 mb-3">
                                                    <label>Upload File</label>
                                                </div>

                                                <div class="col-md-3 mb-3">

                                                    <input type="file" @change="onFileChange" />
                                                    <b-form-valid-feedback>
                                                    </b-form-valid-feedback>
                                                </div>
                                            </div>

                                            <input id="index_var" type="hidden" value="6" />

                                            <button v-if="file" class="btn btn-secondary" id="Bookmark" type="button" @click="onDocumentSubmit()">
                                                Save
                                            </button>

                                            <button disabled class="btn btn-primary el" v-else>
                                                Loading..
                                            </button>

                                        </b-form>
                                    </div>
                                </b-modal>
                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>


                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import S3 from "aws-s3";
import ServiceApi from "@/services/ServiceApi.js";

export default {
    data() {
        return {
            tablefields: [
                { key: 'emp_name', label: 'Employee', sortable: true, },
                { key: 'type', label: 'Leave Type', sortable: true, },
                { key: 'reason', label: 'Reason', sortable: true, },
                { key: 'start_date', label: 'From', sortable: true, },
                { key: 'end_date', label: 'To', sortable: true, },
                { key: "no_days", label: "No. of days", sortable: true },
                { key: 'status', label: 'Status', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],
            datalayout: JSON.parse(localStorage.getItem('loginDetails')),
            items: [],
            leaveId: null,
            file: null,
            file_name: null,
            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
        };
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-warning",
                2: "light-info",
                3: "light-success",
                4: "light-danger",
                5: "light-danger",
            };

            return (status) => statusColor[status];
        },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        }
    },
    mounted() {
        // Set the initial number of items
        this.retrieveLeaveList();
        this.getAwsS3Config();

    },
    methods: {

        async getAwsS3Config() {
            const response = await Service.getAwsS3Configs();
            if (response.code == "200") {
                this.s3 = response.data;
            }
        },
        async retrieveLeaveList() {
            const response = await Service.GetLeaveList();
            if (response.code == "200") {
                this.items = response.data;
                this.totalRows = response.data.length;
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        getId(id) {
            this.leaveId = id;

        },
        onFileChange(e) {

            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }

            let file = files[0];
            const now = Date.now();
            const config = {
                bucketName: this.s3.bucketName,
                dirName: "leave_docs" /* optional */,
                region: this.s3.region,
                accessKeyId: this.s3.accessKeyId,
                secretAccessKey: this.s3.secretAccessKey,
                s3Url: "https://bktqaptive.s3.amazonaws.com" /* optional */,
            };

            const S3Client = new S3(config);
            S3Client.uploadFile(file, this.emp_ref_no+now).then((response) => {
                this.file = response.location;
            });

        },

        onDocumentSubmit() {

            //evt.preventDefault();
            const credentials = {
                id: this.leaveId,
                file: this.file,
            };

            this.editLeaveDocument(credentials);

            this.$bvModal.hide("bv-modal-document");
            // this.retrieveLeaveList();
        },

        async editLeaveDocument(credentials) {
            const response = await ServiceApi.UpdateLeaveDocument(credentials);
            if (response.code == "200") {
                this.$toasted.show(" Leave document updated successfully ", {
                    theme: "outline",
                    position: "top-right",
                    type: "success",
                    duration: 2000,
                });
                this.retrieveLeaveList();
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },

    }
};
</script>