<template>
    <!-- <div> -->
    <!-- <div class="container-fluid"> -->
    <!-- <div class="row"> -->

    <div class="col-sm-12 p-0">
        <!-- <b-tabs content-class="" active-nav-item-class="font-weight-bold text-uppercase" class="red-marg">
            <b-tab class="red-marg">
                <template v-slot:title>
                    <b-nav-item>
                        <feather type="file-text"></feather> Unread
                    </b-nav-item>
                </template>
                <b-card-text>
                    <br>
                    <div class="card card-p">
                        <div class="card-body">
                            <b-row>
                                <b-col xl="3">
                                    <b-button id="primary" variant="outline-primary" style="float: left !important;" @click="makeRead">Mark all as Read</b-button>
                                </b-col>
                                <b-col xl="9">
                                </b-col>
                            </b-row><br><br>
                            <b-row>
                                <b-col xl="6">
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select ">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="unread_items" :fields="tablefields"
                                    :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                   
                                </b-table>
                            </div>
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows1" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>
                           
                        </div>
                    </div>
                </b-card-text>
            </b-tab> -->

            <!-- <b-tab class="red-marg">
                <template v-slot:title>
                    <b-nav-item>
                        <feather type="file-text"></feather> Read
                    </b-nav-item>
                </template>
                <b-card-text>
                    <br> -->
                    <div class="card card-p">
                        <div class="card-body">
                           
                            <b-row>
                                <b-col xl="6">
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select ">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">

                                </b-table>
                            </div>
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>
                            
                        </div>
                    </div>
                <!-- </b-card-text>
            </b-tab>
        </b-tabs> -->
    </div>

</template>

<script>
import Service from '@/services/Service.js';

export default {
    data() {
        return {
            tablefields: [
                { key: 'date', label: 'Date Time', sortable: true, },
                // { key: 'company_name', label: 'Company', sortable: true, },
                { key: 'text', label: 'Notification', sortable: true, },
                // { key: 'actions', label: 'Actions', sortable: true, },
            ],
            items: [],
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            loginData: JSON.parse(localStorage.getItem('loginDetails')),
        };
    },
    computed: {

        statusVariant() {
            const statusColor = {
                1: "light-success",
                0: "light-danger",
            };

            return (status) => statusColor[status];
        },

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        }
    },
    created() {
        // Set the initial number of items
        this.retrieveAllActivities();

    },
    methods: {
        // toggleCheckbox(item) {
        //     item.is_checked = !item.is_checked;
        // },
        
        async retrieveAllActivities() {
            var rqData = {
                user: this.loginData.id
            }
            const response = await Service.GetAllActivities(rqData);
            if (response.code == "200") {
                // console.log(response.data);
                this.items = []
                this.items = response.data;
                // this.unread_items = []
                // response.data.forEach(element => {
                //     if (element.status) {
                //         this.items.push(element)
                //     } else {
                //         this.unread_items.push(element)
                //     }
                // });
                // this.totalRows1 = this.unread_items.length;
                this.totalRows = this.items.length;
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        // addDepartment: function () {
        //     this.$router.push('/home/department-add');
        // },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        
    }
};
</script>
<style>
.per-page {
    padding: 18px 1px;
}

.red-marg {
    margin-top: 0rem !important;
}
</style>