
export default {
  state: {
    token: null,
  },
  getters: {},
  mutations: {
    changeToken (state, payload) {
      state.token = payload
    },

  },
  actions: {}
}
