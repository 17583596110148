<template>
    <div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">


                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">
                            <b-row>
                                <b-col xl="12" v-if="loginData.designation.level.key == 'MANAGER' || is_lead == true">
                                    <b-button class="pull-right" id="default-primary" variant="primary"
                                        @click="addTaskFromChild()">
                                        Add Task
                                    </b-button>
                                    <!-- <b-tooltip target="default-primary" triggers="hover" placement="top">btn btn-primary</b-tooltip> -->
                                </b-col>
                            </b-row>
                            <br />
                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(start_date)="rows">
                                        <span>
                                            {{ rows.item.start_date ? rows.item.start_date.split("T")[0] : "" }}
                                        </span>
                                    </template>
                                    <template #cell(end_date)="rows">
                                        <span>
                                            {{ rows.item.end_date ? rows.item.end_date.split("T")[0] : "" }}
                                        </span>
                                    </template>
                                    <template #cell(status)="rows">
                                        <b-badge :variant="statusVariant(rows.item.status)">

                                            <span v-if="rows.item.status == 1">
                                                Pending
                                            </span>
                                            <span v-else-if="rows.item.status == 2">
                                                Started
                                            </span>
                                            <span v-else>
                                                Completed
                                            </span>
                                        </b-badge>
                                    </template>
                                    <template #cell(active_status)="rows">
                                        <b-badge :variant="statusVariantActive(rows.item.is_active)">

                                            <span v-if="rows.item.is_active == 1">
                                                Active
                                            </span>
                                            <span v-else>
                                                Inactive
                                            </span>
                                        </b-badge>
                                    </template>
                                    <template #cell(actions)="row">
                                        <span>
                                            <span v-if="loginData.designation.level.key == 'MANAGER' || is_lead == true"
                                                @click="EditTask(row.item.id)">
                                                <feather type="edit"></feather>
                                            </span>
                                            <!-- <span v-if="row.item.is_active == 1">
                                                <feather type="thumbs-down" @click="deleteTask(row.item)"></feather>
                                            </span>
                                            <span v-else>
                                                <feather type="thumbs-up" @click="deleteTask(row.item)"></feather>
                                            </span> -->
                                            <span
                                                @click="viewTask(row.item.id)">
                                                <feather type="eye"></feather>
                                            </span>
                                            <span>
                                                <!-- <router-link :to="'/home/task-view/' + row.item.id">
                                                    <feather type="eye"></feather>
                                                </router-link> -->
                                            </span>
                                        </span>
                                    </template>
                                </b-table>
                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>

                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import Swal from "sweetalert2";

export default {
    data() {
        return {
            tablefields: [
                { key: 'name', label: 'Task Name', sortable: true, },
                { key: 'user', label: 'Employee', sortable: true, },
                { key: 'phase', label: 'Project Phase', sortable: true, },
                { key: 'module', label: 'Module', sortable: true, },
                { key: 'type', label: 'Task Type', sortable: true, },
                { key: 'status', label: 'Task Status', sortable: true, },
                // { key: 'start_date', label: 'Start Date', sortable: true, },
                // { key: 'end_date', label: 'End Date', sortable: true, },
                { key: 'active_status', label: 'Active Status', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],

            items: [],

            filter: null,
            is_lead: false,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            loginData: JSON.parse(localStorage.getItem('loginDetails')),
        };
    },
    props: ['project_id', 'heading'],
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-warning",
                2: "light-info",
                3: "light-success",
            };

            return (status) => statusColor[status];
        },
        statusVariantActive() {
            const statusColor = {
                1: "light-success",
                0: "light-danger",
            };

            return (status) => statusColor[status];
        },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        },

    },
    created() {
        // Set the initial number of items
        var head = 4;
        // this.heading = head;
        this.$emit('update:heading', head);
        this.currUser = this.loginData.id;
        this.project_id.allMem.forEach(element => {
            element.mem.forEach(element1 => {
                if (this.currUser == element1.user_id) {
                    if (element1.role == 1) {
                        this.is_lead = true;
                    }
                }
            });
        });

        this.pManager = this.loginData.designation.level.key;
        this.retrieveTask(this.project_id.id, this.currUser, this.pManager);
    },
    methods: {
        EditTask(val) {
            this.project_id.val_type = val;
            this.$emit('update:project_id', this.project_id);
        },
        viewTask(val) {
            this.project_id.val = val;
            this.$emit('update:project_id', this.project_id);
        },
        addTaskFromChild() {
            this.project_id.val_add = '1';
            this.$emit('update:project_id', this.project_id);
        },
        async retrieveTask(proId, user, manager) {
            const reqDatas = {
                projectId: proId,
                user: user,
                manager: manager,
            }
            const response = await Service.GetTasks(reqDatas);
            if (response.code == "200") {
                this.items = response.data;
                this.totalRows = response.data.length;
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        deleteTask(data) {

            if (data.is_active == 1) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to disable this Task!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, disable!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const credentials = {
                            status: data.is_active,
                            id: data.id,
                        };
                        console.log(credentials);
                        var done = this.enableOrDisableTask(credentials);
                        if (done) {
                            this.retrieveTask(this.project_id.id, this.currUser, this.pManager);
                            Swal.fire("Disabled!", "Task has been disabled.", "success")

                        }
                    }
                });
            } else if (data.is_active == 0) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to enable this Task!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, enable!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const credentials = {
                            status: data.is_active,
                            id: data.id,
                        };
                        var done = this.enableOrDisableTask(credentials);
                        if (done) {
                            this.retrieveTask(this.project_id.id, this.currUser, this.pManager);
                            Swal.fire("Disabled!", "Task has been enabled.", "success")

                        }
                    }
                });
            }
        },
        async enableOrDisableTask(credentials) {
            const response = await Service.EnableOrDisableTask(credentials);
            if (response.code == "200") {
                return true;
            } else {
                return false;
            }
        }

    },
};
</script>