<template>
    <div><br>
        <Breadcrumbs main="Onsite Requests" title="Onsite Request Details" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="user-profile">
                <div class="row">
                    <!-- user profile first-style start-->
                    <div class="col-sm-12">
                        <br /><br />
                        <div class="card hovercard text-center">
                            <div class="user-image">
                                <div class="avatar">
                                    <!-- <img alt="" src="../../assets/images/user/7.jpg"> -->

                                    <img v-if="this.items.image_name" class="mr-3 rounded-circle"
                                        :src="this.items.image_name" style="height:100;width:100;" alt="" />

                                    <img v-else class="mr-3 rounded-circle" src="../../assets/images/user/user.png"
                                        style="height:100;width:100;" alt="" />
                                </div>

                            </div>
                            <div class="info">
                                <div class="row">
                                    <div class="col-sm-12 col-lg-12 order-sm-0 order-xl-1">
                                        <div class="user-designation">
                                            <div class="title"><a target="_blank" href=""> {{ this.items.emp_name }}</a>
                                                <span v-if="this.items.emp_ref"> ({{ this.items.emp_ref }}) </span>
                                            </div>
                                            <div class="desc mt-2">{{ this.items.emp_design }}</div>

                                        </div>
                                        <hr />
                                    </div>

                                </div><br /><br />

                                <div class="row">
                                    <div class="col-sm-6 col-lg-12 order-sm-1 order-xl-0">
                                        <br>
                                        <div class="row">

                                            <div class="col-md-2">
                                                <div class="ttl-info text-left">
                                                    <h6><i class="fa fa-comment"></i> Reason</h6><span>{{
                                                        this.items.reason
                                                    }}</span>
                                                </div>
                                            </div>

                                            <div class="col-md-2">
                                                <div class="ttl-info text-left">
                                                    <h6><i class="fa fa-comment"></i> Project</h6><span>{{
                                                        this.items.project
                                                    }}</span>
                                                </div>
                                            </div>

                                            <div class="col-md-2">
                                                <div class="ttl-info text-left">
                                                    <h6><i class="fa fa-calendar mr-2"></i>From date</h6><span>{{
                                                        this.items.start_date ? this.items.start_date : ''
                                                    }}</span>
                                                </div>
                                            </div>


                                            <div class="col-md-2">
                                                <div class="ttl-info text-left">
                                                    <h6><i class="fa fa-calendar mr-2"></i>To date</h6><span>{{
                                                        this.items.end_date ? this.items.end_date : ''
                                                    }}</span>
                                                </div>
                                            </div>

                                            <div class="col-md-2">
                                                <div class="ttl-info text-left">
                                                    <h6><i class="fa fa-calendar mr-2"></i>Duration</h6><span>{{
                                                        this.items.duration ? formateTime(this.items.duration) : ''
                                                    }}</span>
                                                </div>
                                            </div>

                                            <div class="col-md-2">
                                                <div class="ttl-info text-left">
                                                    <h6><i class="fa fa-flag"></i>Status</h6>
                                                    <span>
                                                        <b-badge :variant="statusVariant(this.items.status)">

                                                            <span v-if="this.items.status == 1">
                                                                Pending
                                                            </span>
                                                            <span v-else-if="this.items.status == 2">
                                                                Acknowledged
                                                            </span>
                                                            <span v-else-if="this.items.status == 3">
                                                                Approved
                                                            </span>
                                                            <span v-else-if="this.items.status == 5">
                                                                Rejected
                                                            </span>
                                                        </b-badge>
                                                    </span>
                                                </div>
                                            </div>


                                        </div>


                                    </div>

                                </div>
                                <hr>
                                <div class="social-media">
                                    <ul class="product-color m-t-15">
                                        <li @click="changeStatus(3)"
                                            v-if="(this.datalayout.designation.level.key == 'HR') && (this.items.status == '1' || this.items.status == '2')">
                                            <i class="fa fa-check-square-o" title="Approve" :style="'font-size: 24px;'"></i>
                                        </li>
                                        <li @click="changeStatus(2)"
                                            v-if="(this.items.status == '1') && (this.datalayout.designation.level.key == 'MANAGER' || this.datalayout.designation.level.key == 'MANAGEMENT')">
                                            <i class="fa fa-eye" title="Acknowledge" :style="'font-size: 24px;'"></i>
                                        </li>
                                        <li @click="changeStatus(5)"
                                            v-if="this.items.status == '1' || this.items.status == '2' || this.items.status == '3'">
                                            <i class="fa fa-trash-o" title="Reject" :style="'font-size: 24px;'"></i>
                                        </li>

                                    </ul>
                                </div>

                                <!-- <div class="social-media" v-if="this.items.status == '1' || this.items.status == '2'">
                                    <ul class="list-inline">
                                        
                                        <li class="list-inline-item" @click="changeStatus(3)" v-if="this.items.status != '2' && datalayout.designation.level.key == 'HR'">
                                            <a>
                                                <i class="fa fa-check-square-o">
                                                    <span> Approve</span>
                                                </i>
                                            </a>
                                        </li>
                                        
                                        <li class="list-inline-item" @click="changeStatus(2)" v-if="this.items.status != '2' && datalayout.designation.level.key == 'MANAGER'">
                                            <a>
                                                <i class="fa fa-eye">
                                                    <span> Acknowledge</span>
                                                </i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item" @click="changeStatus(5)">
                                            <a>
                                                <i class="fa fa-trash-o">
                                                    <span> Cancel</span>
                                                </i>
                                            </a>
                                        </li>
                                    </ul>
                                </div> -->
                                <div class="follow">
                                    <div class="row">
                                        <div class="col-6 text-md-right">

                                        </div>

                                        <!-- <div class="col-6 text-md-right" v-if="this.items.status == 3">
                                            <a :href="this.items.pdf" target="_blank" download><b-button id="default-primary" variant="primary">Print</b-button></a>
                                        </div> -->

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Service from '@/services/Service.js';
import moment from 'moment'
import Swal from "sweetalert2";
import ServiceApi from "@/services/ServiceApi.js";

export default {
    data() {
        return {
            items: [],
            LvStatus: null,
            user_id: null,
            holidays: [],
            holidays_arr: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            filter: null,
            pageOptions: [10, 25, 50],
            datalayout: JSON.parse(localStorage.getItem('loginDetails'))
        };
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-warning",
                2: "light-info",
                3: "light-success",
                4: "light-danger",
                5: "light-danger",
            };

            return (status) => statusColor[status];
        },

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        }

    },
    mounted() {

        this.retrieveOnsiteList();
        this.getHolidays();
        //this.getLeaveCountByUser();
    },
    methods: {
        formateTime(time) {
            var hours = Math.floor(time / 3600);
            var minutes = Math.floor((time - (hours * 3600)) / 60);
            var remainingSeconds = time - (hours * 3600) - (minutes * 60);

            if (hours < 10) {
                hours = "0" + hours;
            }
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            if (remainingSeconds < 10) {
                remainingSeconds = "0" + remainingSeconds;
            }

            return hours + ":" + minutes + ":" + remainingSeconds;
        },
        customFormatter(date) {
            return moment(date).format('Y-MM-DD');
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        async getHolidays() {
            const response = await ServiceApi.GetHolidays();
            if (response.code == '200') {

                var date_obj = {};
                var holiday_arr = [];

                response.data.forEach(element => {

                    date_obj = {

                        date: element.date,
                    }
                    holiday_arr.push(date_obj);

                });
                this.holidays = holiday_arr;

            }
        },
        changeStatus(changeStat) {

            Swal.fire({
                title: "Are you sure?",
                // text: "Are you sure !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Sure!",
            }).then((result) => {
                if (result.isConfirmed) {

                    const ReqData = {
                        id: this.$route.params.id,
                        status_to: changeStat,
                        type: 1,
                        user_id: this.items.user_id,
                        start: this.items.start_date,
                        end: this.items.end_date
                    };
                    this.updateOnsite(ReqData);
                }
            });

        },
        async retrieveOnsiteList() {
            const ReqData = {
                id: this.$route.params.id,
            };
            const response = await ServiceApi.GetOnsiteById(ReqData);
            if (response.code == "200") {
                this.items = response.data;

            }
        },

        async updateOnsite(ReqData) {
            const response = await ServiceApi.ChangeOnsiteStatus(ReqData);
            if (response.code == "200") {
                this.$toasted.show(' Status updated successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.retrieveOnsiteList();
            }
            else if (response.code == '400') {
                this.loader = false;
                this.$toasted.show(response.message + ' ' + response.data, { theme: 'outline', position: 'top-right', type: 'error', duration: 3000 });
            }
            else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },


    }
};

</script>
<style>
.product-color li {
    display: inline-block;
    border-radius: 100%;
    height: 31px;
    width: 41px;
    margin: -5px 2px;
}

.hovercard .info .social-media ul li {
    padding-top: 3px;
    margin-right: 10px;
}
</style>