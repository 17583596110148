<template>
    <div>
        <Breadcrumbs main="Client" title="Edit Client Details" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="name">Client Name</label>
                                        <b-form-input type="text" id="name" v-model="name" :state="c_form_result.name"
                                            placeholder="Name"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.name">
                                        </b-form-valid-feedback>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="email">Email</label>
                                        <b-form-input type="email" id="name" v-model="email" :state="c_form_result.email"
                                            placeholder="Email"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.email">
                                        </b-form-valid-feedback>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="phone">Phone</label>
                                        <VuePhoneNumberInput v-model="phone" default-country-code="IN"
                                                @update="onUpdatePhoneNumber" />
                                        <!-- <b-form-input type="text" id="phone" v-model="phone" :state="c_form_result.phone"
                                            placeholder="Phone"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.phone">
                                        </b-form-valid-feedback> -->
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="address">Address</label>
                                        <b-form-input type="text" id="address" v-model="address" :state="c_form_result.address"
                                            placeholder="Address"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.address">
                                        </b-form-valid-feedback>
                                    </div>



                                </div>

                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from '@/services/ServiceApi.js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import vSelect from 'vue-select'
import { VueEditor } from 'vue2-editor';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'

export default {

    components: {
        VuePhoneNumberInput,
        vSelect,
        VueEditor,
    },

    data() {
        return {
            success: false,
            name: '',
            address: '',
            email: '',
            phone: '',
            c_form: {
                name: '',
                address: '',
                email: '',
                phone: '',
            },
            c_form_result: {
                name: null,
                address: null,
                email: null,
                phone: null,
            },
            types: [

                { value: "1", text: "YES" },
                { value: "2", text: "NO" },
            ],

        };
    },
    computed: {

    },
    mounted() {
        this.getClientById();

    },
    methods: {
        async getClientById() {
            const ReqData = {
                id: this.$route.params.id,
            };
            const response = await ServiceApi.GetClientById(ReqData);
            // console.log(response.data)
            if (response.code == '200') {
                this.name = response.data.name;
                this.address = response.data.address;
                this.email = response.data.email;
                this.phone = response.data.phone;
                

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }

        },

        onCustomStyleSubmit(evt) {
            evt.preventDefault();

            if (this.name == '') {
                this.success = false;
                this.$toasted.show(' Please enter client name', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }

            else if (this.address == '') {
                this.success = false;
                this.$toasted.show(' Please enter address', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }

            else if (this.email == '') {
                this.success = false;
                this.$toasted.show(' Please enter email', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }

            else if (this.phone == '') {
                this.success = false;
                this.$toasted.show(' Please enter phone', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
           

            else {
                this.success = true;
            }

            this.saveClient();
        },
        saveClient() {

            if (this.success == true) {
                const status_data = {
                    id: this.$route.params.id,
                    name: this.name,
                    address: this.address,
                    email: this.email,
                    phone: this.phone,
                }
                this.updateClient(status_data)
            }
        },
        async updateClient(status_data) {
            const response = await ServiceApi.UpdateClient(status_data);
            if (response.code == '200') {
                this.$toasted.show(' Client updated successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/clients');
            }
            else if (response.code == '400') {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },

    }
};
</script>
