<template>
</template>

<script>
  var body = document.getElementsByTagName('body')[0];
  import { mapState } from 'vuex';

  export default {
    name: 'SearchBar',
    data () {
      return {
        terms: '',
        searchResult: false,
        searchResultEmpty: false,
      };
    },
    computed: {
      ...mapState({
        menuItems: (state) => state.menu.searchData,
        searchOpen: (state) => state.menu.searchOpen
      }),
    },
    watch: {
      menuItems: function() {
        this.terms ? this.addFix() : this.removeFix();
        if (!this.menuItems.length) this.searchResultEmpty = true;
        else this.searchResultEmpty = false;
      },
    },
    methods: {
      search_open() {
        this.$store.state.menu.searchOpen = true;
      },
      search_close() {
        this.$store.state.menu.searchOpen = false;
      },
      searchterm: function() {
        this.$store.dispatch('menu/searchTerm', this.terms);
      },
      addFix() {
        body.classList.add('offcanvas');
        this.searchResult = true;
      },
      removeFix() {
        body.classList.remove('offcanvas');
        this.searchResult = false;
        this.terms = '';
      },
    },
  };
</script>
