<template>
    <div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="name">Title</label>
                                        <b-form-input type="text" id="designation" v-model="name" placeholder="Title">
                                        </b-form-input>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_company">Task Type</label>
                                        <v-select v-model="task" label="name" :options="taskType" />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_employees">Employee</label>
                                        <v-select v-model="employee" label="user" :options="employees" />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_modules">Project Phase</label>
                                        <v-select v-model="project_phases" label="title" :options="phases"
                                            v-on:input="getModuleByPhase" />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_modules">Module</label>
                                        <v-select v-model="project_modules" label="name" :options="modules" />
                                    </div>
                                    <!-- <div class="col-md-6 mb-3">
                                        <label class="form-label">Status</label>
                                        <b-form-select
                                            v-model="startEnd"
                                            label="Status"
                                            :options="startEndStatus"                    
                                        >
                                        </b-form-select>
                                    </div> -->
                                </div>
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Instructions</label>
                                            <!-- <textarea class="form-control" rows="2" placeholder="Enter Instructions"
                                                v-model="instruct">
                                            </textarea> -->
                                            <!-- <ckeditor :editor="editor"  v-model="instruct"></ckeditor> -->
                                            <vue-editor v-model="instruct"></vue-editor>
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col" v-if="loader == false">
                                        <b-button type="submit" variant="primary">Submit</b-button>
                                    </div>
                                    <div v-if="loader == true" class="loader-box">
                                        <div class="loader-15"></div>

                                    </div>
                                </div>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import moment from "moment";
import { VueEditor } from 'vue2-editor';
export default {

    components: {
        vSelect,
        VueEditor
    },

    data() {
        return {
            loader: false,
            success: false,
            startEndStatus: [
                { value: '1', text: 'Pending' },
                { value: '2', text: 'Start' },
            ],
            descrip: '',
            instruct: '',
            name: '',
            taskType: [],
            employees: [],
            modules: [],
            project_modules: null,
            phases: [],
            project_phases: null,
            employee: null,
            task: null,
            startEnd: null,
            projectId: null,
            teamLead: null,
            currUser: null,
            pManager: null,
            loginData: JSON.parse(localStorage.getItem('loginDetails')),
        };
    },
    props: ['taskProjectId', 'heading'],
    computed: {

    },
    created() {
        var head = 3;
        // this.heading = head;
        this.$emit('update:heading', head);
        this.getTasks();
        this.getUsers();
        // this.getModules();
        this.getProjectPhase();
        // this.getModuleByPhase();

    },
    methods: {
        onCustomStyleSubmit(evt) {
            evt.preventDefault();
            // var today = new Date().getTime();
            // var sDate = new Date(this.$route.params.start.split('T')[0]).getTime();
            // var eDate = new Date(this.$route.params.end.split('T')[0]).getTime();

            if (this.name == '' || this.task == null) {
                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                this.saveTask();
            }
        },
        saveTask() {
            var today = moment(new Date()).format("DD-MM-YYYY hh:mm:ss")
            var no_date = null;
            const task_data = {
                name: this.name,
                desc: this.instruct,
                type: this.task.id,
                projectId: this.$route.params.id,
                phase: this.project_phases.id,
                module: this.project_modules.id,
                startDate: no_date,
                endDate: no_date,
                user: this.employee.user_id,
                // task_id: null,
            };
            // if(this.startEnd == '2'){
            //     task_data.startDate = today;
            // }else if(this.startEnd == '3'){
            //     task_data.endDate = today;
            // }
            this.addTask(task_data)
        },
        async addTask(task_data) {
            this.loader = true;
            const response = await Service.AddTask(task_data);
            if (response.code == '200') {
                this.loader = false;
                this.$toasted.show(' Task added successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.taskProjectId.val_add = '';
                this.$emit('update:taskEditId', this.taskProjectId);
            } else if (response.code == "400") {
                this.loader = false;
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
        },
        async getTasks() {
            const response = await Service.GetTaskType();
            if (response.code == '200') {
                this.taskType = response.data;
            }
        },
        // async getModules() {
        //     const reqDatas = {
        //         project_id: this.$route.params.id
        //     }
        //     const response = await Service.GetModules(reqDatas);
        //     if (response.code == '200') {
        //         this.modules = response.data;
        //     }
        // },

        async getModuleByPhase() {
            // console.log(this.project_phases);
            const mod_data = { phase: this.project_phases.id };
            const response = await Service.GetModulesByPhase(mod_data);
            if (response.code == '200') {
                this.modules = response.data;
                // console.log(this.modules);
            }
        },

        async getProjectPhase() {
            const reqDatas = {
                project_id: this.$route.params.id
            }
            const response = await Service.GetProjectPhase(reqDatas);
            if (response.code == '200') {
                this.phases = response.data;
            }
        },
        async getUsers() {
            var pro_id = this.$route.params.id;
            const reqData = {
                id: pro_id,
            }
            const response = await Service.GetProjectDetails(reqData);
            if (response.code == '200') {
                this.employees = [];
                this.pManager = this.loginData.designation.level.key;
                this.currUser = this.loginData.id;
                if (this.pManager == 'MANAGER') {
                    this.employees = response.data.all.members;
                } else {
                    var flag = 0;
                    console.log(response.data.all);
                    response.data.all.allMem.forEach(element => {
                        element.mem.forEach(elem => {
                            if ((elem.user_id == this.currUser) && (elem.role == 1)) {
                                // this.employees = element.mem;
                                flag = 1;
                            }
                        });
                    });
                    if (flag == 0) {
                        this.employees = [
                            { user_id: this.currUser, user: this.loginData.name },
                        ];
                    } else if (flag == 1) {
                        const req = {
                            id: pro_id,
                            user_id: this.currUser
                        }
                        const response = await Service.GetMembersByTeam(req);
                        if (response.code == '200') {
                            this.employees = response.data;
                        }
                    }
                    // if(this.employees.length == 0){
                    //     this.employees = [
                    //         { user_id: this.currUser, user: this.loginData.name },
                    //     ];
                    // }
                }
            }
        },
    }
};
</script>
<style>
.ck-content {
    height: 150px;
}
</style>