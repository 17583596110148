<template>

    <div class="card">
        <div class="card-body">
            <!-- <b-row>
                    <b-col xl="12">
                    <b-button
                    id="default-primary"
                  variant="primary"
                 @click="$bvModal.show('bv-modal-example')"
                 v-on:click="getLeaveCountByUser()"
            
                 >Modify Leave Balance
                 </b-button>
                </b-col>
                </b-row> -->
            <br />

            <div class="table-responsive datatable-vue">
                <b-table show-empty stacked="md" :items="items" :fields="tablefields">
                    <template #cell(time)="rows">
                        <span>
                            {{ formatTime(rows.item.time) }}
                        </span>
                    </template>
                </b-table>
                <br>
                <div class="media">
                    <feather type="briefcase" class="icon-space">
                    </feather>
                    <div class="media-body">
                        <h6>
                            Total Project Wage :
                            {{
                                tot_pro_cost ? tot_pro_cost.toFixed(2): ''
                            }}
                        </h6>
                    </div>
                </div>
                <!-- <b-modal id="bv-modal-example" centered size="lg" hide-footer>
                        <template #modal-title>
                            Modify Leave Balance
                        </template>
                        <div class="modal-body">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row" v-for="(item, index) in items" :key="index">
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_name">{{
                                            item.leave_type_name
                                        }}</label>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <b-form-input type="text" id="c_form_count" v-model.lazy="item.count"
                                            :state="c_form_result.count" placeholder="Count" @change="
                                                handleCountChange(
                                                    item.count,
                                                    item.leave_type_name,
                                                    item.leave_type_count,
                                                    item.validation_status
                                                )
                                            ">
                                        </b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.count">
                                        </b-form-valid-feedback>
                                    </div>
                                </div>

                                <input id="index_var" type="hidden" value="6" />

                                <button class="btn btn-secondary" id="Bookmark" type="submit">
                                    Save
                                </button>
                                <button class="btn btn-primary ml-2" type="button" data-dismiss="modal"
                                    v-on:click="getLeaveCountByUser()" @click="$bvModal.hide('bv-modal-example')">
                                    Cancel
                                </button>
                            </b-form>
                        </div>
                    </b-modal> -->
            </div>
        </div>
    </div>
</template>
<script>
import Service from '@/services/Service.js';
// import Swal from "sweetalert2";

export default {

    data() {
        return {
            tablefields: [
                { key: 'user', label: 'Project Memebers', sortable: true, },
                { key: 'time', label: 'Total Time', sortable: true, },
                { key: 'wage', label: 'Wage', sortable: true, },
            ],

            items: [],
            tot_pro_cost: null,
        };
    },
    computed: {

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        }
    },
    props: ['project_det'],
    created() {
        this.getWageDetails(this.project_det)
    },
    methods: {
        async getWageDetails(project) {
            const ReqData = {
                pro_id: project.id,
                users: project.members,
            };
            const response = await Service.GetWageDetails(ReqData);
            if (response.code == "200") {
                this.items = response.data[1];
                this.tot_pro_cost = 0;
                
                response.data[1].forEach(element => {
                    this.tot_pro_cost = (this.tot_pro_cost + element.wage);
                });
            }
        },
        formatTime(time) {
            var sec_num = time;
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours < 10) { hours = "0" + hours; }
            if (minutes < 10) { minutes = "0" + minutes; }
            if (seconds < 10) { seconds = "0" + seconds; }
            var time_seconds = hours + ':' + minutes + ':' + seconds;
            return time_seconds;
        }
    }
};
</script>