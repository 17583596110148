<template>
    <div>
        <!-- Loader -->
        <div v-if="isLoading" class="loader-container">
            <div class="loader"></div>
        </div>
        <Breadcrumbs main="Applied Vacancies" title="Applied Vacancies" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">


                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">
                            <b-row>
                                <b-col xl="12">
                                    <router-link :to="'/home/resume-add/'">
                                        <b-button id="default-primary" variant="primary" >Apply Job
                                        </b-button>
                                    </router-link>
                                    <!-- <b-tooltip target="default-primary" triggers="hover" placement="top">btn btn-primary</b-tooltip> -->
                                </b-col>
                            </b-row><br>
                            <b-row>

                               
                                <!-- Filter for Total Mark -->

                                <b-col xl="3">
                                   
                                   <label class="form-label">Mark From</label>
                                       <b-form-input type="text" id="mark_from" v-model="mark_from" placeholder="Mark From">
                                       </b-form-input>

                               </b-col>
                                <b-col xl="3">
                                   
                                    <label class="form-label">Mark To</label>
                                        <b-form-input type="text" id="mark_to" v-model="mark_to" placeholder="Mark To">
                                        </b-form-input>

                                </b-col>
                              
                                <!-- Filter for Applied Position -->
                                <b-col xl="3">
                                    <div class="form-group">
                                        <label class="col-form-label">Select Job Vacancy </label>
                                        <v-select v-model="vacancy" label="title" :options="this.vacancies" />
                                        
                                    </div>
                                </b-col>
                                <!-- Filter for Current Status -->
                                <b-col xl="3">
                                    <div class="form-group">
                                        <label class="col-form-label">Select Current Status </label>
                                        <v-select v-model="resume_status" label="name" :options="resume_statuses" />
                                        
                                    </div>
                                </b-col>
                              
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <b-button variant="primary" type="submit" class="sm" @click.prevent="validationForm">
                                        Generate
                                    </b-button>
                                </b-col>
                            </b-row><br>
                           

                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(slNo)="row">
                                        <span>{{ row.item.slNo }}</span>
                                    </template>

                                    <!-- <template #cell(start_date)="rows">
                                        <span>
                                            {{ rows.item.start_date.split("T")[0] }}
                                        </span>
                                    </template>
                                    <template #cell(end_date)="rows">
                                        <span>
                                            {{ rows.item.end_date.split("T")[0] }}
                                        </span>
                                    </template> -->
                                    <template #cell(actions)="row">
                                        <span>
                                            <router-link :to="'/home/resume-view/' + row.item.resume_id">
                                                <feather type="eye"></feather>
                                            </router-link>
                                        </span>                                      
                                        <span>
                                            <router-link :to="'/home/resume-edit/' + row.item.resume_id">
                                                <feather type="edit"></feather>
                                            </router-link>
                                        </span>
                                                                      
                                    </template>

                                </b-table>

                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>

                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import S3 from "aws-s3";
import ServiceApi from "@/services/ServiceApi.js";
import vSelect from "vue-select";

export default {
    components: {
        vSelect,
    },
    data() {
        return {
          
            tablefields: [ 
                { key: 'slNo', label: 'Sl No', sortable: true, },
                { key: 'candidate', label: 'Name', sortable: true, },
                { key: 'email', label: 'Email', sortable: true, },
                { key: 'phone', label: 'Phone', sortable: true, },
                { key: 'age', label: 'Age', sortable: true, },
                { key: 'gender', label: 'Gender', sortable: true, },
                { key: 'total_mark', label: 'Total Mark', sortable: true, },
                { key: 'applied_pos', label: 'Applied Position', sortable: true, },
                { key: 'current_status', label: 'Current Status', sortable: true, },
                { key: 'applied_date', label: 'Applied Date', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],
            datalayout: JSON.parse(localStorage.getItem('loginDetails')),
            items: [],
            // leaveId: null,
            file: null,
            file_name: null,
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            isLoading : false,
            vacancy: null,
            resume_status: null,
            vacancies:[],
            resume_statuses: [],
            resume_status_id:null,
            vacancy_id:null,
            mark_to:null,
            mark_from:null
          
        };
    },
    // computed: {
    //     statusVariant() {
    //         const statusColor = {
    //             1: "light-warning",
    //             2: "light-info",
    //             3: "light-success",
    //             4: "light-danger",
    //             5: "light-danger",
    //         };

    //         return (status) => statusColor[status];
    //     },
    //     sortOptions() {
    //         // Create an options list from our fields
    //         return this.tablefields
    //             .filter(f => f.sortable)
    //             .map(f => {
    //                 return { text: f.label, value: f.key };
    //             });
    //     }
    // },

    mounted() {
        // Set the initial number of items
        this.retrieveAppliedVacancyList();
        this.getVacancyDetails();
        this.getResumeStatus();
        //this.getAwsS3Config();
    },

    methods: {

        // async getAwsS3Config() {
        //     const response = await Service.getAwsS3Configs();
        //     if (response.code == "200") {
        //         this.s3 = response.data;
        //     }
        // },

        async retrieveAppliedVacancyList() {
            this.isLoading = true;
            const response = await Service.GetAppliedVacanciesList();
            // console.log(response);
            if (response.code == "200") {
                // this.items = response.data;
                this.items = response.data.map((item, index) => {
                    return { ...item, slNo: index + 1 };
                });

                this.totalRows = response.data.length;
            }
            this.isLoading = false;
        },
        async getResumeStatus() {
            const response = await ServiceApi.GetResumeStatus();
            if (response.code == '200') {
                this.resume_statuses = response.data;
            }
        },
        validationForm() {
            if ((this.vacancy == null || this.vacancy == '') && 
                (this.resume_status == null || this.resume_status == '') && 
                (this.mark_to == null || this.mark_to == '') && 
                (this.mark_from == null || this.mark_from == '')) {
                    this.$toasted.show(' Please select the fields', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {

                // Check if mark_from and mark_to are numeric or decimal
                if (this.mark_from !== null && !/^\d+(\.\d+)?$/.test(this.mark_from)) {
                    this.$toasted.show('Mark from should be a number or decimal', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                    return;
                }
                if (this.mark_to !== null && !/^\d+(\.\d+)?$/.test(this.mark_to)) {
                    this.$toasted.show('Mark to should be a number or decimal', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                    return;
                }

                if (this.mark_from !== null && this.mark_to !== null && this.mark_from !== "" && this.mark_to !== "" && this.mark_from >= this.mark_to) {
                    this.$toasted.show('Mark from should be less than mark to', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                    return;
                }

                if (this.vacancy) {
                    this.vacancy_id = this.vacancy.vacancy_id;
                } else {
                    this.vacancy_id = null;
                }
                if (this.resume_status) {
                    this.resume_status_id = this.resume_status.status_id;
                } else {
                    this.resume_status_id = null;
                }
                const ReqData = {
                    vacancy_id: this.vacancy_id,
                    resume_status_id: this.resume_status_id,
                    mark_to: this.mark_to,
                    mark_from: this.mark_from,
                };
                this.GetReport(ReqData);
            }   
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getVacancyDetails() {

            const response = await ServiceApi.GetJobVacancies();
            if (response.code == '200') {
                this.vacancies = response.data;
            }

            else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }

        },

        async GetReport(ReqData) {
            this.isLoading= true;
            const response = await Service.GetAppliedVacancyReport(ReqData);
            if (response.code == '200') {
                this.isLoading = false;
                // Assuming response.data is an array of items
                this.items = response.data.map((item, index) => {
                    return { ...item, slNo: index + 1 };
                });
                // Assuming the totalRows should be set based on the length of the items array
                this.totalRows = this.items.length;
            } else {
                this.isLoading = false;
                // Handle error or empty response
                this.items = []; // Reset items to empty array
                this.totalRows = 0;
            }
        },

    }
};
</script>