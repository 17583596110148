<template>
    <div><br>
        <Breadcrumbs main="Payroll components" title="Edit Payroll components" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_name">Component</label>
                                        <b-form-input type="text" id="c_form_name" v-model="name" placeholder="Component">
                                        </b-form-input>

                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_name">Short code</label>
                                        <b-form-input type="text" id="c_form_name" v-model="shortCode"
                                            placeholder="Short code">
                                        </b-form-input>

                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_name">Description</label>
                                        <b-form-input type="text" id="c_form_name" v-model="desc" placeholder="Description">
                                        </b-form-input>

                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_name">Category</label>
                                        <b-form-select v-model="category" label="value" :options="categoryArr">
                                        </b-form-select>

                                    </div>

                                </div>
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <label for="c_form_name">Calculation type</label>
                                        <b-form-select v-model="calc_type" label="value" :options="calc_arr"
                                            v-on:input="makeEquation()">
                                        </b-form-select>

                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="c_form_name">Calculation value</label>
                                        <textarea class="form-control" type="text" id="c_form_name" v-model="calc_value"
                                            placeholder="Calculation value">
                                        </textarea>

                                    </div>
                                    <div class="col-md-4 " v-if="showEquMake">
                                        <label for="c_form_name">Components</label>
                                        <!-- <b-form-select v-model="pay_comp" :options="items"
                                            v-on:input="addValToEqu()">
                                        </b-form-select> -->

                                        <v-select v-model="pay_comp" label="text" placeholder="Components" :options="items"
                                            v-on:input="addValToEqu()" :multiple="false" />

                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_name">Employee type</label>
                                        <!-- <b-form-select v-model="emp_type" label="value" :options="emp_type_arr">
                                        </b-form-select> -->

                                        <v-select v-model="emp_type" label="text" placeholder="Employee type"
                                            :options="emp_type_arr" :multiple="true" />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_name">Recurring type</label>
                                        <b-form-select v-model="recur_type" label="value" v-on:input="showMonths()"
                                            :options="[{ value: '1', text: 'Yes' }, { value: '2', text: 'No' }]">
                                        </b-form-select>

                                    </div>
                                </div>
                                <div class="form-row" v-if="showMonthSelect">

                                    <div class="col-md-12 mb-3">
                                        <label for="c_form_name">Recurring Months :</label>
                                        <label class="month">
                                            <input type="checkbox" v-model="allOptions" true-value="true"
                                                false-value="false">
                                            Select all
                                        </label> <br>
                                        <label class="month" v-for="(option, index) in options" :key="option.key">
                                            <input type="checkbox" :value="option.key" v-model="selectedOptions">
                                            {{ option.value }}
                                        </label>

                                    </div>
                                </div>
                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            items: [],
            pay_comp: null,
            success: false,
            name: '',
            desc: '',
            showMonthSelect: false,
            showAll: true,
            showEquMake: false,
            allOptions: false,
            // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            shortCode: '',
            category: null,
            options: [
                { key: 1, value: "Jan" },
                { key: 2, value: "Feb" },
                { key: 3, value: "Mar" },
                { key: 4, value: "Apr" },
                { key: 5, value: "May" },
                { key: 6, value: "Jun" },
                { key: 7, value: "Jul" },
                { key: 8, value: "Aug" },
                { key: 9, value: "Sep" },
                { key: 10, value: "Oct" },
                { key: 11, value: "Nov" },
                { key: 12, value: "Dec" }
            ],
            selectedOptions: [],
            categoryArr: [

                { value: 1, text: 'Earning' },
                { value: 2, text: 'Deduction' }
            ],
            calc_type: null,
            calc_arr: [

                { value: 1, text: 'Equation' },
                { value: 2, text: 'Value' }
            ],
            calc_value: '',
            recur_type: null,
            recurring_months: null,
            emp_type: [],
            emp_type_arr: [

                { value: '1', text: 'Probation' },
                { value: '2', text: 'Permanent' },
                { value: '3', text: 'Contract' },
                { value: '4', text: 'Intern' },
                { value: '5', text: 'Freelance' },
                { value: '6', text: 'Notice period' },
            ]
        };
    },
    watch: {
        allOptions(value) {
            if (value == 'true') {
                this.selectedOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            } else {
                this.selectedOptions = []
            }
        }
    },
    computed: {

    },
    mounted() {
        this.getPayrollCompById();
        this.getAllPayrollComp();
    },
    methods: {
        async getPayrollCompById() {
            const reqObj = {
                id: this.$route.params.id
            }
            const response = await Service.getPayrollComponentsById(reqObj);
            if (response.code == "200") {

                this.name = response.data.name;
                this.shortCode = response.data.short;
                this.desc = response.data.desc;
                this.category = response.data.category;
                this.calc_type = response.data.calc_type;
                this.calc_value = response.data.calc_value;

                this.emp_type_arr.forEach(element => {
                    if (response.data.employee_type.includes(element.value)){
                        this.emp_type.push(element)
                    }
                });
                console.log(this.emp_type);
                this.recur_type = response.data.recurring_type;
                this.selectedOptions = response.data.recur_months;

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getAllPayrollComp() {
            const response = await Service.RetrievePayrollComponents();
            if (response.code == "200") {
                // this.items = response.data;
                response.data.forEach(element => {
                    this.items.push({ value: element.id, text: element.name })
                });

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        addValToEqu() {
            this.calc_value = this.calc_value + '_' + this.pay_comp.text + '_';
        },
        // listAllPayComp(){
        //     alert(1)
        // },
        makeEquation() {
            if (this.calc_type == 1) {
                this.showEquMake = true;
            }else{
                this.showEquMake = false;
            }

        },
        showMonths() {
            if (this.recur_type == 1) {
                this.showMonthSelect = true;
            } else {
                this.showMonthSelect = false;
            }

        },
        onCustomStyleSubmit(evt) {
            evt.preventDefault();

            if (this.name == '' || this.shortCode == '' || this.desc == '' || this.category == null || this.calc_type == null || this.calc_value == '' || this.emp_type == null || this.recur_type == null) {
                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                if (this.recur_type != 1) {
                    var empType = []
                    this.emp_type.forEach(element => {
                        empType.push(Number(element.value))
                    });
                    const dept_data = {
                        id: this.$route.params.id,
                        name: this.name,
                        code: this.shortCode,
                        desc: this.desc,
                        category: this.category,
                        calc_type: this.calc_type,
                        calc_val: this.calc_value,
                        emp_type: empType,
                        recur_type: this.recur_type,
                        months: null
                    }
                    this.editPayroll(dept_data)
                    // console.log(this.name, this.shortCode, this.desc, this.category, this.calc_type, this.calc_value, this.emp_type, this.recur_type, this.selectedOptions);
                } else {
                    // console.log(this.selectedOptions);
                    if (this.selectedOptions.length == 0) {
                        this.$toasted.show(' Please enter required fields 2', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                    } else {
                        var empType = []
                        this.emp_type.forEach(element => {
                            empType.push(Number(element.value))
                        });
                        const dept_data = {
                            id: this.$route.params.id,
                            name: this.name,
                            code: this.shortCode,
                            desc: this.desc,
                            category: this.category,
                            calc_type: this.calc_type,
                            calc_val: this.calc_value,
                            emp_type: empType,
                            recur_type: this.recur_type,
                            months: this.selectedOptions
                        }
                        this.editPayroll(dept_data)
                        // console.log(this.name, this.shortCode, this.desc, this.category, this.calc_type, this.calc_value, this.emp_type, this.recur_type, this.selectedOptions);
                    }
                }
            }

            // this.saveDepartment();
        },
        // saveDepartment() {
        //     if (this.success == true) {
        //         const dept_data = {
        //             name: this.name,
        //         }
        //         this.addDepartment(dept_data)
        //     }
        // },
        async editPayroll(dept_data) {
            console.log(dept_data);
            const response = await Service.UpdatePayrollComponent(dept_data);
            if (response.code == '200') {
                this.$toasted.show(' Payroll component updated successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/payroll-components');
            } else if (response.code == '400') {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        }
    }
};
</script>
<style lang="scss">
@import "vue-select/dist/vue-select.css";

.month {
    padding: 25px;
    font-size: initial;
}

.equMake {
    height: 100%;
}
</style>