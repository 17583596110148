<template>
    <div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="user-profile">
                <div class="row">
                    <!-- user profile first-style start-->
                    <div class="col-sm-12">

                        <br /><br />

                        <div class="card hovercard text-center"><br>
                            <div>
                                <h5> Client View</h5>
                            </div>

                            <div class="info">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-flag"></i> Name </h6>
                                            <span>
                                                {{ this.name }}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-flag"></i> Email </h6>
                                            <span>
                                                {{ this.email }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-flag"></i> Phone </h6>
                                            <span>
                                                {{ this.phone }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-flag"></i> Address </h6>
                                            <span>
                                                {{ this.address }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>              
            </div>
        </div>
    </div>
</template>

<script>

import ServiceApi from '@/services/ServiceApi.js';
import vSelect from 'vue-select'

export default {

    components: {
        vSelect,
    },

    data() {
        return {
            name: '',
            address: '',
            email: '',
            phone: '',
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            items: [],

        };
    },
    props: ['taskId', 'heading'],
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-warning",
                2: "light-info",
                3: "light-success",
                4: "light-info",
                5: "light-info",
            };

            return (status) => statusColor[status];
        },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        },

    },

    mounted() {
        this.getClientById();

    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        async getClientById() {
            const ReqData = {
                id: this.$route.params.id,
            };
            const response = await ServiceApi.GetClientById(ReqData);
            if (response.code == '200') {
                this.name = response.data.name;
                this.address = response.data.address;
                this.email = response.data.email;
                this.phone = response.data.phone;



            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }

        },


    }
};

</script>
<style>
.p-dot {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

ul {
    padding-left: 0px;
    list-style-type: unset;
    margin-bottom: 0;
}</style>