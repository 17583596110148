import Vue from 'vue'
import Router from "vue-router";

import Body from '../components/body'

import Auth from '../auth/auth';
import login from '../auth/login';
import otp from '../auth/otp';
import company from '../pages/Companies/list';
import companyAdd from '../pages/Companies/add';
import companyEdit from '../pages/Companies/edit';
import designation from '../pages/Designation/list';
import designationAdd from '../pages/Designation/add';
import designationEdit from '../pages/Designation/edit';
import department from '../pages/Departments/list';
import departmentAdd from '../pages/Departments/add';
import departmentEdit from '../pages/Departments/edit';
import company_admin from '../pages/Companies/admin/list';
import companyAdminAdd from '../pages/Companies/admin/add';
import companyAdminEdit from '../pages/Companies/admin/edit';
import employees from '../pages/Employees/list';
import employeeAdd from '../pages/Employees/add';
import employeesView from '../pages/Employees/view';
import employeeEdit from '../pages/Employees/edit';
import leaveAdd from '../pages/Leave/add';
import leaveList from '../pages/Leave/list';
import leaveTypes from '../pages/LeaveTypes/list';
import leaveTypeAdd from '../pages/LeaveTypes/add';
import leaveEdit from '../pages/Leave/edit';
import leaveView from '../pages/Leave/view';
import leaveAcknowledge from '../pages/LeaveAck/list';


import tasks from '../pages/Tasks/task-list';
import taskAdd from '../pages/Tasks/add';
import taskEdit from '../pages/Tasks/edit';
import taskView from '../pages/Tasks/task-view';
import projectView from '../pages/project/project-view';
import leaveTypeEdit from '../pages/LeaveTypes/edit';

/* projects */
import projectlist from '../pages/project/project-list';
import createproject from '../pages/project/create-project';
import editProject from '../pages/project/edit-project';
import taskTypeList from '../pages/TaskType/list';
import taskTypeAdd from '../pages/TaskType/add';
import taskTypeEdit from '../pages/TaskType/edit';
import projectMembers from '../pages/project/project-member-list';
import report from '../pages/Reports/view';
import report_calendar from '../pages/Reports/report_calendar';
import monthly_attendance from '../pages/Reports/monthly_attendance';
import lateComers from '../pages/Reports/late-comers';
import leaveReport from '../pages/Reports/leave-report';
import attendanceReport from '../pages/Reports/attendance-report';
import salaryReport from '../pages/Reports/salary-report';
import interReport from '../pages/Reports/interview-report';
// component  
import attendanceList from '../pages/Attendance/list';
import leavesAdd from '../pages/Leave/add-leave';

import onsite from '../pages/Onsite/list';
import onsiteAdd from '../pages/Onsite/add-onsite';
import onsiteView from '../pages/Onsite/view';
import configuration from '../pages/Configuration/list';
import configAdd from '../pages/Configuration/add';
import configEdit from '../pages/Configuration/edit';

import overtime from '../pages/Overtime/list';
import overtimeAdd from '../pages/Overtime/add-overtime';
import overtimeView from '../pages/Overtime/view';
import vacancy from '../pages/Vacancy/list';
import vacancyAdd from '../pages/Vacancy/add';
import vacancyEdit from '../pages/Vacancy/edit';
import vacancyView from '../pages/Vacancy/view';

import appliedvacanciesList from '../pages/Applied_Vacancies/list'; 
import appliedUsersList from '../pages/Applied_Users/list'; 

import resumeView from '../pages/Applied_Vacancies/view'
import resumeTransactionview from '../pages/Applied_Vacancies/view-transaction';
import resumeAdd from '../pages/Applied_Vacancies/add';

import resumeStatus from '../pages/ResumeStatus/list';
import resumeStatusAdd from '../pages/ResumeStatus/add';
import resumeStatusEdit from '../pages/ResumeStatus/edit';
import resumeStatusView from '../pages/ResumeStatus/view';

import holiday from '../pages/Holiday/list';
import holidayAdd from '../pages/Holiday/add';
import holidayEdit from '../pages/Holiday/edit';

import clients from '../pages/Clients/list';
import clientAdd from '../pages/Clients/add';
import clientEdit from '../pages/Clients/edit';
import clientView from '../pages/Clients/view';

/* Dashboards */
import Default from '../pages/dashboard/default';
import pin from '../pages/Attendance/pin';
import daily_attendance from '../pages/Attendance/daily_attendance';
import generateAttendance from '../pages/Attendance/generate_attendance';

// projects
import empProjects from '../pages/Emp_projects/view';
import createPhase from '../pages/Emp_projects/add-phase';
import createModule from '../pages/Emp_projects/add-module';
import manageTask from '../pages/Emp_projects/task-view';
import ProTaskAdd from '../pages/Emp_projects/add-task';
import ProBugAdd from '../pages/Emp_projects/add-bug';
import ProBugList from '../pages/Emp_projects/bug-list';
import managePhase from '../pages/Emp_projects/list-phase';
import editProjPhase from '../pages/Emp_projects/edit-phase';
import manageProject from '../pages/Emp_projects/manage-project';
import manageModule from '../pages/Emp_projects/list-module';
import editProjectModule from '../pages/Emp_projects/edit-module';

// actvities
import activities from '../pages/Activity/all-activities';

// payroll
import listPayrollComp from '../pages/payroll/list'
import addPayrollComp from '../pages/payroll/add'
import editPayrol from '../pages/payroll/edit';
import empPayroll from '../pages/Employees/user-payroll';

import resumeEdit from '../pages/Applied_Vacancies/edit';

import otp_firebase from '../auth/otp-firebase';

Vue.use(Router)

const routes = [
  { path: '', redirect: { name: 'Login' } },
  {
    path: '/home',
    component: Body,
    children: [
      {
        path: '',
        name: 'default',
        component: Default,
        meta: {
          title: 'QCM |  Home',
        }
      },
      {
        path: 'company',
        name: 'Company',
        component: company,
        meta: {
          title: 'QCM | Company manage',
        }
      },
      {
        path: 'company-add',
        name: 'Company',
        component: companyAdd,
        meta: {
          title: 'QCM | Company manage',
        }
      },
      {
        path: 'company-edit/:id',
        name: 'Company',
        component: companyEdit,
        meta: {
          title: 'QCM | Company manage',
        }
      },
      {
        path: 'designations',
        name: 'Designations',
        component: designation,
        meta: {
          title: 'QCM | Designations manage',
        }
      },
      {
        path: 'designation-add',
        name: 'Designations',
        component: designationAdd,
        meta: {
          title: 'QCM | Designations manage',
        }
      },
      {
        path: 'designation-edit/:id',
        name: 'Designations',
        component: designationEdit,
        meta: {
          title: 'QCM | Designations manage',
        }
      },
      {
        path: 'department',
        name: 'Department',
        component: department,
        meta: {
          title: 'QCM | Department manage',
        }
      },
      {
        path: 'department-add',
        name: 'Department',
        component: departmentAdd,
        meta: {
          title: 'QCM | Department manage',
        }
      },
      {
        path: 'department-edit/:id',
        name: 'Department',
        component: departmentEdit,
        meta: {
          title: 'QCM | Department manage',
        }
      },
      {
        path: 'company-admin',
        name: 'Company Admin',
        component: company_admin,
        meta: {
          title: 'QCM | Company admin manage',
        }
      },
      {
        path: 'company-admin-add',
        name: 'Company Admin',
        component: companyAdminAdd,
        meta: {
          title: 'QCM | Company Admin Cerate',
        }
      },
      {
        path: 'company-admin-edit/:id',
        name: 'Company Admin',
        component: companyAdminEdit,
        meta: {
          title: 'QCM | Company Admin modify',
        }
      },
      {
        path: 'employees',
        name: 'Employees',
        component: employees,
        meta: {
          title: 'QCM | Employee manage',
        }
      },
      {
        path: 'project',
        name: 'project-list',
        component: projectlist,
        meta: {
          title: 'QCM | Project manage',
        }
      },
      {
        path: 'create-project',
        name: 'create-project',
        component: createproject,
        meta: {
          title: 'QCM | Create Project',
        }
      },
      {
        path: 'edit-project/:id',
        name: 'edit-project',
        component: editProject,
        meta: {
          title: 'QCM | Edit Project',
        }
      },
      {
        path: 'employee-view/:id/:view',
        name: 'Employees',
        component: employeesView,
        meta: {
          title: 'QCM | Employee View',
        }
      },
      {
        path: 'employees-add',
        name: 'Employees',
        component: employeeAdd,
        meta: {
          title: 'QCM | Employee manage',
        }
      },
      {
        path: 'employee-edit/:id',
        name: 'Employees',
        component: employeeEdit,
        meta: {
          title: 'QCM | Employee manage',
        }
      },
      {
        path: 'leave-types',
        name: 'leave-types',
        component: leaveTypes,
        meta: {
          title: 'QCM | Leave types manage',
        }
      },
      {
        path: 'leavetype-add',
        name: 'leavetype-add',
        component: leaveTypeAdd,
        meta: {
          title: 'QCM | Leave types manage',
        }
      },
      {

        path: 'leavetype-edit/:id',
        name: 'leavetype-edt',
        component: leaveTypeEdit,
        meta: {
          title: 'QCM | Leave types manage',
        }
      },
      {
        path: 'leave',
        name: 'leave',
        component: leaveList,
        meta: {
          title: 'QCM | Leave manage',
        }
      },
      {
        path: 'leave-view/:id',
        name: 'leave',
        component: leaveView,
        meta: {
          title: 'QCM | Leave manage',
        }
      },
      {
        path: 'leave-apply/:id',
        name: 'leave',
        component: leaveAdd,
        meta: {
          title: 'QCM | Leave Apply',
        }
      },
      {
        path: 'leave-edit/:id',
        name: 'leave',
        component: leaveEdit,
        meta: {
          title: 'QCM | Leave Edit',
        }
      },
      {
        path: 'view-project/:id',
        name: 'view-project',
        component: projectView,
        meta: {
          title: 'QCM | View Project',
        }
      },
      {
        path: 'task',
        name: 'Task',
        component: tasks,
        meta: {
          title: 'QCM | Leave manage',
        }
      },
      {
        path: 'task-add/:id/:start/:end',
        name: 'Task',
        component: taskAdd,
        meta: {
          title: 'QCM | Leave manage',
        }
      },
      {
        path: 'task-edit/:id',
        name: 'Task',
        component: taskEdit,
        meta: {
          title: 'QCM | Leave manage',
        }
      },
      {
        path: 'task-view/:id',
        name: 'Task',
        component: taskView,
        meta: {
          title: 'QCM | Leave manage',
        }
      },
      {
        path: 'task-types',
        name: 'Task Type',
        component: taskTypeList,
        meta: {
          title: 'QCM | Task type manage',
        }
      },
      {
        path: 'task-type-add',
        name: 'Task Type',
        component: taskTypeAdd,
        meta: {
          title: 'QCM | Task type manage',
        }
      },
      {
        path: 'task-type-edit/:id',
        name: 'Task Type',
        component: taskTypeEdit,
        meta: {
          title: 'QCM | Task type manage',
        }
      },
      {
        path: 'project-memeber',
        name: 'project-memeber-list',
        component: projectMembers,
        meta: {
          title: 'QCM | Project manage',
        }
      },
      {
        path: 'attendance',
        name: 'attendance',
        component: attendanceList,
        meta: {
          title: 'QCM | Attendance manage',
        }
      },
      {
        path: 'old-report',
        name: 'Report',
        component: report,
        meta: {
          title: 'QCM | Report manage',
        }
      },
      {
        path: 'monthly-attendance',
        name: 'Monthly  Attendance ',
        component: monthly_attendance,
        meta: {
          title: 'QCM | Monthly  Attendance ',
        }
      },
      {
        path: 'report',
        name: 'Report',
        component: report_calendar,
        meta: {
          title: 'QCM | Report manage',
        }
      },
      {
        path: 'late-comers',
        name: 'Late Comers',
        component: lateComers,
        meta: {
          title: 'QCM |  Late Comers Report manage',
        }
      },
      {
        path: 'leave-report',
        name: 'Leave Report',
        component: leaveReport,
        meta: {
          title: 'QCM |  Leave Report',
        }
      },
      {
        path: 'attendance-report',
        name: 'Attendance Report',
        component: attendanceReport,
        meta: {
          title: 'QCM |  Attendance Report',
        }
      },
      {
        path: 'leave-add',
        name: 'leave',
        component: leavesAdd,
        meta: {
          title: 'QCM | Leave Apply',
        }
      },
      {
        path: 'leave-ack',
        name: 'leave Acknowledge',
        component: leaveAcknowledge,
        meta: {
          title: 'QCM | Leave Acknowledge',
        }
      },
      {
        path: 'onsite',
        name: 'Onsite',
        component: onsite,
        meta: {
          title: 'QCM |  Onsite manage',
        }
      },
      {
        path: 'onsite-add',
        name: 'onsite-add',
        component: onsiteAdd,
        meta: {
          title: 'QCM | Onsite manage',
        }
      },
      {
        path: 'onsite-view/:id',
        name: 'onsite-view',
        component: onsiteView,
        meta: {
          title: 'QCM | Onsite manage',
        }
      },
      {
        path: 'overtime',
        name: 'Overtime',
        component: overtime,
        meta: {
          title: 'QCM |  Overtime manage',
        }
      },
      {
        path: 'overtime-add',
        name: 'Overtime Add',
        component: overtimeAdd,
        meta: {
          title: 'QCM | Overtime manage',
        }
      },
      {
        path: 'overtime-view/:id',
        name: 'Overtime View',
        component: overtimeView,
        meta: {
          title: 'QCM | Overtime manage',
        }
      },
      {
        path: 'configuration',
        name: 'Configuration',
        component: configuration,
        meta: {
          title: 'QCM |  Configuration manage',
        }
      },
      {
        path: 'configuration-add',
        name: 'configuration-add',
        component: configAdd,
        meta: {
          title: 'QCM | Configuration manage',
        }
      },
      {
        path: 'configuration-edit/:id',
        name: 'configuration-edit',
        component: configEdit,
        meta: {
          title: 'QCM | Configuration manage',
        }
      },
      {
        path: 'vacancies',
        name: 'Job Vacancies',
        component: vacancy,
        meta: {
          title: 'QCM |  Job Vacancy manage',
        }
      },
      {
        path: 'vacancy-add',
        name: 'vacancy-add',
        component: vacancyAdd,
        meta: {
          title: 'QCM | Job Vacancy manage',
        }
      },
      {
        path: 'vacancy-view/:id',
        name: 'vacancy-view',
        component: vacancyView,
        meta: {
          title: 'QCM | Job Vacancy manage',
        }
      },
      {
        path: 'vacancy-edit/:id',
        name: 'vacancy-edit',
        component: vacancyEdit,
        meta: {
          title: 'QCM | Job Vacancy manage',
        }
      },
      {
        path: 'applied_users',
        name: 'applied_users',
        component: appliedUsersList,
        meta: {
          title: 'QCM | Applied Users List',
        }
      },
      {
        path: 'applied_vacancies',
        name: 'applied_vacancies',
        component: appliedvacanciesList,
        meta: {
          title: 'QCM | Applied Vacancy List',
        }
      },
      {
        path: 'resume-view/:ids',
        name: 'resume details',
        component: resumeView,
        meta: {
          title: 'QCM | Resume Details View',
        }
      },
      {
        path: 'resume-add',
        name: 'resume-add',
        component: resumeAdd,
        meta: {
          title: 'QCM | Resume manage',
        }
      },
      {
        path: 'resume_status',
        name: 'Resume Status ',
        component: resumeStatus,
        meta: {
          title: 'QCM |  Resume Status manage',
        }
      },
      {
        path: 'resume-status-add',
        name: 'Resume Status Add',
        component: resumeStatusAdd,
        meta: {
          title: 'QCM |  Resume Status manage',
        }
      },
      {
        path: 'resume-status-edit/:id',
        name: 'Resume Status Edit',
        component: resumeStatusEdit,
        meta: {
          title: 'QCM |  Resume Status manage',
        }
      },
      {
        path: 'resume-status-view/:id',
        name: 'Resume Status View',
        component: resumeStatusView,
        meta: {
          title: 'QCM |  Resume Status manage',
        }
      },
      {
        path: 'resume-transaction-view/:id',
        name: 'resume transaction details',
        component: resumeTransactionview,
        meta: {
          title: 'QCM | Resume Transaction Details',
        }
      },

      {
        path: 'holidays',
        name: 'Holidays',
        component: holiday,
        meta: {
          title: 'QCM |  Holiday manage',
        }
      },
      {
        path: 'holiday-add',
        name: 'holiday-add',
        component: holidayAdd,
        meta: {
          title: 'QCM | Holiday manage',
        }
      },
     
      {
        path: 'holiday-edit/:id',
        name: 'holiday-edit',
        component: holidayEdit,
        meta: {
          title: 'QCM | Holiday manage',
        }
      },
      {
        path: 'clients',
        name: 'Clients ',
        component: clients,
        meta: {
          title: 'QCM |  Clients',
        }
      },
      {
        path: 'clients-add',
        name: 'Clients Add',
        component: clientAdd,
        meta: {
          title: 'QCM |  Clients manage',
        }
      },
      {
        path: 'clients-edit/:id',
        name: 'Client Edit',
        component: clientEdit,
        meta: {
          title: 'QCM |  Clients manage',
        }
      },
      {
        path: 'clients-view/:id',
        name: 'Client View',
        component: clientView,
        meta: {
          title: 'QCM |  Clients manage',
        }
      },
      
      {
        path: 'verify-passcode',
        name: 'verify-passcode',
        component: pin,
        meta: {
          title: 'QCM |  Verify Passcode',
        }
      },
      {
        path: 'daily-attendance',
        name: 'daily-attendance',
        component: daily_attendance,
        meta: {
          title: 'QCM | Daily Attendance',
        }
      },
      {
        path: 'generate-attendance',
        name: 'generate-attendance',
        component: generateAttendance,
        meta: {
          title: 'QCM |  Generate Attendance',
        }
      },
      {
        path: 'payroll-components',
        name: 'Payroll components',
        component: listPayrollComp,
        meta: {
          title: 'QCM |  Manage Payroll',
        }
      },
      {
        path: 'payroll-components-add',
        name: 'Add Payroll components',
        component: addPayrollComp,
        meta: {
          title: 'QCM |  Manage Payroll',
        }
      },
      {
        path: 'payroll-components-edit/:id',
        name: 'Edit Payroll components',
        component: editPayrol,
        meta: {
          title: 'QCM |  Manage Payroll',
        }
      },
      { path: 'resume-edit/:ids', 
        name: 'resume details', 
        component: resumeEdit, 
        meta: { 
          title: 'QCM | Resume Details Edit', 
        } 
      },
      { path: 'employee-payroll-edit/:id', 
        name: 'Employee payroll details', 
        component: empPayroll, 
        meta: { 
          title: 'QCM | Payroll Details Edit', 
        } 
      },
      { path: 'salary-report', 
        name: 'Employee Salary report', 
        component: salaryReport, 
        meta: { 
          title: 'QCM | Salary Report', 
        } 
      },
      { path: 'interview-report', 
        name: 'Interview report', 
        component: interReport, 
        meta: { 
          title: 'QCM | Interview Report', 
        } 
      },
      { path: 'emp-project/:id', 
        name: 'Projects', 
        component: empProjects, 
        meta: { 
          title: 'QCM | Projects', 
        } 
      },
      {
        path: 'create-phase/:id',
        name: 'create-phase',
        component: createPhase,
        meta: {
          title: 'QCM | Create Phase',
        }
      },
      {
        path: 'create-module/:id',
        name: 'create-module',
        component: createModule,
        meta: {
          title: 'QCM | Create module',
        }
      },
      {
        path: 'manage-task/:id',
        name: 'create-task',
        component: manageTask,
        meta: {
          title: 'QCM | Create task',
        }
      },
      {
        path: 'project-task-add/:id',
        name: 'create-task',
        component: ProTaskAdd,
        meta: {
          title: 'QCM | Create task',
        }
      },
      {
        path: 'project-bug-add/:id',
        name: 'create-bug',
        component: ProBugAdd,
        meta: {
          title: 'QCM | Create Bug',
        }
      },
      {
        path: 'project-bug-list/:id',
        name: 'list-bug',
        component: ProBugList,
        meta: {
          title: 'QCM |  Bug list',
        }
      },
      {
        path: 'manage-phase/:id',
        name: 'manage-phase',
        component: managePhase,
        meta: {
          title: 'QCM | Manage Phase',
        }
      },
      {
        path: 'edit-project-phase/:id',
        name: 'edit-phase',
        component: editProjPhase,
        meta: {
          title: 'QCM | Edit Phase',
        }
      },
      {
        path: 'manage-project/:id',
        name: 'manage-project',
        component: manageProject,
        meta: {
          title: 'QCM | Manage Project',
        }
      },
      {
        path: 'list-project-module/:id',
        name: 'manage-module',
        component: manageModule,
        meta: {
          title: 'QCM | Manage Module',
        }
      },
      {
        path: 'edit-project-module/:id',
        name: 'manage-module',
        component: editProjectModule,
        meta: {
          title: 'QCM | Manage Module',
        }
      },
      // {
      //   path: 'view-notifications',
      //   name: 'manage-notifications',
      //   component: editProjectModule,
      //   meta: {
      //     title: 'QCM | Manage notifications',
      //   }
      // },
      {
        path: 'activities',
        name: 'manage-activities',
        component: activities,
        meta: {
          title: 'QCM | Manage Activities',
        }
      },

    ],
    meta: {
      title: 'QCM |  Home',
    }
  },
  {
    path: '/auth',
    component: Auth,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: login,
        meta: {
          title: ' QCM |  Login',
        }
      },
      {
        path: 'login-otp',
        name: 'Otp',
        component: otp,
        meta: {
          title: 'QCM |  OTP Verification',
        }
      },
      {
        path: 'otp',
        name: 'Otp',
        component: otp_firebase,
        meta: {
          title: 'QCM |  OTP Verification',
        }
      },
    ]
  },
];

const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

export default router
