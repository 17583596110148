import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'


const config = {
    apiKey: "AIzaSyC0sqq1kkq5K4vC7tNulE3GLBK9w8knpXI",
    authDomain: "hrms-63bc2.firebaseapp.com",
    projectId: "hrms-63bc2",
    storageBucket: "hrms-63bc2.appspot.com",
    messagingSenderId: "477933827143",
    appId: "1:477933827143:web:158e8d1494e26ebe0eed62",
    measurementId: "G-W6PG37PHZ8"
};

firebase.initializeApp(config)

export default firebase