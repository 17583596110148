<template>
    <div>
        <!-- Loader -->
        <div v-if="isLoading" class="loader-container">
            <div class="loader"></div>
        </div>

        <Breadcrumbs main="Applied Vacancies" title="Applied Users" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">


                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">
                            <!-- <b-row>
                                <b-col xl="12">
                                    <router-link :to="'/home/resume-add/'">
                                        <b-button id="default-primary" variant="primary" >Apply Job
                                        </b-button>
                                    </router-link>
                                </b-col>
                            </b-row><br> -->

                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(slNo)="row">
                                        <span>{{ row.item.slNo }}</span>
                                    </template>

                                    <!-- <template #cell(start_date)="rows">
                                        <span>
                                            {{ rows.item.start_date.split("T")[0] }}
                                        </span>
                                    </template>
                                    <template #cell(end_date)="rows">
                                        <span>
                                            {{ rows.item.end_date.split("T")[0] }}
                                        </span>
                                    </template> -->
                                    <template #cell(actions)="row">
                                        <span>
                                            <router-link :to="'/home/resume-view/' + row.item.resume_id">
                                                <feather type="eye"></feather>
                                            </router-link>
                                        </span>                                      
                                        <span>
                                            <router-link :to="'/home/resume-edit/' + row.item.resume_id">
                                                <feather type="edit"></feather>
                                            </router-link>
                                        </span>
                                                                      
                                    </template>

                                </b-table>

                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>

                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import S3 from "aws-s3";
import ServiceApi from "@/services/ServiceApi.js";

export default {
    data() {
        return {
            tablefields: [ 
                { key: 'slNo', label: 'Sl No', sortable: true, },
                { key: 'candidate', label: 'Name', sortable: true, },
                { key: 'email', label: 'Email', sortable: true, },
                { key: 'phone', label: 'Phone', sortable: true, },
                { key: 'age', label: 'Age', sortable: true, },
                { key: 'gender', label: 'Gender', sortable: true, },
                { key: 'cur_city', label: 'Current City', sortable: true, },
                // { key: 'applied_pos', label: 'Applied Position', sortable: true, },
                // { key: 'current_status', label: 'Current Status', sortable: true, },
                // { key: 'applied_date', label: 'Applied Date', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],
            datalayout: JSON.parse(localStorage.getItem('loginDetails')),
            items: [],
            // leaveId: null,
            isLoading : false,
            file: null,
            file_name: null,
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
        };
    },
    // computed: {
    //     statusVariant() {
    //         const statusColor = {
    //             1: "light-warning",
    //             2: "light-info",
    //             3: "light-success",
    //             4: "light-danger",
    //             5: "light-danger",
    //         };

    //         return (status) => statusColor[status];
    //     },
    //     sortOptions() {
    //         // Create an options list from our fields
    //         return this.tablefields
    //             .filter(f => f.sortable)
    //             .map(f => {
    //                 return { text: f.label, value: f.key };
    //             });
    //     }
    // },

    mounted() {
        // Set the initial number of items
        this.retrieveAppliedVacancyList();
        //this.getAwsS3Config();
    },

    methods: {

        // async getAwsS3Config() {
        //     const response = await Service.getAwsS3Configs();
        //     if (response.code == "200") {
        //         this.s3 = response.data;
        //     }
        // },

        async retrieveAppliedVacancyList() {
            this.isLoading = true;

            const response = await Service.GetAppliedUsersList();
            // console.log(response);

            if (response.code == "200") {
                // this.items = response.data;
                this.items = response.data.map((item, index) => {
                    return { ...item, slNo: index + 1 };
                });

                this.totalRows = response.data.length;
            }
            this.isLoading = false;
        },


        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        // getId(id) {
        //     this.leaveId = id;

        // },
        // onFileChange(e) {

        //     var files = e.target.files || e.dataTransfer.files;
        //     if (!files.length) {
        //         return;
        //     }

        //     let file = files[0];
        //     const now = Date.now();
        //     const config = {
        //         bucketName: this.s3.bucketName,
        //         dirName: "leave_docs" /* optional */,
        //         region: this.s3.region,
        //         accessKeyId: this.s3.accessKeyId,
        //         secretAccessKey: this.s3.secretAccessKey,
        //         s3Url: "https://bktqaptive.s3.amazonaws.com" /* optional */,
        //     };

        //     const S3Client = new S3(config);
        //     S3Client.uploadFile(file, this.emp_ref_no+now).then((response) => {
        //         this.file = response.location;
        //     });

        // },

        // onDocumentSubmit() {

        //     //evt.preventDefault();
        //     const credentials = {
        //         id: this.leaveId,
        //         file: this.file,
        //     };

        //     this.editLeaveDocument(credentials);

        //     this.$bvModal.hide("bv-modal-document");
        //     // this.retrieveLeaveList();
        // },

        // async editLeaveDocument(credentials) {
        //     const response = await ServiceApi.UpdateLeaveDocument(credentials);
        //     if (response.code == "200") {
        //         this.$toasted.show(" Leave document updated successfully ", {
        //             theme: "outline",
        //             position: "top-right",
        //             type: "success",
        //             duration: 2000,
        //         });
        //         this.retrieveLeaveList();
        //     } else if (response.code == "403") {
        //         this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        //         localStorage.removeItem('loginDetails')
        //         localStorage.removeItem('menuData')
        //         this.$router.push('/');
        //     }
        // },

    }
};
</script>
<style>
/* CSS for loader */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's above other content */
}
.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>