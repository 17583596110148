<template>
    <div>
        <br>
        <Breadcrumbs main="Project bug" title="Project bug List" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">


                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">
                          
                            <b-row>
                                <b-col xl="32">
                                    <a  >
                                                                            <b-button @click="ackLeave(1)" id="default-primary" class="btn-pill"
                                                                                variant="outline-primary">
                                                                               
                                                                                Add Bug
                                                                            </b-button>
                                                                        </a> &nbsp;
                                    </b-col>
                                <b-col xl="4">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                               
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(title)="rows">
                                                        <span v-b-modal.modal-4 @click="describeView(rows.item.full_title)">
                                                            <p class="p-dot">{{ rows.item.title }}</p>
                                                        </span>
                                                    </template>
                                    <template #cell(started_task)="rows">
                                        <b-badge :variant="statusVariant(rows.item.started_task)">

                                            <span v-if="rows.item.started_task == true">
                                                on Progress 
                                            </span>
                                            <span v-else-if="rows.item.started_task == false">
                                                completed
                                            </span>
                                        </b-badge>
                                    </template>
                                   

                                    <template #cell(actions)="rows">
                                        <span  title="Acknowledge">
                                            <feather type="eye" @click="ackLeave(2,rows.item.id)"></feather>
                                        </span>

                                    </template>

                                </b-table>

                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>


                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->

        <b-modal id="modal-4" v-model="modalShow1" cancel-only>
                                    <div class="row">
                                        <div class="col-12">

                                            {{ describe_text }}

                                        </div>
                                    </div>
                                    <!-- <p> {{ describe_text }}</p> -->

                                    <!-- <div class="row">
                                        <div class="col-6">
                                            <b-button class="mt-3 " variant="danger" @click="hideModal1">Close</b-button>
                                        </div>
                                    </div> -->
                                    <template #modal-footer>
                                        <div class="w-100">
                                            <!-- <p class="float-left">Modal Footer Content</p> -->
                                            <b-button variant="danger" size="sm" class="float-right" @click="hideModal1">
                                                Close
                                            </b-button>
                                        </div>
                                    </template>
                                </b-modal>
    </div>
</template>

<script>
import ServiceApi from '@/services/ServiceApi.js';
import Swal from "sweetalert2";

export default {
    data() {
        return {
            tablefields: [
                { key: 'sid', label: 'S No', sortable: true, },
                { key: 'title', label: 'Title', sortable: true, },
                { key: 'created_by', label: 'Created By', sortable: true, },
                { key: 'started_task', label: 'Status', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],

            items: [],
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            modalShow1:false,
            describe_text:null
        };
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-warning",
                2: "light-info",
                3: "light-success",
                4: "light-danger",
                5: "light-danger",
            };
            return (status) => statusColor[status];
        },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        }
    },
    mounted() {
      
        this.getBugList();
    },
    methods: {
        hideModal1() {
            this.describe_text=null,
            this.modalShow1 = false;
        },
        describeView(text) {
            this.describe_text = text;
        },
        async getBugList() {

            const reqDatas = {
                module_id: this.$route.params.id
            }

            const response = await ServiceApi.getBugList(reqDatas);
            if (response.code == "200") {
                this.items = response.data;
                console.log(this.items);
                this.totalRows = response.data.length;
            }
            else{
                this.items = "";
            }
        },
      
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
      
        ackLeave(typ,td=null) {
            if (typ == 1) {
            this.$router.push('/home/project-bug-add/' + this.$route.params.id);
                
            }else{
                this.$router.push('/home/manage-task/' + td);

            }



        },

        async leaveAck(credentials) {
        const response = await ServiceApi.LeaveAck(credentials);
        if (response.code == "200") {
            Swal.fire("Acknowledged!", "Leave has been acknowledged.", "success");
            this.retrieveLeaveAcks();
          
        }
        else if (response.code == '400') {
            //message = response.
            this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
        }
        else if (response.code == "403") {
            this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
            localStorage.removeItem('loginDetails')
            localStorage.removeItem('menuData')
            this.$router.push('/');
        }
        },

        
    }
};
</script>