<template>
    <div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="user-profile">
                <div class="row">
                    <!-- user profile first-style start-->
                    <div class="col-sm-12">

                        <br /><br />

                        <div class="card hovercard text-center"><br>
                            <div>
                                <h5> Resume Transaction view</h5>
                            </div>

                            <div class="col">

                                <h6 class="mb-0"><b> {{ job_vacancy }} : Transaction Details ( {{ current_status }} )</b>
                                </h6> <br>

                                <div class="table-responsive datatable-vue">
                                    <b-table show-empty stacked="md" :items="items" :fields="tablefields">

                                        <template #cell(description)="row">
                                            <span>
                                                <textarea v-if="row.item.description" rows="1"
                                                    v-model="row.item.description" readonly></textarea>
                                            </span>
                                        </template>
                                        <template #cell(review)="row">
                                            <span>
                                                <textarea v-if="row.item.review" rows="1" v-model="row.item.review"
                                                    readonly></textarea>
                                            </span>
                                        </template>
                                        <template #cell(actions)="row">
                                            <span @click="editReview(row.item)">
                                                <feather type="edit" title="Edit Review"></feather>
                                            </span>
                                        </template>
                                    </b-table>
                                </div>
                                <b-modal id="bv-modal-document" centered size="md" hide-footer>
                                    <template #modal-title>
                                        Edit Review
                                    </template>
                                    <div class="modal-body">
                                        <b-form class="needs-validation" @submit="onReviewSubmit">
                                            <div class="form-row">
                                                <div class="col-3">
                                                    <label>Review</label>
                                                </div>

                                                <div class="col-9">

                                                    <textarea class="border-text" rows="4" v-model="reviewDet"></textarea>

                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <button v-if="reviewDet" class="btn btn-secondary" id="Bookmark"
                                                    type="submit">
                                                    Save
                                                </button>

                                                <button disabled class="btn btn-secondary" v-else>
                                                    Save
                                                </button>
                                            </div>
                                        </b-form>
                                    </div>
                                </b-modal>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">

                        <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                            <div class="form-row">
                                <div class="col-md-4 mb-3">
                                    <label for="c_form_departments">Change status</label>
                                    <v-select v-model="resume_status" label="name" :options="resume_statuses" />
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label for="description">Description</label>
                                    <textarea class="form-control" rows="1" placeholder="Enter Description"
                                        v-model="description">
                                        </textarea>
                                </div>
                                <div class="col-md-2 mb-3" v-if="resume_status.inter_status == 1">
                                    <label for="c_form_departments">Interview Date</label>
                                    <datepicker :format="customFormatter" :disabled-dates="disabledDates"
                                        input-class="datepicker-here form-control digits" v-model="interDate">
                                    </datepicker>
                                </div>

                                <div class="col-md-2 mb-3" v-if="showAssignUser">
                                    <label for="c_form_departments">Assign user</label>
                                    <v-select v-model="assign_user" label="name" :options="employees" />
                                </div>

                            </div>

                            <div class="form-row">

                                <div class="col-md-6 mb-3" v-if="showMailSubject">
                                    <label for="mail_subject">User Mail Subject</label>
                                    <b-form-input type="text" id="mail_subject" v-model="mail_subject"
                                        placeholder="Mail Subject"></b-form-input>
                                </div>
                                <div class="col-md-6 mb-3" v-if="showRecruiterMailSubject">
                                    <label for="recruiter_mail_subject">Recruiter Mail Subject</label>
                                    <b-form-input type="text" id="recruiter_mail_subject" v-model="recruiter_mail_subject"
                                        placeholder="Recruiter Mail Subject"></b-form-input>
                                </div>

                            </div>

                            <div class="form-row">

                                <div class="col-md-6 mb-4" v-if="showUserMailContent">
                                    <label for="c_form_assign_status"> User Mail content</label>
                                    <vue-editor v-model="mail_content"></vue-editor>

                                </div>
                                <div class="col-md-6 mb-4" v-if="showRecruiterMailContent">
                                    <label for="c_form_assign_status">Recruiter Mail content</label>
                                    <vue-editor v-model="recruiter_mail_content"></vue-editor>

                                </div>

                            </div>

                            <b-button type="submit" v-if="loader == false" variant="primary">Submit</b-button>

                            <div v-if="loader == true" class="loader-box">
                                <div class="loader-15"></div>
                            </div>

                        </b-form>

                    </div>
                    <div class="col-sm-6">

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ServiceApi from '@/services/ServiceApi.js';
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import { VueEditor } from 'vue2-editor';
import Swal from "sweetalert2";
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'

export default {

    components: {
        vSelect,
        VueEditor,
        Datepicker
    },

    data() {
        return {
            current_status: '',
            job_vacancy: '',
            assign_user: '',
            resume_status: '',
            mail_content: '',
            description: '',
            mail_subject: '',
            recruiter_mail_subject: '',
            filter: null,
            loader: false,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            items: [],
            interDate: '',
            reviewDet: null,
            trans_id: null,
            tablefields: [
                { key: 'resume_status', label: 'Status', sortable: true, },
                { key: 'assign_user', label: 'Assigned User', sortable: true, },
                { key: 'assign_review', label: 'Review', sortable: true, },
                { key: 'description', label: 'Description', sortable: true, },
                { key: 'interDate', label: 'Interview Date', sortable: true, },
                { key: 'date', label: 'Applied Date', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],
            employees: [],
            resume_statuses: []

        };
    },
    props: ['taskId', 'heading'],
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-warning",
                2: "light-info",
                3: "light-success",
                4: "light-info",
                5: "light-info",
            };

            return (status) => statusColor[status];
        },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        },
        showAssignUser() {
            return this.resume_status.assign_status === 1;
        },
        showMailSubject() {
            this.mail_subject = this.resume_status.mail_subject;
            return this.resume_status.mail_status === 1;
        },
        showRecruiterMailSubject() {
            this.recruiter_mail_subject = this.resume_status.recruiter_mail_subject;
            return this.resume_status.recruiter_mail_status === 1;
        },
        showUserMailContent() {
            this.mail_content = this.resume_status.mail_content;
            return this.resume_status.mail_status === 1;
        },
        showRecruiterMailContent() {
            this.recruiter_mail_content = this.resume_status.recruiter_mail_content;
            return this.resume_status.recruiter_mail_status === 1;
        },
        disabledDates() {
            return {
                to: new Date(), // disable all dates after today
            };
        },

    },

    mounted() {
        this.getTransactionsByAppliedVacancy();
        this.getEmployees();
        this.getResumeStatus();
    },
    methods: {
        editReview(item) {
            this.reviewDet = item.assign_review;
            this.trans_id = item.transaction_id;
            this.$bvModal.show('bv-modal-document');
        },
        onReviewSubmit(evt) {
            evt.preventDefault();
            if (this.reviewDet == '') {
                this.$toasted.show(' Please enter review', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                const rqData = {
                    id: this.trans_id,
                    review: this.reviewDet
                }
                this.updateReview(rqData);
            }
        },
        async updateReview(data){
            const response = await ServiceApi.UpdateReview(data);
            if (response.code == "200") {
                this.$bvModal.hide('bv-modal-document');
                this.$toasted.show(' Review Updated', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.getTransactionsByAppliedVacancy();
            }
        },
        customFormatter(date) {
            return moment(date).format('Y-MM-DD');
        },
        async getTransactionsByAppliedVacancy() {
            const ReqData = {
                id: this.$route.params.id,
            };
            const response = await ServiceApi.GetTransactionsByAppliedVacancy(ReqData);
            if (response.code == "200") {
                this.items = response.data.transaction_data;
                this.totalRows = response.data.length;
                this.current_status = response.data.current_status;
                this.current_status_id = response.data.current_status_id;
                this.current_priority = response.data.current_priority;
                this.job_vacancy = response.data.job_vacancy;
            }

        },
        async getEmployees() {
            const response = await Service.GetEmployees();
            if (response.code == '200') {

                this.employees = response.data;

            }
        },
        async getResumeStatus() {
            const response = await ServiceApi.GetResumeStatus();
            if (response.code == '200') {

                this.resume_statuses = response.data;

                // var resume_statuss = response.data;
                // this.resume_statuses = resume_statuss.filter(module => module.priority > this.current_priority);

            }
        },
        onCustomStyleSubmit(evt) {
            evt.preventDefault();


            if (this.resume_status == '') {
                this.success = false;
                this.$toasted.show(' Please change status', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.resume_status.inter_status == 1) {
                if (this.interDate == '' || this.interDate == null) {
                    this.success = false;
                    this.$toasted.show(' Please enter interview date', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                } else {
                    if (this.description == '') {
                        this.success = false;
                        this.$toasted.show(' Please enter description', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                    }
                    else {
                        this.success = true;
                        this.saveResumeTransaction();
                    }
                }

            }
            // else if(this.current_status_id == this.resume_status.status_id) {
            //     this.success = false;
            //     this.$toasted.show("Can't change into current status", { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });

            // }
            else if (this.description == '') {
                this.success = false;
                this.$toasted.show(' Please enter description', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else {
                this.success = true;
                this.saveResumeTransaction();
            }
        },

        saveResumeTransaction() {

            if (this.success == true) {


                Swal.fire({
                    title: "Are you sure?",
                    text: "Mail will be send to Applicants and Recruiters!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Sure!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const trans_data = {
                            applied_vacancy_id: this.$route.params.id,
                            resume_status_id: this.resume_status.status_id,
                            assign_user_id: this.assign_user.id,
                            description: this.description,
                            mail_content: this.mail_content,
                            recruiter_mail_content: this.recruiter_mail_content,
                            mail_subject: this.mail_subject,
                            recruiter_mail_subject: this.recruiter_mail_subject,
                            inter_date: this.interDate
                        }

                        this.addResumeTransaction(trans_data)
                        // if(done){
                        //     Swal.fire("Disabled!", "Company has been disabled.", "success")
                        //     this.retrieveCompanies();
                        // }
                    }
                });


                //this.addResumeTransaction(trans_data)
            }
        },
        async addResumeTransaction(trans_data) {
            this.loader = true;
            const response = await ServiceApi.AddResumeTransaction(trans_data);
            if (response.code == '200') {
                this.loader = false;
                this.$toasted.show(' Resume transaction added successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                //this.$router.push('/home/applied_vacancies/');
                window.location.reload()

            }
            else if (response.code == '400') {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },


    }
};

</script>
<style>
.loader-box {
    height: 50px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;
}

.p-dot {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

ul {
    padding-left: 0px;
    list-style-type: unset;
    margin-bottom: 0;
}

.border-text {
    border-style: dotted !important;
}
</style>