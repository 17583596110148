<template>
    <div>
        <Breadcrumbs main="Leave types" title="Leave Type List" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">


                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">
                            <b-row>
                                <b-col xl="12">
                                    <router-link :to="'/home/leavetype-add/'">
                                        <b-button id="default-primary" variant="primary" >
                                            Add Leave Type
                                        </b-button>
                                    </router-link>
                                    <!-- <b-tooltip target="default-primary" triggers="hover" placement="top">btn btn-primary</b-tooltip> -->
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">

                                    <template #cell(key)="rows">
                                            <span v-if="rows.item.key == 1">
                                                Paid
                                            </span>
                                            <span v-else>
                                                Un-Paid
                                            </span>
                                    </template>

                                    <template #cell(validation_status)="rows">
                                            <span v-if="rows.item.validation_status == 1">
                                                Yes
                                            </span>
                                            <span v-else>
                                                No
                                            </span>
                                    </template>

                                    <template #cell(status)="rows">
                                        <b-badge :variant="statusVariant(rows.item.status)">
                                           
                                            <span v-if="rows.item.status == 1">
                                                Active
                                            </span>
                                            <span v-else>
                                                InActive
                                            </span>
                                        </b-badge>
                                    </template>
                                    <template #cell(actions)="row">
                                        <span>
                                            <router-link :to="'/home/leavetype-edit/'+row.item.id">
                                                <feather  type="edit"></feather>
                                                    </router-link>
                                        </span>
                                        
                                        <span v-if="row.item.status == 1">
                                            <feather type="thumbs-down" @click="deleteLeaveType(row.item)"></feather>
                                        </span>
                                        <span v-else>
                                            <feather type="thumbs-up" @click="deleteLeaveType(row.item)"></feather>
                                        </span>
                                        <span>
                                           
                                            <feather type="eye" 
                                            @click="$bvModal.show('bv-modal-example')"
                                            v-on:click="getLeaveTypeConfiguration(row.item.id)"
                                            title="View Config"
                                            ></feather>
                                           
                                        </span>

                                    </template>

                                 

                                </b-table>

                                <b-modal
                                    id="bv-modal-example"
                                    centered
                                    size="lg"
                                    hide-footer
                                >
                                    <template #modal-title>
                                    Leave Type Configurations
                                    </template>
                                    <div class="modal-body"
                                    v-if="items_configs !=''">
                                    <b-form
                                        class="needs-validation"
                                       
                                    >
                                        <div
                                        class="form-row"
                                       
                                        v-for="(item, index) in items_configs"
                                        :key="index"
                                        >
                                        <div class="col-md-3 mb-3">
                                            <label for="c_form_name">{{
                                            item.config_name
                                            }}</label>
                                        </div>

                                      

                                        <div class="col-md-5 mb-3">
                                            <textarea class="form-control" rows="1"
                                               v-model="item.description"
                                            readonly>
                                            </textarea>
                                        </div>

                                        <div class="col-md-2 mb-3">
                                            <b-form-input
                                            type="text"
                                            v-model.lazy="item.config_val"
                                            readonly
                                            >
                                            </b-form-input>
                                        </div>

                                        <div class="col-md-2 mb-3">
                                            <b-form-input
                                            type="text"
                                            v-model.lazy="item.check_status"
                                            readonly
                                            >
                                            </b-form-input>
                                        </div>

                                        </div>

                                    </b-form>
                                    </div>

                                    <div class="modal-body" v-else>
                                        <h6>No Leave Type Configurations !</h6>
                                    </div>
                                </b-modal>

                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>


                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import ServiceApi from '@/services/ServiceApi.js';
import Swal from "sweetalert2";

export default {
    data() {
        return {
            tablefields: [
                { key: 'leave_type_name', label: 'Leave Type', sortable: true, },
                { key: 'count', label: 'Count', sortable: true, },
                { key: 'key', label: 'Key', sortable: true, },
                { key: 'key', label: 'Key', sortable: true, },
                { key: 'validation_status', label: 'Validation Status', sortable: true, },
                { key: 'status', label: ' Status', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],

            items: [],
            items_configs: [],
            filter: null,
            config_name: null,
            description: null,
            config_val: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
        };
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-success",
                0: "light-danger",
            };

            return (status) => statusColor[status];
        },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        }
    },
    mounted() {
        // Set the initial number of items
        this.retrieveLeaveTypes();
    },
    methods: {
       
        async retrieveLeaveTypes() {
            const response = await ServiceApi.GetLeaveTypes();
            if (response.code == "200") {
                this.items = response.data;
                this.totalRows = response.data.length;
            }
        },
        async getLeaveTypeConfiguration(configid) {
          
            
            const ReqData = {
                id: configid,
            };

            this.items_configs =[];
            const response = await ServiceApi.GetLeaveTypeConfiguration(ReqData);

            if (response.code == "200") {
                this.items_configs = response.data;

                this.totalRows = response.data.length;
            }
           
            },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        deleteLeaveType(data) {
            if (data.status == 1) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to disable this Leave Type!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, disable!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const credentials = {
                            status:data.status,
                            id: data.id,
                        };
                        var done = this.enableOrDisableLeaveType(credentials);
                        if(done){
                            Swal.fire("Disabled!", "Leave Type has been disabled.", "success")
                            // window.location.reload()
                            this.retrieveLeaveTypes();
                        }
                    }
                });
            } else if (data.status == 0) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to enable this Leave Type!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, enable!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const credentials = {
                            status:data.status,
                            id: data.id,
                        };
                        var done = this.enableOrDisableLeaveType(credentials);
                        if(done){
                            Swal.fire("Disabled!", "Leave Type has been enabled.", "success")
                            // window.location.reload()
                            this.retrieveLeaveTypes();
                        }
                    }
                });
            }
        },
        async enableOrDisableLeaveType(credentials){
            const response = await ServiceApi.EnableOrDisableLeaveType(credentials);
            if (response.code == "200") {
                return true;
            }else{
                return false;
            }
        }
        
    }
};
</script>