<template>
    <div>
        <Breadcrumbs main="Home" title="Edit Company Admin" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="name">Company Admin Name</label>
                                        <b-form-input type="text" id="name" v-model="c_form.name"
                                            :state="c_form_result.name" placeholder="Comapany admn name"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.first_name">
                                            </b-form-valid-feedback>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="name">Company</label>
                                    <v-select
                                            v-model="c_form.CompanyValue"
                                            label="name"
                                            :state="c_form_result.CompanyValue"
                                            :options="Companies"
                                            :required="!c_form.CompanyValue"
                                           
                                        />
                                        <b-form-valid-feedback :state="c_form_result.CompanyValue">
                                            </b-form-valid-feedback>
                                </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="email">Email</label>
                                        <b-form-input type="email" id="email" v-model="c_form.email"
                                            :state="c_form_result.email" placeholder="Email"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.email">
                                            </b-form-valid-feedback>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="address">Phone</label>
                                        <!-- <b-form-input type="text" id="phone" v-model="c_form.phone"
                                            :state="c_form_result.phone" placeholder="Phone"></b-form-input> -->
                                            <VuePhoneNumberInput v-model="c_form.phone" default-country-code="IN" @update="onUpdatePhoneNumber" />
                                        <b-form-valid-feedback :state="c_form_result.phone">
                                            </b-form-valid-feedback>
                                    </div>
                                </div>
                                <!-- <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                <div class="checkbox-animated">
												<div class="learning-header"><span class="f-w-600">Permissions</span></div>
												<label class="d-block" for="chk-ani">
													<input class="checkbox_animated" id="chk-ani" type="checkbox">Company
												</label>
                                                <label class="d-block" for="chk-ani">
													<input class="checkbox_animated" id="chk-ani" type="checkbox">Company
												</label><label class="d-block" for="chk-ani">
													<input class="checkbox_animated" id="chk-ani" type="checkbox">Company
												</label><label class="d-block" for="chk-ani">
													<input class="checkbox_animated" id="chk-ani" type="checkbox">Company
												</label><label class="d-block" for="chk-ani">
													<input class="checkbox_animated" id="chk-ani" type="checkbox">Company
												</label><label class="d-block" for="chk-ani">
													<input class="checkbox_animated" id="chk-ani" type="checkbox">Company
												</label><label class="d-block" for="chk-ani">
													<input class="checkbox_animated" id="chk-ani" type="checkbox">Company
												</label><label class="d-block" for="chk-ani">
													<input class="checkbox_animated" id="chk-ani" type="checkbox">Company
												</label>
												<label class="d-block" for="chk-ani0">
													<input class="checkbox_animated" id="chk-ani0" type="checkbox">Company
												</label>
												<label class="d-block" for="chk-ani1">
													<input class="checkbox_animated" id="chk-ani1" type="checkbox">Company
												</label>
												<label class="d-block" for="chk-ani2">
													<input class="checkbox_animated" id="chk-ani2" type="checkbox">Company
												</label>
											</div>
											</div>
											</div> -->
                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                    
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
    components: {
    vSelect,
    VuePhoneNumberInput
    },
    data() {
        return {
            success: false,
            email: '',
            c_form: {
                name: '',
                CompanyValue: '',
                email: '',
                phone: '',
            },
            c_form_result: {
                name: null,
                CompanyValue: null,
                email: null,
                phone: null,
            },
            Companies:[],
            PhoneNumberValue:null,

        };
    },
    mounted() {
        // Set the initial number of items
        this.GetCompanies();
       this.GetCompaniesAdminById();
        
    },
    methods: {
        onCustomStyleSubmit(evt) {
            evt.preventDefault();
            if (this.c_form.name !== '') {
                this.c_form_result.name = true;
                this.success = true;
            } else {
                this.success = false;
                this.c_form_result.name = false;
            }
            if (this.c_form.email !== '') {
                this.success = true;
                this.c_form_result.email = true;
            } else {
                this.success = false;
                this.c_form_result.email = false;
            }
            this.saveCompanyAdmin();
        },
        onUpdatePhoneNumber (payload) {
      this.PhoneNumberValue = payload.formattedNumber;
      },
        saveCompanyAdmin() {

            if (this.success == true) {
                const comp_data = {
                    id: this.$route.params.id,
                    name: this.c_form.name,
                    company: this.c_form.CompanyValue.company_id,
                    phone: this.PhoneNumberValue,
                    email: this.c_form.email,
                }
                if (this.c_form.CompanyValue == null) {
                    this.$toasted.show('Company is mandatory field ', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
                }else if (this.PhoneNumberValue == null) {
                    this.$toasted.show(' Phone is mandatory field ', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
                }else{
                    this.editCompanyAdmin(comp_data)
                }
            }
        },
        async GetCompanies() {
          const response = await Service.GetCompanies();
            if (response.code =='200') {
            //   console.log(response.data);
                this.Companies =  response.data;
                
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async GetCompaniesAdminById() {
            const ReqData = {
                 id: this.$route.params.id,
            };
          const response = await Service.GetCompaniesAdminById(ReqData);
            if (response.code =='200') {
      
                this.c_form.name = response.data.name
                this.c_form.CompanyValue = { company_id: response.data.company_id, name:response.data.company}
                this.PhoneNumberValue = response.data.phone
                this.c_form.phone = response.data.phone
                this.c_form.email = response.data.email
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async editCompanyAdmin(comp_data) {
            const response = await Service.EditCompanyAdminData(comp_data);
            if (response.code == '200') {
                this.$toasted.show(' Company admin modified successfully ', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
             
                this.$router.push('/home/company-admin');
            } else if(response.code =='400'){
                this.$toasted.show(response.message, { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        }
    }
};
</script>
<style lang="scss">
@import "vue-select/dist/vue-select.css";
</style>