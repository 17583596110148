<template>
    <div>
        <Breadcrumbs main="Clients" title="Add Clients" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">

                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="name">Client Name</label>
                                        <b-form-input type="text" id="name" v-model="c_form.name"
                                            :state="c_form_result.name" placeholder="Name"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.name">
                                        </b-form-valid-feedback>
                                    </div>

                                    <!-- <div class="col-md-6 mb-3">
                                        <label for="priority">Address</label>
                                        <b-form-input type="text" id="address" v-model="c_form.address"
                                            :state="c_form_result.address" placeholder="Address"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.address">
                                        </b-form-valid-feedback>
                                    </div> -->
                                   
                                    <div class="col-md-6 mb-3">
                                        <label for="email">Email</label>
                                        <b-form-input type="email" id="email" v-model="c_form.email"
                                            :state="c_form_result.email" placeholder="Email"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.address">
                                        </b-form-valid-feedback>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="phone">Phone</label>
                                        <VuePhoneNumberInput v-model="c_form.phone" default-country-code="IN" />
                                        <!-- <b-form-input type="text" id="phone" v-model="c_form.phone"
                                            :state="c_form_result.phone" placeholder="Phone"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.phone">
                                        </b-form-valid-feedback> -->
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="address">Address</label>
                                        <textarea class="form-control" rows="1" placeholder="Address"
                                            v-model="c_form.address">
                                        </textarea>
                                        <!-- <b-form-valid-feedback :state="c_form.description">
                                            </b-form-valid-feedback> -->
                                    </div>
                          

                                    <div class="col-md-4 mb-3">
                                    </div>
                                
                                    <div class="col-md-4 mb-3">
                                    </div>
                                </div>

                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from '@/services/ServiceApi.js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import vSelect from 'vue-select'
import { VueEditor } from 'vue2-editor';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'

export default {

    components: {
        VuePhoneNumberInput,
        vSelect,
        VueEditor,
        Datepicker,
    },

    data() {
        return {
            success: false,

            c_form: {
                name: '',
                address: '',
                email: '',
                phone: ''
            },
            c_form_result: {
                name: null,
                address: null,
                email: null,
                phone: null
            },
            types: [

                { value: "1", text: "YES" },
                { value: "2", text: "NO" },
            ],

        };
    },
    computed: {

    },
    mounted() {

    },
    methods: {

        onCustomStyleSubmit(evt) {
            evt.preventDefault();
            if (this.c_form.name == '') {
                this.success = false;
                this.$toasted.show(' Please enter client name', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.address == '') {
                this.success = false;
                this.$toasted.show(' Please enter address', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.email == '') {
                this.success = false;
                this.$toasted.show(' Please enter email', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.phone == '') {
                this.success = false;
                this.$toasted.show(' Please enter phone', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else {
                this.success = true;
            }
            this.saveClients();
        },
        // onUpdatePhoneNumber(payload) {
        //     this.PhoneNumberValue = payload.formattedNumber;
        // },
        saveClients() {

            if (this.success == true) {

                const status_data = {
                    name: this.c_form.name,
                    address: this.c_form.address,
                    email: this.c_form.email,
                    phone: this.c_form.phone,
                }

                this.addClients(status_data)
            }
        },
        async addClients(status_data) {
            const response = await ServiceApi.AddClients(status_data);
            if (response.code == '200') {
                this.$toasted.show(' Client added successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/clients');
            }
            else if (response.code == '400') {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },

    }
};
</script>
