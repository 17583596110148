<template>
    <div><br>
        <Breadcrumbs main="Onsite Requests" title="Onsite Request List" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">


                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">

                            <b-row>
                                <b-col xl="12">
                                    <router-link :to="'/home/onsite-add'">
                                        <b-button v-if="datalayout.designation.level.key == 'HR'" id="default-primary"
                                            variant="primary">Apply Onsite Request
                                        </b-button>
                                    </router-link>
                                </b-col>
                            </b-row>
                            <br />

                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(start_date)="rows">
                                        <span>
                                            {{ rows.item.start_date.split("T")[0] }}
                                        </span>
                                    </template>
                                    <template #cell(end_date)="rows">
                                        <span>
                                            {{ rows.item.end_date.split("T")[0] }}
                                        </span>
                                    </template>
                                    <template #cell(no_days)="rows">
                                        <span>
                                            {{ formateTime(rows.item.no_days) }}
                                        </span>
                                    </template>
                                    <template #cell(status)="rows">
                                        <b-badge :variant="statusVariant(rows.item.status)">

                                            <span v-if="rows.item.status == 1">
                                                Pending
                                            </span>
                                            <span v-else-if="rows.item.status == 2">
                                                Acknowledged
                                            </span>
                                            <span v-else-if="rows.item.status == 3">
                                                Approved
                                            </span>
                                            <span v-else-if="rows.item.status == 4">
                                                Canceled
                                            </span>
                                            <span v-else-if="rows.item.status == 5">
                                                Rejected
                                            </span>
                                        </b-badge>
                                    </template>
                                    <template #cell(actions)="row">

                                        <span>
                                            <router-link :to="'/home/onsite-view/' + row.item.id">
                                                <feather type="eye"></feather>
                                            </router-link>
                                        </span>

                                    </template>



                                </b-table>

                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>


                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from "@/services/ServiceApi.js";

export default {
    data() {
        return {
            tablefields: [
                { key: 'emp_name', label: 'Employee', sortable: true, },
                { key: 'reason', label: 'Reason', sortable: true, },
                { key: 'start_date', label: 'From', sortable: true, },
                { key: 'end_date', label: 'To', sortable: true, },
                { key: 'no_days', label: 'Duration', sortable: true, },
                { key: "project", label: "Project", sortable: true },
                { key: 'status', label: 'Status', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],
            datalayout: JSON.parse(localStorage.getItem('loginDetails')),
            items: [],
            file: null,
            file_name: null,
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
        };
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-warning",
                2: "light-info",
                3: "light-success",
                4: "light-danger",
                5: "light-danger",
            };

            return (status) => statusColor[status];
        },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        }
    },
    mounted() {
        // Set the initial number of items
        this.retrieveOnsiteList();

    },
    methods: {
        formateTime(time) {
            var hours = Math.floor(time / 3600);
            var minutes = Math.floor((time - (hours * 3600)) / 60);
            var remainingSeconds = time - (hours * 3600) - (minutes * 60);

            if (hours < 10) {
                hours = "0" + hours;
            }
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            if (remainingSeconds < 10) {
                remainingSeconds = "0" + remainingSeconds;
            }

            return hours + ":" + minutes + ":" + remainingSeconds;
        },

        async retrieveOnsiteList() {
            const response = await ServiceApi.GetOnsiteList();
            if (response.code == "200") {
                response.data.forEach(element => {
                    if (element.type == 1){
                        this.items.push(element);
                    }
                });
                // this.items = response.data;
                this.totalRows = this.items.length;
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },


    }
};
</script>