<template>
    <li class="onhover-dropdown">
        <div class="notification-box">
            <feather type="mail" @click="notification_open()"></feather><span class="badge badge-pill badge-secondary">{{
                msg.length }}
            </span>
        </div>
        <div class="onhover-show-div notification-dropdown" :class="{ active: notification }">
            <h6 class="f-18 mb-0 dropdown-title">Messages</h6>
            <ul >
                <router-link :to="'/home/employee-view/' + emp_id + '/3'">
                <li class="b-l-primary border-4" v-for="(myMsg, index) in msg">
                    <p>{{ myMsg.intimation.subject }} <span class="font-danger">{{
                        myMsg.chat ? myMsg.chat.length :
                        ''
                    }}</span></p>
                </li>

                </router-link>
            </ul>
        </div>
    </li>
</template>

<script>
import Service from '@/services/Service.js';
import { useSound } from '@vueuse/sound'
import buttonSfx from '../../assets/audio/message.mp3'

export default {
    setup() {
        const { play } = useSound(buttonSfx)

        return {
            play,
        }
    },
    name: 'Notifications',
    data() {
        return {
            notification: false,
            msg: [],
            msgCount: 0,
            emp_id: null,
            msgValCount: 0,
        };
    },
    created() {
        this.getFewMsg();
        // setInterval(() => {
        //     this.getFewMsg();
        // }, 15000);
    },
    methods: {
        notification_open() {
            this.notification = !this.notification;
        },
        async getFewMsg() {
            const response = await Service.GetMessages();
            if (response.code == "200") {
                this.msg = response.data;
                if (response.data.length > 0) {
                    var len = this.msg.length;
                    if(this.msgValCount < len){
                        this.play();
                    }
                    this.msgValCount = len;
                    this.emp_id = response.data[0].curr_user;
                }
               
            }
        },
       
    },
};
</script>
