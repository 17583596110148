<template>
    <div><br>
        <Breadcrumbs main="Project" :title="proj_name + ' / Edit Phase'" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="title">Project Title</label>
                                        <b-form-input type="text" id="title" v-model="title" placeholder="Project Title">
                                        </b-form-input>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="employees">Module Manager</label>
                                        <v-select v-model="selected_employees" label="name" placeholder="Module Manager"
                                            :options="employees" :multiple="true" />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="description">Project Description</label>
                                        <b-form-textarea type="text" id="description" v-model="description"
                                            placeholder="Project Description">
                                        </b-form-textarea>
                                    </div>
                                   
                                </div>
                                
                                <div class="form-row">
                                    <div class="col-md-10 mb-3">
                                        
                                    </div>
                                    <div class="col-md-2 mb-3" v-if="loader">
                                        <b-button type="submit" variant="primary">Update</b-button>
                                    </div>
                                    <div class="col-md-2 mb-3" v-else>
                                        <div class="loader-box">
                                            <div class="loader-15"></div>
                                        </div>
                                    </div>
                                </div>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import moment from "moment";
import { VueEditor } from 'vue2-editor';
export default {

    components: {
        vSelect,
        VueEditor
    },

    data() {
        return {
            loader: true,
            title: '',
            description: '',
            project_id: null,
            phaseStatus: [],
            phases: [
                {
                    value: 1, text: 'Ongoing'
                },
                {
                    value: 2, text: 'Completed'
                },
                {
                    value: 3, text: 'Hold'
                },
            ],
            employees: [],
            selected_employees: [],
            selected_emp_old: [],
            proj_name: null
        };
    },
    // props: ['phaseId', 'projectPhaseId'],
    computed: {

    },
    mounted() {
        this.getPhaseById();
        this.getEmployees();
        //    this.getPhaseStatus();
    },
    methods: {
        async getEmployees() {
            const response = await Service.GetEmployees();
            if (response.code == '200') {
                this.employees = response.data;
                // console.log(this.employees);
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        onCustomStyleSubmit(evt) {
            this.loader = false;
            evt.preventDefault();
            if (this.title == '' || this.description == null) {
                // this.success = false;
                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                this.loader = true;
            } else {
                // this.success = true;
                this.savePhaseEdit();
            }

        },
        savePhaseEdit() {
            const areArraysEqual = JSON.stringify(this.selected_emp_old) === JSON.stringify(this.selected_employees);
            // console.log(this.selected_emp_old, this.selected_employees);
            const update_data = {
                project_id: this.$route.params.id,
                title: this.title,
                description: this.description,
                module_managers: areArraysEqual ? null : this.selected_employees,
                old_list: this.selected_emp_old,
                new_list: this.selected_employees
            };

            // console.log(update_data);
            this.updatePhase(update_data)
        },
        async updatePhase(update_data) {
            const response = await Service.UpdatePhase(update_data);
            if (response.code == '200') {
                this.$toasted.show(' Project Phase updated successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.loader = true;
                this.$router.push('/home/manage-phase/' + response.data);
            } else if (response.code == "400") {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                this.loader = true;
            }
        },

        async getPhaseById() {
            const reqDataPhase = {
                id: this.$route.params.id,
            }
            const response = await Service.GetProjectPhaseById(reqDataPhase);
            if (response.code == '200') {
                // console.log(response.data);
                this.title = response.data.name;
                this.description = response.data.desc;
                this.selected_employees = response.data.users;
                this.selected_emp_old = response.data.users;
                this.proj_name = response.data.proj_name;
                // this.phaseStatus = response.data.status;
                // this.phases.forEach(element => {
                //     // console.log(element);                    
                //     if(element.value == response.data.status){
                //         this.phaseStatus = element;
                //     }
                // });
            }
        },

    }
}
//     }
// };
</script>
<style>
.ck-content {
    height: 150px;
}
</style>