import axios from 'axios';

const url = 'https://qcmapi.qaptive.in/api/'; //live
// const url = 'https://staging.qcmapi.qaptive.in/api/'; //test
// const url = 'http://127.0.0.1:8000/api/'; //local 
// const url = 'http://192.168.1.91:8000/api/'; //local 



var datas = '';
var params = '';

if (localStorage.getItem("loginDetails") != null) {
  datas = JSON.parse(localStorage.getItem('loginDetails'));
}
let config = {
  headers: {
    'Authorization': datas.auth_token
  }
}

export default {
  login(credentials) {
    return axios
      .post(url + 'v1/login', credentials)
      .then(response => response.data);
  },
  loginOtpVerify(credentials) {
    return axios.post(url + 'v1/login-verify-otp', credentials).then(response => response.data);
  },
  logoutApi() {
    return axios.post(url + 'v1/logout', null, config).then(response => response.data);
  },
  AdminUsers() {
    return axios.get(url + 'GetUser/', config).then(response => response.data);
  },
  GetCompanies() {
    return axios.get(url + 'v1/company', config).then(response => response.data);
  },
  AddCompanyData(params) {
    return axios.post(url + 'v1/company', params, config).then(response => response.data);
  },
  GetCompanyById(params) {
    return axios.post(url + 'v1/companyById', params, config).then(response => response.data);
  },
  UpdateCompanyData(params) {
    return axios.put(url + 'v1/company', params, config).then(response => response.data);
  },
  EnableOrDisableComp(params) {
    return axios.post(url + 'v1/companyDelete', params, config).then(response => response.data);
  },
  GetDesignations() {
    return axios.get(url + 'v1/designation', config).then(response => response.data);
  },
  AddDesignation(params) {
    return axios.post(url + 'v1/designation', params, config).then(response => response.data);
  },
  EnableOrDisableDesign(params) {
    return axios.post(url + 'v1/designationDelete', params, config).then(response => response.data);
  },
  GetDesignationById(params) {
    return axios.post(url + 'v1/designationById', params, config).then(response => response.data);
  },
  UpdateDesignation(params) {
    return axios.put(url + 'v1/designation', params, config).then(response => response.data);
  },
  GetDesignationsByDepId(params) {
    return axios.post(url + 'v1/designationByDep', params, config).then(response => response.data);
  },
  GetCompaniesAdmin() {
    return axios.get(url + 'v1/company-admin', config).then(response => response.data);
  },
  AddCompanyAdminData(params) {
    return axios.post(url + 'v1/company-admin', params, config).then(response => response.data);
  },
  enableOrDisableCompanyAdmin(params) {
    return axios.post(url + 'v1/company-admin-delete', params, config).then(response => response.data);
  },
  GetCompaniesAdminById(params) {
    return axios.post(url + 'v1/company-admin-by-id', params, config).then(response => response.data);
  },
  EditCompanyAdminData(params) {
    return axios.put(url + 'v1/company-admin', params, config).then(response => response.data);
  },
  GetEmployees() {
    return axios.get(url + 'v1/users', config).then(response => response.data);
  },
  EnableOrDisableUser(params) {
    return axios.post(url + 'v1/usersDelete', params, config).then(response => response.data);
  },
  AddEmployee(params) {
    return axios.post(url + 'v1/users', params, config).then(response => response.data);
  },
  GetEmpDetailsById(params) {
    return axios.post(url + 'v1/userByIdForEdit', params, config).then(response => response.data);
  },
  EditEmployee(params) {
    return axios.put(url + 'v1/users', params, config).then(response => response.data);
  },
  GetLeaveTypes(params) {
    return axios.post(url + 'v1/leave-type', params, config).then(response => response.data);
  },
  AddLeave(params) {
    return axios.post(url + 'v1/leave', params, config).then(response => response.data);
  },
  GetLeaveList() {
    return axios.get(url + 'v1/leave', config).then(response => response.data);
  },
  GetLeaveById(params) {
    return axios.post(url + 'v1/leaveById', params, config).then(response => response.data);
  },
  ChangeEmpLeaveStatus(params) {
    return axios.put(url + 'v1/leave', params, config).then(response => response.data);
  },
  GetMembers() {
    return axios.get(url + 'v1/get-employees', config).then(response => response.data);
  },
  AddProjects(params) {
    return axios.post(url + 'v1/project', params, config).then(response => response.data);
  },
  getAwsS3Configs() {
    return axios.get(url + 'v1/getAwsS3Configs', config).then(response => response.data);
  },
  getProjects() {
    return axios.get(url + 'v1/project', config).then(response => response.data);
  },
  GetLeaveEmployees() {
    return axios.get(url + 'v1/get-leave-employees', config).then(response => response.data);
  },
  GetDesignationLevels() {
    return axios.get(url + 'v1/get-designation-level', config).then(response => response.data);
  },
  getDesignationByEmp(params) {
    return axios.post(url + 'v1/get-designation-by-emp', params, config).then(response => response.data);
  },
  getMenuWithModules() {
    return axios.get(url + 'v1/getMenuWithModules', config).then(response => response.data);
  },
  GetProjectDetails(params) {
    return axios.post(url + 'v1/projectDetails', params, config).then(response => response.data);
  },
  GetTaskEmp(params) {
    return axios.post(url + 'v1/projectView', params, config).then(response => response.data);
  },
  GetProjectForEdit(params) {
    return axios.post(url + 'v1/projectViewForEdit', params, config).then(response => response.data);
  },
  UpdateProject(params) {
    return axios.put(url + 'v1/project', params, config).then(response => response.data);
  },
  GetProjectDetailsForTask(params) {
    return axios.post(url + 'v1/projectViewForTask', params, config).then(response => response.data);
  },
  GetTasks(params) {
    return axios.post(url + 'v1/tasksByProjectId', params, config).then(response => response.data);
  },
  GetTaskType() {
    return axios.get(url + 'v1/task-type', config).then(response => response.data);
  },
  AddTask(params) {
    return axios.post(url + 'v1/tasks', params, config).then(response => response.data);
  },
  GetEmpTaskById(params) {
    return axios.post(url + 'v1/taskById', params, config).then(response => response.data);
  },
  UpdateTask(params) {
    return axios.put(url + 'v1/tasks', params, config).then(response => response.data);
  },
  EnableOrDisableTask(params) {
    return axios.post(url + 'v1/taskDelete', params, config).then(response => response.data);
  },
  AddTaskHistory(params) {
    return axios.post(url + 'v1/taskHistory', params, config).then(response => response.data);
  },
  GetUserAssignTask(params) {
    return axios.post(url + 'v1/userAssignTask', params, config).then(response => response.data);
  },
  UpdateUserInTaskHistory(params) {
    return axios.put(url + 'v1/taskHistory', params, config).then(response => response.data);
  },
  UpdateStatusInTask(params) {
    return axios.post(url + 'v1/taskStatusUpdate', params, config).then(response => response.data);
  },
  getTaskHistoryDetails(params) {
    return axios.post(url + 'v1/taskHistoryDetails', params, config).then(response => response.data);
  },
  EndProject(params) {
    return axios.post(url + 'v1/projectEnd', params, config).then(response => response.data);
  },
  EnableOrDisableTaskType(params) {
    return axios.post(url + 'v1/taskTypeDelete', params, config).then(response => response.data);
  },
  AddTaskType(params) {
    return axios.post(url + 'v1/addTaskType', params, config).then(response => response.data);
  },
  GetTaskTypeById(params) {
    return axios.post(url + 'v1/taskTypeById', params, config).then(response => response.data);
  },
  UpdateTaskType(params) {
    return axios.put(url + 'v1/updateTaskType', params, config).then(response => response.data);
  },
  PunchingDetailsStatusUpdateApi(data) {
    return axios.post(url + 'v1/punching-details-status-update', data, config).then(response => response.data);
  },
  getConfigApi() {
    return axios.get(url + 'v1/get-config', config).then(response => response.data);
  },
  checkPunchingLogoutStatusApi() {
    return axios.get(url + 'v1/check-punching-logout-status', config).then(response => response.data);
  },
  checkPunchingLoginStatusApi() {
    return axios.get(url + 'v1/check-punching-login-status', config).then(response => response.data);
  },
  lastPunchingSixDayDetailsApi() {
    return axios.get(url + 'v1/last-punching-six-day-details', config).then(response => response.data);
  },
  GetWageDetails(params) {
    return axios.post(url + 'v1/projectWage', params, config).then(response => response.data);
  },
  AddMessage(params) {
    return axios.post(url + 'v1/message', params, config).then(response => response.data);
  },
  GetIntimations() {
    return axios.get(url + 'v1/message', config).then(response => response.data);
  },
  RecentActivityApi() {
    return axios.get(url + 'v1/recent-activity', config).then(response => response.data);
  },
  PunchingWorkingDayHistoryDetailsApi() {
    return axios.get(url + 'v1/punching-working-day-history-details-api', config).then(response => response.data);
  },
  GetEmployeeMsg() {
    return axios.get(url + 'v1/getEmp', config).then(response => response.data);
  },
  GetMsgByLimit(params) {
    return axios.post(url + 'v1/messageByLimit', params, config).then(response => response.data);
  },
  GetIntimationsById(params) {
    return axios.post(url + 'v1/intimationById', params, config).then(response => response.data);
  },
  sendMessage(params) {
    return axios.post(url + 'v1/sendMessage', params, config).then(response => response.data);
  },
  GetMessages() {
    return axios.get(url + 'v1/messageByLimit', config).then(response => response.data);
  },
  GetReports(params) {
    return axios.post(url + 'v1/report', params, config).then(response => response.data);
  },
  GetActivity(params) {
    return axios.post(url + 'v1/single-report', params, config).then(response => response.data);
  },
  getAllProjects() {
    return axios.get(url + 'v1/allProjects', config).then(response => response.data);
  },
  getTodayliveTimeApi() {
    return axios.get(url + 'v1/punching-live-time', config).then(response => response.data);
  },
  AddExtraMem(params) {
    return axios.put(url + 'v1/message', params, config).then(response => response.data);
  },
  getLateComeingApi(params) {
    return axios.post(url + 'v1/get-late-coming', params, config).then(response => response.data);
  },
  getLateComersApi(params) {
    return axios.post(url + 'v1/get-late-comers', params, config).then(response => response.data);
  },
  GetLeaveReport(params) {
    return axios.post(url + 'v1/getLeaveReports', params, config).then(response => response.data);
  },
  GetEmployeesForReport() {
    return axios.get(url + 'v1/users-for-report', config).then(response => response.data);
  },
  GetAttendanceReport(params) {
    return axios.post(url + 'v1/getAttendanceReports', params, config).then(response => response.data);
  },
  DownloadReport(params) {
    return axios.post(url + 'v1/download-Reports', params, config).then(response => response.data);
  },
  getEmplyeeProjects(params) {
    return axios.post(url + 'v1/getProjectsByEmployee', params, config).then(response => response.data);
  },
  getTasksByProjects(params) {
    return axios.post(url + 'v1/getTasksByProject', params, config).then(response => response.data);
  },
  GetAppliedVacanciesList() {
    return axios.get(url + 'v1/resume-history', config).then(response => response.data);
  },
  GetAppliedUsersList() {
    return axios.get(url + 'v1/resume-user-history', config).then(response => response.data);
  },
  GetResumesViewById(params) {
    return axios.post(url + 'v1/resume-view', params, config).then(response => response.data);
  },
  DownloadAttendanceReport(params) {
    return axios.post(url + 'v1/download-attendance', params, config).then(response => response.data);
  },
  RetrievePayrollComponents() {
    return axios.get(url + 'v1/getPayrollComponents', config).then(response => response.data);
  },
  AddPayrollComponent(params) {
    return axios.post(url + 'v1/addPayrollComponent', params, config).then(response => response.data);
  },
  getPayrollComponentsById(params) {
    return axios.post(url + 'v1/getPayrollComponentById', params, config).then(response => response.data);
  },
  UpdatePayrollComponent(params) {
    return axios.put(url + 'v1/updatePayrollComponent', params, config).then(response => response.data);
  },
  EnableOrDisableComponent(params) {
    return axios.post(url + 'v1/EnableOrDisablePayrol', params, config).then(response => response.data);
  },
  GetPayCompByType(params) {
    return axios.post(url + 'v1/GetPayCompByType', params, config).then(response => response.data);
  },
  AddEmployeeComponents(params) {
    return axios.post(url + 'v1/AddEmployeeComponents', params, config).then(response => response.data);
  },
  GetUserComponentDetails(params) {
    return axios.post(url + 'v1/GetUserComponentDetails', params, config).then(response => response.data);
  },
  ApproveMonthlyAttendance(params) {
    return axios.post(url + 'v1/ApproveMonthlyAttendance', params, config).then(response => response.data);
  },
  GetSalaryReports(params) {
    return axios.post(url + 'v1/GetSalaryReports', params, config).then(response => response.data);
  },
  DownloadSalaryReport(params) {
    return axios.post(url + 'v1/DownloadSalaryReport', params, config).then(response => response.data);
  },
  ApprovePayroll(params) {
    return axios.post(url + 'v1/ApprovePayroll', params, config).then(response => response.data);
  },
  GetPayslipByUser(params) {
    return axios.post(url + 'v1/GetPayslipByUser', params, config).then(response => response.data);
  },
  GeneratePayslipPDFbyUser(params) {
    return axios.post(url + 'v1/GeneratePayslipPDFbyUser', params, config).then(response => response.data);
  },
  checkphoneNumber(credentials) {
    return axios.post(url + 'v1/checkNumberExist', credentials).then(response => response.data);
  },
  verifyFireBaseOtp(credentials) {
    return axios.post(url + 'v1/FirebaseVerifyOtp', credentials).then(response => response.data);
  },
  GetInterviewReports(params) {
    return axios.post(url + 'v1/GetInterviewReports', params, config).then(response => response.data);
  },
  GetInterviewReportByDate(params) {
    return axios.post(url + 'v1/GetInterviewReportByDate', params, config).then(response => response.data);
  },
  GetProjectPhase(params) {
    return axios.post(url + 'v1/project-phase', params, config).then(response => response.data);
  },
  AddPhase(params) {
    return axios.post(url + 'v1/add-phase', params, config).then(response => response.data);
  },
  UpdatePhase(params) {
    return axios.post(url + 'v1/update-phase', params, config).then(response => response.data);
  },
  GetPhaseById(params) {
    return axios.post(url + 'v1/edit-phase', params, config).then(response => response.data);
  },
  GetViewPhaseById(params) {
    return axios.post(url + 'v1/view-phase', params, config).then(response => response.data);
  },
  EnableOrDisablePhase(params) {
    return axios.put(url + 'v1/delete-phase', params, config).then(response => response.data);
  },
  GetModules(params) {
    return axios.post(url + 'v1/modules', params, config).then(response => response.data);
  },
  GetModulesByPhase(params) {
    return axios.post(url + 'v1/get-phase-modules', params, config).then(response => response.data);
  },
  AddModules(params) {
    return axios.post(url + 'v1/add-modules', params, config).then(response => response.data);
  },
  UpdateModules(params) {
    return axios.post(url + 'v1/update-modules', params, config).then(response => response.data);
  },
  GetModulesById(params) {
    return axios.post(url + 'v1/module-by-id', params, config).then(response => response.data);
  },
  GetViewModulesById(params) {
    return axios.post(url + 'v1/view-modules', params, config).then(response => response.data);
  },
  EnableOrDisableModules(params) {
    return axios.put(url + 'v1/delete-modules', params, config).then(response => response.data);
  },
  HoldProject(params) {
    return axios.post(url + 'v1/projectHold', params, config).then(response => response.data);
  },
  UnHoldProject(params) {
    return axios.post(url + 'v1/projectUnHold', params, config).then(response => response.data);
  },
  GetSubTask(params) {
    return axios.post(url + 'v1/list-subtask', params, config).then(response => response.data);
  },
  AddSubTask(params) {
    return axios.post(url + 'v1/subtask', params, config).then(response => response.data);
  },
  addNewSubtask(params) {
    return axios.post(url + 'v1/addSubTask', params, config).then(response => response.data);
  },
  GetMembersByTeam(params) {
    return axios.post(url + 'v1/membersByTeam', params, config).then(response => response.data);
  },
  StopSubtask(params) {
    return axios.post(url + 'v1/StopSubtask', params, config).then(response => response.data);
  },
  GetPhaseDataById(params) {
    return axios.post(url + 'v1/GetPhaseDataById', params, config).then(response => response.data);
  },
  GetPhaseAndModuleDet(params) {
    return axios.post(url + 'v1/GetPhaseAndModuleDet', params, config).then(response => response.data);
  },
  GetProjectPhaseById(params) {
    return axios.post(url + 'v1/GetProjectPhaseById', params, config).then(response => response.data);
  },
  AddBug(params) {
    return axios.post(url + 'v1/AddBug', params, config).then(response => response.data);
  },
  GetNotifications(params) {
    return axios.post(url + 'v1/GetNotifications', params, config).then(response => response.data);
  },
  makeNotificationRead(params){
    return axios.post(url + 'v1/makeNotificationRead', params, config).then(response => response.data);
  },
  GetAllNotifications(params){
    return axios.post(url + 'v1/GetAllNotifications', params, config).then(response => response.data);
  },
  getFeaturesNotificationStatusUpdate(){
    return axios.get(url + 'v1/featuresNotificationStatusUpdate',config).then(response => response.data);
  },
  UpdateFeaturesNotificationStatusUpdate(){
    return axios.post(url + 'v1/UpdateFeaturesNotificationStatusUpdate',null,config).then(response => response.data);
  },
  makeNotificationAsRead(params){
    return axios.post(url + 'v1/MakeNotificationAsRead', params, config).then(response => response.data);
  },
  GetAllActivities(params){
    return axios.post(url + 'v1/GetAllActivities', params, config).then(response => response.data);
  },
  getTimeReportApi(params){
    return axios.post(url + 'v2/time-report/', params, config).then(response => response.data);
  },
  GetAppliedVacancyReport(params){
    return axios.post(url + 'v1/getAppliedVacancyReport', params, config).then(response => response.data);
  },
};


