<template>
    <div>
        <h4 class="mb-0">Members</h4>
        <div class="container-fluid">
            <div class="row">


                <div class="col-md-12">
                    <!-- <div class="card"> -->

                    <!-- <div class="card-body">
                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">

                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                </b-table>
                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>


                        </div> -->

                    <!-- </div> -->
                    <div class="card-body">
                        <table class="table table-condensed table-bordered table-striped" id="datatable-content"
                            v-if="items.length > 0">
                            <thead class="datatable-head" id="datatable-head">
                                <tr>
                                    <th>
                                        <center> Team </center>
                                    </th>
                                    <th>
                                <tr>
                                    <th >Employee</th>
                                    <th >Designation</th>
                                    <th >Team Role</th>
                                </tr>
                                </th>

                                </tr>
                            </thead>
                            <tbody class="datatable-body" id="datatable-body">
                                <tr v-for="value3 in items">
                                    <td>
                                        <center> {{ value3.team }}</center>
                                    </td>
                                    <td>
                                <tr v-for="value2 in value3.mem">
                                    <td>
                                        {{ value2.user }}
                                    </td>
                                    <td>
                                        {{ value2.desig }}
                                    </td>
                                    <td>
                                        {{ value2.role == 1 ? 'Team Lead' : 'Member' }}
                                    </td>
                                </tr>

                                </td>
                                </tr>
                            </tbody>
                        </table>
                        <br>
                        
                    </div>
                </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
// import Service from '@/services/ServiceApi.js';
// import Swal from "sweetalert2";

export default {

    data() {
        return {
            tablefields: [
                // { key: 'user_id', label: 'User ID', sortable: true, },
                { key: 'user', label: 'Name', sortable: true, },
            ],

            items: [],

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
        };
    },
    computed: {

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        }
    },
    props: ['p_members'],
    created() {

        this.showMembers(this.p_members);
        // console.log(this.p_members);
    },
    methods: {
        showMembers(mem) {

            this.items = mem;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

    }
};
</script>