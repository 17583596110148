import axios from 'axios';

const url = 'https://qcmapi.qaptive.in/api/'; //live
// const url = 'https://staging.qcmapi.qaptive.in/api/'; //test
// const url = ' http://127.0.0.1:8000/api/'; //local 
// const url = 'http://192.168.1.91:8000/api/'; //local 


var datas = '';
var params = '';

if (localStorage.getItem("loginDetails") != null) {
     datas =  JSON.parse(localStorage.getItem('loginDetails'));
} 
let config = {
  headers: {
    'Authorization': datas.auth_token
  }
}

export default {
 
  GetDepartments() {
    return axios.get(url + 'v1/department',config).then(response => response.data);
  },

  AddDepartment(ReqData) {
    return axios.post(url + 'v1/department',ReqData,config).then(response => response.data);
  },
  GetDepartmentById(params){
    return axios.post(url + 'v1/departmentById', params,config).then(response => response.data);
  },
  UpdateDepartment(ReqData){
    return axios.put(url + 'v1/department', ReqData,config).then(response => response.data);
  },
  EnableOrDisableDepartment(params){
    return axios.post(url + 'v1/departmentDelete', params,config).then(response => response.data);
  },
  GetUserById(params){
    return axios.post(url + 'v1/userById', params,config).then(response => response.data);
  },
  GetLeaveCountByUser(params){
    return axios.post(url + 'v1/leaveCountByUser', params,config).then(response => response.data);
  },
  UpdateUserLeave(params){
    return axios.put(url + 'v1/leaveCountByUser', params,config).then(response => response.data);
  },
  GetLeavesByUser(params){
    return axios.post(url + 'v1/leavesByUser', params,config).then(response => response.data);
  },
  CancelLeave(params){
    return axios.post(url + 'v1/cancelLeave', params,config).then(response => response.data);
  },
  GetDocumentsByUser(params){
    return axios.put(url + 'v1/documentsByUser', params,config).then(response => response.data);
  },
  AddDocument(params){
    return axios.post(url + 'v1/documentsByUser', params,config).then(response => response.data);
  },
  GetProjectsByUser(params){
    return axios.put(url + 'v1/projectsByUser', params,config).then(response => response.data);
  },
  GetProjectTasksByUser(params){
    return axios.post(url + 'v1/projectsByUser', params,config).then(response => response.data);
  },
  DeleteDocument(params){
    return axios.post(url + 'v1/deleteDocument', params,config).then(response => response.data);
  },
  GetLeaveConfig() {
    return axios.get(url + 'v1/leave-configs',config).then(response => response.data);
  },
  GetLeaveTypes() {
    return axios.get(url + 'v1/leave-types',config).then(response => response.data);
  },
  AddLeaveType(ReqData) {
    return axios.post(url + 'v1/leave-types',ReqData,config).then(response => response.data);
  },
  GetLeaveTypeConfiguration(ReqData) {
    return axios.post(url + 'v1/leave-configs',ReqData,config).then(response => response.data);
  },
  EnableOrDisableLeaveType(params){
    return axios.post(url + 'v1/leaveTypeDelete', params,config).then(response => response.data);
  },
  GetLeaveTypeById(params){
    return axios.post(url + 'v1/leaveTypeById', params,config).then(response => response.data);
  },
  UpdateLeaveType(ReqData){
    return axios.put(url + 'v1/leave-types', ReqData,config).then(response => response.data);
  },
  GetSessions(){
    return axios.get(url + 'v1/getSession',config).then(response => response.data);
  },
  UpdateLeaveDocument(ReqData){
    return axios.post(url + 'v1/leave-document', ReqData,config).then(response => response.data);
  },
  GetHolidays() {
    return axios.get(url + 'v1/holidays',config).then(response => response.data);
  },
  GetTodayLeaves() {
    return axios.get(url + 'v1/today-leaves',config).then(response => response.data);
  },
  GetAttendance() {
    return axios.get(url + 'v1/attendance',config).then(response => response.data);
  },
  UpdateAttendance(ReqData) {
    return axios.post(url + 'v1/attendance',ReqData,config).then(response => response.data);
  },
  ApplyLeave(ReqData) {
    return axios.post(url + 'v1/leave-add',ReqData,config).then(response => response.data);
  },
  GetLeaveAcks() {
    return axios.get(url + 'v1/leave-ack',config).then(response => response.data);
  },
  LeaveAck(ReqData) {
    return axios.put(url + 'v1/leave-ack',ReqData,config).then(response => response.data);
  },
  // UpdateLeave(ReqData) {
  //   return axios.put(url + 'v1/leave-update',ReqData,config).then(response => response.data);
  // },
  GetOnsiteHistoryByUser(params){
    return axios.put(url + 'v1/onsiteHistoryByUser', params,config).then(response => response.data);
  },
  AddOnsite(ReqData) {
    return axios.post(url + 'v1/onsiteHistoryByUser',ReqData,config).then(response => response.data);
  },
  GetOnsiteList() {
    return axios.get(url + 'v1/onsiteHistory',config).then(response => response.data);
  },
  ApplyOnsite(ReqData) {
    return axios.post(url + 'v1/onsiteHistory',ReqData,config).then(response => response.data);
  },
  GetOnsiteById(ReqData) {
    return axios.put(url + 'v1/onsiteHistoryById',ReqData,config).then(response => response.data);
  },
  ChangeOnsiteStatus(ReqData) {
    return axios.post(url + 'v1/onsiteHistoryById',ReqData,config).then(response => response.data);
  },
  GetConfigs(){
    return axios.get(url + 'v1/configs',config).then(response => response.data);
  },
  AddConfig(ReqData){
    return axios.post(url + 'v1/configs',ReqData,config).then(response => response.data);
  },
  EnableOrDisableConfig(params){
    return axios.put(url + 'v1/configs', params,config).then(response => response.data);
  },
  GetConfigurationById(params){
    return axios.put(url + 'v1/config-edit', params,config).then(response => response.data);
  },
  UpdateConfig(params){
    return axios.post(url + 'v1/config-edit', params,config).then(response => response.data);
  },
  GetVacancies(){
    return axios.get(url + 'v1/vacancy',config).then(response => response.data);
  },
  AddVacancy(ReqData){
    return axios.post(url + 'v1/vacancy',ReqData,config).then(response => response.data);
  },
  EnableOrDisableVacancy(params){
    return axios.put(url + 'v1/vacancy', params,config).then(response => response.data);
  },
  GetVacancyById(params){
    return axios.post(url + 'v1/vacancy-edit', params,config).then(response => response.data);
  },
  UpdateVacancy(params){
    return axios.post(url + 'v1/vacancy-update', params,config).then(response => response.data);
  },
  VerifyPassCodeApi(params){
    return axios.post(url + 'v1/Verify-passcode', params,config).then(response => response.data);
  },
  generateAttendanceApi(params){
    return axios.post(url + 'v1/generate-attendance', params,config).then(response => response.data);
  },
  GetResumeStatus(){
    return axios.get(url + 'v1/resume-status',config).then(response => response.data);
  },
  EnableOrDisableResumeStatus(params){
    return axios.put(url + 'v1/resume-status', params,config).then(response => response.data);
  },
  AddResumeStatus(ReqData){
    return axios.post(url + 'v1/resume-status',ReqData,config).then(response => response.data);
  },
  GetResumeStatusById(params){
    return axios.post(url + 'v1/resume-status-edit', params,config).then(response => response.data);
  },
  UpdateResumeStatus(params){
    return axios.post(url + 'v1/resume-status-update', params,config).then(response => response.data);
  },
  GetTransactionsByAppliedVacancy(params){
    return axios.post(url + 'v1/resume-transaction-view', params,config).then(response => response.data);
  },
  AddResumeTransaction(ReqData){
    return axios.post(url + 'v1/resume-transaction',ReqData,config).then(response => response.data);
  },
  GetAssignedTransactions(ReqData){
    return axios.post(url + 'v1/resume-transaction-employee-view',ReqData, config).then(response => response.data);
  },
  AddReview(ReqData){
    return axios.post(url + 'v1/resume-transaction-review',ReqData,config).then(response => response.data);
  },
  GetClients(){
    return axios.get(url + 'v1/clients',config).then(response => response.data);
  },
  AddClients(ReqData){
    return axios.post(url + 'v1/clients',ReqData,config).then(response => response.data);
  },
  GetClientById(params){
    return axios.post(url + 'v1/clients-edit', params,config).then(response => response.data);
  },
  UpdateClient(params){
    return axios.post(url + 'v1/clients-update', params,config).then(response => response.data);
  },
  EnableOrDisableClients(params){
    return axios.put(url + 'v1/clients', params,config).then(response => response.data);
  },
  UpdateResume(params){
    return axios.post(url + 'v1/resume-update', params,config).then(response => response.data);
  },
  AddAcademicDetails(ReqData){
    return axios.post(url + 'v1/resume-academics',ReqData,config).then(response => response.data);
  },
  EnableOrDisableResumeAcademic(params){
    return axios.put(url + 'v1/resume-academics', params,config).then(response => response.data);
  },
  GetResumeAcademicsById(params){
    return axios.post(url + 'v1/resume-academics-edit', params,config).then(response => response.data);
  },
  UpdateAcademicDetails(params){
    return axios.post(url + 'v1/resume-academics-update', params,config).then(response => response.data);
  },
  AddWorkDetails(ReqData){
    return axios.post(url + 'v1/resume-works',ReqData,config).then(response => response.data);
  },
  EnableOrDisableResumeWork(params){
    return axios.put(url + 'v1/resume-works', params,config).then(response => response.data);
  },
  GetResumeWorksById(params){
    return axios.post(url + 'v1/resume-works-edit', params,config).then(response => response.data);
  },
  UpdateWorkDetails(params){
    return axios.post(url + 'v1/resume-works-update', params,config).then(response => response.data);
  },
  GetAllHolidays() {
    return axios.get(url + 'v1/holiday',config).then(response => response.data);
  },
  EnableOrDisableHoliday(params){
    return axios.put(url + 'v1/holiday', params,config).then(response => response.data);
  },
  AddHoliday(ReqData){
    return axios.post(url + 'v1/holiday',ReqData,config).then(response => response.data);
  },
  GetHolidayById(ReqData){
    return axios.post(url + 'v1/holiday-edit',ReqData,config).then(response => response.data);
  },
  UpdateHoliday(ReqData){
    return axios.post(url + 'v1/holiday-update',ReqData,config).then(response => response.data);
  },
  GetJobVacancies(){
    return axios.get(url + 'v1/job-vacancy',config).then(response => response.data);
  },
  AddResume(ReqData){
    return axios.post(url + 'v1/add-resume',ReqData,config).then(response => response.data);
  },
  GetNewOpenings(reqdata) {
    return axios.post(url + 'v1/resume-openings', reqdata, config).then(response => response.data);
  },
  ApplyJobVacancy(reqdata) {
    return axios.post(url + 'v1/resume-apply', reqdata, config).then(response => response.data);
  },
  UpdateReview(reqdata){
    return axios.post(url + 'v1/update-review', reqdata, config).then(response => response.data);
  },
  getBugList(reqdata){
    return axios.post(url + 'v1/get-bug-list', reqdata, config).then(response => response.data);
  },
  getDailyAttendanceApi(params){
    return axios.post(url + 'v1/getDailyAttendanceApi', params, config).then(response => response.data);
  },
  updateDailyAttendanceApi(params){
    return axios.post(url + 'v1/updateDailyAttendanceApi', params, config).then(response => response.data);
  }
};


