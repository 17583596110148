<template>
    <div>
        <!-- <Breadcrumbs main="Report" title="Payroll Report" /> -->
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <!-- <div class="card">
                        <div class="card-body"> -->
                    <b-row>
                        <b-col xl="4">
                            <!-- <b-form-input type="month" value="2015-01" v-model="month"></b-form-input> -->
                            <datepicker :minimumView="'month'" input-class="datepicker-here form-control digits"
                                v-model="month" class="showAbove"></datepicker>
                        </b-col>
                        <!-- <b-col xl="3">
                                    <v-select v-model="to_users" label="name" placeholder="Users" :options="users"
                                        :multiple="true" />
                                </b-col> -->
                        <b-col xl="4" class="load-style">
                            <b-button id="default-primary" v-if="!isLoading" variant="primary" @click="Generate()">
                                View
                            </b-button>
                            <div class="loader-box " v-else>
                                <div class="loader-15"></div>
                            </div>
                        </b-col>
                        <!-- <b-col xl="3" class="load-style1" v-if="downFlag">

                            <b-button v-if="!isLoading1" id="default-primary" variant="primary" @click="downloadExcel()"
                                class="pull-right">
                                Download
                            </b-button>
                            <div class="loader-box box12" v-else>
                                <div class="loader-15"></div>
                            </div>
                        </b-col> -->
                        <b-col xl="4" v-if="loadApprove" class="pull-right">
                            <div class="loader-box box12 approve-load">
                                <div class="loader-15"></div>
                            </div>
                        </b-col>
                        <b-col xl="4" v-else-if="loaded" class="pull-right">
                            <b-col xl="4" v-if="downFlag && !is_approve">
                                <h4 style="width: max-content;">
                                    <i class="fa fa-cog app-color2"> Not Approved</i>
                                </h4>
                            </b-col>
                            <b-col xl="4" v-if="is_approve">
                                <h4 style="width: max-content;">
                                    <i class="fa fa-check-circle app-color"> Approved</i>
                                </h4>
                            </b-col>
                        </b-col>
                    </b-row>

                    <div class="table-responsive mt25 table-container showBelow" id="datatable">
                        <table class="table table-condensed table-bordered table-striped" id="datatable-content"
                            v-if="result_arr.length != 0">
                            <div v-if="'datas' in result_arr[0]">
                                <thead class="datatable-head" id="datatable-head">
                                    <tr>
                                        <th class="">
                                            <center> User </center>
                                        </th>
                                        <th>
                                            <center> Actual </center>
                                    <tr>
                                        <th v-for="val in actaulHead">{{ val }}</th>

                                    </tr>
                                    </th>
                                    <th>
                                        <center> Earning </center>
                                        <tr>
                                            <th v-for="val2 in actaulHead">{{ val2 }}</th>
                                        </tr>
                                    </th>
                                    <th>
                                        <center> Deduction </center>
                                        <tr>
                                            <th v-for="val1 in dedHead">{{ val1 }}</th>
                                        </tr>
                                    </th>
                                    <th>
                                        Net salary
                                    </th>
                                    </tr>
                                </thead>

                                <tbody class="datatable-body" id="datatable-body">

                                    <tr v-for="value3 in result_arr">
                                        <td class="">
                                            <center> {{ value3.user_name }}</center>
                                        </td>

                                        <td>
                                    <tr v-if="value3.datas">
                                        <td v-for="value2 in value3.datas.actual">
                                            <div>
                                                {{ value2.value }}
                                            </div>
                                        </td>
                                    </tr>
                                    </td>

                                    <td>
                                        <tr v-if="value3.datas">
                                            <td v-for="value2 in value3.datas.earning">
                                                <div>
                                                    {{ value2.value }}
                                                </div>
                                            </td>
                                        </tr>
                                    </td>

                                    <td>
                                        <tr v-if="value3.datas">
                                            <td v-for="value2 in value3.datas.deduction">
                                                <div>
                                                    {{ value2.value }}
                                                </div>
                                            </td>
                                        </tr>
                                    </td>

                                    <td v-if="value3.datas">
                                        {{ value3.datas.net_salary }}
                                    </td>
                                    </tr>
                                </tbody>
                            </div>
                            <!-- <div v-else>
                                {{ showNotAvailable() }}
                            </div> -->
                        </table>
                    </div>
                    <!-- </div>

                    </div> -->
                </div>

            </div>
        </div>

        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import Swal from "sweetalert2";
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';

export default {
    components: {
        vSelect,
        Datepicker
    },
    data() {
        return {
            month: "",
            loaded: false,
            result_arr: [],
            heading: [],
            users: [],
            activity: [],
            to_users: null,
            modalShow: false,
            curr_day: '',
            isLoading: false,
            link: null,
            is_approve: false,
            isLoading1: false,
            actaulHead: [],
            dedHead: [],
            downFlag: false,
            reportMonth: null,
            loadApprove: false,
            loginData: JSON.parse(localStorage.getItem('loginDetails')),
        };
    },
    computed: {

    },
    mounted() {

        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        if (currentMonth / 10 >= 1) {
            var make_month = currentMonth;
        } else {
            var make_month = "0" + currentMonth;
        }
        this.month = currentYear + "-" + make_month;
        // console.log(this.loginData);

        // this.retrieveEmployees();
    },
    methods: {
        // showNotAvailable() {

        //     if ('datas' in this.result_arr[0]) {

        //     }else{
        //         console.log(this.result_arr);

        //         if (this.result_arr[0].actual.length == 0){
        //             this.$toasted.show('Not available for this month', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        //         }
        //     }

        // },
        async downloadExcel() {
            this.isLoading1 = true;

            const reqData = {
                month: this.reportMonth,
                report_data: this.result_arr
            }
            // console.log(reqData);
            const response = await Service.DownloadSalaryReport(reqData);
            if (response.code == "200") {
                this.link = response.data;
                // window.location.href = this.link;
                window.open(this.link, '_blank');
            } else if (response.code == "403") {
                this.isLoading1 = false;
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
            this.isLoading1 = false;
        },
        // approvePayroll() {
        //     Swal.fire({
        //         title: "Are you sure?",
        //         text: "Are you sure you want to approve this Payroll report! You cannot revert this!",
        //         icon: "warning",
        //         showCancelButton: true,
        //         confirmButtonColor: "#3085d6",
        //         cancelButtonColor: "#d33",
        //         confirmButtonText: "Yes, Approve!",
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             this.loadApprove = true
        //             this.approveMonthlyPayroll();
        //         }
        //     });
        // },
        // async approveMonthlyPayroll() {
        //     // console.log('dd');
        //     const reqData = {
        //         month: this.reportMonth,
        //         report_data: this.result_arr
        //     }
        //     // console.log(reqData);
        //     const response = await Service.ApprovePayroll(reqData);
        //     if (response.code == "200") {
        //         this.is_approve = response.data
        //         this.loadApprove = false;
        //     } else if (response.code == "403") {
        //         this.loadApprove = false;
        //         this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        //         localStorage.removeItem('loginDetails')
        //         localStorage.removeItem('menuData')
        //         this.$router.push('/');
        //     }
        //     // this.loadApprove = false
        // },
        Generate() {
            this.isLoading = true;
            this.generateReport();
        },
        async generateReport() {
            this.isLoading = true;
            this.downFlag = false;
            var users = [];

            var empData = {
                id: this.loginData.id,
                name: this.loginData.name,
                gender: this.loginData.gender,
                phone: this.loginData.phone,
                email: this.loginData.email,
                designation: this.loginData.designation.name,
                emp_ref_no: this.loginData.emp_ref_no,
                join: this.loginData.joined_date.split('+')[0],
                status: this.loginData.status,
            }
            users.push(empData)

            const dateObj = new Date(this.month);
            const year = dateObj.getUTCFullYear();
            const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const formattedDate = `${year}-${month}`;
            this.month = formattedDate;

            const reqData = {
                month: this.month,
                users: users
            }
            const response = await Service.GetSalaryReports(reqData);
            this.result_arr = []
            if (response.code == "200") {
                this.result_arr = response.data.values;
                // console.log(this.result_arr);
                // if (this.result_arr.length != 0) {
                //     if ('datas' in this.result_arr[0]) {
                //         this.loaded = true;
                //     } else {
                //         this.loaded = false;
                //     }
                // } else {
                //     this.loaded = false;
                // }
                this.reportMonth = response.data.month;
                if (this.result_arr.length != 0) {
                    if ('datas' in this.result_arr[0]) {
                        this.is_approve = response.data.approve;
                        this.loaded = true;
                    } else {
                        this.loaded = false;
                        this.$toasted.show('Report Not available for this month', { theme: 'outline', position: 'top-right', type: 'info', duration: 2000 });
                    }
                } else {
                    this.loaded = false;
                    this.$toasted.show('Report Not available for this month', { theme: 'outline', position: 'top-right', type: 'info', duration: 2000 });
                }
                this.actaulHead = []
                if (this.result_arr.length != 0) {
                    if ('datas' in this.result_arr[0]) {
                        response.data.values[0].datas.actual.forEach(element => {
                            this.actaulHead.push(element.comp)
                        });
                    }
                }

                this.dedHead = []
                if (this.result_arr.length != 0) {
                    if ('datas' in this.result_arr[0]) {
                        response.data.values[0].datas.deduction.forEach(element => {
                            this.dedHead.push(element.comp)
                        });
                    }
                }


            } else if (response.code == "403") {
                this.isLoading = false;
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
            this.isLoading = false;
            this.downFlag = true;
        },
        // getTimeFormate(time_sec) {
        //     var sec_num = time_sec
        //     var hours = Math.floor(sec_num / 3600);
        //     var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        //     var seconds = sec_num - (hours * 3600) - (minutes * 60);

        //     if (hours < 10) { hours = "0" + hours; }
        //     if (minutes < 10) { minutes = "0" + minutes; }
        //     if (seconds < 10) { seconds = "0" + seconds; }
        //     return hours + ':' + minutes + ':' + seconds;
        // },
        // async retrieveEmployees() {
        //     const response = await Service.GetEmployeesForReport();
        //     if (response.code == "200") {
        //         this.users = response.data

        //     } else if (response.code == "403") {
        //         this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
        //         localStorage.removeItem('loginDetails')
        //         localStorage.removeItem('menuData')
        //         this.$router.push('/');
        //     }
        // },
        // async viewDetails(id, day) {
        //     this.curr_day = day;
        //     var req_data = {
        //         'user': id,
        //         'day': day
        //     }
        //     const response = await Service.GetActivity(req_data);
        //     if (response.code == "200") {

        //         this.activity = response.data;
        //     } else if (response.code == "403") {
        //         this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        //         localStorage.removeItem('loginDetails')
        //         localStorage.removeItem('menuData')
        //         this.$router.push('/');
        //     }
        // },
        // async savePDF() {
        //     var req_data = {
        //         file : this.$refs.html2Pdf
        //     }
        //     console.log(req_data);
        // }
    }
};
</script>
<style>
/* .table thead th {
    border: 1px solid #c9c9c9 !important;
    width: 90px !important;
}


.table-bordered td,
.table-bordered th {
    border-color: #c9c9c9 !important;
    width: 90px !important;
} */

.mt25 {
    margin-top: 25px;
}

.padz {
    padding: 2px 15px;
}

th.pinned,
td.pinned {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 1;
}

.table-container {
    height: 450px;
    overflow: auto;
}

.load-style .loader-box {

    height: 38px;
    text-align: left !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: left !important;
    -ms-flex-align: left !important;
    align-items: left !important;
    vertical-align: left !important;
    -webkit-box-pack: left !important;
    -ms-flex-pack: left !important;
    justify-content: left !important;
    -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;

}

.load-style1 .box12 {

    float: right;
    margin-right: 68px;
    height: 38px;
    text-align: left !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: left !important;
    -ms-flex-align: left !important;
    align-items: left !important;
    vertical-align: left !important;
    -webkit-box-pack: left !important;
    -ms-flex-pack: left !important;
    justify-content: left !important;
    -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;

}

/* .leave-col {
    background-color: antiquewhite;
    width: 650px;
    text-align: center;
} */

.app-color {
    color: #00e99b;
}

.app-color2 {
    color: #ffc107;
}

.approve-load {
    height: 30px !important;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    padding-top: 0px !important;
}

.showAbove {
    z-index: 1 !important;
}

.showBelow {
    z-index: auto !important;
}
</style>