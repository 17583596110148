<template>
  <li class="onhover-dropdown">
    <div class="notification-box">
      <feather type="bell" @click="notification_open()"></feather><span class="badge badge-pill badge-secondary">{{
        notification_count }} </span>
    </div>
    <div class="onhover-show-div notification-dropdown" :class="{ active: notification }">
      <h6 class="f-18 mb-0 dropdown-title">Notitications</h6>
      <ul>
        <li class="b-l-primary border-4" v-for="noti in activities">
          <p>{{ noti.text }} </p><br>
          <p><span class="font-danger">{{ noti.date }}</span></p>
        </li>
        <!-- <li class="b-l-success border-4">
          <p>Order Complete </p><br>
          <p><span class="font-success">1 hr</span></p>
        </li>
        <li class="b-l-info border-4">
          <p>Tickets Generated</p><br>
          <p><span class="font-info">3 hr</span></p>
        </li>
        <li class="b-l-warning border-4">
          <p>Delivery Complete</p><br>
          <p><span class="font-warning">6 hr</span></p>
        </li> -->
        <li><a class="f-w-700" @click="redirectToChild">View all</a></li>
      </ul>
    </div>
  </li>
</template>

<script>
import Service from '@/services/Service.js';
import buttonNotifyRead from './closeButtonForNotify.vue'

export default {
  name: 'Notifications',
  components: {
    buttonNotifyRead
  },
  data() {
    return {
      notification: false,
      activities: [],
      notification_count: 0,
      loginData: JSON.parse(localStorage.getItem('loginDetails')),
      notifyMessage: [],
      trigger: null,
      cur_user_enc_id: null,
      oldMagecound : 0
    };
  },
  mounted() {
    this.requestNotificationPermission();
    this.getNotifications(this.loginData.id);
   

  },
  methods: {
    requestNotificationPermission() {
      if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log('Notifications Enabled');
          }else{
            console.log('Not Enabled');
          }
        });
      }
    },
    requestNotificationPermissionNotf(data) {
      if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            new Notification("Qcm Notification", {
              body: data.text,
            });
          }
        });
      }
    },
    notification_open() {
      this.notification = !this.notification;
    },
    redirectToChild() {
      this.$router.push({
        path: '/home/employee-view/' + this.cur_user_enc_id + '/9',
      })
    },
    async getNotifications(user) {
      const reqDatas = {
        user_id: user
      }
    
      const response = await Service.GetNotifications(reqDatas);
      this.activities = []
      if (response.code == '200') {

        console.log('data'+ typeof response.data.data.length);
          console.log('old'+ typeof this.oldMagecound);
        if (this.oldMagecound < response.data.data.length) {

          console.log('data'+ response.data.data.length);
          console.log('old'+this.oldMagecound);
          this.oldMagecound = response.data.data.length;
      

       

          this.requestNotificationPermissionNotf(response.data.data[0])
         


        }

        

       
        this.cur_user_enc_id = response.data.user_id;
        this.activities = response.data.data;
        if (this.notification_count < this.activities.length) {
          if (this.notifyMessage.length == 0) {
            this.activities.forEach(element => {
              var content = {
                // Your component or JSX template
                component: buttonNotifyRead,

                props: {
                  uid: element.uid,
                  message: element.text
                },

              };
              let uid = element.uid;
              this.$toast(content, {
                position: "bottom-right",
                timeout: false,
                closeOnClick: false,
                hideProgressBar: false,
                draggable: false,
                closeButton: false,
                icon: 'fa fa-bell',
                id: uid
              });
            });
          } else {
            var difference = [
              ...this.notifyMessage.filter(obj1 => !this.activities.find(obj2 => obj2.date === obj1.date)),
              ...this.activities.filter(obj2 => !this.notifyMessage.find(obj1 => obj1.date === obj2.date))
            ];
            // console.log(difference);
            difference.forEach(element => {
              let uid = element.uid;
              var content = {
                // Your component or JSX template
                component: buttonNotifyRead,

                props: {
                  uid: element.uid,
                  message: element.text
                },

              };
              this.$toast(content, {
                position: "bottom-right",
                timeout: false,
                closeOnClick: false,
                hideProgressBar: false,
                draggable: false,
                closeButton: false,
                icon: 'fa fa-bell',
                id: uid
              });
            });
          }
        } else if (this.notification_count > this.activities.length) {
          this.notifyMessage.forEach(element => {
            this.$toast.dismiss(element.uid);
          });
        }
        // console.log(this.notification_count, this.activities.length);
        this.notifyMessage = response.data.data;
        this.notification_count = this.activities.length;
      }

    },
  },
};
</script>
