<template>
    <div>
        <Breadcrumbs main="Job Vacancies" title="Add Job Vacancy" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <label for="title">Job Title</label>
                                        <b-form-input type="text" id="title" v-model="c_form.title"
                                            :state="c_form_result.title" placeholder="Title"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.title">
                                        </b-form-valid-feedback>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="value">No. of Vacancies</label>
                                        <b-form-input type="number" id="no_of_vacancies" v-model="c_form.no_of_vacancies"
                                            :state="c_form_result.no_of_vacancies"
                                            placeholder="No. of Vacancies"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.no_of_vacancies">
                                        </b-form-valid-feedback>
                                    </div>
                                    <!-- <div class="col-md-6 mb-3">
                                        <label for="value">Qualification</label>
                                        <b-form-input type="text" id="qualification" v-model="c_form.qualification"
                                            :state="c_form_result.qualification" placeholder="Qualification"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.qualification">
                                            </b-form-valid-feedback>
                                    </div> -->
                                    <div class="col-md-4 mb-3">
                                        <label for="title">Department</label>
                                        <b-form-input type="text" id="department" v-model="c_form.department"
                                            :state="c_form_result.department" placeholder="Department"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.department">
                                        </b-form-valid-feedback>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <div class="form-group">
                                            <label class="form-label">Start date</label>
                                            <datepicker :format="customFormatter"
                                                input-class="datepicker-here form-control digits"
                                                v-model="c_form.start_date">
                                            </datepicker>
                                        </div>
                                    </div>

                                    <div class="col-md-4 mb-3">
                                        <div class="form-group">
                                            <label class="form-label">End date</label>
                                            <datepicker :format="customFormatter"
                                                input-class="datepicker-here form-control digits" v-model="c_form.end_date">
                                            </datepicker>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="title">Location</label>
                                        <b-form-input type="text" id="location" v-model="c_form.location"
                                            :state="c_form_result.location" placeholder="Location"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.location">
                                        </b-form-valid-feedback>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="value">Package</label>
                                        <b-form-input type="text" id="package" v-model="c_form.package"
                                            :state="c_form_result.package" placeholder="Package"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.package">
                                        </b-form-valid-feedback>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="c_form_priority">Priority</label>
                                        <v-select v-model="c_form.priority" label="text" :options="priorityTypes" />

                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="c_form_job_type">Job Type</label>
                                        <v-select v-model="c_form.job_type" label="text" :options="job_types" />

                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <label for="value">Description</label>
                                        <vue-editor v-model="c_form.description"></vue-editor>
                                    </div>
                                    <!-- <div class="col-md-2 mb-3">
                                    </div> -->
                                    <div class="col-md-12 mb-3">
                                        <label for="value">Short Description</label>
                                        <b-form-textarea rows="4" v-model="c_form.short_description"
                                            placeholder="Enter Short Description"></b-form-textarea>

                                    </div>

                                    <div class="col-md-4 mb-3">
                                        <label for="pass_mark">Pass Mark</label>
                                        <b-form-input type="number" id="pass_mark" v-model="c_form.pass_mark" placeholder="Pass Mark"></b-form-input>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                    <label for="keywords">Keywords</label>
                                    <div v-for="(item, index) in c_form.keywords" :key="index" class="mb-2">
                                        <div class="d-flex">
                                            <b-form-input type="text" v-model="item.keyword" class="mr-2" placeholder="Keyword"></b-form-input>
                                            <b-form-input type="number" v-model="item.mark" class="mr-2" placeholder="Mark"></b-form-input>
                                            <b-button v-if="c_form.keywords.length > 1" @click="removeKeyword(index)" variant="danger" size="sm">-</b-button>
                                        </div>
                                    </div>
                                    <b-button @click="addKeywordField" variant="success" size="sm">+</b-button>

                                </div>

                                    <!-- <div class="col-md-2 mb-3">
                                    </div> -->


                                </div>

                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from '@/services/ServiceApi.js';
import vSelect from 'vue-select'
import { VueEditor } from 'vue2-editor';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'

export default {

    components: {
        vSelect,
        VueEditor,
        Datepicker,
    },

    data() {
        return {
            success: false,
            description: '',
            short_description: '',
            c_form: {
                title: '',
                //qualification: '',
                description: '',
                short_description: '',
                no_of_vacancies: '',
                package: '',
                priority: '',
                start_date: '',
                end_date: '',
                location:'',
                job_type:'',
                department:'',
                pass_mark: '', 
                keywords: [{ keyword: '', mark: '' }]

            },
            c_form_result: {
                title: null,
                //qualification: null,
                description: null,
                short_description: null,
                no_of_vacancies: null,
                package: null,
                priority: null,
                start_date: null,
                end_date: null,
                location:null,
                job_type:null,
                department:null

            },
            priorityTypes: [

                { value: "1", text: "Immediate" },
                { value: "2", text: "Normal" },
            ],
            job_types: [

            { value: "1", text: "Full Time" },
            { value: "2", text: "Part Time" },
            ],

        };
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        customFormatter(date) {
            return moment(date).format('Y-MM-DD');
        },
        addKeywordField() {
            this.c_form.keywords.push({ keyword: '', mark: '' }); 
        },
        removeKeyword(index) {
            this.c_form.keywords.splice(index, 1); 
        },
        onCustomStyleSubmit(evt) {
            evt.preventDefault();

            if (this.c_form.title == '') {
                this.success = false;
                this.$toasted.show(' Please enter Title', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }

            // else if (this.c_form.qualification == '') {
            //     this.success = false;
            //     this.$toasted.show(' Please enter qualification', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
            // }
            else if (this.c_form.priority.value == '') {
                this.success = false;
                this.$toasted.show(' Please select priority', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }

            else if (this.c_form.description == '') {
                this.success = false;
                this.$toasted.show(' Please enter description', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.department == '') {
                this.success = false;
                this.$toasted.show(' Please enter department', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.job_type.value == '') {
                this.success = false;
                this.$toasted.show(' Please select job type', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.short_description == '') {
                this.success = false;
                this.$toasted.show(' Please enter short description', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.no_of_vacancies == '') {
                this.success = false;
                this.$toasted.show(' Please enter No. of vacancies', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.start_date == '') {
                this.success = false;
                this.$toasted.show(' Please select start date', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.end_date == '') {
                this.success = false;
                this.$toasted.show(' Please select end date', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }

            else {
                this.success = true;
            }

            this.saveVacancy();
        },
        saveVacancy() {

            if (this.success == true) {
                // if (this.c_form.keywords.some(item => item.keyword.trim() !== '')) {
                if (this.c_form.keywords.every(item => item.keyword.trim() !== '' && item.mark !== '')) {
                    const passMark = parseInt(this.c_form.pass_mark); 
                    if (!isNaN(passMark)) {
                    const vacancy_data = {
                        title: this.c_form.title,
                        //qualification: this.c_form.qualification,
                        package: this.c_form.package,
                        short_description: this.c_form.short_description,
                        description: this.c_form.description,
                        no_of_vacancies: this.c_form.no_of_vacancies,
                        priority: this.c_form.priority.value,
                        job_type: this.c_form.job_type.value,
                        location: this.c_form.location,
                        department: this.c_form.department,
                        start_date: this.customFormatter(this.c_form.start_date),
                        end_date: this.customFormatter(this.c_form.end_date),

                        pass_mark: passMark,
                        keywords: this.c_form.keywords.reduce((acc, curr) => {
                            const mark = parseInt(curr.mark); // Parse mark as integer
                            if (curr.keyword.trim() !== '' && !isNaN(mark)) { // Check if mark is a valid integer
                                acc[curr.keyword.trim()] = mark;
                            }
                            return acc;
                        }, {})

                    }
                    this.addVacancy(vacancy_data)
                }
                else{
                    this.$toasted.show('Please enter a valid pass mark', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 }); 
                }

                    
                } else {
                    this.$toasted.show('Please enter at least one keyword and mark', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                }

              
                
            }
        },
        async addVacancy(vacancy_data) {
            const response = await ServiceApi.AddVacancy(vacancy_data);
            if (response.code == '200') {
                this.$toasted.show(' Vacancy added successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/vacancies');
            }
            else if (response.code == '400') {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },

    }
};
</script>
