<template>
    <div><br>
        <Breadcrumbs main="Project" :title="projName + ' / Add Phase'" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="title">Phase Title</label>
                                        <b-form-input type="text" id="title" v-model="title" placeholder="Phase Title">
                                        </b-form-input>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="employees">Module Manager</label>
                                        <v-select v-model="selected_employees" label="name" placeholder="Module Manager"
                                            :options="employees" :multiple="true" />
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="description">Phase Description</label>
                                        <b-form-textarea type="text" id="description" v-model="description"
                                            placeholder="Phase Description">
                                        </b-form-textarea>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-10">

                                    </div>
                                    <div class="col-2" v-if="loader">
                                        <b-button type="submit" variant="primary">Submit</b-button>
                                    </div>
                                    <div class="col-2" v-else>
                                        <div class="loader-box">
                                            <div class="loader-15"></div>
                                        </div>
                                    </div>
                                </div>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import moment from "moment";
import { VueEditor } from 'vue2-editor';
export default {

    components: {
        vSelect,
        VueEditor
    },

    data() {
        return {
            loader: true,
            success: false,
            title: '',
            description: '',
            project_id: null,
            employees: [],
            selected_employees: null,
            projName: null,
            // loginData: JSON.parse(localStorage.getItem('loginDetails')),
        };
    },
    computed: {
    },
    mounted() {
        this.getEmployees();
        this.getProjectDetails();
    },
    methods: {
        async getProjectDetails(){
            var rqData = {
                id: this.$route.params.id
            }
            const response = await Service.GetProjectForEdit(rqData);
            if (response.code == '200') {
                this.projName = response.data.all.name;
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getEmployees() {
            const response = await Service.GetEmployees();
            if (response.code == '200') {
                this.employees = response.data;
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        onCustomStyleSubmit(evt) {
            this.loader = false;
            evt.preventDefault();
            if (this.title == '' || this.description == null || this.selected_employees.length == 0) {
                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                this.loader = true;
            } else {
                this.savePhase();
            }
        },
        savePhase() {
            const phase_data = {
                title: this.title,
                description: this.description,
                projectId: this.$route.params.id,
                module_managers: this.selected_employees,
            };
            // console.log(phase_data);
            this.addPhase(phase_data)
        },
        async addPhase(phase_data) {
            const response = await Service.AddPhase(phase_data);
            if (response.code == '200') {
                this.loader = true;
                this.$toasted.show(' Phases added successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/emp-project/' + this.$route.params.id);
            } else if (response.code == "400") {
                this.loader = true;
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
        },

    }
};
</script>
<style>
.ck-content {
    height: 150px;
}
.loader-box {
    height: 50px !important;
}
</style>