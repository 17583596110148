<template>
    <div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">


                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">
                            <b-row>
                                <b-col xl="12">
                                    <b-button class="pull-right" id="default-primary" variant="primary" @click="addPhase()">
                                        Add Project Phase
                                    </b-button>
                                    <!-- <b-tooltip target="default-primary" triggers="hover" placement="top">btn btn-primary</b-tooltip> -->
                                </b-col>
                            </b-row>
                            <br/>
                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <!-- <template #cell(start_date)="rows">
                                        <span>
                                            {{ rows.item.start_date ? rows.item.start_date.split("T")[0] : "" }}
                                        </span>
                                    </template>
                                    <template #cell(end_date)="rows">
                                        <span>
                                            {{ rows.item.end_date ? rows.item.end_date.split("T")[0] : "" }}
                                        </span>
                                    </template> -->
                                    <template #cell(status)="rows">
                                        <b-badge :variant="statusVariant(rows.item.status)">

                                            <span v-if="rows.item.status == 1">
                                                Ongoing
                                            </span>
                                            <span v-else-if="rows.item.status == 2">
                                                Completed
                                            </span>
                                            <span v-else>
                                                Hold
                                            </span>
                                        </b-badge>
                                    </template>


                                    <template #cell(active_status)="rows">
                                        <b-badge :variant="statusVariantActive(rows.item.active_status)">
                                            <span v-if="rows.item.active_status == 1">
                                                Active
                                            </span>
                                            <span v-else>
                                                Inactive
                                            </span>
                                        </b-badge>
                                    </template>
                                    <template #cell(actions)="row">
                                        <span>
                                            <span @click="EditPhase(row.item.id)">
                                                <feather type="edit"></feather>
                                            </span>
                                            <span @click="viewPhase(row.item.id)">
                                                <feather type="eye"></feather>
                                            </span>
                                            <!-- <span @click="deletePhase(row.item.id)">
                                                <feather type="eye"></feather>
                                            </span> -->
                                            <span v-if="row.item.active_status == 1">
                                            <feather type="thumbs-down" @click="deletePhase(row.item)"></feather>
                                            </span>
                                            <span v-else>
                                            <feather type="thumbs-up" @click="deletePhase(row.item)"></feather>
                                            </span>
                                        </span>
                                    </template>


                                    <!-- <template #cell(actions)="row">
                                        <span>
                                            <span v-if="project_id.progress_status == 'bg-primary'"
                                                @click="EditPhase(row.item.id)">
                                                <feather type="edit"></feather>
                                            </span>
                                          
                                            <span @click="viewPhase(row.item.id)">
                                                <feather type="eye"></feather>
                                            </span>
                                            <span>
                                               
                                            </span>
                                        </span>
                                    </template> -->

                                </b-table>
                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>


                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import Swal from "sweetalert2";

export default {
    data() {
        return {
            tablefields: [
                { key: 'title', label: 'Project Phase Title', sortable: true, },
                { key: 'description', label: 'Description', sortable: true, },
                { key: 'project_name', label: 'Project Name', sortable: true, },
                // { key: 'project', label: 'Project name', sortable: true, },
                // { key: 'type', label: 'Task Type', sortable: true, },
                { key: 'status', label: 'Project Phase Status', sortable: true, },
                // { key: 'start_date', label: 'Start Date', sortable: true, },
                // { key: 'end_date', label: 'End Date', sortable: true, },
                { key: 'active_status', label: 'Active Status', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],

            items: [],

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            loginData: JSON.parse(localStorage.getItem('loginDetails')),
        };
    },
    props: ['phaseId','projectPhaseId'],
    computed: {
        statusVariant() {
            const statusColor = {
                3: "light-warning",
                2: "light-info",
                1: "light-success",
            };
            return (status) => statusColor[status];
        },
        statusVariantActive() {
            const statusColor = {
                1: "light-success",
                0: "light-danger",
            };
            return (status) => statusColor[status];
        },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        },
    },
    created() {
        // console.log(this.$route.params.id);
        this.retrievePhase(this.$route.params.id);
    },
    methods: {
        viewPhase(id) {
            this.$emit('update:phaseId', 1);
            this.$emit('update:projectPhaseId', id);
        },
        EditPhase(id) {
            this.$emit('update:phaseId', 2);
            this.$emit('update:projectPhaseId', id);
        },
        deletePhase(id) {
            this.$emit('update:phaseId', 1);
            this.$emit('update:projectPhaseId', id);
        },
        addPhase() {
            this.$emit('update:phaseId', 3);
        },
        async retrievePhase(proId) {
            const reqDatas = {
                project_id: proId
            }
            const response = await Service.GetProjectPhase(reqDatas);
            if (response.code == "200") {
                this.items = response.data;
                this.totalRows = response.data.length;
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        deletePhase(data) {

            if (data.active_status == 1) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to disable this Project Phase!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, disable!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const credentials = {
                            status: data.active_status,
                            id: data.id,
                        };
                        // console.log(credentials);
                        var done = this.enableOrDisablePhase(credentials);
                        if (done) {
                            Swal.fire("Disabled!", "Project Phase has been disabled.", "success")
                            this.retrievePhase(this.$route.params.id);
                        }
                    }
                });
            } else if (data.active_status == 0) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to enable this Project Phase!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, enable!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const credentials = {
                            status: data.active_status,
                            id: data.id,
                        };
                        var done = this.enableOrDisablePhase(credentials);
                        if (done) {
                            Swal.fire("Enabled!", "Project Phase has been enabled.", "success")
                            this.retrievePhase(this.$route.params.id);
                        }
                    }
                });
            }
        },
        async enableOrDisablePhase(credentials) {
            const response = await Service.EnableOrDisablePhase(credentials);
            if (response.code == "200") {
                return true;
            } else {
                return false;
            }
        }

    },
};
</script>