<template>
    <div>
        <b-row>
            <b-col xl="7">

                <b-input-group class="datatable-btn">
                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                </b-input-group>

            </b-col>

            <b-col xl="5">
                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <div class="table-responsive datatable-vue">
            <b-table show-empty stacked="md" :items="payslipData" :fields="tablefields" :filter="filter"
                :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                <template #cell(month)="rows">
                    <span>
                        {{ getFormatedDate(rows.item.month) }}
                    </span>
                </template>
                <template #cell(link)="rows">
                    <span>
                        <!-- {{ rows.item.start_date.split("T")[0] }} -->
                        <!-- {{ loading[rows.index] }} -->
                        <b-button id="default-primary" class="btn-pill" variant="outline-success" @click="downloadPDF(rows.item, rows.index)">Download
                        </b-button>
                        <!-- <div class="loader-box load-style" v-else-if="!loading[rows.index]">
                            <div class="loader-15"></div>
                        </div> -->
                    </span>
                </template>
            </b-table>
        </div>
        <b-col md="6" class="my-1 p-0">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
        </b-col>
    </div>
</template>
<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'

export default {
    components: {
        vSelect,
        Datepicker,

    },
    data() {
        return {
            filter: null,
            loading: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            tablefields: [
                { key: 'month', label: 'Date', sortable: true, },
                { key: 'link', label: 'PDF', sortable: true, }
            ],
            payslipData: []
        };
    },
    computed: {

    },
    mounted() {
        this.getPayslipByUser(this.$route.params.id)
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        downloadPDF(data, index){
            // this.loading[index] = false;
            // console.log(this.loading);
            if (data.link != null){
                // window.location.href = data.link;
                window.open(data.link, '_blank');
                // this.loading[index] = true;
            }else{
                // console.log(data);
                this.generatePayslipPDF(data, index);
            }
            
        },
        async generatePayslipPDF(row, index){
            var dateString = row.month;
            var date = new Date(dateString);

            // Get the year
            var year = date.getFullYear();

            // Get the month name
            var options = { month: 'long' };
            var month = new Intl.DateTimeFormat('en-US', options).format(date);

            // Combine the year and month
            var month_string =  `${year}_${month}`;
            var reqData = {
                user: this.$route.params.id,
                month: row.month,
                month_string: month_string
            }
            // console.log(reqData);
            const response = await Service.GeneratePayslipPDFbyUser(reqData);
            if (response.code == '200') {
                // console.log(response.data);
                // window.location.href = response.data;
                // this.loading[index] = true;
                window.open(response.data, '_blank');
            } else if (response.code == '400') {
                //message = response.
                // this.loading[index] = true;
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
                // this.loading[index] = true;
            }
            
        },
        getFormatedDate(date) {
            var dateString = date;
            var date = new Date(dateString);

            // Get the year
            var year = date.getFullYear();

            // Get the month name
            var options = { month: 'long' };
            var month = new Intl.DateTimeFormat('en-US', options).format(date);

            // Combine the year and month
            return `${year} ${month}`;
        },
        async getPayslipByUser(user_id) {
            var data = {
                user_id: user_id
            }
            const response = await Service.GetPayslipByUser(data);
            if (response.code == '200') {
                console.log(response.data);
                this.payslipData = response.data;
                this.payslipData.forEach(element => {
                    this.loading.push(true)
                });
                // console.log(this.loading);
                this.totalRows = response.data.length;
            } else if (response.code == '400') {
                //message = response.
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        }
    }

};
</script>
<style>
    .load-style{
        height: 40px !important;
    }
</style>