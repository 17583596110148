<template>
  <li class="profile-nav onhover-dropdown p-0 mr-0">
    <div class="media profile-media">
     
      <img v-if="image_name"
        class="b-r-10"
        :src=image_name
        style="height:50px; width:50px"
        alt=""
      />
      <img v-else
        class="b-r-10"
        src="../../assets/images/dashboard/profile.jpg"
        alt=""
      /> 
      <div class="media-body">
        <span>{{ name }}</span>
        
        <p class="mb-0 font-roboto">
          {{ data.designation ? data.designation.name : 'Admin' }} <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
     
      <li v-if="data.user_type == 3">
        <router-link :to="'/home/employee-view/' + id + '/'+ 0"><feather type="user"></feather><span>Profile</span></router-link>
      </li>
      <li>
        <a @click="logout"
          ><feather type="log-in"></feather><span>Log out</span></a
        >
      </li>
    </ul>
  </li>
</template>

<script>
import Service from '@/services/Service.js';

  export default {
    name: 'Profile',
    data() {
      return {
        name: '',
        role: '',
        image_name:null,
        data:JSON.parse(localStorage.getItem('loginDetails')),
        lastbuttonstatus:localStorage.getItem('lastbuttonstatus'),
        id:JSON.parse(localStorage.getItem('id'))
      };
    },
    mounted: function () {
      this.name = this.data.name
      this.image_name = this.data.image_name
      if (this.data.user_type == 1) {
        this.role = 'Global Admin'
      }else if (this.data.user_type == 2) {
        this.role = 'Company admin'
      }
      else if (this.data.user_type == 3) {
        this.role = 'Employee'
      }
     
    },
    methods: {
      logout: function() {
          this.logoutApi();
      
       
      
      },
      async logoutApi(){
        const response = await Service.logoutApi();
        if (response.code == 200) {
          localStorage.removeItem('loginDetails')
          localStorage.removeItem('menuData')
                localStorage.removeItem('lastworkingtime')
                localStorage.removeItem('lastbuttonstatus')
                localStorage.removeItem('PunchingDetailLogin')
                localStorage.removeItem('PunchingDetail')
                localStorage.removeItem('config')
                localStorage.removeItem('id')
          this.$router.push('/');
        }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
      }
    },
  };
</script>
