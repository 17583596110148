<template>
    <div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="name">Module Name</label>
                                        <b-form-input type="text" id="name" v-model="name" placeholder="Module Name">
                                        </b-form-input>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="description">Module Description</label>
                                        <b-form-textarea type="text" id="description" v-model="description" placeholder="Module Description">
                                        </b-form-textarea>                                      
                                    </div>
                                    <!-- <div class="col-md-6 mb-3">
                                        <label for="employee">Company</label>
                                        <v-select
                                            v-model="employees"
                                            label="name"
                                            :options="employees"                       
                                        />                                     
                                    </div> -->
                                    <b-col md="6">
                                        <label for="phases">Project Phase</label>
                                        <v-select v-model="phase" label="title" placeholder="Project Phase"
                                            :options="phases" />
                                    </b-col>

                                    <b-col md="6">
                                        <label for="employees">Employee</label>
                                        <v-select v-model="selected_employees" label="name" placeholder="Employees"
                                            :options="employees" :multiple="true" />
                                    </b-col>


                                    <!-- <b-col md="3">                                            
                                    <v-select v-model="input.mem" label="name" placeholder="Members"
                                    :multiple="true" :options="tMem"
                                    @input="addExtra(input.mem, input.teamId, index)"
                                    :clearable="false" />
                                    </b-col> -->

                                    <!-- <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Description</label>
                                           
                                         
                                            <vue-editor v-model="descrip"></vue-editor>

                                        </div>
                                    </div>
                                </div> -->
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <b-button type="submit" variant="primary">Submit</b-button>
                                    </div>
                                    <!-- <div  class="loader-box">
                                        <div class="loader-15"></div>
                                    </div> -->
                                </div>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import moment from "moment";
import { VueEditor } from 'vue2-editor';
export default {

    components: {
        vSelect,
        VueEditor
    },

    data() {
        return {
            loader: false,
            // success: false,
            // startEndStatus: [
            //     { value: '1', text: 'Pending' },
            //     { value: '2', text: 'Start' },
            // ],
            name: '',
            description: '',
            employees: [],
            selected_employees: [],
            phase: null,
            phases: [],
            phase_id: null,
            // loginData: JSON.parse(localStorage.getItem('loginDetails')),
        };
    },
    props: ['modulesId'],
    computed: {
    },
    created() {
        this.getEmployees();
        this.retrievePhase(this.$route.params.id);
    },
    methods: {
        async retrievePhase(proId) {
            const reqDatas = {
                project_id: proId
            }
            const response = await Service.GetProjectPhase(reqDatas);
            if (response.code == "200") {
                this.phases = response.data;       
                console.log(this.phases);   
            }
        },

        async getEmployees() {
            const response = await Service.GetEmployees();
            if (response.code == '200') {
                //   console.log(response.data);
                this.employees = response.data;

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        onCustomStyleSubmit(evt) {
            evt.preventDefault();
            if (this.name == '' || this.description == null || this.phase == null) {
                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                this.saveModules();
            }
        },
        saveModules() {
            // console.log(this.selected_employees);
            const modules_data = {
                name: this.name,
                description: this.description,
                // project_id: this.$route.params.id,
                selected_employees: this.selected_employees,
                phase: this.phase.id,
            };
            this.addModules(modules_data)
        },
        async addModules(modules_data) {
            this.loader = true;
            const response = await Service.AddModules(modules_data);
            if (response.code == '200') {
                this.loader = false;
                this.$toasted.show(' Modules added successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$emit('update:modulesId', 4);
            } else if (response.code == "400") {
                this.loader = false;
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
        },



        // addExtra(input, team, index) {
        //     if (this.extraEmp.length > 0) {
        //         this.extraEmp.forEach(cntnt => {
        //             if (cntnt.ind == index) {
        //                 this.extraEmp[index] = { 'ind': index, 'team': team, 'extra': input };
        //                 this.employees = this.employees.filter(x => !input.includes(x));
        //             } else {
        //                 this.extraEmp[index] = { 'ind': index, 'team': team, 'extra': input };
        //                 this.employees = this.employees.filter(x => !input.includes(x));
        //             }
        //         });
        //     } else {
        //         this.extraEmp[index] = { 'ind': index, 'team': team, 'extra': input };
        //         this.employees = this.employees.filter(x => !input.includes(x));
        //     }
        // },



        // async getTasks() {
        //     const response = await Service.GetTaskType();
        //     if (response.code == '200') {
        //         this.taskType = response.data;
        //     }
        // },

        // async getUsers() {
        //     var pro_id = this.$route.params.id;
        //     const reqData = {
        //         id: pro_id,
        //     }
        //     const response = await Service.GetModulesDetails(reqData);
        //     if (response.code == '200') {
        //         this.title = response.data.all.title;
        //         this.description = response.data.all.description;
        //         this.currUser = this.loginData.id;
        //         if (this.pManager == 'MANAGER') {
        //             this.employees = response.data.all.members;
        //         } else {
        //             response.data.all.allMem.forEach(element => {
        //                 element.mem.forEach(elem => {
        //                     if ((elem.user_id == this.currUser) && (elem.role == 1)) {
        //                         this.employees = element.mem;
        //                     }
        //                 });
        //             });
        //             if(this.employees.length == 0){
        //                 this.employees = [
        //                     { user_id: this.currUser, user: this.loginData.name },
        //                 ];
        //             }
        //         }
        //     }
        // },
    }
};
</script>
<style>
.ck-content {
    height: 150px;
}
</style>