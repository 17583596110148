<template>
    <div>

        <!-- {{ id }} -->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <b-col xl="3">
                                    <datepicker :minimumView="'month'" input-class="datepicker-here form-control digits"
                                        v-model="date" ></datepicker>
                                </b-col>
                                <b-col xl="3">
                                    <b-button id="default-primary" variant="primary" @click="Generate()">
                                        Generate
                                    </b-button>
                                </b-col>
                            </b-row>


                        </div>

                    </div>

                    <div class="card">
                        <div class="card-body">
                            

                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                </b-table>
                            </div>
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>
                        </div>

                    </div>
                </div>

            </div>
        </div>


    </div>
</template>
  
<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'
import Service from '@/services/Service.js';
export default {
    name: 'app',
    components: {
        Datepicker
    },
    data() {
        return {
            date:  moment('01-'+moment().format('MMM-Y')).format('Y-MM-DD') ,
            tablefields: [
                { key: 'date', label: 'Date', sortable: true, },
                { key: 'time', label: 'Time', sortable: true, },
                { key: 'reason', label: 'Reason', sortable: true, },
            ],

            items: [],
            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
        };
    },
    props: ['id'],
    mounted() {

       var  req ={

        id:this.id,
        date:   moment('01-'+moment().format('MMM-Y')).format('Y-MM-DD') 

       }
        this.getLateComeing(req)
    },
    methods: {
        customFormatter(date) {
            // return moment(date).startOf('month');
        },
        Generate(){
            var  req ={

            id:this.id,
            date:moment(this.date).format('Y-MM-DD') 

            }
this.getLateComeing(req)
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getLateComeing(req) {
            console.log(req);
            const response = await Service.getLateComeingApi(req);
            if (response.code =='200') {
                
                this.items =  response.data;
                this.totalRows = response.data.length;
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
    }

}
</script>
  
<style lang="scss" scoped></style>
  