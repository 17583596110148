<template>
    <div>

        <br>
        <Breadcrumbs main="Report" title="Attendance report" />
        <!-- {{ id }} -->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <b-col xl="3">
                                    <datepicker :minimumView="'month'" input-class="datepicker-here form-control digits"
                                        v-model="date"></datepicker>
                                </b-col>
                                <b-col xl="3">
                                    <v-select v-model="to_users" label="name" placeholder="Users" :options="users"
                                        :multiple="true" />
                                </b-col>
                                <b-col xl="2" class="load-style">
                                    <b-button id="default-primary" v-if="!isLoading" variant="primary" @click="Generate()">
                                        View
                                    </b-button>
                                    <div class="loader-box " v-else>
                                        <div class="loader-15"></div>
                                    </div>
                                </b-col>

                                <b-col xl="2" v-if="downFlag" class="load-style2">

                                    <b-button id="default-primary" variant="outline-primary" @click="downloadExcel"
                                        v-if="!isDLoading" class="pull-right">
                                        Download
                                    </b-button>
                                    <div class="loader-box " v-else>
                                        <div class="loader-15"></div>
                                    </div>

                                </b-col>
                                <b-col xl="2" v-if="downFlag && !is_approve">
                                    <b-button id="default-primary" variant="outline-success" @click="approveAttendance">
                                        Approve
                                    </b-button>
                                </b-col>
                                <b-col xl="2" v-if="is_approve">
                                    <h4 style="width: max-content;">
                                        <i class="fa fa-check-circle app-color"> Approved</i>
                                    </h4>
                                </b-col>
                            </b-row>

                            <!-- here -->
                            <div class="table-responsive mt25 table-container" id="datatable">
                                <table class="table table-condensed table-bordered table-striped" id="datatable-content">
                                    <thead class="datatable-head" id="datatable-head">
                                        <tr>
                                            <th class="pinned bold-font">
                                                <center> User </center>
                                            </th>
                                            <th v-for="value1 in heading">
                                                <center class="bold-font"> {{ value1.key }}</center>
                                                <center>
                                        <tr v-if="value1.day != null">
                                            <th class="bold-font">{{ value1.day }}</th>
                                        </tr>
                                        </center>
                                        </th>
                                        </tr>
                                    </thead>

                                    <tbody class="datatable-body" id="datatable-body">
                                        <tr v-for="value3 in reportData">
                                            <td class="pinned bold-font">
                                                <center> {{ value3.user }}</center>
                                            </td>
                                            <td v-for="value2 in value3.day_arr">
                                                <center class="item-fill">
                                                    {{ value2.value }}
                                                </center>

                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>


    </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import Swal from "sweetalert2";

export default {
    name: 'app',
    components: {
        Datepicker,
        vSelect
    },
    data() {
        return {
            date: moment('01-' + moment().format('MMM-Y')).format('Y-MM-DD'),
            // tablefields: [
            //     { key: 'user', label: 'Employee', sortable: true, },
            //     { key: 'leave_type', label: 'Leave type', sortable: true, },
            //     { key: 'no_leaves', label: 'Number of leaves', sortable: true, },
            //     { key: 'balance', label: 'Balance', sortable: true, },
            //     { key: 'remaining', label: 'Remaining Leaves', sortable: true, },
            // ],

            items: [],
            filter: null,
            downFlag: false,
            to_users: null,
            users: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            isLoading: false,
            isDLoading: false,
            fullPage: true,
            heading: [],
            reportData: [],
            paramMonth: null,
            is_approve: false,
            attendance_params: []
        };
    },
    mounted() {
        this.retrieveEmployees();

    },
    methods: {
        approveAttendance() {
            Swal.fire({
                title: "Are you sure?",
                text: "Are you sure you want to approve this Attendance! You cannot revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Approve!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.approveMonthlyAttendance();
                }
            });
        },
        async approveMonthlyAttendance() {
            // console.log(this.reportData);
            this.attendance_params = []
            this.reportData.forEach(element => {
                element.day_arr.forEach(element1 => {
                    if (element1.key == 'T.LOP') {
                        this.attendance_params.push({
                            user_name: element.user,
                            user_id: element.user_id,
                            attendance: element.day_arr[element.day_arr.length - 1].value,
                            lop: element1.value
                        });
                    }
                });
            });
            var tot_working_days = {
                month: this.paramMonth,
                work_days: this.attendance_params
            }
            // console.log(tot_working_days);
            const response = await Service.ApproveMonthlyAttendance(tot_working_days);
            if (response.code == "200") {
                // console.log(response.data)
                this.is_approve = true
                Swal.fire("Approved!", "Attendance has been approved.", "success")
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        downloadExcel() {
            this.isDLoading = true;
            var req = {
                'json_data': this.reportData,
                'month': this.paramMonth
            }
            this.downloadAttendance(req);
            // window.location.href = this.items;
        },
        async downloadAttendance(data) {
            const response = await Service.DownloadAttendanceReport(data);
            if (response.code == '200') {
                this.isDLoading = false;
                // window.location.href = response.data;
                window.open(response.data, '_blank');
            }
            this.isDLoading = false;
        },
        // getRemaining(userIndex, leaveIndex) {
        //     let count = this.items[userIndex].leave[leaveIndex].count;
        //     let remaining = this.items[userIndex].leave[leaveIndex].remaining;
        //     return parseFloat(remaining) + parseFloat(count);
        // },
        async retrieveEmployees() {
            const response = await Service.GetEmployeesForReport();
            if (response.code == "200") {
                this.users = response.data

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        Generate() {
            this.is_approve = false;
            this.isLoading = true;
            var users;

            if (this.to_users == null) {
                users = [];
            } else {
                users = this.to_users;
            }
            var req = {

                users: users,
                month: moment(this.date).format('Y-MM')

            }
            this.getAttendanceReport(req)
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getAttendanceReport(req) {
            this.downFlag = false;
            const response = await Service.GetAttendanceReport(req);
            if (response.code == '200') {
                this.isLoading = false;
                // this.items = response.data.file;
                this.reportData = response.data.data;
                this.paramMonth = response.data.month;
                this.is_approve = response.data.is_approve;
                // console.log(this.is_approve);
                this.downFlag = true;
                this.totalRows = response.data.length;
                this.heading = []
                response.data.data[0].day_arr.forEach(element => {
                    this.heading.push({
                        'day': element.day ? element.day : null,
                        'key': element.key
                    })
                });
                // console.log(this.heading);
            } else if (response.code == "403") {
                this.isLoading = false;
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
            this.isLoading = false;
        },
    }

}
</script>
<style>
.load-style .loader-box {

    height: 38px;
    text-align: left !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: left !important;
    -ms-flex-align: left !important;
    align-items: left !important;
    vertical-align: left !important;
    -webkit-box-pack: left !important;
    -ms-flex-pack: left !important;
    justify-content: left !important;
    -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;

}

.load-style2 .loader-box {

    height: 38px;
    text-align: right !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: right !important;
    -ms-flex-align: right !important;
    align-items: right !important;
    vertical-align: right !important;
    -webkit-box-pack: right !important;
    -ms-flex-pack: right !important;
    justify-content: right !important;
    -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;

}

.bold-font {
    font-weight: bold;
}

.item-fill {
    width: max-content;
}
.app-color{
    color: #00e99b;
}
</style>