<template>
    <!-- <v-app> -->
    <div>
        <Breadcrumbs main="Attendance" title="Attendance list" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">


                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">

                            <b-row>
                               
                                <!-- <b-col xl="3" class="load-style" v-if="datalayout.designation.level.key == 'HR'">
                                    <b-button id="default-primary" variant="primary" @click="GetPinPage()">
                                        Generate
                                    </b-button>
                                </b-col> -->
                            </b-row>
                            <b-row>
                                
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>
                               
                                <b-col xl="6">
                                    
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">

                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <!-- <template #cell(status)="rows">
                                        <b-badge :variant="statusVariant(rows.item.status)">
                                           
                                            <span v-if="rows.item.status == 1">
                                                Active
                                            </span>
                                            <span v-else>
                                                InActive
                                            </span>
                                        </b-badge>
                                    </template> -->

                                    <template #cell(punch_in)="rows">
                                        <span>
                                            {{ rows.item.punch_in | moment("DD-MM-YYYY H:m:s") }}
                                            <!-- {{ rows.item.punch_in ? rows.item.punch_in.split("T")[0] : "" }} {{
                                                rows.item.punch_in ? rows.item.punch_in.split("T")[1] : ""
                                            }} -->
                                        </span>
                                    </template>
                                    <template #cell(punch_out)="rows">
                                        <span>
                                            {{ rows.item.punch_out | moment("DD-MM-YYYY H:m:s") }}
                                            <!-- {{ rows.item.punch_out ? rows.item.punch_out.split("T")[0] : "" }} {{
                                                rows.item.punch_out ? rows.item.punch_out.split("T")[1] : ""
                                            }} -->

                                        </span>
                                    </template>
                                    <template #cell(date)="rows">
                                        <span>
                                            {{ rows.item.date | moment("DD-MM-YYYY") }}
                                            <!-- {{ rows.item.punch_out ? rows.item.punch_out.split("T")[0] : "" }} {{
                                                rows.item.punch_out ? rows.item.punch_out.split("T")[1] : ""
                                            }} -->

                                        </span>
                                    </template>
                                    <template #cell(actions)="row">

                                        <span v-if="row.item.status == 5 && row.item.punch_out == null"
                                            @click="$bvModal.show('bv-modal-edit')"
                                            v-on:click="getId(row.item.id, row.item.punch_in, row.item.create_at)">

                                            <feather type="edit" title="Edit"></feather>

                                        </span>


                                    </template>

                                </b-table>


                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>





                        </div>

                        <v-app>
                            <b-modal id="bv-modal-edit" centered size="lg" hide-footer>
                                <template #modal-title>
                                    Edit Punching Details ( Date : {{create}} )
                                </template>
                                <div class="modal-body">
                                    <b-form class="needs-validation">

                                        <div class="form-group" v-if="this.punchIn != null">
                                            <label class="form-label">Punch In</label>
                                            <b-form-input type="text" v-model="this.punchIn" readonly>
                                            </b-form-input>

                                        </div>
                                        <div class="form-group" v-if="this.punchIn == null">
                                            <label class="form-label">Punch In</label>
                                            <br>
                                            <date-picker v-model="punch_in"  valueType="format" type="datetime"></date-picker>

                                            <!-- <datetime format="YYYY-MM-DD H:i:s" v-model='punch_in'></datetime> -->

                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Punch Out</label>
                                            <br>
                                            <date-picker v-model="punch_out"  valueType="format" type="datetime"></date-picker>
                                            <!-- <datetime format="YYYY-MM-DD H:i:s" v-model='punch_out'></datetime> -->

                                        </div>


                                        <input id="index_var" type="hidden" value="6" />

                                        <button class="btn btn-secondary" id="Bookmark" type="button"
                                            @click="onPunchSubmit()">
                                            Save
                                        </button>



                                    </b-form>
                                </div>
                            </b-modal>
                        </v-app>

                    </div>
                </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
    <!-- </v-app> -->
</template>

<script>
import ServiceApi from '@/services/ServiceApi.js';
import Datetime from 'vuejs-datetimepicker';
import moment from 'moment'

// import Vue from 'vue'
// import DatetimePicker from 'vuetify-datetime-picker'
// Vue.use(DatetimePicker)
export default {
    components: {

        Datetime,
    },

    data() {
        return {
            tablefields: [
                { key: 'name', label: 'Name', sortable: true, },
                { key: 'emp_id', label: 'Employee ID', sortable: true, },
                { key: 'punch_in', label: 'Punch In', sortable: true, },
                { key: 'punch_out', label: 'Punch Out', sortable: true, },
                { key: 'date', label: 'Date', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],

            items: [],
            punchId: null,
            punchIn: null,
            filter: null,
            punch_in: null,
            punch_out: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            datalayout: JSON.parse(localStorage.getItem('loginDetails')),
            create:null
        };
    },
    computed: {

        statusVariant() {
            const statusColor = {
                1: "light-success",
                0: "light-danger",
            };

            return (status) => statusColor[status];
        },

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        }
    },
    mounted() {
        // Set the initial number of items
        this.retrieveAttendance();

    },
    methods: {
        GetPinPage(){
            this.$router.push('/home/verify-passcode');
        },
        async retrieveAttendance() {
            const response = await ServiceApi.GetAttendance();
            if (response.code == "200") {
                this.items = response.data;
                this.totalRows = response.data.length;
            }
            else if (response.code == "400") {
                this.items = [];
            }

            else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },

        async editAttendance(credentials) {

            const response = await ServiceApi.UpdateAttendance(credentials);

            if (response.code == "200") {
                this.$toasted.show("Attendance updated successfully", {
                    theme: "outline",
                    position: "top-right",
                    type: "success",
                    duration: 2000,
                });
                this.retrieveAttendance();
            }
            else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },

        getId(id, punchIn, create_at) {
            this.punchId = id;
            this.create = moment(create_at).format('YYYY-MM-DD');

            if (punchIn != null) {
                // this.punchIn = punchIn.split("T")[0] + ' ' + punchIn.split("T")[1]
                 this.punchIn =  this.punch_in = moment(punchIn).format('YYYY-MM-DD H:m:s');

            }else{

                this.punch_in = null;
                this.punchIn = null;
                this.punch_out =null;

            }

        },
        onPunchSubmit() {

           console.log(this.punch_in);
           console.log(this.punch_out);

            if (this.punch_out == null) {

                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });


            }else if (this.punch_in == null) {


                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });


            }else if (this.create != this.punch_out.split(' ')[0]) {
                
                this.$toasted.show('Punch-in  date are not the same as the generated record', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });



            }else if (this.create != this.punch_in.split(' ')[0]) {
               
                
                this.$toasted.show('punch-out date are not the same as the generated record', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });



            }else{


            if (this.punch_in == null) {
                var punchin = this.punchIn;
            }
            else {
                var punchin = this.punch_in;
            }
            if (punchin.split(' ')[0] == this.punch_out.split(' ')[0]) {

                var PunchInDatetime = new Date(punchin);
                var PunchOutDatetime = new Date(this.punch_out);

                if (PunchInDatetime < PunchOutDatetime) {

                    const credentials = {
                        id: this.punchId,
                        punch_in: punchin,
                        punch_out: this.punch_out,
                    };

                    this.editAttendance(credentials);
                    this.$bvModal.hide("bv-modal-edit");
                    this.punchId = null;
                    this.punch_in = null;
                    this.punch_out = null;
                } else {
                    this.$toasted.show('Punch-out time should be greater than punch-in time', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                }
            }else{
                this.$toasted.show('Punch-in date and punch-out date are not same', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
        }

        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

    }
};
</script>
<style>
.mx-datepicker {
    position: relative;
    display: inline-block;
    width: 100% !important;
}

</style>