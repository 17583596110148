<template>
    <div>
        <Breadcrumbs main="Projects" title="Edit Task Type" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_name">Name</label>
                                        <b-form-input type="text" id="c_form_name" v-model="nameValue"
                                            :state="c_form_result.name" placeholder="Name"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.name">
                                        </b-form-valid-feedback>
                                    </div>
                                  
                                </div>
                            
                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';

export default {
    components: {
   
    },
    data() {
        return {
          
            nameValue:null,
            success:false,
            c_form: {
                name: '',
              
            },
            c_form_result: {
                name: null,
               
            },


        };
    },
    computed: {
   

    },
    mounted() {
       
        this.getTaskTypeById()
        
    },
    methods: {

        onCustomStyleSubmit(evt) {
            evt.preventDefault();

            if (this.nameValue !== '') {
                this.success = true;
                this.c_form_result.name = true;
            } else {
                this.c_form_result.name = false;
                this.success = false;
            }

            this.saveTaskType();
          
        },

        saveTaskType() {

            if (this.success == true) {

                const dept_data = {
                    id: this.$route.params.id,
                    name: this.nameValue,
                    
                }
                
                this.updateTaskType(dept_data)
            }
        },
       
        async updateTaskType(dept_data) {
            const response = await Service.UpdateTaskType(dept_data);
            if (response.code == '200') {
                this.$toasted.show(' Department updated successfully ', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/task-types');
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getTaskTypeById(){
            const ReqData = {
                 id: this.$route.params.id,
            };
            const response = await Service.GetTaskTypeById(ReqData);

            if (response.code == '200') {

                this.nameValue = response.data.name;
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }

        }

    }
};
</script>
<style lang="scss">
@import "vue-select/dist/vue-select.css";
</style>