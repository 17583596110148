<template>
    <div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="user-profile">
                <div class="row">
                    <!-- user profile first-style start-->
                    <div class="col-sm-12">
                        <br /><br />
                        <div class="card hovercard text-center">
                            <div class="user-image">
                                <!-- <div class="avatar"><img alt="" src="../../assets/images/user/7.jpg"></div> -->
                            </div>
                            <div class="info">
                                <div class="row">


                                    <div class="col-md-6">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-list-alt"></i>Title</h6>
                                            <span> {{ this.title }} </span>
                                        </div>
                                    </div>


                                    <div class="col-md-6">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-list-alt"></i>Description</h6>
                                            <span> {{ this.description }} </span>
                                        </div>
                                    </div>


                                    <!-- <div class="col-md-6">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-list-alt"></i>Project Name</h6>
                                            <span> {{ this.project_name }} </span>
                                        </div>
                                    </div> -->

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Service from '@/services/Service.js';
import vSelect from 'vue-select';
import { VueEditor } from 'vue2-editor';
import Swal from "sweetalert2";

export default {
    components: {
        vSelect,
        VueEditor
    },
    data() {
        return {
            title: '',
            description: '',
            filter: null,
            // totalRows: 1,
            // currentPage: 1,
            // perPage: 10,
            // pageOptions: [10, 25, 50],
            items: [],
            loader:false,
        };
    },
    props: ['phaseId','projectPhaseId'],
    mounted() {        
        this.retrieveViewPhase();
    },
    methods: {
        async retrieveViewPhase() {
            // console.log(this.projectPhaseId);
            const ViewReqData = {
                id: this.projectPhaseId,
            };
            const response = await Service.GetViewPhaseById(ViewReqData);
            // console.log(response);   
            if (response.code == "200") {
                this.title = response.data.title;
                this.description = response.data.description;
            }
        },
    }
};

</script>
<style>
.p-dot {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

ul {
    padding-left: 0px;
    list-style-type: unset;
    margin-bottom: 0;
}
</style>