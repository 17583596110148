<template>
    <div><br>
        <Breadcrumbs main="Onsite" title="Apply Onsite" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onFormSubmit">
                                <div class="form-row">
                                    <div class="row">
                                        <!-- <div class="col-md-4">
                                            <label for="c_form_departments">Type</label>
                                            <v-select v-model="onsiteOrOvertime" label="name" :options="onsiteOverTime"
                                                @input="showOrHideTask()" />
                                        </div> -->
                                        <div class="col-md-4">
                                            <label for="c_form_departments">Employee</label>
                                            <v-select v-model="employee" label="name" :options="employees"
                                                @input="getProjects()" />
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="c_form_departments">Project</label>
                                                <v-select v-model="project" label="project_name" :options="empProjects" />
                                            </div>
                                        </div>
                                    </div>
                                </div><br>
                                <div class="form-row">
                                    <div class="row">
                                        <!-- <div class="col-md-10">
                                            <label for="c_form_departments">Employee</label>
                                            <v-select
                                                v-model="employee"
                                                label="name"
                                                :options="employees"
                                            />
                                        </div> -->
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-label">Start date and time</label>
                                                <br>
                                                <!-- <datetime format="YYYY-MM-DD H:i:s" v-model='start_date'></datetime> -->
                                                <date-picker v-model="start_date"  valueType="format" type="datetime"></date-picker>
                                                <!-- <datepicker :format="customFormatter"
                                                    input-class="datepicker-here form-control digits" v-model="start_date">
                                                </datepicker> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-label">End date and time</label>
                                                <br>
                                                <!-- <datetime format="YYYY-MM-DD H:i:s" v-model='end_date'></datetime> -->
                                                <date-picker  v-model="end_date"  valueType="format" type="datetime"></date-picker>
                                                
                                                <!-- <datepicker :format="customFormatter"
                                                    input-class="datepicker-here form-control digits" v-model="end_date">
                                                </datepicker> -->

                                            </div>
                                        </div>
                                        <!-- <div class="col-md-4" v-if="hideTask">
                                            <div class="form-group">
                                                <label for="c_form_departments">Task</label>
                                                <v-select v-model="task" label="name" :options="empTasks" />
                                            </div>
                                        </div> -->

                                    </div>
                                    <!-- <div class="col-md-6">
                                        <label class="form-label">Reason</label>
                                        <textarea class="form-control" rows="4" placeholder="Enter leave reason" v-model="reason">
                                        </textarea>
                                    </div> -->
                                </div>

                                <!-- <div class="form-row">
                                    <div class="row">



                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-label">End date and time</label>
                                                <datepicker :format="customFormatter"
                                                    input-class="datepicker-here form-control digits" v-model="end_date">
                                                </datepicker>

                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-label">Session</label>
                                                
                                                <v-select v-model="session2" label="name" :options="sessions" />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                        </div>
                                    </div>
                                   
                                </div> -->

                                <div class="form-row">
                                    <div class="col-md-12">
                                        <label class="form-label">Reason</label>
                                        <textarea class="form-control" rows="3" placeholder="Enter reason" v-model="reason">
                                        </textarea>
                                    </div>
                                </div><br>
                                <b-button v-if="loader == false" type="submit" variant="primary">Submit</b-button>

                                <div v-if="loader == true" class="loader-box">
                                    <div class="loader-15"></div>
                                </div>

                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from "@/services/ServiceApi.js";
import vSelect from 'vue-select'
import Datetime from 'vuejs-datetimepicker';
import moment from 'moment'
import S3 from "aws-s3";

export default {

    components: {
        vSelect,
        Datetime,
        
    },

    data() {
        return {
            disabledDates: {
                to: new Date(Date.now() - 8640000)
            },
            loader: false,
            success: false,
            file: null,
            file_name: null,
            reason: null,
            start_date: new Date(),
            end_date: new Date(),
            holidays: [],
            holidays_arr: [],
            duration: null,
            sessions: [
            ],
            session1: null,
            session2: null,
            emp_ref_no: null,
            order_arr: [],
            employee: null,
            employees: [],
            // hideTask: false,
            empProjects: [],
            project: null,
            // empTasks: [],
            // task: null,
            // onsiteOrOvertime: {
            //     "name": "Onsite",
            //     "id": "1",
            // },
            // onsiteOverTime: [
            //     {
            //         "name": "Onsite",
            //         "id": "1",
            //     },
            //     {
            //         "name": "Overtime",
            //         "id": "2"
            //     }
            // ]
        };
    },
    mounted() {
        // this.getSessions();
        // this.getHolidays();
        this.getEmployees();
    },
    methods: {

        async getProjects() {
            var req = {
                id: this.employee.id
            }
            const response = await ServiceApi.GetProjectsByUser(req);
            if (response.code == "200") {
                var resultArr = [];
                response.data.forEach(element => {
                    if (element.status === 1){
                        resultArr.push(element)
                    }
                });
                this.empProjects = resultArr;
            }
        },
        // async getTasks() {
        //     var req = {
        //         project: this.project.id
        //     }
        //     const response = await Service.getTasksByProjects(req);
        //     if (response.code == "200") {
        //         this.empTasks = response.data;
        //     }
        // },
        async getEmployees() {
            const response = await Service.GetEmployees();
            if (response.code == '200') {
                this.employees = response.data;
            }
        },

        // customFormatter(date) {
        //     return moment(date).format('Y-MM-DD HH:MM:SS');
        // },

        onFormSubmit(evt) {
            evt.preventDefault();

        

            if ((this.employee == null) || (this.reason == null) || (this.start_date == null) || (this.end_date == null)) {
                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else if (this.end_date.split(' ')[0] != this.start_date.split(' ')[0]) {
                this.$toasted.show(' From Date and To Date should be same ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else if(this.end_date.split(' ')[1] <= this.start_date.split(' ')[1]){
                this.$toasted.show(' End time should be greater than start time ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }else {
                this.addOnsite();

            }

        },

        async addOnsite() {

            const leave_data = {
                employee_id: this.employee.id,
                reason: this.reason,
                from_date: this.start_date,
                to_date: this.end_date,
                project: this.project ? this.project.id : null,
                type: 1
            }
            
            this.loader = true;
            const response = await ServiceApi.ApplyOnsite(leave_data);
            if (response.code == '200') {
                this.loader = false;
                this.$toasted.show(' Request applied successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                // this.$router.push('/home/employee-view/' + this.$route.params.id);
                this.$router.push('/home/onsite/');

            } else if (response.code == '201') {
                this.loader = false;
                this.$toasted.show(response.message + ' ' + response.data, { theme: 'outline', position: 'top-right', type: 'error', duration: 3000 });
            }


        },

    }
};
</script>

<style>
.loader-box {
    height: 50px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;
}

.scroll-hider ul[data-v-4bd11526] {
    width: 58px !important;
}
</style>
