<template>
    <div>
        <Breadcrumbs main="Companies" title="Company" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">


                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">
                            <b-row>
                                <b-col xl="12">
                                    <router-link :to="'/home/company-add/'">
                                        <b-button id="default-primary" variant="primary" >Add
                                            Company
                                        </b-button>
                                    </router-link>
                                    <!-- <b-tooltip target="default-primary" triggers="hover" placement="top">btn btn-primary</b-tooltip> -->
                                </b-col>
                            </b-row><br>
                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(status)="rows">
                                        <b-badge :variant="statusVariant(rows.item.status)">

                                            <span v-if="rows.item.status == 1">
                                                Active
                                            </span>
                                            <span v-else>
                                                InActive
                                            </span>
                                        </b-badge>
                                    </template>
                                    <template #cell(actions)="row">
                                        <span>
                                            <router-link :to="'/home/company-edit/' + row.item.company_id">
                                                <feather type="edit"></feather>
                                            </router-link>
                                        </span>
                                        <span v-if="row.item.status == 1">
                                            <feather type="thumbs-down" @click="deleteComp(row.item)"></feather>
                                        </span>
                                        <span v-else>
                                            <feather type="thumbs-up" @click="deleteComp(row.item)"></feather>
                                        </span>
                                        <span v-if="row.item.company_token">
                                            <!-- <feather type="key"   @click="$bvModal.show('bv-modal-token')" title="Company Token"></feather> -->
                                            <feather type="key"    @click="showTokenModal(row.item.company_token)" title="Company Token"></feather>

                                           
                                        </span>


                                    </template>
                                    <template #cell(logo_name)="rows">
                                        <img v-if="rows.item.logo_name" :src="rows.item.logo_name" style="height:40px; width:140px"/>
                                    </template>
                                </b-table>
                            </div>

                            <b-modal id="bv-modal-token" centered size="md" hide-footer v-model="showModal" @hide="resetModal">
                                <template #modal-title>
                                 Company Token
                                </template>
                                <div class="modal-body">
                                  <b-form class="needs-validation">


                                    <div class="form-row">
                                        <div class="token-modal-content">
                                            <div class="token-text">{{ modalCompanyToken }}</div>
                                        </div>

                                     
                                    </div>
                                   
                                  </b-form>
                                </div>
                              </b-modal>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>


                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import Swal from "sweetalert2";

export default {
    data() {
        return {
            tablefields: [
                { key: 'name', label: 'Name', sortable: true, },
                { key: 'logo_name', label: 'Logo', sortable: true, },
                { key: 'address', label: 'Address', sortable: true, },
                { key: 'email', label: 'Email', sortable: true, },
                { key: 'phone', label: 'Phone', sortable: true, },
                { key: 'website_link', label: 'Website Link', sortable: true, },
                { key: 'status', label: 'Status', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],

            items: [],
            showModal: false,
            modalCompanyToken: '',
            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
        };
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-success",
                0: "light-danger",
            };

            return (status) => statusColor[status];
        },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        }
    },
    mounted() {
        // Set the initial number of items
        this.retrieveCompanies();
    },
    methods: {
        // urlEncryption(id){
        //    var d = CryptoJS.AES.encrypt(""+id+"", 'qcm').toString().replace('/','Por21Ld');
        //    d.replace('Por21Ld','/')
        //    var bytes    = CryptoJS.AES.decrypt(d, 'qcm');
        //    var originalText = bytes.toString(CryptoJS.enc.Utf8);

        //     console.log(originalText); 
        // },
        async retrieveCompanies() {
            const response = await Service.GetCompanies();
            if (response.code == "200") {
                this.items = response.data;
                this.totalRows = response.data.length;
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        deleteComp(data) {
            if (data.status == 1) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to disable this Company!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, disable!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const credentials = {
                            status:data.status,
                            id: data.company_id,
                        };
                        var done = this.enableOrDisableCompany(credentials);
                        if(done){
                            Swal.fire("Disabled!", "Company has been disabled.", "success")
                            this.retrieveCompanies();
                        }
                    }
                });
            } else if (data.status == 0) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to enable this Company!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, enable!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const credentials = {
                            status:data.status,
                            id: data.company_id,
                        };
                        var done = this.enableOrDisableCompany(credentials);
                        if(done){
                            Swal.fire("Disabled!", "Company has been enabled.", "success")
                            this.retrieveCompanies();
                        }
                    }
                });
            }
        },
        async enableOrDisableCompany(credentials){
            const response = await Service.EnableOrDisableComp(credentials);
            if (response.code == "200") {
                return true;
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }else{
                return false;
            }
        },
        showTokenModal(companyToken) {
        this.modalCompanyToken = companyToken;
        this.showModal = true;
        },
        resetModal() {
        this.modalCompanyToken = '';
        }
    }
};
</script>
<style>
.token-modal-content {
  max-width: 400px; /* Adjust the maximum width as needed */
  overflow: auto;
}

.token-text {
  text-overflow: ellipsis;
}
</style>