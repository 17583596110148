<template>
    <div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="title">Project Title</label>
                                        <b-form-input type="text" id="title" v-model="title" placeholder="Project Title">
                                        </b-form-input>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="description">Project Description</label>
                                        <b-form-input type="text" id="description" v-model="description" placeholder="Project Description">
                                        </b-form-input>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="status">Status</label>
                                        <v-select id="status" v-model="phaseStatus" label="text" :options="phases" />
                                    </div>

                                    
                                </div>
                                <div class="form-row">
                                </div>
                                <div class="form-row">


                                    <!-- <div class="col-md-6 mb-3">
                                        <label for="c_form_departments">Employee</label>
                                        <v-select
                                            v-model="employee"
                                            label="user"
                                            :options="employees" 
                                        />
                                    </div> -->
                                </div>
                                <b-button type="submit" variant="primary">Update</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import moment from "moment";
import { VueEditor } from 'vue2-editor';
export default {

    components: {
        vSelect,
        VueEditor
    },

    data() {
        return {
            // success: false,
            title: '',
            description: '',
            project_id: null,
            phaseStatus: [],
            phases: [
                {
                    value: 1, text: 'Ongoing'
                },
                {
                    value: 2, text: 'Completed'
                },
                {
                    value: 3, text: 'Hold'
                },
            ],
            // task: null,
            // startEnd: null,
            // startEndStatus: [],
            // employees: [],
            // employee: null,
            // chk_stat: null,
            // pSdate: null,
            // pEdate: null,
            // id_pro: null,
        };
    },
    props: ['phaseId','projectPhaseId'],
    computed: {

    },
    created() {
       this.getPhaseById();
    //    this.getPhaseStatus();
    },
    methods: {
        onCustomStyleSubmit(evt) {
            evt.preventDefault();
            if (this.title == '' || this.description == null) {
                // this.success = false;
                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                // this.success = true;
                this.savePhaseEdit();
            }

        },
        savePhaseEdit() {
            const update_data = {
                project_id: this.projectPhaseId,
                title: this.title,
                description: this.description,
                status: this.phaseStatus.value,
            };
            // console.log(this.phaseStatus.value);
            this.updatePhase(update_data)
        },
        async updatePhase(update_data) {
            const response = await Service.UpdatePhase(update_data);
            if (response.code == '200') {
                this.$toasted.show(' Project Phase updated successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$emit('update:phaseId', 4);
            } else if (response.code == "400") {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
        },

        //heading ----- phase status
        // async getPhaseStatus() {
        //     const response = await Service.GetPhaseStatus();
        //     if (response.code == '200') {
        //         this.phaseStatus = response.data;
        //     }
        // },

        async getPhaseById(){
            const reqDataPhase = {
                id: this.projectPhaseId,
            }
            const response = await Service.GetPhaseById(reqDataPhase);
            if (response.code =='200') {
                this.title = response.data.title;
                this.description = response.data.description;
                // this.phaseStatus = response.data.status;
                this.phases.forEach(element => {
                    // console.log(element);                    
                    if(element.value == response.data.status){
                        this.phaseStatus = element;
                    }
                });
            }
        },
        // async getTaskById(task_id) {
        //     const ReqData = {
        //         id: task_id,
        //     }
        //     const response = await Service.GetEmpTaskById(ReqData);
        //     if (response.code == '200') {

                // this.chk_stat = response.data.status;

                // if (response.data.status == '1') {
                //     this.startEnd = '1';
                //     this.startEndStatus = [{ value: '1', text: 'Pending' },
                //                             { value: '2', text: 'Start' },];
                // } else if (response.data.status == '2') {
                //     this.startEnd = '2';
                //     this.startEndStatus = [{ value: '2', text: 'Start' },
                //                             { value: '3', text: 'End' },];
                // } else if (response.data.status == '3') {
                //     this.startEnd = '3';
                //     this.startEndStatus = [{ value: '3', text: 'End' },];
                // }
                // this.name = response.data.name;
                // this.descrip = response.data.desc;
                // this.task = { name: response.data.type, id: response.data.type_id };
                // // this.employee = { name: response.data.member.project, id: response.data.project_id };
                // this.project_id_task = response.data.project_id;
                // this.curr_start_date = this.startDate = response.data.start_date ? response.data.start_date.split('T')[0] : null;
                // this.curr_end_date = this.endDate = response.data.end_date ? response.data.end_date.split('T')[0] : null;
                // this.pSdate = response.data.pStartDate;
                // this.pEdate = response.data.pEndDate;
                // this.getUsers(this.project_id_task);
                // this.getUserAssign(response.data.id);
            }
        }
//     }
// };
</script>
<style>
 .ck-content { height:150px; }
</style>