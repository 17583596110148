<template>
    <div>
        <Breadcrumbs main="Holidays" title="Holiday List" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">


                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">
                            <b-row>
                                <b-col xl="12">
                                    <b-button id="default-primary" variant="primary" @click="addHoliday">Add Holiday</b-button>
                                </b-col>
                            </b-row><br>
                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select ">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                              
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                   
                                    
                                    <template #cell(date)="rows">
                                    <span>
                                    {{ dateConvert(rows.item.date) }}
                                    </span>
                                    </template>

                                    <template #cell(status)="rows">
                                        <b-badge :variant="statusVariant(rows.item.status)">
                                           
                                            <span v-if="rows.item.status == 1">
                                                Active
                                            </span>
                                            <span v-else>
                                                InActive
                                            </span>
                                        </b-badge>
                                    </template>
                                    <template #cell(actions)="row">
                                        <span>
                                            <router-link :to="'/home/holiday-edit/'+row.item.id">
                                                <feather  type="edit"></feather>
                                                    </router-link>
                                        </span>
                                        
                                        <span v-if="row.item.status == 1">
                                            <feather type="thumbs-down" @click="deleteHoliday(row.item)"></feather>
                                        </span>
                                        <span v-else>
                                            <feather type="thumbs-up" @click="deleteHoliday(row.item)"></feather>
                                        </span>
                                       
                                    </template>
                                </b-table>
                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>


                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import ServiceApi from '@/services/ServiceApi.js';
import Service from '@/services/Service.js';

import Swal from "sweetalert2";

export default {
    data() {
        return {
            tablefields: [
                { key: 'name', label: 'Holiday', sortable: true, },
                { key: 'date', label: 'Date', sortable: true, },
                { key: 'year', label: 'Year', sortable: true, },
                { key: 'status', label: 'Status', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],

            items: [],

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
        };
    },
    computed: {

        statusVariant() {
            const statusColor = {
                1: "light-success",
                0: "light-danger",
            };

            return (status) => statusColor[status];
        },
       
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        }
    },
    mounted() {
        // Set the initial number of items
        this.retrieveHolidays();
        
        
    },
    methods: {
        dateConvert(dateString) {
			const date = new Date(dateString);

			const options = {
				day: 'numeric',
				month: 'short',
				year: 'numeric'
			};

			const formattedDate = date.toLocaleDateString('en-US', options);
			return formattedDate;

		},
        async retrieveHolidays(){
            const response = await ServiceApi.GetAllHolidays();
            if (response.code == "200") {
                this.items = response.data;
                this.totalRows = response.data.length;
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        addHoliday: function () {
            this.$router.push('/home/holiday-add');
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        deleteHoliday(data) {
            if (data.status == 1) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to disable this Holiday!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, disable!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const credentials = {
                            status:data.status,
                            id: data.id,
                        };
                        var done = this.enableOrDisableHoliday(credentials);
                        if(done){
                            Swal.fire("Disabled!", "Holiday has been disabled.", "success")
                            // window.location.reload()
                            this.retrieveHolidays();
                        }
                    }
                });
            } else if (data.status == 0) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to enable this Holiday!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, enable!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const credentials = {
                            status:data.status,
                            id: data.id,
                        };
                        var done = this.enableOrDisableHoliday(credentials);
                        if(done){
                            Swal.fire("Disabled!", "Holiday has been enabled.", "success")
                            // window.location.reload()
                            this.retrieveHolidays();
                        }
                    }
                });
            }
        },
        async enableOrDisableHoliday(credentials){
            const response = await ServiceApi.EnableOrDisableHoliday(credentials);
            if (response.code == "200") {
                return true;
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }else{
                return false;
            }
        }
    }
};
</script>
<style>
.per-page{
    padding: 18px 1px;
}
</style>