<template>
    <div><br>
        <Breadcrumbs main="Report" title="Monthly Report" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <b-col xl="3">
                                    <!-- <b-form-input type="month" value="2015-01" v-model="month"></b-form-input> -->
                                    <datepicker :minimumView="'month'" input-class="datepicker-here form-control digits"
                                        v-model="month"></datepicker>
                                </b-col>
                                <b-col xl="3">
                                    <v-select v-model="to_users" label="name" placeholder="Users" :options="users"
                                        :multiple="true" />
                                </b-col>
                                <b-col xl="3" class="load-style">
                                    <b-button id="default-primary" v-if="!isLoading" variant="primary" @click="Generate()">
                                        View
                                    </b-button>
                                    <div class="loader-box " v-else>
                                        <div class="loader-15"></div>
                                    </div>
                                </b-col>
                                <b-col xl="3" class="load-style1">

                                    <b-button v-if="!isLoading1" id="default-primary" variant="primary"
                                        @click="downloadExcel()" class="pull-right">
                                        Download
                                    </b-button>
                                    <div class="loader-box box12" v-else>
                                        <div class="loader-15"></div>
                                    </div>
                                </b-col>
                            </b-row>

                            <div class="table-responsive mt25 table-container" id="datatable">
                                <table class="table table-condensed table-bordered table-striped" id="datatable-content">
                                    <thead class="datatable-head" id="datatable-head">
                                        <tr>
                                            <th class="pinned">
                                                <center> User </center>
                                            </th>
                                            <th v-for="value1 in heading">
                                                <center> {{ value1.day }}</center>
                                                <tr
                                                    v-if="value1.day != 'Total Late Punch-in duration' && value1.day != 'Total Work Duration' && value1.day != 'Total OT Duration'&& value1.day != 'Total OD Duration' && value1.day != 'Total Early Punch-out duration'">
                                                    <th>Start Time</th>
                                                    <th>End Time</th>
                                                    <th>Work Duration</th>
                                                    <th>Late Punch-in Duration</th>
                                                    <th>Early Punch-out Duration</th>
                                                    <th>Overtime</th>
                                                    <th>Onsite</th>
                                                    <th>Leave Info</th>
                                                </tr>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody class="datatable-body" id="datatable-body">

                                        <tr v-for="value3 in result_arr">
                                            <td class="pinned">
                                                <center> {{ value3.user }}</center>
                                            </td>
                                            <td v-for="value2 in value3.day_arr">
                                                <div v-if="value2.time_details.length > 0">
                                                    <tr v-if="'end_time' in value2.time_details[0]"
                                                        @click="viewDetails(value3.user_id, value2.day)" v-b-modal.modal-3>
                                                        <td>{{
                                                            value2.time_details[0].start_time ? value2.time_details[0].start_time : ''
                                                        }}</td>
                                                        <td>{{
                                                            value2.time_details[0].end_time ? value2.time_details[0].end_time : ''
                                                        }}</td>
                                                        <td>{{ value2.time_details[0].value }}</td>
                                                        <td>{{ value2.time_details[0].late }}</td>
                                                        <td>{{ value2.time_details[0].eve_late }}</td>
                                                        <td>{{ value2.time_details[0].overtime_day }}</td>
                                                        <td>{{ value2.time_details[0].onsite_day }}</td>
                                                        <td>{{ value2.time_details[0].lInfo }}</td>
                                                    </tr>
                                                    <tr v-else>
                                                        <!-- <td></td>
                                                                    <td></td> -->
                                                        <td class="leave-col">{{ value2.time_details[0].start_time }}</td>
                                                    </tr>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <b-modal id="modal-3" v-model="modalShow" hide-footer>
            <div class="row">
                <h5 class="text-center" style="width: 100%;">Punching Activities</h5>
                <h5 class="text-center" style="width: 100%;">{{ curr_day }}</h5>
            </div>
            <div class="row padz">
                <table class="table table-condensed table-bordered table-wd table-striped" id="datatable-content"
                    v-if="activity.length > 0">
                    <thead class="datatable-head" id="datatable-head">
                        <tr>
                            <th>
                                IN Time
                            </th>
                            <th>
                                OUT Time
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                Reason
                            </th>
                        </tr>
                    </thead>
                    <tbody class="datatable-body" id="datatable-body">
                        <tr v-for="value3 in activity">
                            <td>
                                {{ value3.in_time ? value3.in_time : '' }}
                            </td>
                            <td>
                                {{ value3.out_time ? value3.out_time : '' }}
                            </td>
                            <td>
                                {{ getStatus(value3.status) }}
                            </td>
                            <td>
                                {{ value3.text }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-12">
                    <b-button class="mt-3 " variant="danger" @click="hideModal">Close</b-button>
                </div>
            </div>
        </b-modal>
    <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import Swal from "sweetalert2";
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';

export default {
    components: {
        vSelect,
        Datepicker
    },
    data() {
        return {
            month: "",
            result_arr: [],
            heading: [],
            users: [],
            activity: [],
            to_users: null,
            modalShow: false,
            curr_day: '',
            isLoading: false,
            link: null,
            isLoading1: false
        };
    },
    computed: {

    },
    mounted() {
        this.isLoading = true;
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        if (currentMonth / 10 >= 1) {
            var make_month = currentMonth;
        } else {
            var make_month = "0" + currentMonth;
        }
        this.month = currentYear + "-" + make_month;
        // console.log(this.month);
        this.generateReport();
        this.retrieveEmployees();
    },
    methods: {
        async downloadExcel() {
            this.isLoading1 = true;
            var users1;

            if (this.to_users == null) {
                users1 = [];
            } else {
                users1 = this.to_users;
            }

            const dateObj = new Date(this.month);
            const year = dateObj.getUTCFullYear();
            const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const formattedDate = `${year}-${month}`;
            this.month = formattedDate;

            const reqData = {
                month: this.month,
                users: users1
            }
            const response = await Service.DownloadReport(reqData);
            if (response.code == "200") {
                this.link = response.data.link
                // window.location.href = this.link;
                window.open(this.link, '_blank');
            } else if (response.code == "403") {
                this.isLoading1 = false;
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
            this.isLoading1 = false;
        },
        getStatus(status) {
            switch (status) {
                case 1:
                    return "IN";
                case 2:
                    return "OUT";
                case 3:
                    return "BREAK";
                case 4:
                    return "RESUME";
                case 5:
                    return "SYSTEM END";
            }
        },

        hideModal() {
            this.modalShow = false;
        },
        Generate() {
            this.isLoading = true;
            this.generateReport();
        },
        async generateReport() {
            var users;

            if (this.to_users == null) {
                users = [];
            } else {
                users = this.to_users;
            }

            const dateObj = new Date(this.month);
            const year = dateObj.getUTCFullYear();
            const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const formattedDate = `${year}-${month}`;
            this.month = formattedDate;

            const reqData = {
                month: this.month,
                users: users
            }
            const response = await Service.GetReports(reqData);
            if (response.code == "200") {
                this.isLoading = false;
                this.result_arr = response.data.data
                this.link = response.data.link
                this.heading = response.data.data[0].day_arr
            } else if (response.code == "403") {
                this.isLoading = false;
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
            this.isLoading = false;
        },
        getTimeFormate(time_sec) {
            var sec_num = time_sec
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours < 10) { hours = "0" + hours; }
            if (minutes < 10) { minutes = "0" + minutes; }
            if (seconds < 10) { seconds = "0" + seconds; }
            return hours + ':' + minutes + ':' + seconds;
        },
        async retrieveEmployees() {
            const response = await Service.GetEmployeesForReport();
            if (response.code == "200") {
                this.users = response.data

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async viewDetails(id, day) {
            this.curr_day = day;
            var req_data = {
                'user': id,
                'day': day
            }
            const response = await Service.GetActivity(req_data);
            if (response.code == "200") {

                this.activity = response.data;
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        // async savePDF() {
        //     var req_data = {
        //         file : this.$refs.html2Pdf
        //     }
        //     console.log(req_data);
        // }
    }
};
</script>
<style>
.table thead th {
    border: 1px solid #c9c9c9 !important;
}

/* 
.table-bordered td,
.table-bordered th {
    border-color: #c9c9c9 !important;
   
} */

.table-bordered td, .table-bordered th {
    border-color: #c9c9c9 !important;
    width: 640px !important;
}

.table-wd{
    border-color: #c9c9c9 !important;
    width: 640px !important;
}

.mt25 {
    margin-top: 25px;
}

.padz {
    padding: 2px 15px;
}

th.pinned,
td.pinned {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 1;
}

.table-container {
    height: 450px;
    overflow: auto;
}

.load-style .loader-box {

    height: 38px;
    text-align: left !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: left !important;
    -ms-flex-align: left !important;
    align-items: left !important;
    vertical-align: left !important;
    -webkit-box-pack: left !important;
    -ms-flex-pack: left !important;
    justify-content: left !important;
    -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;

}

.load-style1 .box12 {

    float: right;
    margin-right: 68px;
    height: 38px;
    text-align: left !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: left !important;
    -ms-flex-align: left !important;
    align-items: left !important;
    vertical-align: left !important;
    -webkit-box-pack: left !important;
    -ms-flex-pack: left !important;
    justify-content: left !important;
    -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;

}

.leave-col {
    background-color: antiquewhite;
    width: 640px !important;
    text-align: center;
}
</style>