<template>
    <div>
        <Breadcrumbs main="Designations" title="Edit Designation" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <label for="name">Designation</label>
                                        <b-form-input type="text" id="designation" v-model="name"
                                            :state="c_form_result.name" placeholder="Designation"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.name">
                                            </b-form-valid-feedback>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="c_form_departments">Department</label>
                                        <v-select
                                            v-model="departmentValue"
                                            label="name"
                                            :options="departments" 
                                        />
                                        <b-form-valid-feedback :state="c_form_result.department">
                                            </b-form-valid-feedback>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="c_form_departments">Level</label>
                                        <v-select
                                            v-model="level"
                                            label="name"
                                            :options="LevelArray" 
                                        />
                                       
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-12 mb-3">
                                        <label for="Permissions"><b>Permissions</b></label><br/>
                                        <ul>
                                            <li v-for="(item, index) in menuData">
                                                <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated"  :value="item.key" v-model="selectedMenus" :id="item.name" type="checkbox"> <b>{{item.name}}</b>
                                                </label>
                                                <ul :style="'padding-left:14px'">
                                                    <li v-for="(child, index_child) in item.children">
                                                        <label class="d-block" for="chk-ani">
                                                            <input class="checkbox_animated"  :value="child.key" v-model="selectedMenus" :id="child.name" type="checkbox"> {{child.name}}
                                                        </label>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_company">Company</label>
                                        <v-select
                                            v-model="companys"
                                            label="name"
                                            :options="companies"                       
                                        />
                                        <b-form-valid-feedback :state="c_form_result.company">
                                            </b-form-valid-feedback>
                                    </div>
                                </div> -->
                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from '@/services/ServiceApi.js';
import vSelect from 'vue-select'

export default {

    components: {
      vSelect,
    },

    data() {
        return {
            success: false,
            email: '',
            //companies: [],
            departments: [],
            departmentValue: null,
            //companys: {},
            name: '',
            
            c_form_result: {
                name: null,
                department: null,
                // level: null,
            },
            // LevelArray:[{id:'HR',name:"HR"},{id:'MR',name:"MANAGER"},{id:'EM',name:"EMPLOYEE"},{id:'MANAGEMENT',name:"MANAGEMENT"}],
            level: null,
            LevelArray:[],
            menuData: [],
            selectedMenus:['HOME']


        };
    },
    computed: {

    },
    mounted() {
        this.getDesignationById()
        //this.getCompanies();
        this.getDepartments();
        this.GetDesignationLevel();
        this.getMenuWithModules()
    },
    methods: {
        onCustomStyleSubmit(evt) {
            evt.preventDefault();
            // if (this.name !== '') {
            //     this.c_form_result.name = true;
            //     this.success = true;
            // } else {
            //     this.success = false;
            //     this.c_form_result.name = false;
            // }
            if(((this.name === "") || (this.name === null))){
                this.success = false;
                this.$toasted.show(' Please enter Designation ', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
            }

            else if(((this.departmentValue === "") || (this.departmentValue === null))){
                this.success = false;
                this.$toasted.show(' Please select Department ', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
            }else if(((this.level === "") || (this.level === null))){
                this.success = false;
                this.$toasted.show(' Please select Level ', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
            }else{
                this.success = true;
            }

            this.saveDesignation();
        },
        saveDesignation() {
            if (this.success === true) {
                const design_data = {
                    id: this.$route.params.id,
                    name: this.name,
                    department: this.departmentValue.department_id,
                    level: this.level.id,
                    access_permissions: this.selectedMenus,
                }
                this.updateDesignation(design_data);
            }
        },
        async updateDesignation(design_data) {
            const response = await Service.UpdateDesignation(design_data);
            if (response.code == '200') {
                this.$toasted.show(' Designation updated successfully ', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/designations');
            }
            else if (response.code == '400') {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        // async getCompanies() {
        //     const response = await Service.GetCompanies();
        //     if (response.code =='200') {
        //         this.companies =  response.data;
        //     }
        // },
        async getDepartments() {
            const response = await ServiceApi.GetDepartments();
            if (response.code =='200') {
                this.departments =  response.data;
                
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async GetDesignationLevel() {
            const response = await Service.GetDesignationLevels();
            if (response.code =='200') {
                this.LevelArray =  response.data;
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getDesignationById(){
            const ReqData = {
                 id: this.$route.params.id,
            };
            const response = await Service.GetDesignationById(ReqData);
            if (response.code =='200') {
                this.name = response.data.name;
                //this.companys = { name: response.data.company, company_id: response.data.company_id };
                this.departmentValue = { name: response.data.department, department_id: response.data.department_id };
                this.level = { name: response.data.level.key, id: response.data.level.id };
                this.selectedMenus = response.data.access_permissions
                // if (response.data.level == "HR") {

                //     this.level = {id:"HR",name:"HR"}
                    
                // }else if  (response.data.level == "MR") {

                //     this.level = {id:"MR",name:"MANAGER"}
                // }
                // else if  (response.data.level == "EM") {
                //     this.level = {id:"EM",name:"EMPLOYEE"}
                // }
                // else if  (response.data.level == "MANAGEMENT") {
                //     this.level = {id:"MANAGEMENT",name:"MANAGEMENT"}
                // }
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
    
        },
        async getMenuWithModules() {
            const response = await Service.getMenuWithModules();
            if (response.code == '200') {
                var menus = response.data;
                this.menuData = menus.filter(module => module.key !== "COMPANIES" && module.key !== "CONFIGURATIONS");
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        }
    }
};
</script>
