<template>
  <div>

    <Breadcrumbs main="Home" title="Home" />
    <div class="container-fluid" v-if="data.user_type != 3">
      <div class="row second-chart-list third-news-update">


      </div>
    </div>
    <div v-if="data.user_type == 3">
      <!-- Container-fluid starts-->
      <div class="container-fluid">
        <div class="row">
          <div class="snowflakes" aria-hidden="true" v-if="holiday ==  true">
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
</div>
          <div class="col-xl-3 col-lg-12 xl-35 morning-sec box-col-12">
            <div class="card total-users cardover">

              <div class="card-header card-no-border">
                <div class="rate-card col-xl-12">
                  <div class="whatsnew-btn" v-if="greeting">
                    <h2> <a href="javascript:void(0)" class="">Hi, {{ name }}!</a> </h2> {{ greeting }}
                  </div>
                  <div class="whatsnew-btn" v-else>
                    <h2> <a href="javascript:void(0)" class="">Hi, loading!</a> </h2> loading
                  </div>
                </div>
                <div class="card-header-right">
                  <router-link :to="'/home/employee-view/' + id + '/' + viewName" v-if="data.user_type == 3">
                    <img class="img-fluid user-img" src="../../assets/images/logo/user.gif" alt title="Profile" />
                  </router-link>

                </div>
                <h4 class="f-w-600 font-primary" id="greeting">

                </h4>
              </div>

              <div class="card-body pt-0">

                <div class="apex-chart-container goal-status text-center row">
                  <ul class="col-xl-12">
                    <li>
                      <!-- <div class="cardz">
                            <div class="card-body progress-showcase row">
                              <div class="col">
                                <h1 v-if="time != ''"> <span class="font-primary"> <span id="txt">{{ time }}</span></span>
                                </h1>
                                <h1 v-else> <span class="font-primary"> <span id="txt">loading</span></span></h1>
                                <b-progress class="sm-progress-bar" :value="Startvalue" striped animated :max="100"
                                  variant="primary"></b-progress>
                                <div class="btngroup">

                                  <button class="btnz2" @click="Time('start')" v-if="startbutton"><i
                                      class="fa fa-play"></i></button>
                                  <button class="btnz2" @click="Time('pause')" v-if="pausebutton"><i
                                      class="fa fa-pause"></i></button>
                                  <button class="btnz2" @click="Time('resume')" v-if="resumebutton"><i
                                      class="fa fa-play"></i></button>
                                  <button class="btnz2" @click="Time('stop')" v-if="stopbutton"><i
                                      class="fa fa-stop"></i></button>
                                </div>
                                <div class="btngroup">

                                  <p>{{ status }}</p>
                                </div>
                              </div>

                            </div>
                          </div> -->
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px" y="0px" style="display: none;">
                        <symbol id="wave">
                          <path
                            d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z">
                          </path>
                          <path
                            d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z">
                          </path>
                          <path
                            d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z">
                          </path>
                          <path
                            d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z">
                          </path>
                        </symbol>
                      </svg>
                      <div class="box pc12">
                        <div class="percent">

                          <h1 v-if="time != ''"> <span class="font-primaryz"> <span id="txt">{{ time }}</span></span>
                          </h1>
                          <h1 v-else> <span class="font-primaryz"> <span id="txt">loading</span></span></h1>
                          <div class="btngroup">

                            <button class="btnz2" @click="Time('start')" v-if="startbutton"><i
                                class="fa fa-play"></i></button>
                            <button class="btnz2" @click="Time('pause')" v-if="pausebutton"><i
                                class="fa fa-pause"></i></button>
                            <button class="btnz2" @click="Time('resume')" v-if="resumebutton"><i
                                class="fa fa-play"></i></button>
                            <button class="btnz2" @click="Time('stop')" v-if="stopbutton"><i
                                class="fa fa-stop"></i></button>
                          </div>
                          <div class="btngroup">

                            <p>{{ status }}</p>
                          </div>
                        </div>
                        <div id="water" class="water">
                          <svg viewBox="0 0 560 20" class="water_wave water_wave_back">
                            <use xlink:href="#wave"></use>
                          </svg>
                          <svg viewBox="0 0 560 20" class="water_wave water_wave_front">
                            <use xlink:href="#wave"></use>
                          </svg>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="static-top-widget-card">
              <div class="card"><!----><!---->
                <div class="card-body" style="display: block;"><!----><!---->
                  <div>
                    <div class="media static-top-widget">
                      <div class="media-body">
                        <h6 class="font-roboto">Today's Working Hours</h6>

                        <h4 class="mb-0 counter" v-if="today__time != 0">{{ today__time }}</h4>
                        <h4 class="mb-0 counter" v-else>0</h4>
                      </div><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="44"
                        height="46" viewBox="0 0 44 46">
                        <defs>
                          <clipPath id="clip-_1">
                            <rect width="44" height="46" />
                          </clipPath>
                        </defs>
                        <g id="_1" data-name="1" clip-path="url(#clip-_1)">
                          <g id="date" transform="translate(1 1.801)">
                            <rect id="Rectangle_119" data-name="Rectangle 119" width="42.199" height="1.513"
                              transform="translate(0 10.956)" fill="#e1e6e9" />
                            <path id="Path_2866" data-name="Path 2866"
                              d="M0,173.524a1.442,1.442,0,0,0,1.442,1.442H40.757a1.442,1.442,0,0,0,1.442-1.442V145.236H0Z"
                              transform="translate(0 -132.767)" fill="#ebf0f3" />
                            <path id="Path_2867" data-name="Path 2867"
                              d="M42.2,46.648a1.442,1.442,0,0,0-1.442-1.442H1.442A1.442,1.442,0,0,0,0,46.648v5.633H42.2Z"
                              transform="translate(0 -41.325)" fill="#e56353" />
                            <g id="Group_3363" data-name="Group 3363" transform="translate(5.928)">
                              <path id="Path_2868" data-name="Path 2868"
                                d="M70.01,0a.959.959,0,0,0-.959.959V6.8a.959.959,0,0,0,1.918,0V.959A.959.959,0,0,0,70.01,0Z"
                                transform="translate(-69.051)" fill="#64798a" />
                              <path id="Path_2869" data-name="Path 2869"
                                d="M115.453,0a.959.959,0,0,0-.959.959V6.8a.959.959,0,0,0,1.918,0V.959A.959.959,0,0,0,115.453,0Z"
                                transform="translate(-110.593)" fill="#64798a" />
                              <path id="Path_2870" data-name="Path 2870"
                                d="M355.647,0a.959.959,0,0,0-.959.959V6.8a.959.959,0,1,0,1.918,0V.959A.959.959,0,0,0,355.647,0Z"
                                transform="translate(-330.165)" fill="#64798a" />
                              <path id="Path_2871" data-name="Path 2871"
                                d="M401.089,0a.959.959,0,0,0-.959.959V6.8a.959.959,0,0,0,1.918,0V.959A.959.959,0,0,0,401.089,0Z"
                                transform="translate(-371.705)" fill="#64798a" />
                            </g>
                            <path id="Path_2872" data-name="Path 2872"
                              d="M245.762,214.585v17.822a8.911,8.911,0,0,0,0-17.822Z"
                              transform="translate(-224.662 -196.162)" fill="#3ea69b" />
                            <path id="Path_2873" data-name="Path 2873"
                              d="M141.967,223.5a8.911,8.911,0,0,0,8.911,8.911V214.585A8.911,8.911,0,0,0,141.967,223.5Z"
                              transform="translate(-129.779 -196.162)" fill="#44c4a1" />
                            <path id="Path_2874" data-name="Path 2874"
                              d="M185.3,282.481c-.04,0-.079,0-.119-.006a1.152,1.152,0,0,1-.817-.475l-2.311-3.228a1.151,1.151,0,0,1,1.872-1.34l1.555,2.172,5.406-4.951a1.151,1.151,0,0,1,1.554,1.7l-6.363,5.827A1.151,1.151,0,0,1,185.3,282.481Z"
                              transform="translate(-166.228 -250.797)" fill="#31978c" />
                            <path id="Path_2875" data-name="Path 2875"
                              d="M185.3,275.85c-.04,0-.079,0-.119-.006a1.152,1.152,0,0,1-.817-.475l-2.311-3.228a1.151,1.151,0,1,1,1.872-1.34l1.555,2.172,5.406-4.95a1.151,1.151,0,1,1,1.554,1.7l-6.363,5.827A1.151,1.151,0,0,1,185.3,275.85Z"
                              transform="translate(-166.228 -244.736)" fill="#fff" />
                          </g>
                        </g>
                      </svg>

                    </div>
                    <div class="progress-widget">
                      <div class="progress sm-progress-bar progress-animate">
                        <div role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                          class="progress-gradient-primary" style="width: 48%;"><span class="animate-circle"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div><!----><!---->
              </div>
            </div>
            <div class="static-top-widget-card">
              <div class="card"><!----><!---->
                <div class="card-body" style="display: block;"><!----><!---->
                  <div>
                    <div class="media static-top-widget">
                      <div class="media-body">
                        <h6 class="font-roboto">7 Day's Working Hours</h6>
                        <h4 class="mb-0 counter" v-if="week__time != 0">{{ week__time }}</h4>
                        <h4 class="mb-0 counter" v-else>0</h4>
                      </div><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="44"
                        height="46" viewBox="0 0 44 46">
                        <defs>
                          <clipPath id="clip-_7">
                            <rect width="44" height="46" />
                          </clipPath>
                        </defs>
                        <g id="_7" data-name="7" clip-path="url(#clip-_7)">
                          <g id="week" transform="translate(1.577 -2.457)">
                            <path id="Path_2876" data-name="Path 2876"
                              d="M0,114.873v28.474H20.423l1.949-13.9-1.949-14.576L10.847,112.5Z"
                              transform="translate(0 -97.932)" fill="#f5f8f9" />
                            <path id="Path_2877" data-name="Path 2877"
                              d="M261.423,110.3l-9.746-2.8L241,110.3v28.474h20.423Z"
                              transform="translate(-220.577 -93.356)" fill="#e3eaee" />
                            <path id="Path_2878" data-name="Path 2878" d="M0,50.5v7.627H20.423l1.61-3.644L20.423,50.5Z"
                              transform="translate(0 -41.186)" fill="#ff3f5c" />
                            <path id="Path_2879" data-name="Path 2879" d="M241,50.5h20.423v7.627H241Z"
                              transform="translate(-220.577 -41.186)" fill="#ca2e43" />
                            <path id="Path_2880" data-name="Path 2880" d="M105.5,5.5h2.542v7.627H105.5Z"
                              transform="translate(-96.559)" fill="#c2bce0" />
                            <path id="Path_2881" data-name="Path 2881" d="M346.5,5.5h2.542v7.627H346.5Z"
                              transform="translate(-317.136)" fill="#a299d1" />
                            <path id="Path_2882" data-name="Path 2882" d="M45,401.5h6.441v2.542H45Z"
                              transform="translate(-41.186 -362.441)" fill="#6e61b6" />
                            <path id="Path_2883" data-name="Path 2883" d="M407,401.5h2.542v2.542H407Z"
                              transform="translate(-372.509 -362.441)" fill="#463b84" />
                            <path id="Path_2884" data-name="Path 2884" d="M347,401.5h2.542v2.542H347Z"
                              transform="translate(-317.594 -362.441)" fill="#463b84" />
                            <path id="Path_2885" data-name="Path 2885"
                              d="M191.992,318.409l2.311,1.059,1.842-4.018.763-4.139-.763-1.962Z"
                              transform="translate(-175.722 -278.1)" fill="#6e61b6" />
                            <path id="Path_2886" data-name="Path 2886"
                              d="M181.861,192.5v2.542h5.012l.763-1.186-.763-1.356Z"
                              transform="translate(-166.449 -171.153)" fill="#6e61b6" />
                            <path id="Path_2887" data-name="Path 2887"
                              d="M247.283,192.5H241v2.542h3.374L241,202.4v6.1l6.283-13.7Z"
                              transform="translate(-220.577 -171.153)" fill="#463b84" />
                          </g>
                        </g>
                      </svg>

                    </div>
                    <div class="progress-widget">
                      <div class="progress sm-progress-bar progress-animate">
                        <div role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                          class="progress-gradient-success" style="width: 60%;"><span class="animate-circle"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div><!----><!---->
              </div>
            </div>
            <div class="static-top-widget-card">
              <div class="card"><!----><!---->
                <div class="card-body" style="display: block;"><!----><!---->
                  <div>
                    <div class="media static-top-widget">
                      <div class="media-body">
                        <h6 class="font-roboto">30 Day's Working Hours</h6>
                        <h4 class="mb-0 counter" v-if="thirty__time != 0">{{ thirty__time }}</h4>
                        <h4 class="fmb-0 counter" v-else>0</h4>
                      </div><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="44"
                        height="46" viewBox="0 0 44 46">
                        <defs>
                          <clipPath id="clip-_30">
                            <rect width="44" height="46" />
                          </clipPath>
                        </defs>
                        <g id="_30" data-name="30" clip-path="url(#clip-_30)">
                          <g id="calendar_1_" data-name="calendar (1)" transform="translate(0.938 -7.043)">
                            <path id="Path_2888" data-name="Path 2888"
                              d="M117.05,14.1v7.285l-16.745.75-18.437-.75V14.1a.956.956,0,0,1,.953-.953H116.1A.954.954,0,0,1,117.05,14.1Z"
                              transform="translate(-74.926)" fill="#fd4755" />
                            <path id="Path_2889" data-name="Path 2889"
                              d="M455.4,14.1v7.285l-2.883.17V14.1a.954.954,0,0,0-.954-.954h2.883A.954.954,0,0,1,455.4,14.1Z"
                              transform="translate(-413.28 0)" fill="#e41f2d" />
                            <path id="Path_2890" data-name="Path 2890"
                              d="M117.05,404.149v5.862a.88.88,0,0,1-.88.88H82.748a.88.88,0,0,1-.88-.88v-5.862Z"
                              transform="translate(-74.926 -357.848)" fill="#7ca1b1" />
                            <path id="Path_2891" data-name="Path 2891"
                              d="M456.2,404.149v5.862a.88.88,0,0,1-.88.88h-2.883a.88.88,0,0,0,.88-.88v-5.862Z"
                              transform="translate(-414.077 -357.848)" fill="#678d98" />
                            <path id="Path_2892" data-name="Path 2892"
                              d="M117.05,110.318v24.913a3.479,3.479,0,0,1-3.478,3.479c-.514,0-30.79-1.054-30.79-1.054l-.914-2.424V110.318H117.05Z"
                              transform="translate(-74.926 -88.93)" fill="#eaf6ff" />
                            <path id="Path_2893" data-name="Path 2893"
                              d="M428.159,110.319v24.913a3.479,3.479,0,0,1-3.478,3.479l-2.883-.092a3.428,3.428,0,0,0,1.441-.22,3.481,3.481,0,0,0,2.037-3.167V110.319Z"
                              transform="translate(-386.034 -88.931)" fill="#c8effe" />
                            <path id="Path_2894" data-name="Path 2894"
                              d="M38.646,407.628H3.464A3.48,3.48,0,0,1,0,404.495a.315.315,0,0,1,.313-.346H34.726a.473.473,0,0,1,.467.422A3.48,3.48,0,0,0,38.646,407.628Z"
                              transform="translate(0 -357.848)" fill="#c8effe" />
                            <path id="Path_2895" data-name="Path 2895"
                              d="M382.37,407.628h-2.883a3.479,3.479,0,0,1-3.453-3.057.473.473,0,0,0-.466-.421h2.883a.473.473,0,0,1,.466.421A3.479,3.479,0,0,0,382.37,407.628Z"
                              transform="translate(-343.724 -357.848)" fill="#99e6fc" />
                            <circle id="Ellipse_144" data-name="Ellipse 144" cx="1.437" cy="1.437" r="1.437"
                              transform="translate(34.409 15.759)" fill="#c8effe" />
                            <circle id="Ellipse_145" data-name="Ellipse 145" cx="1.437" cy="1.437" r="1.437"
                              transform="translate(11.784 15.759)" fill="#c8effe" />
                            <g id="Group_3364" data-name="Group 3364" transform="translate(10.329 28.994)">
                              <path id="Path_2896" data-name="Path 2896"
                                d="M125.676,201.3h-3.222a.636.636,0,0,1,0-1.272h3.222a.636.636,0,0,1,0,1.272Z"
                                transform="translate(-121.818 -200.032)" fill="#99e6fc" />
                            </g>
                            <g id="Group_3365" data-name="Group 3365" transform="translate(10.329 32.288)">
                              <path id="Path_2897" data-name="Path 2897"
                                d="M125.676,240.155h-3.222a.636.636,0,0,1,0-1.272h3.222a.636.636,0,0,1,0,1.272Z"
                                transform="translate(-121.818 -238.883)" fill="#99e6fc" />
                            </g>
                            <g id="Group_3366" data-name="Group 3366" transform="translate(10.329 35.664)">
                              <path id="Path_2898" data-name="Path 2898"
                                d="M126.312,279.335v5.5a.636.636,0,0,1-.636.636h-3.222a.636.636,0,0,1-.636-.636v-5.5a.636.636,0,0,1,.636-.636h3.222A.636.636,0,0,1,126.312,279.335Z"
                                transform="translate(-121.818 -278.699)" fill="#fd4755" />
                            </g>
                            <g id="Group_3367" data-name="Group 3367" transform="translate(18.187 25.7)">
                              <path id="Path_2899" data-name="Path 2899"
                                d="M223.362,168.826a4.433,4.433,0,0,0-8.782-.858.636.636,0,0,0,1.248.245,3.161,3.161,0,1,1,3.1,3.774.636.636,0,0,0,0,1.272,3.161,3.161,0,1,1-3.13,3.6,3.2,3.2,0,0,1-.031-.442.636.636,0,0,0-1.272,0,4.433,4.433,0,1,0,6.718-3.8A4.435,4.435,0,0,0,223.362,168.826Z"
                                transform="translate(-214.497 -164.121)" fill="#61a2f9" />
                              <path id="Path_2900" data-name="Path 2900"
                                d="M342.276,161.182a4.932,4.932,0,0,0-4.927,4.927v6.862a4.927,4.927,0,1,0,9.853,0v-6.862A4.932,4.932,0,0,0,342.276,161.182Zm3.655,11.789a3.655,3.655,0,1,1-7.31,0v-6.862a3.655,3.655,0,1,1,7.31,0Z"
                                transform="translate(-326.933 -161.182)" fill="#61a2f9" />
                            </g>
                          </g>
                        </g>
                      </svg>

                    </div>
                    <div class="progress-widget">
                      <div class="progress sm-progress-bar progress-animate">
                        <div role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                          class="progress-gradient-secondary" style="width: 75%;"><span class="animate-circle"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div><!----><!---->
              </div>
            </div>
          </div>

          <div class="col-xl-9 col-lg-12 xl-35 morning-sec box-col-12 earning-card ">
            <div class="row m-0">
              <div class="col-xl-12 earning-content p-0">
                <div class="card">
                  <div class="card-header card-no-border">
                    <h5>Last 6 days Update</h5>
                    <div class="card-header-right">
                      <ul class="list-unstyled card-option">
                        <!-- ftgh -->
                      </ul>
                    </div>
                  </div>
                  <div class="row m-0 chart-left">
                    <div v-for="six in LastSixDay" :style="`padding: 8px 17px 18px 22px;`">

                      <vue-ellipse-progress v-if="six.time != 0" :progress="getvaluSix(six.time)" :size="100"
                        color="#51bb25" empty-color="#ff9191" :gap="50" :loading="false" animation="loop 0">
                        <span>{{
                          secToFormat(six.time)
                        }}</span>
                      </vue-ellipse-progress>

                      <vue-ellipse-progress v-else :progress="0" :size="100" color="#ff9191" empty-color="#ff9191"
                        :loading="false" animation="loop 0">
                        <span>00:00:00</span>
                      </vue-ellipse-progress>
                      <p slot="legend-caption " class="text-center" style="margin-top: 1px;"><b>{{ six.date }}</b></p>
                      <p class="text-center">{{ getDayName(six.date) }}</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="row ">
              <div class="col-xl-6 xl-83 box-col-6">
                <div class="card cardover">
                  <div class="card-header">
                    <h5>Recent Activity</h5>
                  </div>
                  <div class="card-body">
                    <div class="activity-media">
                      <div class="chart-left newClass">
                        <div class="col-xl-12 p-0 left_side_earning" v-for=" RecentActivity in RecentActivityData">
                          <h6 class="font-roboto">{{ RecentActivity.text }}</h6>
                          <p class="font-roboto">{{
                            getFormattedDate(RecentActivity.date)
                          }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 xl-83 box-col-6">
                <div class="card cardover blaz">
                  <div class="card-header">
                    <h5>Quick Links</h5>
                  </div>
                  <div class="card-body">
                    <div class="activity-media">
                      <div class="row">

                        <div class="col mt25">
                          <a @click="redirectToChild('1')">
                            <div class="menu-icon">
                              <feather type="list"></feather>
                            </div>
                            <p class="text-center">Projects</p>
                          </a>
                        </div>

                        <div class="col mt25">
                          <a @click="redirectToChild('2')">
                            <div class="menu-icon">
                              <feather type="user-minus"></feather>
                            </div>
                            <p class="text-center">Leaves</p>
                          </a>
                        </div>

                        <div class="col mt25">
                          <a @click="redirectToChild('3')">
                            <div class="menu-icon">
                              <feather type="message-square"></feather>
                            </div>
                            <p class="text-center">Messages</p>
                          </a>
                        </div>

                        <div class="col mt25">
                          <a class="show" href="/home/monthly-attendance">
                            <div class="menu-icon">
                              <feather type="file-text"></feather>
                            </div>
                            <p class="text-center">Attendance</p>
                        </a>
                       
                        </div>

                      </div>
                      <div class="row">

                        <div class="col mt25">
                          <a @click="redirectToChild('5')">
                            <div class="menu-icon">
                              <feather type="user"></feather>
                            </div>
                            <p class="text-center">Personal Details</p>
                          </a>
                        </div>

                        <div class="col mt25">
                          <a @click="redirectToChild('6')">
                            <div class="menu-icon">
                              <feather type="phone"></feather>
                            </div>
                            <p class="text-center">Contact Details</p>
                          </a>
                        </div>

                        <div class="col mt25">
                          <a @click="redirectToChild('7')">
                            <div class="menu-icon">
                              <feather type="briefcase"></feather>
                            </div>
                            <p class="text-center">Company Details</p>
                          </a>
                        </div>

                        <div class="col mt25">
                          <a @click="redirectToChild('8')">
                            <div class="menu-icon">
                              <feather type="book"></feather>
                            </div>
                            <p class="text-center">User Documents</p>
                          </a>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row ">
              <div class="col-xl-6 xl-83 box-col-6">
                <div class="card cardover">
                  <div class="card-header">
                    <h5 class="float-l">Chat</h5>
                    <div class="float-r">
                      <b-button v-b-modal.modal-6 id="default-primary" variant="primary" style="margin: 0 auto;">Add
                        Intimation topic
                      </b-button>
                    </div>
                  </div>
                  <div class="card-body noscl">
                    <div class="activity-media">
                      <message />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 xl-83 box-col-6">
                <div class="card cardover">
                  <div class="card-header">
                    <h5>Leaves</h5>
                  </div>
                  <div class="card-body makeFit">
                    <div class="activity-media cust-scroll">
                      <!-- <div class="our-product"> -->

                      <!-- <div v-if="items">

                        <div class="row leave-row" v-for="(items, date) in items" :key="date">
                          <div class="col-4 ">
                            {{ checkToday(date.split(' ')[0]) }}
                          </div>
                          <div class="col-2" v-for="item in items" :key="item.id">
                           
                            <div v-if="item.session">
                              <img class="img-fluid img-40 rounded-circle mb-3 half-round" v-if="item.photo == null"
                                src="../../assets/images/appointment/app-ent.jpg" alt="Image description">
                              <img class="img-fluid img-40 rounded-circle mb-3 half-round" v-else :src="item.photo"
                                alt="Image description">
                            </div>
                            <div v-else>
                              <img class="img-fluid img-40 rounded-circle mb-3 full-round" v-if="item.photo == null"
                                src="../../assets/images/appointment/app-ent.jpg" alt="Image description">
                              <img class="img-fluid img-40 rounded-circle mb-3 full-round" v-else :src="item.photo"
                                alt="Image description">
                            </div>
                          </div>

                        </div>
                      </div>
                      <div v-else>
                        <p :style="`text-align: center;`"> <img class="img-fluid leave-img"
                            src="../../assets/images/logo/document.gif" alt /> </p>
                      </div> -->
                      <div v-if="items">
                        <div class="card-container" v-for="(items, date) in items" :key="date">
                          <div class="photo-container">
                            <div class="date">
                              <div class="day">{{ checkToday(date.split(' ')[0]).split(' ')[0] }} <br> {{
                                checkToday(date.split(' ')[0]).split(' ')[1] }} </div>
                              <!-- <div class="month">MAY</div> -->
                            </div>
                            <!-- <div class="image"></div> -->
                          </div>
                          <div class="info-container">
                            <div v-for="item in items" :key="item.id" style="padding-left: 10px !important;">
                              <div v-if="item.session" class="img-container" v-b-tooltip.hover :title="item.emp_name">
                                <img class="img-fluid img-40 rounded-circle mb-3 half-round" v-if="item.photo == null"
                                  src="../../assets/images/appointment/app-ent.jpg" alt="Image description">
                                <img class="img-fluid img-40 rounded-circle mb-3 half-round" v-else :src="item.photo"
                                  alt="Image description">
                              </div>
                              <div v-else class="img-container" v-b-tooltip.hover :title="item.emp_name">
                                <img class="img-fluid img-40 rounded-circle mb-3 full-round" v-if="item.photo == null"
                                  src="../../assets/images/appointment/app-ent.jpg" alt="Image description">
                                <img class="img-fluid img-40 rounded-circle mb-3 full-round" v-else :src="item.photo"
                                  alt="Image description">
                              </div>
                            </div>
                            <!-- <div class="event-name">
                            Pre-Mason Day
                          </div>
                          <div class="event-location">
                            Early celebration in the Corner Pocket
                          </div> -->
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <p :style="`text-align: center;`"> <img class="img-fluid leave-img"
                            src="../../assets/images/logo/document.gif" alt /> </p>
                      </div>
                      <!-- </div> -->

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>





      <b-modal id="modal-3" v-model="modalShow" hide-footer>
        <template #modal-title>
          {{ reason }}
        </template>
        <div class="row">
          <div class="col-12">
            <form>
              <label class="col-form-label" for="message-text">Message:</label>
              <b-form-textarea id="textarea" v-model="text" placeholder="Enter message" rows="4"></b-form-textarea>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <b-button class="mt-3 " variant="danger" @click="hideModal">Close</b-button>
          </div>
          <div class="col-6">
            <b-button class="mt-3 float-right" variant="primary" @click="handleSubmit()">Submit</b-button>
          </div>
        </div>
      </b-modal>
      <!-- Container-fluid Ends-->
    </div>
  </div>
</template>
<script>
import Service from '@/services/Service.js';
import ServiceApi from '@/services/ServiceApi.js';
import message from '@/pages/Chat/quick-chat';
import location_erorr from '../../auth/location_error.vue'
import { throttle } from 'lodash';
import { CProgress, CProgressBar } from '@coreui/vue';
import moment from 'moment'
var primary = localStorage.getItem('primary_color') || '#7366ff';
var secondary = localStorage.getItem('secondary_color') || '#f73164';
var Knob = require('knob');
var ordervalue = Knob({
  value: 35,
  angleOffset: 90,
  className: 'review',
  thickness: 0.2,
  width: 65,
  height: 65,
  fgColor: primary,
});
var productvalue = Knob({
  value: 60,
  angleOffset: 90,
  className: 'review',
  thickness: 0.2,
  width: 65,
  height: 65,
  fgColor: primary,
});

export default {
  components: {
    CProgress,
    CProgressBar,
    message,

  },
  data() {
    return {
      holiday:true,
      viewName: '0',
      reason: null,
      name: '',
      LiveTimeApiCall: null,
      LastSixDay: [],
      RecentActivityData: [],
      data: JSON.parse(localStorage.getItem('loginDetails')),
      greeting: '',
      time: '',
      Startvalue: 0,
      status: 'sign in',
      startStats: 1,
      config: JSON.parse(localStorage.getItem('config')),
      afterLogin: "",
      beforeLogout: "",
      afterLogout: "",
      workingHr: null,
      lastHr: null,
      startbutton: true,
      pausebutton: false,
      resumebutton: false,
      stopbutton: false,
      modalShow: false,
      location: null,
      configReloadStatus: 1,
      text: "",
      login: null,
      tablefields: [

        { key: 'emp_name', label: 'Employee', sortable: true, },
        { key: 'em_id', label: 'Employee ID', sortable: true, },
        { key: 'department', label: 'Department', sortable: true, },
        { key: 'designation', label: 'Designation', sortable: true, },
        { key: 'start_date', label: 'From', sortable: true, },
        { key: 'end_date', label: 'To', sortable: true, },
      ],
      items: null,
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],

      LastHistoryDay: [],
      thirty__time: 0,
      week__time: 0,
      today__time: 0,
      id: JSON.parse(localStorage.getItem('id')),
      smallchart4: {
        chartData_1: {
          labels: ['Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Q6', 'Q7'],
          series: [
            [400, 600, 800, 1000, 700, 1100, 300],
            [1000, 500, 600, 300, 700, 200, 1100],
          ],
        },
        chartOptions: {
          stackBars: true,
          axisX: {
            showGrid: false,
            showLabel: false,
            offset: 0,
          },
          axisY: {
            low: 0,
            showGrid: false,
            showLabel: false,
            offset: 0,
            labelInterpolationFnc: function (value) {
              return value / 1000 + 'k';
            },
          },
        },
        eventHandlers: [
          {
            event: 'draw',
            fn(data) {
              if (data.type === 'bar') {
                data.element.attr({
                  style: 'stroke-width: 3px',
                });
              }
            },
          },
        ],
      },

    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {


// Get the current date
      var currentDates = new Date();

      // Set the target date (December 25, 2023)
      var targetDates = new Date('2023-12-25');

      // Compare the current date with the target date
      if (currentDates < targetDates) {
         
        this.holiday = true;


      } else {


        this.holiday = false;

      }


    // this.fetchDataThrottled = throttle(this.getTodayliveTime, 1000); // Throttle the API call to once per second
    // this.fetchDataThrottledGetConfigData = throttle(this.getConfigData, 1000); // Throttle the API call to once per second
    // this.startFetchingData();
    this.getTodayliveTime();
    this.getConfigData();
    this.RecentActivity();
    this.getTodayLeaves();
    // this.getLocation();


    this.name = this.data.name
    setInterval(() => {
      var d = new Date();
      var curHr = this.addZero(d.getHours());
      var curMi = this.addZero(d.getMinutes());
      var curSend = this.addZero(d.getSeconds());
      var meridiem = curHr >= 12 ? 'PM' : 'AM';
      this.time = curHr + ':' + curMi;
      if (curHr < 12) {
        this.greeting = 'Good morning!';
      } else if (curHr < 18) {
        this.greeting = 'Good afternoon!';
      } else {
        this.greeting = 'Good evening!';
      }
    }, 1000);


    //  var conf = JSON.parse(localStorage.getItem('config'))
    //   this.workingHr = conf.filter((e) => e.name == 'total_working_in_second');
    this.loaddata()
  },
  created() {

    this.LiveTimeApiCall = setInterval(() => {
      this.getTodayliveTime();
      this.getConfigData();
    }, 7000);

  },
  beforeDestroy() {
    clearInterval(this.LiveTimeApiCall)
  },
  methods: {
  //   startFetchingData() {
  //     setInterval(() => {
  //       this.fetchDataThrottled();
  //     }, 1000);
      
  //     setInterval(() => {
  //       this.fetchDataThrottledGetConfigData();
  //     }, 1000);

  //   },
  //   beforeUnmount() {
  //   this.fetchDataThrottled.cancel(); // Cancel the throttled function to prevent any pending calls
  //   this.fetchDataThrottledGetConfigData.cancel(); // Cancel the throttled function to prevent any pending calls
  // },
    // getLocation() {
    //   if (navigator.geolocation) {

    //     navigator.geolocation.getCurrentPosition(
    //       this.handleLocationSuccess,
    //       this.handleLocationError
    //     );
    //   } else {
    //     console.error('Geolocation is not supported by this browser.');
    //   }
    // },
    // handleLocationSuccess(position) {


    //   this.location = {
    //     latitude: position.coords.latitude,
    //     longitude: position.coords.longitude,
    //   };






    //   console.log('Location:', this.location);
    // },
    // handleLocationError(error) {
    //   console.error('Error getting location:', error);
    // },
    checkToday(date) {
      var currentDate = new Date();

      var monthName = new Date(0, date.split('-')[1] - 1).toLocaleString('en', { month: 'long' });
      // console.log(date.split('-')[2], monthName);
      // Format the current date in the same format as datestr
      var currentDateString = currentDate.toISOString().split("T")[0];

      if (date === currentDateString) {
        return "Today";
      } else {
        return date.split('-')[2] + ' ' + monthName;
      }
    },
    // limitName(name) {
    //   if (name.length > 11) {
    //     return name.slice(0, 10) + '..';
    //   } else {
    //     return name;
    //   }
    // },
    redirectToChild(val) {
      this.$router.push({
        path: '/home/employee-view/' + this.id + '/' + val,
      })
    },
    async getTodayliveTime() {

      var water = document.getElementById("water");
      const response = await Service.getTodayliveTimeApi();
      if (response.code == 200) {

        this.lastHr = response.data;
        if (this.workingHr != null) {
          this.Startvalue = (this.lastHr / this.workingHr[0].value) * 100;


          if (this.Startvalue <= 100) {
            water.style.transform = 'translate(0' + ',' + (100 - this.Startvalue) + '%)';

          } else {
            water.style.transform = 'translate(0' + ',' + (100 - 100) + '%)';

          }

        } else {
          this.Startvalue = 0;
        }
        this.today__time = response.time;

      }
      else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },
    secToFormat(seconds) {
      return moment.utc(seconds * 1000).format("HH:mm:ss");
    },

    getFormattedDate(date) {
      return moment(date).format("MMMM D, YYYY")
    },
    async getConfigData() {

      const response = await Service.getConfigApi();
      if (response.code == 200) {

       

        if(this.configReloadStatus == 1)
        {
          localStorage.setItem('config', JSON.stringify(response.data))

          this.workingHr = response.data.filter((e) => e.name == 'total_working_in_second');

        }
       

        if (response.last_status == 1) {
          localStorage.setItem('lastbuttonstatus', 'start')
        } else if (response.last_status == 2) {
          localStorage.setItem('lastbuttonstatus', 'stop')
        } else if (response.last_status == 3) {
          localStorage.setItem('lastbuttonstatus', 'pause')
        } else if (response.last_status == 4) {
          localStorage.setItem('lastbuttonstatus', 'resume')
        } else {
          localStorage.setItem('lastbuttonstatus', 'stop')
        }
        localStorage.setItem('lastworkingtime', response.total_time)
        this.loaddata();
        console.log('Live status update successful');
        if(this.configReloadStatus == 1)
        {
        this.lastPunchingSixDayDetails();
        this.PunchingWorkingDayHistoryDetails();
        }
        this.configReloadStatus = 2;


      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },
    async lastPunchingSixDayDetails() {

      const response = await Service.lastPunchingSixDayDetailsApi();
      if (response.code == 200) {



        this.LastSixDay = response.data;


      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },
    async PunchingWorkingDayHistoryDetails() {

      const response = await Service.PunchingWorkingDayHistoryDetailsApi();
      if (response.code == 200) {



        this.LastHistoryDay = response.data;
        this.thirty__time = this.LastHistoryDay[1].thirty__time
        this.week__time = this.LastHistoryDay[0].week__time;



      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },
    async RecentActivity() {

      const response = await Service.RecentActivityApi();
      if (response.code == 200) {



        this.RecentActivityData = response.data;


      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },

    async getTodayLeaves() {

      const response = await ServiceApi.GetTodayLeaves();
      if (response.code == 200) {

        this.items = response.data;
        console.log(typeof (this.items));
      }
      else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },
    hideModal() {
      this.modalShow = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    loaddata() {

      var lastbuttonstatu = localStorage.getItem('lastbuttonstatus')
      if (lastbuttonstatu == 'start') {

        this.startbutton = false;
        this.pausebutton = true;
        this.stopbutton = true;
        this.status = 'Sign out';


      } else if (lastbuttonstatu == 'pause') {
        this.status = 'Break';

        this.startbutton = false;
        this.pausebutton = false;
        this.resumebutton = true;
        this.stopbutton = true;
      } else if (lastbuttonstatu == 'resume') {
        this.startbutton = false;
        this.pausebutton = true;
        this.resumebutton = false;
        this.stopbutton = true;
        this.status = 'Sign out';
      } else if (lastbuttonstatu == 'stop') {
        this.startbutton = true;
        this.pausebutton = false;
        this.resumebutton = false;
        this.stopbutton = false;
        this.status = 'Sign in';
      } else {

        this.startbutton = true;
        this.pausebutton = false;
        this.resumebutton = false;
        this.stopbutton = false;
        this.status = 'Sign in';

      }


      var lastworkingtime = localStorage.getItem('lastworkingtime')
      this.lastHr = lastworkingtime;
      if (this.workingHr != null) {
        this.Startvalue = (this.lastHr / this.workingHr[0].value) * 100;
      } else {
        this.Startvalue = 0;
      }


    },
    getvaluSix(val) {
      if (val != 0) {
        var pre = (val / this.workingHr[0].value) * 100;
        if (pre >= 100) {
          return 100;
        } else {
          return pre;
        }

      } else {
        return 0;
      }

    },
    Time(sta) {

      // if (this.location == null) {
      //   this.$toast.error(location_erorr, {
      //     position: "top-right",
      //     timeout: 2000,
      //   });
      // } else {


        // this.Startvalue = 30;
        var st = ''

        var lastbuttonstatus = localStorage.getItem('lastbuttonstatus')

        if (sta == 'start') {

          this.checkPunchingLoginStatus();

        } else if (sta == 'pause') {

          const data = {
            status: 3,
            // latitude: this.location.latitude,
            // longitude: this.location.longitude,

          }
          this.status = 'Break';
          this.PunchingDetailsStatusUpdate(data);
          localStorage.setItem('lastbuttonstatus', 'pause')
          this.startbutton = false;
          this.pausebutton = false;
          this.resumebutton = true;
          this.stopbutton = true;
        } else if (sta == 'resume') {

          const data = {
            status: 4,
            // latitude: this.location.latitude,
            // longitude: this.location.longitude,

          }
          this.status = 'Sign out';
          this.PunchingDetailsStatusUpdate(data);
          localStorage.setItem('lastbuttonstatus', 'resume')
          this.startbutton = false;
          this.pausebutton = true;
          this.resumebutton = false;
          this.stopbutton = true;

        } else if (sta == 'stop') {

          this.checkPunchingLogoutStatus();
        }
      // }
    },
    async handleSubmit() {

      if (!this.text) {
        this.$toasted.show('Reason is required', { theme: 'outline', position: 'top-right', icon: 'times', type: 'error', duration: 750 });


      } else {

        if (this.login == 1) {
          this.startbutton = false;
          this.pausebutton = true;
          this.stopbutton = true;
          localStorage.setItem('lastbuttonstatus', 'start')
        } else if (this.login == 2) {
          this.startbutton = true;
          this.pausebutton = false;
          this.resumebutton = false;
          this.stopbutton = false;
          localStorage.setItem('lastbuttonstatus', 'stop')
        }

        this.modalShow = false;
        const data = {
          status: this.login,
          text: this.text,
          // latitude: this.location.latitude,
          // longitude: this.location.longitude,

        }

        this.PunchingDetailsStatusUpdate(data);

      }



    },
    getDayName(dateStr) {
      let date = new Date(dateStr);
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return daysOfWeek[date.getDay()];
    },
    async checkPunchingLogoutStatus() {
      const response = await Service.checkPunchingLogoutStatusApi();

      if (response.code == '200') {


        if (response.data.alertstartStats == 1) {

          this.reason = response.data.reason;
          this.modalShow = true;
          this.status = 'Sign in';
          this.login = 2;


        } else if (response.data.alertstartStats == 2) {

          this.reason = response.data.reason;
          this.modalShow = true;
          this.status = 'Sign in';
          this.login = 2;
        } else {


          const data = {
            status: 2,
            text: null,
            // latitude: this.location.latitude,
            // longitude: this.location.longitude,

          }

          this.PunchingDetailsStatusUpdate(data);

          this.startbutton = true;
          this.pausebutton = false;
          this.resumebutton = false;
          this.stopbutton = false;
          localStorage.setItem('lastbuttonstatus', 'stop')


        }

      } else if (response.code == "404") {
        this.$toasted.show(response.status, { theme: 'outline', position: 'top-right', type: 'error', duration: 5000 });


      }


    },
    async checkPunchingLoginStatus() {
      const response = await Service.checkPunchingLoginStatusApi();

      if (response.code == '200') {


        if (response.data.alertstartStats == 1) {

          this.reason = response.data.reason;
          this.modalShow = true;
          this.login = 1;

        } else {


          const data = {
            status: 1,
            text: null,
            // latitude: this.location.latitude,
            // longitude: this.location.longitude,

          }

          this.PunchingDetailsStatusUpdate(data);

          this.startbutton = false;
          this.pausebutton = true;
          this.stopbutton = true;
          localStorage.setItem('lastbuttonstatus', 'start')
          this.status = 'Sign out';
        }


      } else if (response.code == "404") {
        this.$toasted.show(response.status, { theme: 'outline', position: 'top-right', type: 'error', duration: 5000 });


      }


    },
    async PunchingDetailsStatusUpdate(data) {
      const response = await Service.PunchingDetailsStatusUpdateApi(data);
      if (response.code == '200') {


        console.log(response);
        this.text = null;
        localStorage.setItem('lastworkingtime', response.data.total_time)
        this.loaddata();
        this.RecentActivity();
        this.PunchingWorkingDayHistoryDetails();


      } else if (response.code == "404") {

        if (data.status == 3) {
          this.status = 'Sign out';
          localStorage.setItem('lastbuttonstatus', 'start')
          this.startbutton = false;
          this.pausebutton = true;
          this.resumebutton = false;
          this.stopbutton = true;
        } else if (data.status == 4) {
          this.status = 'Break';
          localStorage.setItem('lastbuttonstatus', 'pause')
          this.startbutton = false;
          this.pausebutton = false;
          this.resumebutton = true;
          this.stopbutton = true;
        }
        this.text = null;
        this.$toasted.show(response.status, { theme: 'outline', position: 'top-right', type: 'error', duration: 5000 });


      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        localStorage.removeItem('lastworkingtime')
        localStorage.removeItem('lastbuttonstatus')
        localStorage.removeItem('PunchingDetailLogin')
        localStorage.removeItem('PunchingDetail')
        localStorage.removeItem('config')
        localStorage.removeItem('id')
        this.$router.push('/');
      }
    },
    addZero(i) {
      if (i < 10) {
        i = '0' + i;
      }
      return i;
    }
  },
};
</script>

<style>
@media screen and (max-width: 1660px) {
  .chat-box .people-list ul {
    max-height: auto !important;
    overflow: auto !important;
  }
}

.chart-left p {
  margin-top: -9px;
  margin-bottom: 1rem;
}

.float-r {
  float: right;
}

.float-l {
  float: left;
}

.pc12 {
  margin-top: 12pc;
}

.font-primaryz {
  color: #fff;
}

.cardover {
  height: 415px !important;
  overflow: auto;
}

.card .card-header.card-no-border {

  height: 62px !important;
}

h4,
.h4 {
  font-size: 1.2rem !important;
}

.blaz a p {
  color: #000;
  font-weight: 500;
}

.blaz a:hover {
  color: #000;
}

.dark-only .blaz a p {
  color: hsla(0, 0%, 100%, .7);
  font-weight: 500;
}

.dark-only .blaz a:hover {
  color: hsla(0, 0%, 100%, .7);
}
body.dark-only .page-wrapper.only-body, .page-wrapper.only-body .page-body-wrapper {
    background-color: #1d1e26;
}

.mt25 {
  margin-bottom: 25px;
}

a:hover .menu-icon {
  background: #000;
}

.menu-icon {
  text-align: center;
  background: #7b67fd;
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 59px;
  margin: 0 auto;
  border-radius: 50%;
  margin-bottom: 8px;
}

.cardz {
  width: 225px;
  height: 225px;
  border-radius: 50%;
  background: #f3f3f3;
  -webkit-box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
  box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
  margin: 0 auto;
  line-height: 254px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 5px #ffff;
  flex-direction: column;
}

.card .card-header {
  background-color: #fff;
  padding: 15px 50px 15px !important;
  border-bottom: 1px solid #ecf3fa;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
}

.earning-card .card .card-body .chart-left {
  padding: 5px 0 42px 15px !important;
}

p {
  margin-top: 0;
  margin-bottom: 0rem !important;
}

.earning-card .card .card-body .left_side_earning {
  margin-bottom: 20px !important;
}

.total-users .goal-status ul:last-child li {
  border-top: 1px solid #ecf3fa;
  margin-top: 27px;
  padding-top: 0px
}

.total-users .goal-status ul:last-child li {
  border-top: none;
  margin-top: -33px !important;
  padding-top: 0px;
}

.third-news-update span {
  font-weight: 500;
  color: #505050;
}

.btnz {

  height: 25px;
  width: 25px;
  /* position: absolute;
    margin-left: -15px;
    margin-top: 10px; */
}

.btnz2 {

  height: 25px;
  width: 25px;
  font-size: 22px;
  margin: 1px 5px;
  /* position: absolute;
    margin-left: 16px;
    margin-top: 10px; */
}

.btngroup {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.sm-progress-bar {
  height: 11px;
}

p {
  margin-top: -10px;
  margin-bottom: 1rem;
}

.user {
  font-size: 32px;
  color: #8268fd !important;
}

.user-img {
  margin-top: -25px;
  width: 50px;
}

.cardover {
  height: 445px;
  overflow: auto;
}

.activity-media .media {
  padding: 9px 0;
}

.dark-only .cardz {
  width: 225px;
  height: 225px;
  border-radius: 50%;
  background: #000000;
  -webkit-box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
  box-shadow: 15px 15px 30px #000000, -15px -15px 30px #2c2c2c;
  margin: 0 auto;
  line-height: 254px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: solid 5px #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.newClass {
  height: 305px;
  overflow: auto;
}

.card .card-header.card-no-border {
  border-bottom: none !important;
  height: 82px;
}

.earning-card .card .card-body .chart-left {
  padding: 46px 0 20px 40px;
}

.leave-img {
  margin-top: 58px;
  width: 150px;
  height: 97px;
}

.row+.row {
  margin-top: -10px !important;
}

.img-40 {
  /* width: 55px !important; */
  height: 40px !important;
}
</style>
<style lang="scss">
$bgColor: #020438;
$boxColor: #020438;
$waterFColor: #4D6DE3;
$waterBColor: #C7EEFF;

.box {
  height: 230px;
  width: 230px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $boxColor;
  border-radius: 100%;
  overflow: hidden;

  .percent {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 64px;
    flex-direction: column;

    h1 {
      font-size: .5em;
      margin: 0;
      color: #fff !important;

    }

  }

  .water {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    transform: translate(0, 100%);
    background: $waterFColor;
    transition: all .3s;

    &_wave {
      width: 200%;
      position: absolute;
      bottom: 100%;

      &_back {
        right: 0;
        fill: $waterBColor;
        animation: wave-back 1.4s infinite linear;
      }

      &_front {
        left: 0;
        fill: $waterFColor;
        margin-bottom: -1px;
        animation: wave-front .7s infinite linear;
      }
    }
  }
}

@keyframes wave-front {
  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes wave-back {
  100% {
    transform: translate(50%, 0);
  }
}

.whatsnew-btn {
  h2 {
    font-size: 1.5rem;
  }
}

.status {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.name-chat {

  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.noscl .chat-box .people-list ul {
  max-height: auto !important;
}

.makeFit {
  padding: 28px !important;
}

.padZero {
  padding: 0px 8px !important;
}

.time-style {
  padding: 0px 5px 0px 0px !important;
  margin: 4px !important;
  font-size: 10px !important;
}

.sess-cont {
  width: 193px !important;
  float: right !important;
}

.cust-scroll {
  max-height: 325px;
  overflow: auto;
  padding-top: 0px;
}

.half-round {
  background-image: linear-gradient(to bottom, #d1d1d1 50%, red 50%);
  box-sizing: border-box;
  padding: 3px;
}

.full-round {
  background-image: linear-gradient(to bottom, red 50%, red 50%);
  box-sizing: border-box;
  padding: 3px;
}

.leave-row {
  height: 60px;
  margin-top: -13px !important;
  margin-bottom: 17px !important;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #6ebbff;
  border-radius: 31px;
  margin-left: 0px !important;
}

.photo-container {
  width: 90px;
  font-size: 1.5em;
  background-color: #020438;
  color: white;
  padding: 0px;
  text-align: center;
  grid-area: photo;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
}

.card-container {
  margin: 10px;
  display: grid;
  grid-template-areas:
    'photo info';
  grid-template-columns: 150px 1fr;
  // width: 360px;
  background-color: #ffffff;
  // box-shadow: 0 0px 0px rgba(0, 0, 0, -1.84), 0 0px 6px rgba(0, 0, 0, 0.15);
  line-height: 1.75em;
  height: 55px;
  border: 1px solid #e7e7e7;
}

.info-container {
  display: flex;
  padding: 0px;
  grid-area: info;
  height: 55px;
  margin-left: -60px;
  margin-top: 8px;
}

.event-name {
  font-weight: bold;
  padding-bottom: 0px;
}

.event-location {
  font-weight: 300;
}

.day {
  font-weight: 200 !important;
  font-size: medium;
  padding: 0px !important;
}

.month {
  font-weight: 600;
  padding: 0px !important;
}

.img-container {
  height: 45px;
}

@media(max-width: 600px) {
  .card-container {
    width: 20%;
  }
}



body.dark-only .card-container {
    margin: 10px;
    display: grid;
    grid-template-areas: "photo info";
    grid-template-columns: 150px 1fr;
    background-color: #262932 !important;
    line-height: 1.75em;
    height: 55px;
    border: 1px solid #e7e7e7;
}







.snowflake {
  color: #fff;
  font-size: 3em;
  font-family: 'Molle', cursive;
  text-shadow: 0 0 1px #000;
}
.snowflake {
    color: #9ce4f3 !important;
    position: fixed;
    top: -10%;
    z-index: 9999;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    -webkit-animation-name: snowflakes-fall,snowflakes-shake;
    -webkit-animation-duration: 10s,3s;
    -webkit-animation-timing-function: linear,ease-in-out;
    -webkit-animation-iteration-count: infinite,infinite;
    -webkit-animation-play-state: running,running;
    animation-name: snowflakes-fall, snowflakes-shake;
    animation-duration: 10s,3s;
    animation-timing-function: linear,ease-in-out;
    animation-iteration-count: infinite,infinite;
    animation-play-state: running,running;
}
@-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}


</style>