<template>
    <div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="name">Module Name</label>
                                        <b-form-input type="text" id="name" v-model="name" placeholder="Module Name">
                                        </b-form-input>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="description">Module Description</label>
                                        <b-form-input type="text" id="description" v-model="description" placeholder="Module Description">
                                        </b-form-input>
                                    </div>
                                    <b-col md="6">
                                        <label for="phases">Project Phase</label>
                                        <v-select v-model="phase" label="title" placeholder="Project Phase"
                                            :options="phases" />
                                    </b-col>

                                    <b-col md="6" v-if="moduleStatus.value !=2">
                                        <label for="selectedEmployeeNames">Employee</label>
                                        <v-select v-model="selected_employees" label="name" placeholder="Employees"
                                            :options="employees" :multiple="true"/>                                          
                                        <!-- <div v-if="selectedEmployeeNames.length > 0">
                                            <ul>
                                                <li v-for="name in selectedEmployeeNames">{{ name }}</li>
                                            </ul>
                                        </div>  -->
                                    </b-col>
                                    
                                    <div class="col-md-6 mb-3">
                                        <label for="status">Status</label>
                                        <v-select id="status" v-model="moduleStatus" label="text" :options="status" />
                                    </div>

                                    
                                </div>
                                <div class="form-row">
                                </div>
                                <div class="form-row">


                                    <!-- <div class="col-md-6 mb-3">
                                        <label for="c_form_departments">Employee</label>
                                        <v-select
                                            v-model="employee"
                                            label="user"
                                            :options="employees" 
                                        />
                                    </div> -->
                                </div>
                                <b-button type="submit" variant="primary">Update</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import moment from "moment";
import { VueEditor } from 'vue2-editor';
export default {

    components: {
        vSelect,
        VueEditor
    },

    data() {
        return {
            // success: false,
            name: '',
            module_id: null,
            description: '',
            project_id: null,
            moduleStatus: [],
            status: [
                {
                    value: 1, text: 'Ongoing'
                },
                {
                    value: 2, text: 'Completed'
                },
                {
                    value: 3, text: 'Hold'
                },
                {
                    value: 4, text: 'Testing'
                },
                {
                    value: 5, text: 'Reopen'
                },
            ],
            employees: [],
            selected_employees: [],
            selectedEmployeeNames: [],
            phase: null,
            phases: [],
            phase_id: null,
        };
    },
    props: ['modulesId', 'projectModulesId'],
    computed: {

    },
    created() {
        this.getModulesById();
        this.getEmployees();
        this.retrievePhase(this.$route.params.id);
    },
    methods: {
        async retrievePhase(proId) {
            const reqDatas = {
                project_id: proId
            }
            const response = await Service.GetProjectPhase(reqDatas);
            if (response.code == "200") {
                this.phases = response.data;          
            }
        },

        async getEmployees() {
            const response = await Service.GetEmployees();
            if (response.code == '200') {
                this.employees = response.data;
                this.selectedEmployeeNames = response.data;
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },

        onCustomStyleSubmit(evt) {
            evt.preventDefault();
            if (this.name == '' || this.description == null) {
                // this.success = false;
                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                // this.success = true;
                this.saveModules();
            }

        },
        saveModules() {
            // console.log(this.selected_employees);
            const modules_data = {
                id: this.module_id,
                name: this.name,
                description: this.description,
                selected_employees: this.selected_employees,
                phase: this.phase.id,
                status: this.moduleStatus.value,
                phase_id: this.phase.phase_id,
            };
            this.updateModules(modules_data)
        },
        async updateModules(update_data) {
            const response = await Service.UpdateModules(update_data);
            if (response.code == '200') {
                this.$toasted.show(' Module updated successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$emit('update:modulesId', 4);
            } else if (response.code == "400") {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
        },
        

        //heading ----- phase status
        // async getPhaseStatus() {
        //     const response = await Service.GetPhaseStatus();
        //     if (response.code == '200') {
        //         this.phaseStatus = response.data;
        //     }
        // },

        async getModulesById(){
            const reqDataPhase = {
                id: this.projectModulesId,
            }
            const response = await Service.GetModulesById(reqDataPhase);
            if (response.code =='200') {
                this.module_id = response.data.module_id;
                this.name = response.data.name;
                this.description = response.data.description;
                this.phase = { title: response.data.phase_name, phase_id: response.data.phase_id };
                this.selected_employees =  response.data.employees;
                // this.phaseStatus = response.data.status;
                this.status.forEach(element => {
                    // console.log(element);                  
                    if(element.value == response.data.status){
                        this.moduleStatus = element;
                    }
                });
                // console.log(this.moduleStatus);
            }
        },
        // async getTaskById(task_id) {
        //     const ReqData = {
        //         id: task_id,
        //     }
        //     const response = await Service.GetEmpTaskById(ReqData);
        //     if (response.code == '200') {

                // this.chk_stat = response.data.status;

                // if (response.data.status == '1') {
                //     this.startEnd = '1';
                //     this.startEndStatus = [{ value: '1', text: 'Pending' },
                //                             { value: '2', text: 'Start' },];
                // } else if (response.data.status == '2') {
                //     this.startEnd = '2';
                //     this.startEndStatus = [{ value: '2', text: 'Start' },
                //                             { value: '3', text: 'End' },];
                // } else if (response.data.status == '3') {
                //     this.startEnd = '3';
                //     this.startEndStatus = [{ value: '3', text: 'End' },];
                // }
                // this.name = response.data.name;
                // this.descrip = response.data.desc;
                // this.task = { name: response.data.type, id: response.data.type_id };
                // // this.employee = { name: response.data.member.project, id: response.data.project_id };
                // this.project_id_task = response.data.project_id;
                // this.curr_start_date = this.startDate = response.data.start_date ? response.data.start_date.split('T')[0] : null;
                // this.curr_end_date = this.endDate = response.data.end_date ? response.data.end_date.split('T')[0] : null;
                // this.pSdate = response.data.pStartDate;
                // this.pEdate = response.data.pEndDate;
                // this.getUsers(this.project_id_task);
                // this.getUserAssign(response.data.id);
            }
        }
//     }
// };
</script>
<style>
 .ck-content { height:150px; }
</style>