<template>
  <div><br>
    <Breadcrumbs main="Project" title="Projects List" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 project-list">
          <div class="card">
            <div class="row m-0">
              <div class="col-sm-12 p-0">
                <b-tabs content-class="mt-3">
                  <b-tab active>
                    <template v-slot:title>
                      <feather type="align-justify"></feather>All Projects
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <project-table />
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>

                  <b-tab>
                    <template v-slot:title>
                      <feather type="target"></feather> Ongoing
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-xl-4 col-lg-6" v-for="(project, index) in allprojects" :key="index"
                                v-if="project.progress_status == 'bg-primary'" :class="index < 3 ? 'mb-4' : ''">
                                <div>
                                  <div class="project-box example_d" @click="viewProject(project.id)">
                                    <span class="badge" :class="project.progress_status">On Going</span>
                                   
                                    <h6>{{ project.name }}</h6>

                                    <br>
                                    <div class="row details det-cont">
                                      <div class="col-6">
                                        <span>Priority </span>
                                      </div>
                                      <div class="col-6 text-primary" :class="'text-' + project.type">{{ project.priority
                                        == 1 ? 'High' : project.priority == 2 ? 'Medium' : 'Low' }}</div>
                                      <div class="col-6">
                                        <span>Duration</span>
                                      </div>
                                      <div class="col-6 text-primary" :class="'text-' + project.type">{{
                                        getTimeAsForemated(project.duration) }}
                                      </div>
                                      <div class="col-6">
                                        <span>Last Update Date</span>
                                      </div>
                                      <div class="col-6 text-primary" :class="'text-' + project.type">{{
                                        project.activity_date }}
                                      </div>
                                      <div class="col-6">
                                        <span>Total Subtasks</span>
                                      </div>
                                      <div class="col-6 text-primary" :class="'text-' + project.type">{{ project.task }}
                                      </div>
                                    </div><br>

                                    <br />
                                    <div class="row">
                                      <div class="col-6">
                                        <router-link :to="'edit-project/' + project.id">
                                          <div v-if="project.progress_status == 'bg-primary'">

                                            <b-button class="btn-pill" id="default-xs-primary" size="md"
                                              variant="outline-primary">
                                              <i class="fa fa-edit">Edit</i>
                                            </b-button>
                                          </div>
                                          
                                        </router-link>
                                      </div>
                                      <div class="col-6" style="left: 40px;">
                                        <router-link :to="'manage-project/' + project.id">
                                          <b-button class="btn-pill" id="default-xs-dark" size="md"
                                            variant="outline-dark">
                                            Manage
                                          </b-button>
                                        </router-link>
                                      </div>
                                    </div>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>


                  <b-tab>
                    <template v-slot:title>
                      <feather type="check-circle"></feather> Completed
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-xl-4 col-lg-6" v-for="(project, index) in allprojects" :key="index"
                                v-if="project.progress_status == 'bg-success'" :class="index < 3 ? 'mb-4' : ''">
                                <div>
                                  <div class="project-box example_d" @click="viewProject(project.id)">

                                    <span class="badge" :class="project.progress_status">Completed</span>

                                    <h6>{{ project.name }}</h6>

                                    <br>
                                    <div class="row details det-cont">
                                      <div class="col-6">
                                        <span>Priority </span>
                                      </div>
                                      <div class="col-6 text-primary" :class="'text-' + project.type">{{ project.priority
                                        == 1 ? 'High' : project.priority == 2 ? 'Medium' : 'Low' }}</div>
                                      <div class="col-6">
                                        <span>Duration</span>
                                      </div>
                                      <div class="col-6 text-primary" :class="'text-' + project.type">{{
                                        getTimeAsForemated(project.duration) }}
                                      </div>
                                      <div class="col-6">
                                        <span>Completed Date</span>
                                      </div>
                                      <div class="col-6 text-primary" :class="'text-' + project.type">{{
                                        project.activity_date }}
                                      </div>
                                      <div class="col-6">
                                        <span>Total Subtasks</span>
                                      </div>
                                      <div class="col-6 text-primary" :class="'text-' + project.type">{{ project.task }}
                                      </div>
                                    </div><br>

                                    <br />
                                    <div class="row">
                                      <div class="col-6">
                                        <router-link :to="'edit-project/' + project.id">
                                         
                                          <div>

                                            <b-button class="btn-pill" id="default-xs-success" size="md"
                                              variant="outline-success">
                                              <i class="fa fa-edit">Edit</i>
                                            </b-button>

                                          </div>
                                          
                                        </router-link>
                                      </div>
                                      <div class="col-6" style="left: 40px;">
                                        <router-link :to="'manage-project/' + project.id">
                                          <b-button class="btn-pill" id="default-xs-dark" size="md"
                                            variant="outline-dark">
                                            Manage
                                          </b-button>
                                        </router-link>
                                      </div>
                                    </div>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>




                  <!-- new 17 -->
                  <b-tab>
                    <template v-slot:title>
                      <feather type="check-circle"></feather> Hold
                    </template>
                    <b-card-text>
                      <div class="col-sm-12 p-0">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-xl-4 col-lg-6" v-for="(project, index) in allprojects" :key="index"
                                v-if="project.progress_status == 'bg-warning'" :class="index < 3 ? 'mb-4' : ''">
                                <div>
                                  <div class="project-box example_d" @click="viewProject(project.id)">

                                    <span class="badge" :class="project.progress_status">Hold</span>
                                    <h6>{{ project.name }}</h6>

                                    <br>
                                    <div class="row details det-cont">
                                      <div class="col-6">
                                        <span>Priority </span>
                                      </div>
                                      <div class="col-6 text-primary" :class="'text-' + project.type">{{ project.priority
                                        == 1 ? 'High' : project.priority == 2 ? 'Medium' : 'Low' }}</div>
                                      <div class="col-6">
                                        <span>Duration</span>
                                      </div>
                                      <div class="col-6 text-primary" :class="'text-' + project.type">{{
                                        getTimeAsForemated(project.duration) }}
                                      </div>
                                      <div class="col-6">
                                        <span>Hold Date</span>
                                      </div>
                                      <div class="col-6 text-primary" :class="'text-' + project.type">{{
                                        project.activity_date }}
                                      </div>
                                      <div class="col-6">
                                        <span>Total Subtasks</span>
                                      </div>
                                      <div class="col-6 text-primary" :class="'text-' + project.type">{{ project.task }}
                                      </div>
                                    </div><br>

                                    <br />
                                    <div class="row">
                                      <div class="col-6">
                                        <router-link :to="'edit-project/' + project.id">
                                          
                                          <div>

                                            <b-button class="btn-pill" id="default-xs-warning" size="md"
                                              variant="outline-warning">
                                              <i class="fa fa-edit">Edit</i>
                                            </b-button>

                                          </div>
                                        </router-link>
                                      </div>
                                      <div class="col-6" style="left: 40px;">
                                        <router-link :to="'manage-project/' + project.id">
                                          <b-button class="btn-pill" id="default-xs-dark" size="md"
                                            variant="outline-dark">
                                            Manage
                                          </b-button>
                                        </router-link>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <!-- new 17 end -->

                </b-tabs>
              </div>
              <div class="col-sm-6 create-project-btn">
                <div class="text-right">
                  <div class="form-group mb-0 mr-0">
                   
                    <router-link to="create-project"><a class="btn btn-primary p-add-btn" href="">
                        <i data-feather="plus-square"> </i>Create New Project</a></router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Service from '@/services/Service.js';
import ProjectTable from './project-table.vue';

export default {
  components: {
    ProjectTable,
  },
  data() {
    return {
      allprojects: null,
      doneprojects: null,
    };
  },
  mounted() {
    this.getProjects();
  },
  computed: {
    // ...mapState({
    //   allprojects: state => state.common.allprojects,
    //   doingprojects: state => state.common.doingprojects,
    //   doneprojects: state => state.common.doneprojects
    // })
  },
  methods: {
    getTimeAsForemated(time) {
      var sec_num = time;
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours < 10) { hours = "0" + hours; }
      if (minutes < 10) { minutes = "0" + minutes; }
      if (seconds < 10) { seconds = "0" + seconds; }
      return hours + ':' + minutes + ':' + seconds;
    },
    async getProjects() {
      const response = await Service.getProjects();
      if (response.code == "200") {
        this.allprojects = response.data.all;
        // console.log(this.allprojects);

        //calculate percentage
        this.allprojects.forEach(pro => {

          var start = pro.start_date.split('T')[0];
          var end = pro.end_date.split('T')[0];
          var from_dt = new Date(start);
          var to_dt = new Date(end);
          var Difference_In_Time = to_dt.getTime() - from_dt.getTime();
          var Difference_In_Days = Math.trunc((Difference_In_Time / (1000 * 3600 * 24)) + 1);

          var today = new Date();
          var Difference_In_Time_till_now = today.getTime() - from_dt.getTime();
          var Difference_In_Days_till_now = Math.trunc((Difference_In_Time_till_now / (1000 * 3600 * 24)) + 1);

          var progress_percent = ((Difference_In_Days_till_now / Difference_In_Days) * 100).toFixed(0);
          if (progress_percent >= 100) {
            pro.progress = 100;
          } else if (progress_percent < 0) {
            pro.progress = 0;
          } else {
            pro.progress = progress_percent;
          }

        });

      }
    },
    viewProject(id) {
      this.$router.push('/home/emp-project/' + id);
    },
    getImgUrl(path) {
      return require('@/assets/images/' + path);
    },
  }
};
</script>
<style>
.p-dot {
  display: inline-block;
  width: 300px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.project-box {
  border: 3px solid #dddddd !important;
  border-radius: 30px !important;
  padding: 30px;
  background-color: white;
  position: relative;
}

.project-box:hover {
  background-color: rgb(254, 254, 254) !important;
  transform: translate(-0.5%, -0.5%) scale(1);
}

.p-add-btn {
  padding: 10px !important;
  margin-top: 10px !important;
}
</style>