<template>
  <div>
    <Breadcrumbs main="Project" title="Project Create" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <px-card>
            <div slot="with-padding">
              <div class="form theme-form">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label>Project Title</label>
                      <input class="form-control" type="text" placeholder="Project name *" v-model="name">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label>Client name</label>
                      <input class="form-control" type="text" placeholder="Name client or company name"
                        v-model="client">
                    </div>
                  </div>
                </div>


                <!-- new 17 -->
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Priority</label>
                      <v-select id="priority" label="text" :options="priorities" v-model="priority" />
                    </div>
                  </div>
                </div>
                


                <!-- <div class="row">

                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Manpower Calculation Type</label>
                      <v-select v-model="Manpower" label="name" :options="ManpowerArray" />

                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Project unit Rate</label>
                      <input class="form-control" type="number" placeholder="Enter project unit Rate"
                        v-model="unit_rate">
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Project duration</label>
                      <input class="form-control" type="number" placeholder="Enter Project duration" v-model="duration">
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Total Project Rate</label>
                      <input class="form-control" type="number" placeholder="Enter project Rate" v-model="rate">
                    </div>
                  </div>
                </div> -->
                <div class="row">

                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Starting date</label>
                      <datepicker :format="customFormatter" input-class="datepicker-here form-control digits"
                        v-model="sdate"></datepicker>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Ending date</label>
                      <datepicker :format="customFormatter" input-class="datepicker-here form-control digits"
                        v-model="edate"></datepicker>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Upload project file</label>
                      <input type="file" @change="onFileChange" />
                    </div>
                  </div>
                </div>

                <!-- <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Project members</label>
                      <v-select v-model="members" label="name" :options="membersArray" :multiple="true"
                        v-on:input="remove-Emp(members)" />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Team lead</label>
                      <v-select v-model="manager" label="name" :options="Updatedmembers" />
                    </div>
                  </div>
                </div> -->
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label>Enter some Details</label>
                      <textarea class="form-control" id="exampleFormControlTextarea4" rows="3"
                        v-model="details"></textarea>
                    </div>
                  </div>
                </div>
                <!-- <b-col md="12" v-if="isOptionsHide">
                  <div v-for="(input, index) in options" :key="`optionInput-${index}`" class="input ">
                    <b-form-group :label="`Team-${index + 1}`">
                      <b-row>
                        <b-col md="5">
                          <b-form-input v-model="input.value" type="text"
                            :placeholder="`Enter Team Name-${index + 1}`" />
                        </b-col>
                        <b-col md="3">
                          <v-select v-model="input.mem" label="name" :options="tMem" :multiple="true"
                            placeholder="Members" @input="removeEmp(input.mem, index)" />
                        </b-col>
                        <b-col md="3">

                          <v-select v-model="input.lead" label="name" :options="tLead" placeholder="Team Lead"
                            @input="saveField(input.value, input.lead, input.mem)" />
                        </b-col>
                        <b-col md="1">

                          <svg @click="addField(input.value, input.lead, input.mem, options, index)"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                            class="ml-2 cursor-pointer">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path fill="green"
                              d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                          </svg>

                          <svg v-show="options.length > 1" @click="removeField(index, options, input.mem)"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                            class="ml-2 cursor-pointer">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path fill="#EC4899"
                              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                          </svg>
                        </b-col>
                      </b-row>

                    </b-form-group>
                  </div>

                </b-col> -->

                <div class="row">
                  <div class="col" v-if="loader == false">
                    <div class="form-group mb-0"><a class="btn btn-success mr-3" @click="save()">Add</a></div>
                  </div>
                  <div v-if="loader == true" class="loader-box">
                              <div class="loader-15"></div>
                        
                      </div>
                </div>
              </div>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import Service from '@/services/Service.js';
import moment from 'moment'

import S3 from 'aws-s3';
import { Buffer } from "buffer";

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;


export default {
  components: {
    Datepicker,
    vSelect,
  },
  data() {
    return {
      priority: [],
      priorities: [
        {
          value: 1, text: 'High'
        }, 
        {
          value: 2, text: 'Medium'
        }, 
        {
          value: 3, text: 'Low'
        },
      ],
      remData: [],
      memLen: 0,
      teamArrLen: 0,
      teamArr: [],
      options: [],
      tLead: [],
      isOptionsHide: true,
      ManpowerArray: [{ id: 0, name: 'Hourly' }, { id: 1, name: 'Day wise' }, { id: 2, name: 'Fix price' },],
      name: null,
      client: null,
      rate: 1,
      Manpower: { id: 2, name: 'Fix price' },
      unit_rate: 1,
      sdate: null,
      edate: null,
      membersArray: [],
      details: null,
      members: null,
      manager: null,
      file: null,
      fileName: null,
      s3: null,
      Updatedmembers: [],
      loader:false

    }
  },

  mounted() {
    // this.getMembersList();
    this.getAwsS3Config();

  },
  methods: {
    addField(value, lead, mem, fieldType, index) {
      // this.teamArr.push({ 'Tname': value, 'lead': lead, 'mem': mem })
      // console.log(fieldType[fieldType.length - 1].value);
      if (fieldType[fieldType.length - 1].value == '' || fieldType[fieldType.length - 1].lead.length == 0) {
        this.$toasted.show('Enter Team Details ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      } else {
        this.members = [];
        this.manager = [];
        var index = this.tLead.indexOf(lead);
        this.remData[index] = this.tLead.splice(index, 1);
        fieldType.push({ value: '', mem: [], lead: [] });
        this.tMem = this.tLead
        this.teamArrLen++;
      }

    },
    saveField(value, lead, mem) {

      if (this.teamArr.length > 0) {
        if (value == '') {
          this.$toasted.show('Enter Team name ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
          this.options[this.teamArrLen].lead = [];
          this.options[this.teamArrLen].mem = [];
        } else if (mem.length == 0) {
          this.$toasted.show('Enter Team members ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
          this.options[this.teamArrLen].lead = [];
        } else {
          if (this.teamArr[this.teamArrLen - 1].Tname == value) {
            this.teamArr[this.teamArrLen - 1].lead = lead;
          } else {
            this.teamArr.push({ 'Tname': value, 'lead': lead, 'mem': mem })

          }
        }
      } else {
        if (value == '') {
          this.$toasted.show('Enter Team name ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
          this.options[0].lead = [];
          this.options[0].mem = [];
        } else if (mem.length == 0) {
          this.$toasted.show('Enter Team members ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
          this.options[0].lead = [];
        } else {
          this.teamArr.push({ 'Tname': value, 'lead': lead, 'mem': mem })

        }
      }
    },
    removeField(index, fieldType, mem) {
      if (this.remData[index - 1]) this.tLead.push(this.remData[index - 1][0]);
      mem.forEach(element => {
        this.tLead.push(element);
      });
      fieldType.splice(index, 1);
      this.teamArr.splice(index, 1);
      this.tMem = this.membersArray;
      this.teamArrLen--;
    },

    customFormatter(date) {
      return moment(date).format('Y-MM-DD');
    },
    async getMembersList() {
      const response = await Service.GetMembers();
      if (response.code == "200") {
        this.membersArray = response.data;
        this.Updatedmembers = this.membersArray;

        this.options = [{ value: "", mem: [], lead: [] }],
          // this.options[0].mem = this.membersArray;
          this.tMem = this.tLead = this.membersArray;
        this.memLen = this.membersArray.length;
      }
    },
    async getAwsS3Config() {
      const response = await Service.getAwsS3Configs();
      if (response.code == "200") {
        this.s3 = response.data;

      }
    },
    save() {
      if (this.name == null) {
        this.$toasted.show('Project name is mandatory field ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      } 
      else if (this.client == null) {
        this.$toasted.show('Client name is mandatory field ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

      }
      else if (this.priority == null) {
        this.$toasted.show('Select priority ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

      }
      else if (this.Manpower == null) {
        this.$toasted.show('Manpower Calculation Type is mandatory field ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

      }
      else if (this.unit_rate == null) {
        this.$toasted.show('Project unit Rate is mandatory field ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

      }
      else if (this.rate == null) {
        this.$toasted.show('Project Rate is mandatory field ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

      }
      else if (this.sdate == null) {
        this.$toasted.show('Starting date is mandatory field ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

      }
      else if (this.edate == null) {
        this.$toasted.show('End date is mandatory field ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

      }
      else if (this.details == null) {
        this.$toasted.show('Project Details is mandatory field ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

      }
      // else if (this.members == null) {
      //   this.$toasted.show('Project members is mandatory field ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

      // }
      // else if (this.manager == null) {
      //   this.$toasted.show('Project Manager is mandatory field ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

      // } 
      else {

        const credentials = {
          name: this.name,
          client: this.client,
          priority: this.priority.value,
          // members: this.members,
          manpower: this.Manpower?.id,
          unit_rate: this.unit_rate,
          rate: this.rate,
          sdate: this.customFormatter(this.sdate),
          edate: this.customFormatter(this.edate),
          details: this.details,
          // lead: this.manager?.id,
          file: this.file,
          // team: this.teamArr,
        };
        // console.log(credentials);
        this.AddProject(credentials);
      }
    },
    async AddProject(credentials) {
      this.loader = true;
      const response = await Service.AddProjects(credentials);
      if (response.code == "200") {
        this.loader = false;
        this.$toasted.show(' Project Created successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        this.$router.push('/home/project');
      } else if (response.code == "400") {
        this.loader = false;
        this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }
    },
    removeEmp(members, index) {
      this.tLead = this.tMem.filter(x => !members.includes(x));
    },

    onFileChange(e) {
      if (this.name == null) {
        this.$toasted.show('Project name is mandatory field ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
        e.target.value = null;
        return
      } else {
        var files = e.target.files || e.dataTransfer.files
        if (!files.length) {
          return
        }


        let file = files[0];

        const config = {
          bucketName: this.s3.bucketName,
          dirName: 'project_doc', /* optional */
          region: this.s3.region,
          accessKeyId: this.s3.accessKeyId,
          secretAccessKey: this.s3.secretAccessKey,
          s3Url: 'https://bktqaptive.s3.amazonaws.com', /* optional */
        }

        const S3Client = new S3(config);
        S3Client.uploadFile(file, this.name).then(response => {
          this.file = response.location;


        })



      }

    },
  }
};
</script>
<style lang="scss">
@import "vue-select/dist/vue-select.css";
</style>