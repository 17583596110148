<template>
    <div><br>
        <Breadcrumbs main="Employees" title="Employee payroll components" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <h5>{{ userDet.name }}</h5>
                                        <span>
                                            {{ userDet.desig }}
                                        </span>
                                    </div>
                                    <div class="col right-text">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5">
                        <div class="card">
                            <div class="row m-0">
                                <div class="card-body">
                                    <div class="card-header">
                                        <h6 class="card-title mb-0">Recursive components</h6>

                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <v-select v-model="pay_comp" label="text" placeholder="Components"
                                                :options="items" v-on:input="addValToEquation()" :multiple="false" />
                                        </div>
                                    </div>
                                    <div v-if="showCompDet"><br>
                                        <div class="row">
                                            <div class="col-2"><label class="form-label">
                                                    Name</label><br></div>
                                            <div class="col-4"><label class="form-label">Calculation
                                                    value</label><br></div>
                                            <div class="col-3"><label class="form-label">Components</label><br></div>
                                        </div>
                                        <div class="row">

                                            <div class="col-2">
                                                <div class="form-group">

                                                    <label class="form-label">
                                                        <h6>{{ pay_comp.text }}</h6>
                                                    </label>

                                                </div>
                                            </div>
                                            <div class="col-5">
                                                <div class="form-group">

                                                    <textarea class="form-control" type="text" rows="2" id="c_form_name"
                                                        v-model="pay_comp.value" placeholder="Calculation value">
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <v-select v-model="equ_pay_comp" label="name" placeholder="Components"
                                                        :options="comp_det" v-on:input="makeEqaution1" :multiple="false" />
                                                </div>
                                            </div>
                                            <div class="col-1">
                                                <div class="form-group">
                                                    <svg @click="addForSaveComp" xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24" width="24" height="24"
                                                        class="ml-2 cursor-pointer">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path fill="green"
                                                            d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-7">
                        <div class="card">
                            <div class="card-body">
                                <div class="card-header">
                                    <h6 class="card-title mb-0">Non-Recursive components</h6>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <v-select v-model="pay_comp2" label="text" placeholder="Components"
                                            :options="nonRecurComp" v-on:input="addNonReccValToEquation()"
                                            :multiple="false" />
                                    </div>
                                </div>
                                <div v-if="showCompDet2"><br>
                                    <div class="row">
                                        <div class="col-2"><label class="form-label">
                                                Month</label><br></div>
                                        <div class="col-2"><label class="form-label">
                                                Name</label><br></div>
                                        <div class="col-4"><label class="form-label">Calculation
                                                value</label><br></div>
                                        <div class="col-3"><label class="form-label">Components</label><br></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <div class="form-group">
                                                <datepicker :minimumView="'month'" formate="MMM-yyyy"
                                                    input-class="datepicker-here form-control digits" v-model="date">
                                                </datepicker>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-group">

                                                <label class="form-label">
                                                    <h6>{{ pay_comp2.text }}</h6>
                                                </label>

                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">

                                                <textarea class="form-control" type="text" rows="2" id="c_form_name"
                                                    v-model="pay_comp2.value" placeholder="Calculation value">
                                                    </textarea>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <v-select v-model="equ_pay_comp2" label="name" placeholder="Components"
                                                    :options="comp_det" v-on:input="makeEqaution2" :multiple="false" />
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div class="form-group">
                                                <svg @click="addForSaveComp2" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer">
                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                    <path fill="green"
                                                        d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-header">
                                        <h6 class="card-title mb-0">All components</h6>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <b-table show-empty :items="allCompArr" :fields="tablefields" :filter="filter"
                                                :current-page="currentPage" :per-page="perPage">
                                                <template #cell(month)="rows">

                                                    <span v-if="rows.item.month == ''">
                                                        Not applicable
                                                    </span>
                                                    <span v-else>
                                                        {{ rows.item.month }}
                                                    </span>

                                                </template>
                                                <template #cell(type)="rows">
                                                    <b-badge :variant="statusVariant(rows.item.type)">

                                                        <span v-if="rows.item.type == 1">
                                                            Recurring
                                                        </span>
                                                        <span v-else>
                                                            Non-recurring
                                                        </span>
                                                    </b-badge>
                                                </template>
                                                <template #cell(actions)="row">
                                                    <span>
                                                        <feather type="trash" @click="removeFromTable(row.item)"></feather>
                                                    </span>
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-right">
                    <button class="btn btn-primary" type="submit" @click="saveEmpPayrollComp">
                        Submit
                    </button>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'

export default {
    components: {
        vSelect,
        Datepicker,

    },
    data() {
        return {
            tablefields: [
                { key: 'text', label: 'Name', sortable: true, },
                { key: 'value', label: 'Value', sortable: true, },
                { key: 'month', label: 'Month', sortable: true, },
                { key: 'type', label: 'Type', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],
            date: new Date(),
            items: [],
            userDet: [],
            nonRecurComp: [],
            pay_comp2: null,
            comp_det: [],
            pay_comp: null,
            j: -1,
            k: -1,
            l: -1,
            showCompDet: false,
            showCompDet2: false,
            equ_pay_comp: null,
            equ_pay_comp2: null,
            allCompArr: [],
            perPage: 100,
            currentPage: 1,
            filter: null,
            savedRecurrComp: [],
            savedNonRecurrComp: [],
            // savedComp: []
        };
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-success",
                2: "light-info",
            };

            return (status) => statusColor[status];
        },

    },
    mounted() {

        this.getUserComponentDetails();

    },
    methods: {
        findDifference(arr1, arr2) {
            var difference = [];
            difference = arr2.filter(obj1 => !arr1.find(obj2 => obj1.id === obj2.id));
            return difference;
        },
        removeFromTable(rData) {
            if (rData.type == 1) {
                this.items.push(rData)
                this.allCompArr = this.allCompArr.filter(item => item.id !== rData.id);
            } else if (rData.type == 2) {
                this.nonRecurComp.push(rData)
                this.allCompArr = this.allCompArr.filter(item => item.id !== rData.id);
            }
        },
        async getPayrollDetailsByUser() {
            const rData = {
                id: this.$route.params.id
            }
            const response = await Service.GetPayCompByType(rData);
            if (response.code == "200") {
                this.userDet = response.data.user;
                this.comp_det = [];
                this.items = []
                this.comp_det = response.data.payrollData;

                response.data.payrollData.forEach(element => {
                    if (element.recurring_type == 1) {
                        this.items.push({
                            id: element.id,
                            value: element.calc_value,
                            text: element.name,
                            month: '',
                            type: 1
                        })
                    } else {

                        this.nonRecurComp.push({
                            id: element.id,
                            value: element.calc_value,
                            text: element.name,
                            month: '',
                            type: 2
                        })
                    }

                });
                // console.log('fisrt', this.savedRecurrComp, this.items);
                // console.log('ebd', this.savedNonRecurrComp, this.nonRecurComp);
                this.items = this.findDifference(this.savedRecurrComp, this.items)
                this.nonRecurComp = this.findDifference(this.savedNonRecurrComp, this.nonRecurComp)

            }
        },
        addForSaveComp() {
            if (!this.allCompArr.includes(this.pay_comp)) {
                this.allCompArr.push(this.pay_comp);
                this.items = this.items.filter(item => item.id !== this.pay_comp.id);
            } else {
                this.$toasted.show(' Component already exist ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            // console.log(this.pay_comp);
            this.pay_comp = '';
            this.showCompDet = false;
            // console.log(this.pay_comp);
        },
        addForSaveComp2() {
            let day = this.date.getDate().toString().padStart(2, '0');
            let month = (this.date.getMonth() + 1).toString().padStart(2, '0');
            let year = this.date.getFullYear();
            let formattedDate = `${year}-${month}-${day}`;
            this.pay_comp2.month = formattedDate
            // console.log(this.allCompArr, this.pay_comp2);
            // console.log(this.allCompArr.includes(this.pay_comp2));
            if (!this.allCompArr.includes(this.pay_comp2)) {
                let day = this.date.getDate().toString().padStart(2, '0');
                let month = (this.date.getMonth() + 1).toString().padStart(2, '0');
                let year = this.date.getFullYear();
                let formattedDate = `${year}-${month}-${day}`;
                this.pay_comp2.month = formattedDate
                this.allCompArr.push(this.pay_comp2);
                this.nonRecurComp = this.nonRecurComp.filter(item => item.id !== this.pay_comp2.id);
            } else {
                this.$toasted.show(' Component already exist ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            this.pay_comp2 = '';
            this.showCompDet2 = false;
        },
        makeEqaution1() {
            this.pay_comp.value = this.pay_comp.value + '_' + this.equ_pay_comp.name + "_";
        },
        makeEqaution2() {
            this.pay_comp2.value = this.pay_comp2.value + '_' + this.equ_pay_comp2.name + "_";
        },
        addNonReccValToEquation() {
            this.showCompDet2 = true
        },
        addValToEquation() {
            this.showCompDet = true
        },
        saveEmpPayrollComp() {
            if (this.allCompArr.length == 0) {
                this.$toasted.show(' Enter component details ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                const comp_data = {
                    user: this.$route.params.id,
                    pay_det: this.allCompArr
                }
                this.addEmployeeComponents(comp_data);
                // console.log(comp_data);
            }
        },
        async addEmployeeComponents(data) {
            const response = await Service.AddEmployeeComponents(data);
            if (response.code == '200') {
                this.$toasted.show(this.userDet.name + 's Components saved successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/employees');
            } else if (response.code == '400') {
                //message = response.
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getUserComponentDetails() {
            var rqData = {
                id: this.$route.params.id
            }
            const response = await Service.GetUserComponentDetails(rqData);
            if (response.code == '200') {
                this.allCompArr = response.data;
                console.log(this.allCompArr);
                response.data.forEach(element => {
                    if (element.type == 1) {
                        this.savedRecurrComp.push(element)
                    } else {
                        this.savedNonRecurrComp.push(element)
                    }
                });
                this.getPayrollDetailsByUser();

            }
        }
    }

};

</script>
<style>
    .right-text{
        text-align: center;
    }
</style>