<template>
    <div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <label for="name">Title</label>
                                        <b-form-input type="text" id="designation" v-model="name" placeholder="Title">
                                        </b-form-input>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="c_form_company">Task Type</label>
                                        <v-select v-model="task" label="name" :options="taskType" />
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="c_form_company">Employee</label>
                                        <v-select v-model="employee" label="user" :options="employees" />

                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Description</label>
                                            <!-- <textarea class="form-control" rows="2" placeholder="Enter Description"
                                                v-model="descrip">
                                            </textarea> -->

                                            <vue-editor v-model="descrip"></vue-editor>

                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">

                                </div>
                                <div class="form-row">


                                    <!-- <div class="col-md-6 mb-3">
                                        <label for="c_form_departments">Employee</label>
                                        <v-select
                                            v-model="employee"
                                            label="user"
                                            :options="employees" 
                                        />
                                    </div> -->
                                </div>
                                <b-button type="submit" variant="primary">Update</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import moment from "moment";
import { VueEditor } from 'vue2-editor';
export default {

    components: {
        vSelect,
        VueEditor
    },

    data() {
        return {
            success: false,
            descrip: '',
            curr_start_date: null,
            curr_end_date: null,
            taskType: [],
            // projects: [],
            name: '',
            project_id_task: null,
            task: null,
            startEnd: null,
            startEndStatus: [],
            employees: [],
            employee: null,
            chk_stat: null,
            pSdate: null,
            pEdate: null,
            id_pro: null,
            pManager: null,
            currUser: null,
            loginData: JSON.parse(localStorage.getItem('loginDetails')),
        };
    },
    props: ['taskEditId', 'heading'],
    computed: {

    },
    created() {
        var head = 2;
        // this.heading = head;
        this.$emit('update:heading', head);
        this.getTaskById(this.taskEditId.val_type);
        this.getTasks();
        this.pManager = this.loginData.designation.level.key;
        this.currUser = this.loginData.id;
        // console.log(this.taskEditId);
        if (this.pManager == 'MANAGER') {
            this.taskEditId.allMem.forEach(element => {
                element.mem.forEach(element1 => {
                    // console.log(element1);
                    this.employees.push(element1);
                });
            });
        } else {
            var pro_id = this.$route.params.id;
            const req = {
                id: pro_id,
                user_id: this.currUser
            }
            this.getMemForLead(req)
            // var flag = 0;
            // this.taskEditId.allMem.forEach(element => {
            //     element.mem.forEach(element1 => {
            //         // console.log(element1);
            //         // this.employees.push(element1);
            //         if ((element1.user_id == this.currUser) && (element1.role == 1)) {
            //             // this.employees = element.mem;
            //             flag = 1;
            //         }
            //     });
            // });
            // if (flag == 0) {
            //     this.employees = [
            //         { user_id: this.currUser, user: this.loginData.name },
            //     ];
            // } else if (flag == 1) {
            //     const req = {
            //         id: pro_id,
            //         user_id: this.currUser
            //     }
            //     this.getMemForLead(req)
            // }
        }

    },
    methods: {
        async getMemForLead(req) {
            const response = await Service.GetMembersByTeam(req);
            if (response.code == '200') {
                this.employees = response.data;
            }
        },
        onCustomStyleSubmit(evt) {
            evt.preventDefault();
            // var sDate = new Date(this.pSdate.split('T')[0]).getTime();
            // var eDate = new Date(this.pEdate.split('T')[0]).getTime();
            // var today = new Date().getTime();

            if (this.name == '' || this.task == null) {
                this.success = false;
                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                this.success = true;
                this.saveTask();
            }

        },
        saveTask() {
            var today = moment(new Date()).format("DD-MM-YYYY hh:mm:ss")
            var no_date = null;
            const task_data = {
                id: this.taskEditId.val_type,
                name: this.name,
                desc: this.descrip,
                type: this.task.id,
                user: this.employee.user_id,
                project: this.project_id_task,
                startDate: no_date,
                endDate: no_date,
            };

            // if (this.chk_stat == this.startEnd) {
            //     task_data.startDate = this.curr_start_date;
            //     task_data.endDate = this.curr_end_date;
            // } else {
            //     if (this.startEnd == '2') {
            //         task_data.startDate = today;
            //     } else if (this.startEnd == '3') {
            //         task_data.endDate = today;
            //     }
            // }
            this.updateTask(task_data)
        },
        async updateTask(task_data) {
            const response = await Service.UpdateTask(task_data);
            if (response.code == '200') {
                this.$toasted.show(' Task updated successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.taskEditId.val_type = '';
                this.$emit('update:taskEditId', this.taskEditId);
            } else if (response.code == "400") {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
        },
        async getTasks() {
            const response = await Service.GetTaskType();
            if (response.code == '200') {
                this.taskType = response.data;
            }
        },
        // async getUsers(pId) {
        //     const reqDataUser = {
        //         id: this.project_id_task,
        //     }

        //     const response = await Service.GetProjectDetails(reqDataUser);
        //     if (response.code =='200') {

        //         this.employees = response.data.all.members;

        //     }
        // },
        // async getUserAssign(task){
        //     const reqDataTask = {
        //         task_id: task,
        //     }
        //     const response = await Service.GetUserAssignTask(reqDataTask);
        //     if (response.code =='200') {
        //         console.log(response.data[0]);
        //         this.employee = response.data[0];
        //     }
        // },
        async getTaskById(task_id) {
            const ReqData = {
                id: task_id,
            }
            const response = await Service.GetEmpTaskById(ReqData);
            if (response.code == '200') {

                // this.chk_stat = response.data.status;

                // if (response.data.status == '1') {
                //     this.startEnd = '1';
                //     this.startEndStatus = [{ value: '1', text: 'Pending' },
                //                             { value: '2', text: 'Start' },];
                // } else if (response.data.status == '2') {
                //     this.startEnd = '2';
                //     this.startEndStatus = [{ value: '2', text: 'Start' },
                //                             { value: '3', text: 'End' },];
                // } else if (response.data.status == '3') {
                //     this.startEnd = '3';
                //     this.startEndStatus = [{ value: '3', text: 'End' },];
                // }
                this.name = response.data.name;
                this.descrip = response.data.desc;
                this.task = { name: response.data.type, id: response.data.type_id };
                this.employee = response.data.user;
                this.project_id_task = response.data.project_id;
                // this.curr_start_date = this.startDate = response.data.start_date ? response.data.start_date.split('T')[0] : null;
                // this.curr_end_date = this.endDate = response.data.end_date ? response.data.end_date.split('T')[0] : null;
                // this.pSdate = response.data.pStartDate;
                // this.pEdate = response.data.pEndDate;
                // this.getUsers(this.project_id_task);
                // this.getUserAssign(response.data.id);
            }
        }
    }
};
</script>
<style>
.ck-content {
    height: 150px;
}
</style>