<template>
    <div>
        <Breadcrumbs main="Leave" title="User Leave Details" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="user-profile">
                <div class="row">
                    <!-- user profile first-style start-->
                    <div class="col-sm-12">
                        <br /><br />
                        <div class="card hovercard text-center">
                            <div class="user-image">
                                <div class="avatar">
                                    <!-- <img alt="" src="../../assets/images/user/7.jpg"> -->

                                    <img v-if="this.items.image_name" class="mr-3 rounded-circle"
                                        :src="this.items.image_name" style="height:100;width:100;" alt="" />

                                    <img v-else class="mr-3 rounded-circle" src="../../assets/images/user/user.png"
                                        style="height:100;width:100;" alt="" />
                                </div>

                            </div>
                            <div class="info">
                                <div class="row">
                                    <div class="col-sm-12 col-lg-12 order-sm-0 order-xl-1">
                                        <div class="user-designation">
                                            <div class="title"><a target="_blank" href=""> {{ this.items.emp_name }}</a>
                                                <span v-if="this.items.emp_ref"> ({{ this.items.emp_ref }}) </span>
                                            </div>
                                            <div class="desc mt-2">{{ this.items.emp_design }}</div>

                                        </div>
                                        <hr />
                                    </div>

                                </div><br /><br />

                                <div class="row">
                                    <div class="col-sm-6 col-lg-6 order-sm-1 order-xl-0">
                                        <br>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="ttl-info text-left">
                                                    <h6><i class="fa fa-briefcase"></i> Leave Type</h6>
                                                    {{ this.items.type }}
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="ttl-info text-left">
                                                    <h6><i class="fa fa-comment"></i> Leave Reason</h6><span>{{
                                                        this.items.reason
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="ttl-info text-left">
                                                    <h6><i class="fa fa-calendar mr-2"></i>From date</h6><span>{{
                                                        this.items.start_date ? this.items.start_date.split("T")[0] : ""
                                                    }}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="ttl-info text-left">
                                                    <h6><i class="fa fa-calendar mr-2"></i>To date</h6><span>{{
                                                        this.items.end_date ? this.items.end_date.split("T")[0] : ""
                                                    }}</span>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="ttl-info text-left">
                                                    <h6><i class="fa fa-flag"></i> Leave Status</h6>
                                                    <span>
                                                        <b-badge :variant="statusVariant(this.items.status)">

                                                            <span v-if="this.items.status == 1">
                                                                Pending
                                                            </span>
                                                            <span v-else-if="this.items.status == 2">
                                                                Acknowledged
                                                            </span>
                                                            <span v-else-if="this.items.status == 3">
                                                                Approved
                                                            </span>
                                                            <span v-else-if="this.items.status == 5">
                                                                Rejected
                                                            </span>
                                                        </b-badge>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-md-6" v-if="this.items.file_name">
                                                <div class="ttl-info text-left">
                                                    <h6><i class="fa fa-file"></i> Document</h6>
                                                    <a :href="this.items.file_name" target="_blank" download>
                                                        <feather type="download">Download</feather>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                    <div class="col-sm-6 col-lg-6 order-sm-1 order-xl-0">

                                        <h6 class="mb-0">Employee Leave Balance</h6> <br>

                                        <div class="table-responsive datatable-vue">
                                            <b-table show-empty stacked="md" :items="items_leaves" :fields="tablefields">

                                            </b-table>
                                        </div>

                                    </div>
                                </div>
                                <hr>
                                <div class="social-media">
                                    <ul class="product-color m-t-15">
                                        <li @click="changeStatus(3)"
                                            v-if="(datalayout.designation.level.key == 'HR') && (this.items.status == '1' || this.items.status == '2')">
                                            <i class="fa fa-check-square-o" title="Approve" :style="'font-size: 24px;'"></i>
                                        </li>
                                        <li @click="changeStatus(2)"
                                            v-if="(this.items.status == '1') && (datalayout.designation.level.key == 'MANAGER' || datalayout.designation.level.key == 'MANAGEMENT')">
                                            <i class="fa fa-eye" title="Acknowledge" :style="'font-size: 24px;'"></i>
                                        </li>
                                        <li @click="changeStatus(5)"
                                            v-if="this.items.status == '1' || this.items.status == '2' || this.items.status == '3'">
                                            <i class="fa fa-trash-o" title="Cancel" :style="'font-size: 24px;'"></i>
                                        </li>

                                    </ul>
                                </div>

                                <!-- <div class="social-media" v-if="this.items.status == '1' || this.items.status == '2'">
                                    <ul class="list-inline">
                                        
                                        <li class="list-inline-item" @click="changeStatus(3)" v-if="this.items.status != '2' && datalayout.designation.level.key == 'HR'">
                                            <a>
                                                <i class="fa fa-check-square-o">
                                                    <span> Approve</span>
                                                </i>
                                            </a>
                                        </li>
                                        
                                        <li class="list-inline-item" @click="changeStatus(2)" v-if="this.items.status != '2' && datalayout.designation.level.key == 'MANAGER'">
                                            <a>
                                                <i class="fa fa-eye">
                                                    <span> Acknowledge</span>
                                                </i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item" @click="changeStatus(5)">
                                            <a>
                                                <i class="fa fa-trash-o">
                                                    <span> Cancel</span>
                                                </i>
                                            </a>
                                        </li>
                                    </ul>
                                </div> -->
                                <div class="follow">
                                    <div class="row">
                                        <div class="col-6 text-md-right">

                                        </div>

                                        <div class="col-6 text-md-right" v-if="this.items.status == 3">
                                            <a :href="this.items.pdf" target="_blank" download><b-button
                                                    id="default-primary" variant="primary">Print</b-button></a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Service from '@/services/Service.js';
import moment from 'moment'
import Swal from "sweetalert2";
import ServiceApi from "@/services/ServiceApi.js";

export default {
    data() {
        return {
            items: [],
            items_leaves: [],
            LvStatus: null,
            user_id: null,
            holidays: [],
            holidays_arr: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            filter: null,
            pageOptions: [10, 25, 50],
            tablefields: [
                { key: "leave_type_name", label: "Leave Type", sortable: true },
                { key: "count", label: "Leave Balance", sortable: true },
            ],
            datalayout: JSON.parse(localStorage.getItem('loginDetails'))
        };
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-warning",
                2: "light-info",
                3: "light-success",
                4: "light-danger",
                5: "light-danger",
            };

            return (status) => statusColor[status];
        },

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        }

    },
    mounted() {

        this.retrieveLeaveList();
        this.getHolidays();
        //this.getLeaveCountByUser();
    },
    methods: {
        customFormatter(date) {
            return moment(date).format('Y-MM-DD');
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async retrieveLeaveList() {
            const ReqData = {
                id: this.$route.params.id,
            };
            const response = await Service.GetLeaveById(ReqData);
            if (response.code == "200") {
                this.items = response.data;


                this.user_id = response.data.user_id;

                const Data = {
                    id: this.user_id,
                };


                const resp = await ServiceApi.GetLeaveCountByUser(Data);

                if (resp.code == "200") {
                    this.items_leaves = resp.data;

                    this.totalRows = resp.data.length;

                }


            }
        },
        async getHolidays() {
            const response = await ServiceApi.GetHolidays();
            if (response.code == '200') {

                var date_obj = {};
                var holiday_arr = [];

                response.data.forEach(element => {

                    date_obj = {

                        date: element.date,
                    }
                    holiday_arr.push(date_obj);

                });
                this.holidays = holiday_arr;

            }
        },
        changeStatus(changeStat) {

            if (changeStat == 5) {

                Swal.fire({
                    title: "Warning !",
                    // text: "Assuming that leave was taken, You can't cancel the leave if the employee has already taken it.",
                    html: "<h6><b>Assuming that leave was taken, You can't cancel the leave if the employee has already taken it.</b></h6><br><h6>Click continue to cancel the leave if the employee did not take it.</h6>",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Continue",
                    cancelButtonText: "Back",
                }).then((result) => {
                    if (result.isConfirmed) {


                        Swal.fire({
                            title: "Are you sure?",
                            text: "are you sure to cancel the leave",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, Sure!",
                            cancelButtonText: "No!",
                        }).then((result) => {
                            if (result.isConfirmed) {


                                //month array calc

                                this.holidays.forEach(dates => {
                                    var day = moment(dates.date)
                                    this.holidays_arr.push(day.format('MM/DD/YYYY'));

                                });

                                var leave_start_date = this.items.start_date.split("T")[0];
                                var leave_end_date = this.items.end_date.split("T")[0];

                                var date1 = new Date(this.customFormatter(leave_start_date));
                                var date2 = new Date(this.customFormatter(leave_end_date));

                                var getDaysBetweenDates = function (startDate, endDate) {
                                    var now = startDate.clone(), dates = [];

                                    while (now.isSameOrBefore(endDate)) {
                                        dates.push(now.format('MM/DD/YYYY'));
                                        now.add(1, 'days');
                                    }
                                    return dates;
                                };

                                var startDate = moment(date1);
                                var endDate = moment(date2);

                                var dateList = getDaysBetweenDates(startDate, endDate);

                                var date_arr1 = []
                                var date_arrr = []

                                dateList.forEach(dates => {
                                    let date = new Date(dates);
                                    let day = date.toLocaleString('en-us', { weekday: 'long' });
                                    if ((day != 'Saturday') && (day != 'Sunday')) {
                                        date_arr1.push(dates);
                                    }

                                });
                                date_arrr = date_arr1.filter(val => !this.holidays_arr.includes(val));

                                var result_obj = this.getDateDetails(dateList, leave_start_date, leave_end_date, this.items.start_session, this.items.end_session)
                                var result_obj2 = this.getDateDetails(date_arrr, leave_start_date, leave_end_date, this.items.start_session, this.items.end_session)

                                //month array end

                                const ReqData = {
                                    id: this.$route.params.id,
                                    status_to: changeStat,
                                    month_array: result_obj.month_arr,
                                    month_array_without: result_obj2.month_arr,
                                    start: leave_start_date,
                                    end: leave_end_date,
                                    type: this.items.type,
                                    user_id: this.user_id
                                };
                                this.cancelLeave(ReqData);
                            }
                        });
                    }
                });

            }
            else {
                var texts = '';
                if (changeStat == 3) {
                    texts = "are you sure to Approve this leave"
                }
                else if (changeStat == 2) {
                    texts = "are you sure to Acknowledge this leave"
                }
                Swal.fire({
                    title: "Are you sure?",
                    text: texts,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Sure!",
                }).then((result) => {
                    if (result.isConfirmed) {


                        //month array calc

                        this.holidays.forEach(dates => {
                            var day = moment(dates.date)
                            this.holidays_arr.push(day.format('MM/DD/YYYY'));

                        });

                        var leave_start_date = this.items.start_date.split("T")[0];
                        var leave_end_date = this.items.end_date.split("T")[0];

                        var date1 = new Date(this.customFormatter(leave_start_date));
                        var date2 = new Date(this.customFormatter(leave_end_date));

                        var getDaysBetweenDates = function (startDate, endDate) {
                            var now = startDate.clone(), dates = [];

                            while (now.isSameOrBefore(endDate)) {
                                dates.push(now.format('MM/DD/YYYY'));
                                now.add(1, 'days');
                            }
                            return dates;
                        };

                        var startDate = moment(date1);
                        var endDate = moment(date2);

                        var dateList = getDaysBetweenDates(startDate, endDate);

                        var date_arr1 = []
                        var date_arrr = []

                        dateList.forEach(dates => {
                            let date = new Date(dates);
                            let day = date.toLocaleString('en-us', { weekday: 'long' });
                            if ((day != 'Saturday') && (day != 'Sunday')) {
                                date_arr1.push(dates);
                            }

                        });
                        date_arrr = date_arr1.filter(val => !this.holidays_arr.includes(val));

                        var result_obj = this.getDateDetails(dateList, leave_start_date, leave_end_date, this.items.start_session, this.items.end_session)
                        var result_obj2 = this.getDateDetails(date_arrr, leave_start_date, leave_end_date, this.items.start_session, this.items.end_session)

                        //month array end

                        const ReqData = {
                            id: this.$route.params.id,
                            status_to: changeStat,
                            month_array: result_obj.month_arr,
                            month_array_without: result_obj2.month_arr,
                            start: leave_start_date,
                            end: leave_end_date,
                            type: this.items.type,
                            user_id: this.user_id
                        };
                        this.cancelLeave(ReqData);
                    }
                });
            }



        },

        async cancelLeave(ReqData) {
            const response = await Service.ChangeEmpLeaveStatus(ReqData);
            if (response.code == "200") {
                this.$toasted.show(' Status updated successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.retrieveLeaveList();
            }
            else if (response.code == '400') {
                this.loader = false;
                this.$toasted.show(response.message + ' ' + response.data, { theme: 'outline', position: 'top-right', type: 'error', duration: 3000 });
            }
            else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        getDateDetails(dateList, start_date, end_date, session1, session2) {

            var date1 = new Date(this.customFormatter(start_date));
            var date2 = new Date(this.customFormatter(end_date));
            var Difference_In_Time = date2.getTime() - date1.getTime();

            var month_arr = [];
            var month;
            var year;
            var x = 0;
            var result_arr = [];
            dateList.forEach(dates => {
                month = Number(dates.split('/')[0]);
                year = dates.split('/')[2]
                // month_arr.push(month);
                month_arr.push({ month_val: month, year_val: year });

                if (x != dates.split('/')[0]) {
                    result_arr.push(dates.split('/')[0]);
                    x = dates.split('/')[0];
                } else {

                }
            });

            var month_array = []

            month_arr.sort();

            var current = null;
            var cnt = 0;
            for (var i = 0; i < month_arr.length; i++) {
                if (month_arr[i].month_val != current) {

                    if (cnt > 0) {
                        month_array.push({ month: current, value: cnt, year: month_arr[0].year_val });
                    }
                    current = month_arr[i].month_val;
                    cnt = 1;
                } else {
                    cnt++;
                }
            }
            if (cnt > 0) {
                month_array.push({ month: current, value: cnt, year: month_arr[month_arr.length - 1].year_val });
            }

            var date_arr = []
            dateList.forEach(dates => {
                let date = new Date(dates);
                let day = date.toLocaleString('en-us', { weekday: 'long' });
                if ((day != 'Saturday') && (day != 'Sunday')) {
                    date_arr.push(day);
                }

            });

            if (session1 && session2) {
                var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24));
                var date_arr_length = date_arr.length - 1;
                if (result_arr.length == 2) {
                    month_array[0].value = month_array[0].value - .5;
                    month_array[1].value = month_array[1].value - .5;
                } else if (result_arr.length == 1) {
                    month_array[0].value = month_array[0].value - 1;
                }
            } else if (session1 || session2) {
                var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24)) + .5;
                var date_arr_length = date_arr.length - .5;
                if (session1) {
                    month_array[0].value = month_array[0].value - .5;
                } else if (session2) {
                    if (result_arr.length == 2) {
                        month_array[1].value = month_array[1].value - .5;
                    } else if (result_arr.length == 1) {
                        month_array[0].value = month_array[0].value - .5;
                    }
                }
            } else {
                var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24)) + 1;
                var date_arr_length = date_arr.length;
            }
            var obj = { 'date_arr_length': date_arr_length, 'diff_in_days': Difference_In_Days, 'month_arr': month_array };
            return obj;
        },

    }
};

</script>
<style>
.product-color li {
    display: inline-block;
    border-radius: 100%;
    height: 31px;
    width: 41px;
    margin: -5px 2px;
}

.hovercard .info .social-media ul li {
    padding-top: 3px;
    margin-right: 10px;
}
</style>