<template>
    <div>
        <p>{{ message }}</p><br>
        <b-button variant="outline-warning" class="btn-xs" @click="handleButtonClick(uid)" style="float: right;"><i
                class="fa fa-check-circle"></i> Read</b-button>
    </div>
</template>
  
<script>
import Service from '@/services/Service.js';

export default {

    props: {
        message: String,
        uid: String
    },
    data() {
    return {
     
      loginData: JSON.parse(localStorage.getItem('loginDetails')),
      
    };
  },
    methods: {
        handleButtonClick(parameter) {
            // Perform custom action with the parameter
            // console.log('Button clicked with parameter:', parameter);
            this.makeNotificationRead(parameter);
            
        },
        async makeNotificationRead(uid) {
            const reqDatas = {
                uid: uid,
                user_id: this.loginData.id
            }
            const response = await Service.makeNotificationRead(reqDatas);
            if (response.code ="200"){
                this.$toast.dismiss(uid);
            }
        }
    },
};
</script>