<template>
  <div>
    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 footer-copyright text-center">
            <p class="mb-0">Copyright {{ new Date().getFullYear() }} © Qaptive Technologies Pvt. Ltd</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import json from '../data/menu';
  import Service from '@/services/Service.js';
  export default {
    name: 'footerpage',
    data() {
      return {
        customizer: false,
        data:
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(json)),
      };
    },
    mounted: function () {
      // this.getConfigData()
      this. loginIsValid()
      if (localStorage.getItem("menuData") != null) {
        this.$store.commit("menu/getData", JSON.parse(localStorage.getItem("menuData")));
      }
    },
    methods: {
      // async getConfigData(){
      //   const response = await Service.getConfigApi();
      //   if (response.code == 200) {

      //     localStorage.setItem('config', JSON.stringify(response.data))
      //     if (response.last_status == 1) {
      //     localStorage.setItem('lastbuttonstatus', 'start')
      //   } else if (response.last_status == 2) {
      //     localStorage.setItem('lastbuttonstatus', 'stop')
      //   } else if (response.last_status == 3) {
      //     localStorage.setItem('lastbuttonstatus', 'pause')
      //   } else if (response.last_status == 4) {
      //     localStorage.setItem('lastbuttonstatus', 'resume')
      //   }else{
      //     localStorage.setItem('lastbuttonstatus', 'stop')
      //   }


      //   }
      // },
      loginIsValid:function(){
               
               if (localStorage.getItem("loginDetails") === null) {
                 localStorage.removeItem('loginDetails')
                 this.$router.push('/');
              }else{
                //  this.$router.push('/home');
              }
          },

        }
  };
</script>
<style>
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .media-body p {
  font-size: 12px !important;
  line-height: 3 !important;
  color: rgba(43,43,43,.7);
}

table .feather  {
    display: inline-block;
    overflow: hidden;
    width: 16px ;
    margin-right: 7px;
}

p {
  font-size: 13px;
  line-height: 2.3 !important;
  letter-spacing: 0.7px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.4 !important;
}
</style>
