<template>
    <!-- <v-app> -->
    <div>
        <Breadcrumbs main="Verify Passcode" title="Verify Passcode" />
       
     
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <b-col xl="4">
                                    </b-col>
                                <b-col xl="4">
                                    <div class="login-main login-form-card">
                      <h4>Unlock</h4>
                      <div class="form-group">
                        <label class="col-form-label"
                          >Enter Passcode</label
                        >
                        <input
                          class="form-control"
                          type="password"
                          name="login[password]"
                          required=""
                          placeholder="****"
                          v-model="pin"
                        />
                        <div
                             v-if="pinvalid"
                              class="feedbacksd"
                            >
                              Passcode is required
                            </div>
                      </div>
                      <div class="form-group mb-0">
                        
                        <button class="btn btn-primary btn-block" @click="VerifyPin()">
                          Unlock
                        </button>
                      </div>
          
                  </div>
                                </b-col>
                                <b-col xl="4">
                                    </b-col>
                            </b-row>
                        </div>

                    </div>

                </div>

            </div>
        </div>



    </div>
    <!-- </v-app> -->
</template>

<script>
import ServiceApi from '@/services/ServiceApi.js';


export default {
    components: {

    },

    data() {
        return {
            datalayout: JSON.parse(localStorage.getItem('loginDetails')),
            pin:null,
            pinvalid:null
        };
    },
    computed: {

        
    },
    mounted() {
      

    },
    methods: {
        VerifyPin(){
            if (this.pin !=null) {
                this.pinvalid = false; 
                var req = {
                    pin: this.pin,
                    }
                this.VerifyPassCode(req);
            }else{
                this.pinvalid = true; 
            }

        },
        async VerifyPassCode(req) {
          
            const response = await ServiceApi.VerifyPassCodeApi(req);
            if (response.code == '200') {
                this.$store.commit("changeToken",  response.data);
                this.$router.push('/home/generate-attendance');
            } else if (response.code == "400") {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },

    }
};
</script>
<style>
.feedbacksd {
    display: block !important;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
}

</style>