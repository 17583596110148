<template>
    <div><br>
        <Breadcrumbs main="Project" :title="proj_name" />
        <div class="container-fluid">
           
            <div class="row">
                <div class="col-md-12 project-list">
                    <div class="card card-p">
                        <div class="row m-0">
                            <div class="col-sm-12 p-0">
                                <b-tabs content-class="" active-nav-item-class="font-weight-bold text-uppercase"
                                    class="red-marg">
                                    <b-tab v-for="phaseData in items" class="red-marg">
                                        <template v-slot:title>
                                            <b-nav-item @click="getModules(phaseData)">
                                                <feather type="file-text"></feather> {{ phaseData.title }}
                                            </b-nav-item>
                                        </template>
                                        <b-card-text>
                                            <div class="col-sm-12 p-0">
                                                <div class="card card-p">
                                                    <div class="card-body">
                                                        <div class="row">

                                                            <div @click="goToProjectList()" v-if="pManager == 'EMPLOYEE'">
                                                                <b-avatar class="cust-color" variant="warning" size="2.5em">
                                                                    <feather type="arrow-left"></feather>
                                                                </b-avatar>
                                                            </div>
                                                            
                                                            <div class="add-mod-div"
                                                                v-if="pManager == 'MANAGEMENT' || has_prev == true">
                                                                <router-link
                                                                    :to="'/home/list-project-module/' + phaseData.id">
                                                                    <b-button @click="addModule(phaseData)" v-if="btn_prev || pManager == 'MANAGEMENT'"
                                                                        class="btn-pill pull-right mt-12"
                                                                        id="default-xs-primary" size="md"
                                                                        variant="outline-primary">
                                                                        Manage Module 
                                                                    </b-button>
                                                                </router-link>
                                                            </div><br><br>
                                                            <div class="accordion full-w" role="tablist">

                                                                <b-card no-body class="mb-1"
                                                                    v-for="(moduleData, index) in modules">
                                                                    <b-card-header header-tag="header" class="p-1"
                                                                        role="tab">
                                                                        <b-button block v-b-toggle="'accordion-' + index"
                                                                            :variant="changeColorWithTask(moduleData.tasks)">{{
                                                                                moduleData.module_name
                                                                            }}</b-button>
                                                                    </b-card-header>
                                                                    <b-collapse :id="'accordion-' + index" visible
                                                                        accordion="my-accordion" role="tabpanel">
                                                                        <b-card-body>
                                                                            <b-card-text>
                                                                                <div class="row">
                                                                                    <div class="col-md-1">
                                                                                        <div class="card custom-card bg-add-col cust-card"
                                                                                            @click="goToTaskAdd(moduleData)">
                                                                                            <div
                                                                                                class="text-center profile-details">
                                                                                                <h5 class="make-marg-add">Add
                                                                                                    Task
                                                                                                </h5>
                                                                                                <h6></h6>
                                                                                            </div>
                                                                                            <div class="card-footer footer-style row text-center">
                                                                                                <div class="col-sm-12 p-0">
                                                                                                    <br>
                                                                                                    <h3 class="counter">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                            viewBox="0 0 22 22"
                                                                                                            width="24"
                                                                                                            height="24"
                                                                                                            class="ml-1 cursor-pointer">
                                                                                                            <path
                                                                                                                fill="none"
                                                                                                                d="M0 0h24v24H0z" />
                                                                                                            <path
                                                                                                                fill="green"
                                                                                                                d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                                                                                        </svg>
                                                                                                    </h3>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-1">
                                                                                        <div class="card custom-card bg-add-col2 cust-card"
                                                                                            @click="goToBugAdd(moduleData)">
                                                                                            <div
                                                                                                class="text-center profile-details">
                                                                                                <h5 class="make-marg-add">Bug
                                                                                                    View
                                                                                                </h5>
                                                                                                <h6></h6>
                                                                                            </div>
                                                                                            <div class="card-footer footer-style row text-center">
                                                                                                <div class="col-sm-12 p-0">
                                                                                                    <br>
                                                                                                    <h3 class="counter">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"/></svg>
                                                                                                    </h3>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-2 around-pad"
                                                                                        v-for="(tasks, index) in moduleData.tasks" v-if="tasks.type == 1">
                                                                                        <div @click="goToTasks(tasks)"
                                                                                            :class="[tasks.started_task ? 'card custom-card cust-card bg-col-bulb' : 'card custom-card cust-card bg-col']">
                                                                                            <div
                                                                                                class="text-center profile-details">
                                                                                                <h5 v-b-tooltip.hover
                                                                                                    :title="tasks.full_title"
                                                                                                    class="make-marg">{{
                                                                                                        tasks.title }}</h5>
                                                                                                <h6></h6>
                                                                                            </div>
                                                                                            <div class="card-footer row">
                                                                                                <div class="col-sm-12 p-0">
                                                                                                    <h6 class="mb-0">Subtasks
                                                                                                    </h6>
                                                                                                    <h3 class="counter">{{
                                                                                                        tasks.sub_task_count
                                                                                                    }}
                                                                                                    </h3>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-2 around-pad"
                                                                                        v-for="(tasks, index) in moduleData.tasks" v-if="tasks.type == 2">
                                                                                        <div @click="goToTasks(tasks)"
                                                                                            :class="[tasks.started_task ? 'card custom-card cust-card bg-col-bulb' : 'card custom-card cust-card bg-col-bug']">
                                                                                            <div
                                                                                                class="text-center profile-details">
                                                                                                <h5 v-b-tooltip.hover
                                                                                                    :title="tasks.full_title"
                                                                                                    class="make-marg">{{
                                                                                                        tasks.title }}</h5>
                                                                                                <h6></h6>
                                                                                            </div>
                                                                                            <div class="card-footer row">
                                                                                                <div class="col-sm-12 p-0">
                                                                                                    <h6 class="mb-0">Subtasks
                                                                                                    </h6>
                                                                                                    <h3 class="counter">{{
                                                                                                        tasks.sub_task_count
                                                                                                    }}
                                                                                                    </h3>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </b-card-text>
                                                                        </b-card-body>
                                                                    </b-collapse>
                                                                </b-card>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card-text>
                                    </b-tab>

                                </b-tabs>

                            </div>
                           
                            <div class="col-sm-3 create-project-btn" v-if="pManager == 'MANAGER' || pManager == 'MANAGEMENT'">
                                <div class="text-right">
                                    <div class="form-group mb-0 mr-0">
                                        <router-link :to="'/home/manage-phase/' + $route.params.id">
                                            <b-button class="btn-pill pull-right mt-12" id="default-xs-info" size="md"
                                                variant="outline-info">
                                                <i data-feather="plus-square"> </i>Manage Phase
                                            </b-button>
                                        </router-link>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Service from "@/services/Service.js";
// import vSelect from "vue-select";
// import moment from "moment";
// import Swal from "sweetalert2";

export default {

    components: {

    },
    data() {
        return {
            loginData: JSON.parse(localStorage.getItem('loginDetails')),
            projectPhase: [],
            items: [],
            modules: [],
            projectDet: [],
            pManager: null,
            emp_id: JSON.parse(localStorage.getItem('id')),
            has_prev: false,
            btn_prev: false,
            proj_name: null
        };
    },
    mounted() {
        // this.getProjectDetails(this.$route.params.id);
        this.getPhaseById(this.$route.params.id);
        this.pManager = this.loginData.designation.level.key;
        // console.log(this.loginData);
    },
    methods: {
        goToProjectList() {
            this.$router.push('/home/employee-view/' + this.emp_id + '/1');
        },
        changeColorWithTask(tasks) {
            var stat = 0
            tasks.forEach(element => {
                if (element.started_task) {
                    stat = 1
                }
            });
            return stat ? 'info' : 'primary';
        },
        // async getProjectDetails(pid) {
        //     var ReqData = {
        //         id: pid
        //     }
        //     const response = await Service.GetProjectDetails(ReqData);
        //     if (response.code == "200") {
        //         this.projectDet = response.data.all;
        //         // console.log(this.projectDet);
        //     }
        // },
        goToTaskAdd(mod) {
            // alert(this.$route.params.id)
            this.$router.push('/home/project-task-add/' + mod.id);
        },
        goToBugAdd(mod){
            // this.$router.push('/home/project-bug-add/' + mod.id);
            this.$router.push('/home/project-bug-list/' + mod.id);

        },
        goToTasks(taks) {
            this.$router.push('/home/manage-task/' + taks.id);
        },
        async getModules(phase) {
            this.btn_prev = false;
            this.modules = []
            var phaseData = {
                id: phase.id,
                type: this.pManager,
                prev: this.has_prev

            }
            const response = await Service.GetPhaseById(phaseData);
            if (response.code == '200') {
                this.modules = response.data.data;
                this.btn_prev = response.data.flag;
                // console.log(this.modules);
            }
        },
        async getPhaseById(project) {
            const reqDatas = {
                project_id: project
            }
            const response = await Service.GetProjectPhase(reqDatas);
            if (response.code == "200") {
                this.items = response.data;
                this.proj_name = this.items[0].project_name;
                // console.log(this.items);
                this.items.forEach(element => {
                    element.mem.forEach(element1 => {
                        if (element1.id == this.loginData.id) {
                            this.has_prev = true;
                        }
                    });
                });
                this.getModules(this.items[0]);
            }else if(response.code == "404"){
                this.proj_name = response.data
            }
        },
        say: function (message) {
            this.activeclass = message;
        },
        statusVariant(status) {
            const statusColor = {
                // 5: "light-info",
                // 4: "light-info",
                '3': "light-warning",
                '2': "light-info",
                '1': "light-success",
            };
            return statusColor[status];
        },
    },
};
</script>
<style lang="scss">
.mt-12 {
    margin-top: 12px;
}

.full-w {
    width: 100%;
}

.ex-btn {
    background-color: #138496 !important;
    border-color: #138496 !important;
}

.add-mod-div {
    position: relative;
    left: 89%;
    padding: 0px;
    margin: -17px;
}

.btn {
    border-radius: 1.25rem !important;
}

.bg-col {
    background-color: #6187f0d4 !important;
}

.bg-col:hover {
    background-color: #3d70fad4 !important;
}

.bg-col-bug {
    background-color: rgba(230, 186, 68, 0.83) !important;
}

.bg-col-bug:hover {
    background-color: rgb(221 161 0 / 83%) !important;
}

.bg-col-bulb {
    background-color: #6187f0d4;
    animation: color-change 2s linear infinite;
}

@keyframes color-change {
    0% {
        background-color: #7d7bfdd4;
    }

    25% {
        background-color: rgb(236, 88, 3);
    }

    50% {
        background-color: rgb(255, 94, 54);
    }

    75% {
        background-color: rgb(117, 142, 253);
    }

    100% {
        background-color: #7d7bfdd4;
    }
}

.custom-card {
    overflow: hidden;
    padding: 20px !important;
}

.bg-add-col {
    background-color: #7fc7bbd4 !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.bg-add-col:hover {
    background-color: #04977fd4 !important;
}

.bg-add-col2 {
    padding-left: 6px !important;
    padding-right: 6px !important;
    background-color: rgb(235 191 137 / 86%) !important;
}

.bg-add-col2:hover {
    background-color: rgb(243 134 0 / 86%) !important;
}

p {
    line-height: 1.7 !important;
}

.make-marg {
    margin: -13px;
    font-size: 15px !important;
}

.make-marg-add {
    margin: -13px;
    font-size: inherit !important;
}

.cust-card {
    letter-spacing: -0.5px !important;
}

.around-pad {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.marTop {
    margin-top: 8px;
    cursor: pointer;
}

.marBot {
    margin-bottom: 0px !important;
}

.margTop {
    margin-top: 18px !important;
}

// .side-row {
//     padding-left: 20px;
//     padding-right: 10px;
//     padding-top: 18px;
//     padding-bottom: 30px;
// }

.ho-btn {
    width: 100%;
}

.card-p {
    margin-bottom: 10px !important;
}

// .mt-3,
// .my-3 {
//     margin-top: -1rem !important;
// }

.create-project-btn {
    max-width: 18% !important;
}

.red-marg {
    margin-top: -0.5rem !important;
}

.cust-color:hover {
    background-color: #cac701 !important;
}
.make-center{
    left: 25px !important;
}
.footer-style{
    width: 100% !important;
    margin-left: 0px !important;
}
</style>