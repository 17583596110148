<template>
    <div>
        <Breadcrumbs main="Resume Status" title="Add Resume Status" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="name">Status Name</label>
                                        <b-form-input type="text" id="name" v-model="c_form.name"
                                            :state="c_form_result.name" placeholder="Name"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.name">
                                        </b-form-valid-feedback>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="priority">Priority</label>
                                        <b-form-input type="number" id="priority" v-model="c_form.priority"
                                            :state="c_form_result.priority" placeholder="priority"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.priority">
                                        </b-form-valid-feedback>

                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="description">Description</label>
                                        <textarea class="form-control" rows="1" placeholder="Enter Description"
                                            v-model="c_form.description">
                                        </textarea>
                                        <!-- <b-form-valid-feedback :state="c_form.description">
                                            </b-form-valid-feedback> -->
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_assign_status">Assign status</label>
                                        <v-select v-model="c_form.assign_status" label="text" :options="types" />
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_assign_status">Applicant Mail status</label>
                                        <v-select v-model="c_form.mail_status" label="text" :options="types" />

                                    </div>
                                    <div class="col-md-6 mb-3" v-if="c_form.assign_status.value == '1'">
                                        <label for="c_form_assign_status"> Recruiter Mail status</label>
                                        <v-select v-model="c_form.recruiter_mail_status" label="text" :options="types" />

                                    </div>
                                    <div class="col-md-6 mb-3" v-if="c_form.assign_status.value == '2'">
                                        <div>
                                            <label for="c_form_assign_status">Interview Date</label>
                                            <v-select v-model="c_form.interview_status" label="text" :options="types" />
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <div v-if="this.c_form.mail_status.value == 1">
                                            <label for="mail_subject">Applicant Mail Subject</label>
                                            <b-form-input type="text" id="mail_subject" v-model="c_form.mail_subject"
                                                :state="c_form_result.mail_subject"
                                                placeholder="Applicant Mail Subject"></b-form-input>
                                            <b-form-valid-feedback :state="c_form_result.mail_subject">
                                            </b-form-valid-feedback>
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <div
                                            v-if="this.c_form.recruiter_mail_status.value == 1 && c_form.assign_status.value == '1'">
                                            <label for="mail_subject">Recruiter Mail Subject</label>
                                            <b-form-input type="text" id="recruiter_mail_subject"
                                                v-model="c_form.recruiter_mail_subject"
                                                :state="c_form_result.recruiter_mail_subject"
                                                placeholder="Recruiter Mail Subject"></b-form-input>
                                            <b-form-valid-feedback :state="c_form_result.recruiter_mail_subject">
                                            </b-form-valid-feedback>
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <div v-if="this.c_form.mail_status.value == 1">
                                            <label for="c_form_assign_status">Applicant Mail Content</label>
                                            <vue-editor v-model="c_form.mail_content"></vue-editor>
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <div
                                            v-if="this.c_form.recruiter_mail_status.value == 1 && c_form.assign_status.value == '1'">
                                            <label for="c_form_assign_status">Recruiter Mail Content</label>
                                            <vue-editor v-model="c_form.recruiter_mail_content"></vue-editor>
                                        </div>
                                    </div>


                                </div>

                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from '@/services/ServiceApi.js';
import vSelect from 'vue-select'
import { VueEditor } from 'vue2-editor';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'

export default {

    components: {
        vSelect,
        VueEditor,
        Datepicker,
    },

    data() {
        return {
            success: false,

            c_form: {
                name: '',
                description: '',
                priority: '',
                assign_status: '',
                interview_status: '',
                mail_status: '',
                mail_content: '',
                recruiter_mail_status: '',
                recruiter_mail_content: '',
                mail_subject: '',
                recruiter_mail_subject: ''

            },
            c_form_result: {
                name: null,
                description: null,
                priority: null,
                assign_status: null,
                mail_status: null,
                mail_content: null,
                recruiter_mail_status: null,
                recruiter_mail_content: null,
                mail_subject: null,
                recruiter_mail_subject: null
            },
            types: [

                { value: "1", text: "YES" },
                { value: "2", text: "NO" },
            ],

        };
    },
    computed: {

    },
    mounted() {

    },
    methods: {

        onCustomStyleSubmit(evt) {
            evt.preventDefault();

            if (this.c_form.name == '') {
                this.success = false;
                this.$toasted.show(' Please enter status name', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }

            else if (this.c_form.priority == '') {
                this.success = false;
                this.$toasted.show(' Please enter priority', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.priority < 0) {
                this.success = false;
                this.$toasted.show(' Please choose positive numbers', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.description == '') {
                this.success = false;
                this.$toasted.show(' Please enter description', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.assign_status.value == '') {
                this.success = false;
                this.$toasted.show(' Please select assign status', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.mail_status.value == '') {
                this.success = false;
                this.$toasted.show(' Please select applicant mail status', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.mail_status.value == 1 && this.c_form.mail_content == '') {

                this.success = false;
                this.$toasted.show(' Please enter applicant mail content', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.assign_status.value == "2" && this.c_form.interview_status == '') {
                
                this.success = false;
                this.$toasted.show(' Please enter Interview status', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                
            }

            else if (this.c_form.assign_status.value == "1") {
                if (this.c_form.recruiter_mail_status == '') {
                    this.success = false;
                    this.$toasted.show(' Please select recruiter mail status', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                }
                else if (this.c_form.recruiter_mail_status.value == '') {
                    this.success = false;
                    this.$toasted.show(' Please select recruiter mail status', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                }
                else if (this.c_form.recruiter_mail_status.value == 1 && this.c_form.recruiter_mail_content == '') {

                    this.success = false;
                    this.$toasted.show(' Please enter recruiter mail content', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                }
                // else if (this.c_form.recruiter_mail_subject == '') {

                //     this.success = false;
                //     this.$toasted.show(' Please enter recruiter mail subject', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                // } 
                else {
                    this.success = true;
                }
            }
            else {
                this.success = true;
            }

            this.saveResumeStatus();
        },
        saveResumeStatus() {

            if (this.success == true) {

                const status_data = {
                    name: this.c_form.name,
                    description: this.c_form.description,
                    priority: this.c_form.priority,
                    mail_status: this.c_form.mail_status.value,
                    inter_status: this.c_form.assign_status.value == '1' ? '1' : this.c_form.interview_status.value,
                    mail_content: this.c_form.mail_content,
                    recruiter_mail_status: this.c_form.recruiter_mail_status ? this.c_form.recruiter_mail_status.value : '',
                    recruiter_mail_content: this.c_form.recruiter_mail_content,
                    mail_subject: this.c_form.mail_subject,
                    recruiter_mail_subject: this.c_form.recruiter_mail_subject,
                    assign_status: this.c_form.assign_status.value,
                }
                // console.log(status_data);
                this.addResumeStatus(status_data)
            }
        },
        async addResumeStatus(status_data) {
            const response = await ServiceApi.AddResumeStatus(status_data);
            if (response.code == '200') {
                this.$toasted.show(' Resume status added successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/resume_status');
            }
            else if (response.code == '400') {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },

    }
};
</script>
