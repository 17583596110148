<template>
    <div>
        <div class="container-fluid">

            <div class="card">
                <!-- <div class="card-body"> -->
                <div class="row">
                    <div class="col-xl-4 col-lg-6" v-for="(project, index) in items" :key="index"
                        :class="index < 3 ? 'mb-4' : ''">
                        <div>
                            <div class="project-box example_d" @click="viewProject(project.id)">
                                <span class="badge" :class="project.progress_status"
                                    v-if="project.progress_status == 'bg-primary'">On Going</span>
                                <span class="badge" :class="project.progress_status"
                                    v-else-if="project.progress_status == 'bg-success'">Completed</span>
                                <span class="badge" :class="project.progress_status"
                                    v-else-if="project.progress_status == 'bg-warning'">Hold</span>
                                <h6>{{ project.name }}</h6>
                                <!-- <p class="p-dot">{{ project.desc }}</p> -->
                                <br>
                                <div class="row details det-cont">
                                    <div class="col-6">
                                        <span>Priority </span>
                                    </div>
                                    <div class="col-6 text-primary" :class="'text-' + project.type">{{ project.priority == 1 ? 'High': project.priority == 2 ? 'Medium' : 'Low' }}</div>
                                    <div class="col-6">
                                        <span>Duration</span>
                                    </div>
                                    <div class="col-6 text-primary" :class="'text-' + project.type">{{ getTimeAsForemated(project.duration) }}
                                    </div>
                                    <div class="col-6">
                                        <span>{{ project.progress_status == 'bg-warning' ? 'Hold Date' : project.progress_status == 'bg-success' ? 'Completed Date' : 'Last Update Date' }}</span>
                                    </div>
                                    <div class="col-6 text-primary" :class="'text-' + project.type">{{ project.activity_date }}
                                    </div>
                                    <div class="col-6">
                                        <span>Total Subtasks</span>
                                    </div>
                                    <div class="col-6 text-primary" :class="'text-' + project.type">{{ project.task }}
                                    </div>
                                </div><br>
                                
                                <br />
                                <div class="row">
                                    <div class="col-6">
                                        <router-link :to="'edit-project/' + project.id">
                                            <div v-if="project.progress_status == 'bg-primary'">
                                               
                                                <b-button class="btn-pill" id="default-xs-primary" size="md"
                                                    variant="outline-primary">
                                                    <i class="fa fa-edit">Edit</i>
                                                </b-button>
                                            </div>
                                            <div v-else-if="project.progress_status == 'bg-success'">
                                                
                                                <b-button class="btn-pill" id="default-xs-success" size="md"
                                                    variant="outline-success">
                                                    <i class="fa fa-edit">Edit</i>
                                                </b-button>
                                                
                                            </div>
                                            <div v-else-if="project.progress_status == 'bg-warning'">
                                
                                                <b-button class="btn-pill" id="default-xs-warning" size="md"
                                                    variant="outline-warning">
                                                    <i class="fa fa-edit">Edit</i>
                                                </b-button>
                                                
                                            </div>
                                        </router-link>
                                    </div>
                                    <div class="col-6" style="left: 40px;">
                                        <router-link :to="'manage-project/' + project.id">
                                            <b-button class="btn-pill" id="default-xs-dark" size="md"
                                                variant="outline-dark">
                                                Manage
                                            </b-button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
            </div>
            
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
// import Swal from "sweetalert2";

export default {
    data() {
        return {
            tablefields: [
                { key: 'name', label: 'Name', sortable: true, },
                { key: 'desc', label: 'Description', sortable: true, },
                { key: 'teamCount', label: 'Team Count', sortable: true, },
                { key: 'count_mem', label: 'Members count', sortable: true, },
                { key: 'start_date', label: 'From Date', sortable: true, },
                { key: 'end_date', label: 'To Date', sortable: true, },
                { key: 'status', label: 'Status', sortable: true, },
            ],
            items: [],
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
        };
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-info",
                2: "light-success",
                3: "light-warning",
            };
            return (status) => statusColor[status];
        },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        }
    },
    created() {
        // Set the initial number of items
        this.getAllProjects();
    },
    methods: {
        getTimeAsForemated(time) {
            var sec_num = time;
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours < 10) { hours = "0" + hours; }
            if (minutes < 10) { minutes = "0" + minutes; }
            if (seconds < 10) { seconds = "0" + seconds; }
            return hours + ':' + minutes + ':' + seconds;
        },
        async getAllProjects() {
            const response = await Service.getProjects();
            if (response.code == "200") {
                this.items = response.data.all;
                // console.log(this.items);
                // this.totalRows = response.data.all.length;
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        viewProject(id) {
            this.$router.push('/home/emp-project/' + id);
        },
        getImgUrl(path) {
            return require('@/assets/images/' + path);
        },
        // deleteEmployee(data) {
        //     if (data.status == 1) {
        //         Swal.fire({
        //             title: "Are you sure?",
        //             text: "Are you sure you want to disable this Employee!",
        //             icon: "warning",
        //             showCancelButton: true,
        //             confirmButtonColor: "#3085d6",
        //             cancelButtonColor: "#d33",
        //             confirmButtonText: "Yes, disable!",
        //         }).then((result) => {
        //             if (result.isConfirmed) {
        //                 const credentials = {
        //                     status: data.status,
        //                     id: data.id,
        //                 };
        //                 var done = this.enableOrDisableEmployee(credentials);
        //                 if (done) {
        //                     Swal.fire("Disabled!", "Employee has been disabled.", "success")
        //                     this.retrieveEmployees();
        //                 }
        //             }
        //         });
        //     } else if (data.status == 0) {
        //         Swal.fire({
        //             title: "Are you sure?",
        //             text: "Are you sure you want to enable this Employee!",
        //             icon: "warning",
        //             showCancelButton: true,
        //             confirmButtonColor: "#3085d6",
        //             cancelButtonColor: "#d33",
        //             confirmButtonText: "Yes, enable!",
        //         }).then((result) => {
        //             if (result.isConfirmed) {
        //                 const credentials = {
        //                     status: data.status,
        //                     id: data.id,
        //                 };
        //                 var done = this.enableOrDisableEmployee(credentials);
        //                 if (done) {
        //                     Swal.fire("Disabled!", "Employee has been enabled.", "success")
        //                     this.retrieveEmployees();
        //                 }
        //             }
        //         });
        //     }
        // },
        // async enableOrDisableEmployee(credentials) {
        //     const response = await Service.EnableOrDisableUser(credentials);
        //     if (response.code == "200") {
        //         return true;
        //     } else if (response.code == "403") {
        //         this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        //         localStorage.removeItem('loginDetails')
        //         localStorage.removeItem('menuData')
        //         this.$router.push('/');
        //     } else {
        //         return false;
        //     }
        // }
    }
};
</script>
<style>
.det-cont {
    background-color: whitesmoke;
    border-radius: 18px;
    padding: 10px;
}

.example_d {
/* color: #20bf6b !important; */

background: #ffffff;
padding: 20px;
/* border: 4px solid #20bf6b !important; */
border-radius: 6px;
display: inline-block;
transition: all 0.3s ease 0s;
}
.example_d:hover {
    text-transform: uppercase;
color: #494949 !important;
border-radius: 50px;
border-color: #494949 !important;
transition: all 0.3s ease 0s;
}
</style>