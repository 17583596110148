<template>
    <div><br>
        <Breadcrumbs main="Employees" title="Edit Employee" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="">
                <div class="row">
                    <div class="col-xl-5">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title mb-0">My Profile</h4>
                                <div class="card-options">
                                    <a class="card-options-collapse" href="#" data-toggle="card-collapse">
                                        <i class="fe fe-chevron-up">
                                        </i>
                                    </a>
                                    <a class="card-options-remove" href="#" data-toggle="card-remove">
                                        <i class="fe fe-x"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <div>

                                    <div class="row mb-2">

                                        <img v-if="image_name" class="mr-3 rounded-circle" :src="image_name"
                                            style="height:80px;width:80px;" alt="" />

                                        <img v-else class="mr-3 rounded-circle" src="../../assets/images/user/user.png"
                                            style="height:80px;width:80px;" alt="" />


                                        <div class="col">
                                            <h3 class="mb-1">{{ c_form.name }}</h3>
                                            <p class="mb-4">{{ c_form.designation.name }}</p>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="form-group">
                                            <label class="form-label">Name</label>
                                            <b-form-input type="text" id="name" v-model="c_form.name"
                                                :state="c_form_result.name" placeholder="Name">
                                            </b-form-input>
                                            <b-form-valid-feedback :state="c_form_result.name">
                                            </b-form-valid-feedback>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="form-label">Employee ID</label>
                                        <b-form-input type="text" id="employeeId" v-model="c_form.employeeId"
                                            :state="c_form_result.employeeId" placeholder="Employee ID">
                                        </b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.employeeId">
                                        </b-form-valid-feedback>
                                    </div>

                                    <div class="form-group">
                                        <label class="form-label">Photo Upload</label>
                                        <input type="file" @change="onFileChange" />
                                        <div id="preview">
                                            <img v-if="photo_url" :src="photo_url" />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Department</label>
                                        <v-select v-model="c_form.department" label="name" :options="departments"
                                            v-on:input="getDesignations" />
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Designation</label>

                                        <v-select v-model="c_form.designation" label="name" :options="designations"
                                            v-on:input="getDesignationsByEm" />
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Email-Address</label>
                                        <b-form-input type="email" id="email" v-model="c_form.email"
                                            :state="c_form_result.email" placeholder="Email">
                                        </b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.email">
                                        </b-form-valid-feedback>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Phone</label>
                                        <VuePhoneNumberInput v-model="c_form.phone" default-country-code="IN"
                                            @update="onUpdatePhoneNumber" />
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Gender</label>
                                        <b-form-select v-model="c_form.gender" label="gender" :options="genders"
                                            :state="c_form_result.gender">
                                        </b-form-select>
                                        <b-form-valid-feedback :state="c_form_result.gender">
                                        </b-form-valid-feedback>
                                    </div>

                                    <div class="form-group">
                                        <label class="form-label">Status</label>
                                        <b-form-select v-model="c_form.emp_status" label="Status" :options="emp_status"
                                            :state="c_form_result.emp_status" v-on:input="getStatus">
                                        </b-form-select>
                                        <b-form-valid-feedback :state="c_form_result.emp_status">
                                        </b-form-valid-feedback>
                                    </div>
                                    <div class="form-group" v-if="showPerDateField">
                                        <label class="form-label">Permanent Date</label>
                                        <!-- <b-form-input type="date" value="2018-01-01" v-model="join_date"></b-form-input> -->
                                        <datepicker :format="customFormatter" :disabled-dates="disabledDates"
                                            input-class="datepicker-here form-control digits" v-model="permen_date">
                                        </datepicker>
                                        <span v-if="showError1" class="errors">This field is required</span>
                                    </div>
                                    <div class="form-group" v-if="showRelievedDateField">
                                        <label class="form-label">Relieved Date</label>
                                        <!-- <b-form-input type="date" value="2018-01-01" v-model="join_date"></b-form-input> -->
                                        <datepicker :format="customFormatter" :disabled-dates="disabledDates"
                                            input-class="datepicker-here form-control digits" v-model="Relieved_date">
                                        </datepicker>
                                        <span v-if="showError2" class="errors">This field is required</span>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Reporting</label>
                                        <!-- <b-form-select
                                                v-model="c_form.emp_status"
                                                label="Status"
                                                :options="emp_status"   
                                                :state="c_form_result.emp_status"                    
                                            > -->
                                        <v-select v-model="c_form.employee" label="name" :options="employeesarray" />
                                        <!-- </b-form-select> -->

                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Joined date</label>
                                        <!-- <b-form-input type="date" value="2018-01-01" v-model="join_date"></b-form-input> -->
                                        <div class="datepicker-container">
                                            <datepicker :format="customFormatter"
                                                input-class="datepicker-here form-control digits" v-model="join_date">
                                            </datepicker>
                                        </div>
                                        <span v-if="showError" class="errors">This field is required</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-xl-7">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title mb-0">Details</h4>
                                <div class="card-options">
                                    <a class="card-options-collapse" href="#" data-toggle="card-collapse">
                                        <i class="fe fe-chevron-up"></i>
                                    </a>
                                    <a class="card-options-remove" href="#" data-toggle="card-remove">
                                        <i class="fe fe-x"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Emergancy Contact Person</label>
                                            <input class="form-control" type="text" placeholder="Name"
                                                v-model="emergancy_name">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Emergancy Contact Number</label>
                                            <VuePhoneNumberInput v-model="emergancy_no" default-country-code="IN"
                                                @update="onUpdateEmergPhoneNumber" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">Blood Group</label>
                                            <select class="form-control btn-square" v-model="blood">
                                                <option value="0">--Blood Group--</option>
                                                <option value="A+">A+</option>
                                                <option value="A-">A-</option>
                                                <option value="B+">B+</option>
                                                <option value="B-">B-</option>
                                                <option value="AB+">AB+</option>
                                                <option value="AB-">AB-</option>
                                                <option value="O+">O+</option>
                                                <option value="O-">O-</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">Higher Qualification</label>
                                            <input class="form-control" type="text" placeholder="Higher Qualification"
                                                v-model="qualification">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Passport Number</label>
                                            <input class="form-control" type="text" placeholder="Passport"
                                                v-model="passport_no">
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Aadhaar Number</label>
                                            <input class="form-control" type="text" placeholder="Aadhaar Number"
                                                v-model="adhar_no">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label"> Wage Unit Price</label>
                                            <input class="form-control" type="text" placeholder="Wage Unit Price"
                                                v-model="wage_unit_price">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">Wage Calculation Type</label>
                                            <b-form-select v-model="c_form.calculation_type" label="Wage Calculation Type"
                                                :options="calculation_type" :state="c_form_result.calculation_type"
                                                v-on:input="getStatus">
                                            </b-form-select>
                                           
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="form-label">Date of birth</label>
                                            <datepicker :format="customFormatter"
                                                input-class="datepicker-here form-control digits" v-model="dob">
                                            </datepicker>
                                           
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Permenent Address</label>
                                            <textarea class="form-control" rows="1" placeholder="Enter Permenet address"
                                                v-model="per_address">
                                                            </textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Current Address</label>
                                            <textarea class="form-control" rows="1" placeholder="Enter current address"
                                                v-model="cur_address">
                                                            </textarea>
                                        </div>
                                    </div>
                                    <div class="col-sm-6" v-if="dateshow">
                                        <div class="form-group">
                                            <label class="form-label">Start date</label>
                                           
                                            <datepicker :format="customFormatter"
                                                input-class="datepicker-here form-control digits" v-model="start_date">
                                            </datepicker>
                                        </div>
                                    </div>
                                    <div class="col-sm-6" v-if="dateshow">
                                        <div class="form-group">
                                            <label class="form-label">End date</label>
                                           
                                            <datepicker :format="customFormatter"
                                                input-class="datepicker-here form-control digits" v-model="end_date">
                                            </datepicker>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="card-footer text-right">
                                <button class="btn btn-primary" type="submit" @click="onCustomStyleSubmit">Update
                                    Details</button>
                            </div>

                        </div>
                    </div> -->
                    <div class="col-xl-7">
                        <div class="card">
                            <div class="row m-0">
                                <b-tabs content-class="mt-7" class="fill-row">
                                    <b-tab active>
                                        <template v-slot:title>
                                            <feather type="check-circle"></feather> Basic Details
                                        </template>
                                        <b-card-text>
                                            <div class="col-sm-12 p-0">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label class="form-label">Emergancy Contact
                                                                        Person</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Name" v-model="emergancy_name">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label class="form-label">Emergancy Contact
                                                                        Number</label>
                                                                    <VuePhoneNumberInput v-model="emergancy_no"
                                                                        default-country-code="IN"
                                                                        @update="onUpdateEmergPhoneNumber" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-label">Blood Group</label>
                                                                    <select class="form-control btn-square" v-model="blood">
                                                                        <option value="0">--Blood Group--</option>
                                                                        <option value="A+">A+</option>
                                                                        <option value="A-">A-</option>
                                                                        <option value="B+">B+</option>
                                                                        <option value="B-">B-</option>
                                                                        <option value="AB+">AB+</option>
                                                                        <option value="AB-">AB-</option>
                                                                        <option value="O+">O+</option>
                                                                        <option value="O-">O-</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-label">Higher Qualification</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Higher Qualification"
                                                                        v-model="qualification">
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-label"> Wage Unit Price</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Wage Unit Price"
                                                                        v-model="wage_unit_price">
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-label">Wage Calculation Type</label>
                                                                    <b-form-select v-model="c_form.calculation_type"
                                                                        label="Wage Calculation Type"
                                                                        :options="calculation_type"
                                                                        :state="c_form_result.calculation_type"
                                                                        v-on:input="getStatus">
                                                                    </b-form-select>

                                                                </div>
                                                            </div>

                                                            <div class="col-sm-6">
                                                                <div class="form-group">
                                                                    <label class="form-label">Date of birth</label>
                                                                    <datepicker :format="customFormatter"
                                                                        input-class="datepicker-here form-control digits"
                                                                        v-model="dob">
                                                                    </datepicker>

                                                                </div>
                                                            </div>

                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label class="form-label">Permenent Address</label>
                                                                    <textarea class="form-control" rows="1"
                                                                        placeholder="Enter Permenet address"
                                                                        v-model="per_address">
                                                            </textarea>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label class="form-label">Current Address</label>
                                                                    <textarea class="form-control" rows="1"
                                                                        placeholder="Enter current address"
                                                                        v-model="cur_address">
                                                            </textarea>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6" v-if="dateshow">
                                                                <div class="form-group">
                                                                    <label class="form-label">Start date</label>

                                                                    <datepicker :format="customFormatter"
                                                                        input-class="datepicker-here form-control digits"
                                                                        v-model="start_date">
                                                                    </datepicker>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6" v-if="dateshow">
                                                                <div class="form-group">
                                                                    <label class="form-label">End date</label>

                                                                    <datepicker :format="customFormatter"
                                                                        input-class="datepicker-here form-control digits"
                                                                        v-model="end_date">
                                                                    </datepicker>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card-text>
                                    </b-tab>

                                    <b-tab>
                                        <template v-slot:title>
                                            <feather type="check-circle"></feather> Bank Details
                                        </template>
                                        <b-card-text>
                                            <div class="col-sm-12 p-0">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div class="row">

                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label class="form-label">Account
                                                                        Number</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Account Number" v-model="acc_numb">
                                                                </div>
                                                            </div>

                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label class="form-label">Branch
                                                                        Name</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Branch Name" v-model="branch">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label class="form-label">IFSC
                                                                        Code</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="IFSC Code" v-model="ifsc">
                                                                </div>
                                                            </div>

                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label class="form-label">Passport Number</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Passport" v-model="passport_no">
                                                                </div>
                                                            </div>


                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label class="form-label">Aadhaar Number</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Aadhaar Number" v-model="adhar_no">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label class="form-label">Pan
                                                                        Number</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Pan Number" v-model="pan_no">
                                                                </div>
                                                            </div>

                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label class="form-label">PF
                                                                        Number</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="PF Number" v-model="pf_no">
                                                                </div>
                                                            </div>

                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label class="form-label">PF UAN
                                                                        ID</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="PF UAN ID" v-model="pf_uan_id">
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card-text>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-right">
                    <button class="btn btn-primary" type="submit" @click="onCustomStyleSubmit">Update
                        Details</button>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from '@/services/ServiceApi.js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'
import S3 from "aws-s3";

export default {
    components: {
        VuePhoneNumberInput,
        vSelect,
        Datepicker
    },
    data() {
        return {
            photo_url: null,
            success: false,
            designations: [],
            departments: [],
            permen_date: '',
            Relieved_date:null,
            showError: false,
            showError1: false,
            showError2: false,
            showPerDateField: true,
            showRelievedDateField:false,
            genders: [
                { value: '', text: 'Please select gender' },
                { value: 'M', text: 'Male' },
                { value: 'F', text: 'Female' },
                { value: 'O', text: 'Other' },
            ],
            emp_status: [
                { value: '', text: 'Please select status' },
                { value: '1', text: 'Probation' },
                { value: '2', text: 'Permanent' },
                { value: '3', text: 'Contract' },
                { value: '4', text: 'Intern' },
                { value: '5', text: 'Freelance' },
                { value: '6', text: 'Notice period' },
                { value: '7', text: 'Relieve' },
            ],
            calculation_type: [
                { value: '', text: 'Please select calculation type' },
                { value: '1', text: 'Hourly' },
                { value: '2', text: 'Daily' },
                { value: '3', text: 'Weekly' },
                { value: '4', text: 'Monthly' },

            ],
            c_form: {
                name: '',
                department: '',
                designation: '',
                email: '',
                phone: '',
                employeeId: '',
                emp_status: '',
                gender: '',
                calculation_type: '',
                employee: null
            },
            c_form_result: {
                name: null,
                email: null,
                employeeId: null,
                emp_status: null,
                gender: null,
            },
            emergancy_name: '',
            emergancy_no: '',
            passport_no: '',
            blood: '',
            qualification: '',
            dob: '',
            join_date: '',
            adhar_no: '',
            start_date: '',
            end_date: '',
            per_address: '',
            cur_address: '',
            PhoneNumberValue: '',
            wage_unit_price: null,
            EmergNumberValue: '',
            dep_id: null,
            file: null,
            image_name: null,
            dateshow: false,
            employeesarray: [],
            acc_numb: null,
            branch: null,
            ifsc: null,
            pan_no: null,
            pf_no: null,
            pf_uan_id: null,

        };
    },
    computed: {
        disabledDates() {
            return {
                from: new Date(), // disable all dates after today
            };
        },
    },
    mounted() {
        // Set the initial number of items
        this.getAwsS3Config();
        this.getEmpDetailsById();
        this.getDepartments();


    },
    methods: {

        async getAwsS3Config() {
            const response = await Service.getAwsS3Configs();
            if (response.code == "200") {
                this.s3 = response.data;
            }
        },

        customFormatter(date) {
            return moment(date).format('Y-MM-DD');
        },
        onCustomStyleSubmit(evt) {
            this.success = false;

            evt.preventDefault();
            // if (this.c_form.name != '') {
            //     this.c_form_result.name = true;
            //     this.success = true;
            // } else {
            //     this.success = false;
            //     this.c_form_result.name = false;
            // }

            if (this.c_form.email != '') {
                this.success = true;
                this.c_form_result.email = true;
            } else {
                this.success = false;
                this.c_form_result.email = false;
            }

            if (this.c_form.employeeId != '') {
                this.c_form_result.employeeId = true;
                this.success = true;
            } else {
                this.success = false;
                this.c_form_result.employeeId = false;
            }

            if (this.c_form.gender != '') {
                this.c_form_result.gender = true;
                this.success = true;
            } else {
                this.success = false;
                this.c_form_result.gender = false;
            }

            if (this.c_form.emp_status != '') {
                this.c_form_result.emp_status = true;
                this.success = true;
            } else {
                this.success = false;
                this.c_form_result.emp_status = false;
            }

            if ((this.c_form.phone == '') || (this.c_form.department == '') || (this.c_form.designation == '') || (this.c_form.name == '') || (this.c_form.email == '') || (this.c_form.employeeId == '') || (this.c_form.gender == '') || (this.c_form.emp_status == '')) {
                this.success = false;
            } else {
                this.success = true;
            }

            if (this.c_form.name !== '') {

                if (/[^a-z A-Z]/.test(this.c_form.name)) {
                    this.c_form_result.name = false;
                    this.success = false;
                    this.$toasted.show(' Please enter a valid name ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

                } else {
                    this.success = true;
                    this.c_form_result.name = true;
                }

            }
            else {
                this.success = false;
                this.c_form_result.name = false;
            }
            if (this.join_date == '') {
                this.success = false;
                this.showError = true;
                // this.$toasted.show(' Please enter Joined date ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                this.success = true;
                this.showError = false;
            }
            if (this.c_form.emp_status == 2) {
                if (this.permen_date == '' || this.permen_date == null) {
                    this.success = false;
                    this.showError1 = true;
                } else {
                    this.success = true;
                    this.showError1 = false;
                }
            }

            if (this.c_form.emp_status == 7) {
                if (this.Relieved_date == '' || this.Relieved_date == null) {
                    this.success = false;
                    this.showError2 = true;
                } else {
                    this.success = true;
                    this.showError2 = false;
                }
            }

            this.saveEmployee();
        },

        onUpdatePhoneNumber(payload) {
            this.PhoneNumberValue = payload.formattedNumber;
        },
        onUpdateEmergPhoneNumber(payload) {
            this.EmergNumberValue = payload.formattedNumber;
        },
        getDesignationsByEm() {
            this.getDesignationByEmp();
        },
        getStatus() {

            if (this.c_form.emp_status == 3 || this.c_form.emp_status == 4 || this.c_form.emp_status == 5) {
                this.dateshow = true;
            } else {
                this.dateshow = false;
            }
            if (this.c_form.emp_status == 2) {
                this.showPerDateField = true;
            } else {
                this.showPerDateField = false;
            }
            if (this.c_form.emp_status == 7) {
                this.showRelievedDateField = true;
            } else {
                this.showRelievedDateField = false;
            }
        },
        async getDesignationByEmp() {

            const data = { designation_id: this.c_form.designation.id };
            console.log(this.c_form.designation);
            const response = await Service.getDesignationByEmp(data);
            if (response.code == '200') {

                this.employeesarray = response.data;

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            } else {
                this.employeesarray = [];
            }
        },
        saveEmployee() {
            if (this.c_form.employee == null || this.c_form.employee == '') {
                this.$toasted.show(' Please enter Required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                this.success = false;
            }
            if (this.success == true) {

                var dob = null;
                var end_date = null;
                var start_date = null;
                var join_date = null;
                var permenenet_date = '';
                var relieved_date = '';
                if (this.dob != null) {
                    dob = this.customFormatter(this.dob);
                }
                if (this.join_date != null) {
                    join_date = this.customFormatter(this.join_date);
                }
                if (this.start_date != '') {
                    start_date = this.customFormatter(this.start_date);
                }
                if (this.end_date != '') {
                    end_date = this.customFormatter(this.end_date);
                }
                if (this.permen_date != '') {
                    if (this.customFormatter(this.permen_date) != 'Invalid date') {
                        permenenet_date = this.customFormatter(this.permen_date);
                    }
                    
                }
                if (this.c_form.emp_status == 7) {
                if (this.Relieved_date != '') {
                  
                    if (this.customFormatter(this.Relieved_date) != 'Invalid date') {
                        relieved_date =  this.customFormatter(this.Relieved_date);
                    }
                }
                }
                if (this.file != null) {
                    var file_name = this.file;

                }
                else {
                    var file_name = this.image_name;
                }
                if (this.PhoneNumberValue == undefined || this.PhoneNumberValue == "" || this.PhoneNumberValue == null) {
                    var phoneNumberVal = "";
                }
                else {
                    var phoneNumberVal = this.PhoneNumberValue;
                }
                const emp_data = {
                    id: this.$route.params.id,
                    name: this.c_form.name,
                    department: this.c_form.department.department_id,
                    designation: this.c_form.designation.id,
                    phone: phoneNumberVal,
                    email: this.c_form.email,
                    ref_no: this.c_form.employeeId,
                    gender: this.c_form.gender,
                    emp_status: this.c_form.emp_status,
                    permenenet_date: permenenet_date,
                    relieved_date: relieved_date,
                    emergancy_name: this.emergancy_name,
                    emergancy_no: this.EmergNumberValue,
                    // passport_no: this.passport_no,
                    blood: this.blood,
                    qualification: this.qualification,
                    dob: dob,
                    join_date: join_date,
                    // adhar_no: this.adhar_no,
                    start_date: start_date,
                    end_date: end_date,
                    per_address: this.per_address,
                    cur_address: this.cur_address,
                    reporting: this.c_form.employee.id,
                    file: file_name,
                    calculation_type: this.c_form.calculation_type,
                    wage_unit_price: this.wage_unit_price,
                    acc_det: {
                        accountNo: this.acc_numb,
                        branch: this.branch,
                        ifsc: this.ifsc,
                        pan_no: this.pan_no,
                        pf_no: this.pf_no,
                        pf_uan_id: this.pf_uan_id,
                        passport_no: this.passport_no,
                        adhar_no: this.adhar_no,
                    }

                }
                this.editEmployee(emp_data)

            } else {
                this.$toasted.show(' Please enter Required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
        },
        async getEmpDetailsById() {
            const ReqData = {
                id: this.$route.params.id,
            };
            const response = await Service.GetEmpDetailsById(ReqData);

            console.log(response);
            if (response.code == '200') {
                this.c_form.name = response.data.name;
                this.image_name = response.data.image_name;
                this.c_form.department = { name: response.data.department, department_id: response.data.department_id };
                this.dep_id = response.data.department_id;
                this.c_form.designation = { name: response.data.designation, id: response.data.designation_id };

                if (response.data.department_id != '') {
                    this.getDesignations();
                }
                this.c_form.employee = { name: response.data.reporting_name, id: response.data.reporting_id };
                this.c_form.phone = response.data.phone;
                this.c_form.email = response.data.email;
                this.c_form.employeeId = response.data.emp_ref_no;
                this.c_form.gender = response.data.gender;
                this.c_form.emp_status = response.data.employee_status;
                this.permen_date = response.data.per_date
                if (response.data.employee_status == 2) {
                    this.permen_date = response.data.per_date
                    this.showPerDateField = true;
                }
                this.Relieved_date = response.data.relieved_date
                
                if (response.data.employee_status == 7) {
                    this.Relieved_date = response.data.relieved_date
                    this.showRelievedDateField = true;
                }
                this.c_form.calculation_type = response.data.calculation_type;
                this.wage_unit_price = response.data.wage_unit_price;
                this.emergancy_name = response.data.emergency_contact_person;
                this.emergancy_no = response.data.emergency_contact_number;
                // this.passport_no = response.data.passport_number;
                this.blood = response.data.blood_group;
                this.qualification = response.data.higher_qualification;
                this.dob = response.data.dob;
                this.join_date = response.data.joined_date;
                // this.adhar_no = response.data.aadhaar_no;
                if (response.data.start_date || response.data.end_date) {
                    this.start_date = response.data.start_date;
                    this.end_date = response.data.end_date;
                }
                this.per_address = response.data.permanent_address;
                this.cur_address = response.data.current_address;
                if (response.data.reporting_id != '') {
                    this.getDesignationByEmp();
                }
                this.acc_numb = response.data.account_no ? response.data.account_no : ''
                this.branch = response.data.branch_name ? response.data.branch_name : ''
                this.ifsc = response.data.ifsc ? response.data.ifsc : ''
                this.pan_no = response.data.pan_no ? response.data.pan_no : ''
                this.pf_no = response.data.pf_no ? response.data.pf_no : ''
                this.pf_uan_id = response.data.pf_uan_id ? response.data.pf_uan_id : ''
                this.passport_no = response.data.passport_number ? response.data.passport_number : ''
                this.adhar_no = response.data.aadhaar_no ? response.data.aadhaar_no : ''

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getDepartments() {
            const response = await ServiceApi.GetDepartments();
            if (response.code == '200') {

                this.departments = response.data;

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getDesignations() {
            if (this.c_form.department.department_id) {
                this.dep_id = this.c_form.department.department_id;
            }
            const dep_data = { depart: this.dep_id };
            const response = await Service.GetDesignationsByDepId(dep_data);
            if (response.code == '200') {

                this.designations = response.data;

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        onFileChange(e) {

            var files = e.target.files || e.dataTransfer.files;

            if (!files.length) {
                return;
            }
            const now = Date.now();

            let file = files[0];

            this.photo_url = URL.createObjectURL(file);
            const config = {
                bucketName: this.s3.bucketName,
                dirName: "user_photos" /* optional */,
                region: this.s3.region,
                accessKeyId: this.s3.accessKeyId,
                secretAccessKey: this.s3.secretAccessKey,
                s3Url: "https://bktqaptive.s3.amazonaws.com" /* optional */,
            };

            const S3Client = new S3(config);

            if (this.c_form.employeeId !== '') {

                S3Client.uploadFile(file, this.c_form.employeeId).then((response) => {
                    this.file = response.location;
                });

            }
            else {
                S3Client.uploadFile(file, now).then((response) => {
                    this.file = response.location;
                });

            }

            //   S3Client.uploadFile(file, now).then((response) => {
            //   this.file = response.location;
            //   });

        },
        async editEmployee(emp_data) {
            const response = await Service.EditEmployee(emp_data);

            if (response.code == '200') {
                this.$toasted.show(' Employee details updated successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/employees');
            }
            else if (response.code == '400') {
                //message = response.
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        }

    }
};
</script>

<style>
#preview {
    display: flex;
    justify-content: center;
    align-items: center;
}

#preview img {
    max-width: 100%;
    max-height: 100px;
}

.errors {
    color: red;
    font-size: 12px;
    font-weight: 100;
}
</style>