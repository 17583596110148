<template>
    <div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <label for="title">Sub Task Title</label>
                                        <b-form-input type="text" id="title" v-model="title" placeholder="Sub Task Title">
                                        </b-form-input>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="description">Description</label>
                                        <b-form-input type="text" id="description" v-model="description" placeholder="Description">
                                        </b-form-input>
                                    </div>
                                    <!-- <div class="col-md-4 mb-3">
                                        <label for="start_time">Start Time</label>
                                        <b-form-input id="start_time" v-model="start_time" placeholder="Start Time"></b-form-input>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="end_time">End Time</label>
                                        <b-form-input id="end_time" v-model="end_time" placeholder="End Time"></b-form-input>
                                    </div> -->
                                    <div class="col-md-4 mb-3">
                                        <label for="c_form_modules">Tasks</label>
                                        <v-select v-model="task" label="task_title" :options="tasks" />
                                    </div>

                                    <!-- time selector -->
                                    <!-- <div class="col-md-4 mb-3">
                                        <label for="start_time">Start Time</label>
                                        <datetime format="H:i:s" id="start_time" v-model="start_time"></datetime>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="end_time">End Time</label>
                                        <datetime format="H:i:s" id="end_time" v-model="end_time"></datetime>
                                    </div> -->
                                   
                                    <!-- <div class="col-md-6 mb-3">
                                        <label class="form-label">Status</label>
                                        <b-form-select
                                            v-model="startEnd"
                                            label="Status"
                                            :options="startEndStatus"                    
                                        >
                                        </b-form-select>
                                    </div> -->
                                </div>
                                <!-- <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Description</label> -->
                                            <!-- <textarea class="form-control" rows="2" placeholder="Enter Instructions"
                                                v-model="instruct">
                                            </textarea> -->
                                            <!-- <ckeditor :editor="editor" v-model="instruct"></ckeditor> -->
                                            <!-- <vue-editor v-model="instruct"></vue-editor> -->
                                        <!-- </div>
                                    </div>                                 
                                </div> -->
                                <div class="row">
                                    <div class="col">
                                        <b-button type="submit" variant="primary">Submit</b-button>
                                    </div>
                                    <!-- <div v-if="loader == true" class="loader-box">
                                        <div class="loader-15"></div>
                                    </div> -->
                                </div>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import moment from "moment";
import { VueEditor } from 'vue2-editor';
// import Datetime from 'vuejs-datetimepicker';
export default {

    components: {
        vSelect,
        VueEditor,
        // Datetime,
    },

    data() {
        return {
            tasks: [],
            task: null,
            title: null,
            description: null,
            // start_time: null,
            // end_time: null,
            projectId: null,
        };
    },
    props: ['projectId'],
    computed: {

    },
    created() {
        this.getTasks();
        // var head = 1;
        // // this.heading = head;
        // this.$emit('update:heading', head);
        // // this.getTasks();
        // // this.getUsers();
    },
    methods: {
        onCustomStyleSubmit(evt) {
            evt.preventDefault();
            if (this.title == '' || this.description == null) {
                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                this.saveSubTask();
            }
        },
        saveSubTask() {
            const sub_task_data = {
                title: this.title,
                description: this.description,
                // start_time: this.start_time,
                // end_time: this.end_time,
                project_id: this.$route.params.id,
                ptask: this.task.id
            };
            this.addSubTask(sub_task_data)
        },
        async addSubTask(sub_task_data) {
            this.loader = true;
            const response = await Service.AddSubTask(sub_task_data);
            if (response.code == '200') {
                this.loader = false;
                this.$toasted.show(' Sub Task added successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$emit('update:projectId',2);
            } else if (response.code == "400") {
                this.loader = false;
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
        },
        // async getTasks() {
        //     const reqDatas = {
        //         project_id: this.$route.params.id
        //     }
        //     const response = await Service.getTasksByProjects(reqDatas);
        //     if (response.code == '200') {
        //         this.tasks = response.data;
        //     }
        // },


        // async getTasks() {
        //     const response = await Service.GetTaskType();
        //     if (response.code == '200') {
        //         this.taskType = response.data;
        //     }
        // },
        // async getUsers() {
        //     var pro_id = this.$route.params.id;
        //     const reqData = {
        //         id: pro_id,
        //     }
        //     const response = await Service.GetProjectDetails(reqData);
        //     if (response.code == '200') {
        //         this.pManager = this.loginData.designation.level.key;
        //         this.currUser = this.loginData.id;
        //         if (this.pManager == 'MANAGER') {
        //             this.employees = response.data.all.members;
        //         } else {
        //             response.data.all.allMem.forEach(element => {
        //                 element.mem.forEach(elem => {
        //                     if ((elem.user_id == this.currUser) && (elem.role == 1)) {
        //                         this.employees = element.mem;
        //                     }
        //                 });
        //             });
        //             if(this.employees.length == 0){
        //                 this.employees = [
        //                     { user_id: this.currUser, user: this.loginData.name },
        //                 ];
        //             }
        //         }
        //     }
        // },
    }
};
</script>
<style>
 .ck-content { height:150px; }
</style>