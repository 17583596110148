<template>
    <div><br>
        <Breadcrumbs main="Project" :title="proj_name + ' / Module list'" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">

                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">
                            <b-row>
                                <b-col xl="6" @click="goToProjectView()">
                                    
                                    <b-avatar class="cust-color" variant="warning" size="2.5em">
                                        <feather type="arrow-left"></feather>
                                    </b-avatar>

                                </b-col>
                                <b-col xl="6">
                                    <b-button class="pull-right" id="default-primary" variant="primary" @click="addModules()">
                                        Add Module
                                    </b-button>
                                    <!-- <b-tooltip target="default-primary" triggers="hover" placement="top">btn btn-primary</b-tooltip> -->
                                </b-col>
                            </b-row>
                            <br /><br>
                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <!-- <template #cell(start_date)="rows">
                                        <span>
                                            {{ rows.item.start_date ? rows.item.start_date.split("T")[0] : "" }}
                                        </span>
                                    </template>
                                    <template #cell(end_date)="rows">
                                        <span>
                                            {{ rows.item.end_date ? rows.item.end_date.split("T")[0] : "" }}
                                        </span>
                                    </template> -->
                                    <template #cell(status)="rows">
                                        <b-badge :variant="statusVariant(rows.item.status)">

                                            <span v-if="rows.item.status == 1">
                                                Ongoing
                                            </span>
                                            <span v-else-if="rows.item.status == 2">
                                                Completed
                                            </span>
                                            <span v-else-if="rows.item.status == 3">
                                                Hold
                                            </span>
                                            <span v-else-if="rows.item.status == 4">
                                                Testing
                                            </span>
                                            <span v-else>
                                                Reopen
                                            </span>
                                        </b-badge>
                                    </template>


                                    <template #cell(active_status)="rows">
                                        <b-badge :variant="statusVariantActive(rows.item.active_status)">
                                            <span v-if="rows.item.active_status == 1">
                                                Active
                                            </span>
                                            <span v-else>
                                                Inactive
                                            </span>
                                        </b-badge>
                                    </template>
                                    <template #cell(actions)="row">
                                        <span>
                                            <span @click="EditModules(row.item.id)">
                                                <feather type="edit"></feather>
                                            </span>
                                            <!-- <span @click="viewModules(row.item.id)">
                                                <feather type="eye"></feather>
                                            </span> -->
                                            <!-- <span @click="deletePhase(row.item.id)">
                                                <feather type="eye"></feather>
                                            </span> -->
                                            <span v-if="row.item.active_status == 1">
                                            <feather type="thumbs-down" @click="deleteModules(row.item)"></feather>
                                            </span>
                                            <span v-else>
                                            <feather type="thumbs-up" @click="deleteModules(row.item)"></feather>
                                            </span>
                                        </span>
                                    </template>


                                    <!-- <template #cell(actions)="row">
                                        <span>
                                            <span v-if="project_id.progress_status == 'bg-primary'"
                                                @click="EditPhase(row.item.id)">
                                                <feather type="edit"></feather>
                                            </span>
                                          
                                            <span @click="viewPhase(row.item.id)">
                                                <feather type="eye"></feather>
                                            </span>
                                            <span>
                                               
                                            </span>
                                        </span>
                                    </template> -->

                                </b-table>
                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>


                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import Swal from "sweetalert2";

export default {
    data() {
        return {
            tablefields: [
                { key: 'name', label: 'Module Name', sortable: true, },
                { key: 'description', label: 'Module Description', sortable: true, },
                { key: 'phase_title', label: 'Project Phase Title', sortable: true, },
                // { key: 'project_name', label: 'Project Name', sortable: true, },
                // { key: 'project', label: 'Project name', sortable: true, },
                // { key: 'type', label: 'Task Type', sortable: true, },
                { key: 'status', label: 'Module Status', sortable: true, },
                // { key: 'start_date', label: 'Start Date', sortable: true, },
                // { key: 'end_date', label: 'End Date', sortable: true, },
                { key: 'active_status', label: 'Active Status', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],

            items: [],
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            pro_id: null,
            proj_name: null,
            loginData: JSON.parse(localStorage.getItem('loginDetails')),
        };
    },
    // props: ['modulesId','projectModulesId'],
    computed: {
        statusVariant() {
            const statusColor = {
                // 5: "light-info",
                // 4: "light-info",
                3: "light-warning",
                2: "light-info",
                1: "light-success",
            };
            return (status) => statusColor[status];
        },
        statusVariantActive() {
            const statusColor = {
                1: "light-success",
                0: "light-danger",
            };
            return (status) => statusColor[status];
        },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        },
    },
    mounted() {
        // console.log(this.loginData);
        this.retrieveModules(this.$route.params.id);
    },
    methods: {
        goToProjectView(){
            this.$router.push('/home/emp-project/' + this.pro_id);
        },
        // viewModules(id) {
        //     this.$emit('update:modulesId', 1);
        //     this.$emit('update:projectModulesId', id);
        // },
        EditModules(id) {
            this.$router.push('/home/edit-project-module/' + id);
        },
        // deleteModules(id) {
        //     this.$emit('update:modulesId', 1);
        //     this.$emit('update:projectModulesId', id);
        // },
        addModules() {
            this.$router.push('/home/create-module/' + this.$route.params.id);
        },
        async retrieveModules(proId) {
            const reqDatas = {
                project_id: proId
            }
            const response = await Service.GetModules(reqDatas);
            // console.log(response);
            if (response.code == "200") {
                // console.log(response.data);
                this.proj_name = response.data.name;
                this.items = response.data.data;
                this.pro_id = response.data.project;
                this.totalRows = response.data.data.length;
            } else if (response.code == "201"){
                this.proj_name = response.data;
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        deleteModules(data) {
            if (data.active_status == 1) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to disable this Module!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, disable!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const credentials = {
                            status: data.active_status,
                            id: data.id,
                        };
                        // console.log(credentials);
                        var done = this.enableOrDisableModules(credentials);
                        if (done) {
                            Swal.fire("Disabled!", "Module has been disabled.", "success")
                            this.retrieveModules(this.$route.params.id);
                        }
                    }
                });
            } else if (data.active_status == 0) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to enable this Module!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, enable!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const credentials = {
                            status: data.active_status,
                            id: data.id,
                        };
                        var done = this.enableOrDisableModules(credentials);
                        if (done) {
                            Swal.fire("Enabled!", "Module has been enabled.", "success")
                            this.retrieveModules(this.$route.params.id);
                        }
                    }
                });
            }
        },
        async enableOrDisableModules(credentials) {
            const response = await Service.EnableOrDisableModules(credentials);
            if (response.code == "200") {
                return true;
            } else {
                return false;
            }
        }

    },
};
</script>