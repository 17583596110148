<template>
  <div>
    <Breadcrumbs main="Home" title="Employee View" />
    <div class="container-fluid">
      <div class="email-wrap bookmark-wrap">
        <div class="row">
          <div class="col-xl-3 box-col-6">
            <div class="email-left-aside">
              <div class="card">
                <div class="card-body">
                  <div class="email-app-sidebar left-bookmark">
                    <div class="media">
                      <!-- <div class="media-size-email"><img class="mr-3 rounded-circle" src="../../assets/images/user/user.png" alt=""></div> -->
                      <div class="media-size-email">


                        <img v-if="data.image_name" class="mr-3 rounded-circle" :src="data.image_name"
                          style="height:80px;width:80px;" alt="" />

                        <img v-else class="mr-3 rounded-circle" src="../../assets/images/user/user.png"
                          style="height:80px;width:80px;" alt="" />

                      </div>
                      <div class="media-body">
                        <h6 class="f-w-600">{{ data.name }}</h6>
                        <p>{{ data.emp_ref_no }}</p>
                        <p>{{ data.email }}</p>
                        <p>{{ data.phone }}</p>
                      </div>
                      <router-link :to="'/home/'">
                        <button type="button" class="btn btn-success btn-circle" title="Home"><i
                            class="fa fa-home"></i></button>

                      </router-link>
                    </div>
                    <hr />
                    <ul class="nav main-menu" role="tablist">
                      <li>
                        <a id="pills-created-tab" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-created" aria-selected="true"><span class="title"
                            v-on:click="say('pills_created')">
                            Personal Details</span></a>
                      </li>
                      <li>
                        <a class="show" id="pills-contact-tab" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-contact" aria-selected="false"><span class="title"
                            v-on:click="say('pills_contact')">
                            Contact Details</span></a>
                      </li>

                      <li>
                        <a class="show" id="pills-company-tab" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-company" aria-selected="false"><span class="title"
                            v-on:click="say('pills_company')">
                            Company Details</span></a>
                      </li>

                      <li>
                        <a class="show" id="pills-document-tab" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-document" aria-selected="false"><span class="title"
                            v-on:click="say('pills_document')">
                            User Documents
                          </span></a>
                      </li>

                      <li v-if="curr_user_id == loginData.id">
                        <a class="show" id="pills-project-tab" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-project" aria-selected="false"><span class="title"
                            v-on:click="say('pills_project')">
                            Projects & Tasks</span></a>
                      </li>
                      <li>
                        <a class="show" id="pills-eleaves-tab" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-eleaves" aria-selected="false" @click="changeComp"><span class="title"
                            v-on:click="say('pills_eleaves')">
                            Leaves</span></a>
                      </li>
                      <li v-if="curr_user_id == loginData.id">
                        <a class="show" id="pills-eleaves-tab" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-eleaves" aria-selected="false"><span class="title"
                            v-on:click="say('pills_intimation')">
                            Intimations</span></a>
                      </li>
                      <li>
                        <a class="show" href="/home/monthly-attendance"><span class="title"
                            >
                            Attendance </span></a>
                      </li>
                      <!-- <li>
                        <a class="show" id="pills-comeing-reports-tab" data-toggle="pill" href="javascript:void(0)"
                          role="tab" aria-controls="pills-comeing-reports" aria-selected="false"><span class="title"
                            v-on:click="say('pills_comeing_reports')">
                            Late Coming Reports</span></a>
                      </li> -->
                      <li>
                        <a class="show" id="pills-onsite-history-tab" data-toggle="pill" href="javascript:void(0)"
                          role="tab" aria-controls="pills-onsite-history" aria-selected="false" @click="changeComp"><span
                            class="title" v-on:click="say('pills_onsite_history')">
                            Onsite history</span></a>
                      </li>
                      <li>
                        <a class="show" id="pills-overtime-history-tab" data-toggle="pill" href="javascript:void(0)"
                          role="tab" aria-controls="pills-overtime-history" aria-selected="false"
                          @click="changeComp"><span class="title" v-on:click="say('pills_overtime_history')">
                            Overtime history</span></a>
                      </li>
                      <li>
                        <a class="show" id="pills-recruitment-tab" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-recruitment" aria-selected="false" @click="changeComp"><span class="title"
                            v-on:click="say('pills_recruitment')">
                            Recruitment Reviews</span></a>
                      </li>
                      <li>
                        <a class="show" id="pills-salarySum" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-salarySum" aria-selected="false" @click="changeComp">
                          <span class="title" v-on:click="say('pills_salarySum')">
                            Salary summary
                          </span>
                        </a>
                      </li>
                      <li>
                        <a class="show" id="pills-payslip" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-payslip" aria-selected="false" @click="changeComp">
                          <span class="title" v-on:click="say('pills_payslip')">
                            Payslip
                          </span>
                        </a>
                      </li>
                      <li>
                        <a class="show" id="pills-notification" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-notification" aria-selected="false" @click="changeComp">
                          <span class="title" v-on:click="say('pills_notification')">
                            Notifications
                          </span>
                        </a>
                      </li>
                      <li>
                        <a class="show" id="pills-activities" data-toggle="pill" href="javascript:void(0)" role="tab"
                          aria-controls="pills-activities" aria-selected="false" @click="changeComp">
                          <span class="title" v-on:click="say('pills_activities')">
                            Activities
                          </span>
                        </a>
                      </li>
                      <li>
                        <hr />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-9 col-md-12 box-col-12">
            <div class="email-right-aside bookmark-tabcontent">
              <div class="card email-body radius-left">
                <div class="pl-0">
                  <div class="tab-content">
                    <div class="tab-pane fade" v-bind:class="activeclass === 'pills_created' ? 'active show' : ''
                      " id="pills-created" role="tabpanel" aria-labelledby="pills-created-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0">Personal Details</h5>
                        </div>

                        <div class="card">
                          <div class="card-body">
                            <div class="collection-filter-block">
                              <ul>
                                <li>
                                  <div class="media">
                                    <feather type="user" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>Gender : {{ gender }}</h6>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="media">
                                    <feather type="droplet" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Blood group : {{ data.blood_group }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div class="media">
                                    <feather type="calendar" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Date Of Birth :
                                        {{ data.dob | moment("DD-MM-YYYY") }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="media">
                                    <feather type="book-open" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Higher Qualification :
                                        {{ data.higher_qualification }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="media">
                                    <feather type="credit-card" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Aadhaar No. : {{ data.aadhaar_no }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div class="media">
                                    <feather type="credit-card" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Passport No. :
                                        {{ data.passport_number }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="fade tab-pane" v-bind:class="activeclass === 'pills_contact' ? 'active show' : ''
                      " id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0">Contact Details</h5>
                        </div>

                        <div class="card">
                          <div class="card-body">
                            <div class="collection-filter-block">
                              <ul>
                                <li>
                                  <div class="media">
                                    <feather type="phone" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>Mobile : {{ data.phone }}</h6>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="media">
                                    <feather type="mail" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>Email : {{ data.email }}</h6>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div class="media">
                                    <feather type="file" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Current Address :
                                        {{ data.current_address }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="media">
                                    <feather type="file" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Permanent Address :
                                        {{ data.permanent_address }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div class="media">
                                    <feather type="user" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Emergency Contact Person :
                                        {{ data.emergency_contact_person }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="media">
                                    <feather type="phone" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Emergency Contact Number :
                                        {{ data.emergency_contact_number }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="fade tab-pane" v-bind:class="activeclass === 'pills_company' ? 'active show' : ''
                      " id="pills-company" role="tabpanel" aria-labelledby="pills-company-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0">Company Details</h5>
                        </div>

                        <div class="card">
                          <div class="card-body">
                            <div class="collection-filter-block">
                              <ul>
                                <li>
                                  <div class="media">
                                    <feather type="credit-card" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>Company : {{ data.company }}</h6>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div class="media">
                                    <feather type="credit-card" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Department : {{ data.department }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="media">
                                    <feather type="credit-card" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Designation : {{ data.designation }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div class="media">
                                    <feather type="calendar" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Joined Date :
                                        {{
                                          data.joined_date
                                          | moment("DD-MM-YYYY")
                                        }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div class="media">
                                    <feather type="check" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Employee status : {{ employee_status }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div class="media" v-if="data.start_date">
                                    <feather type="calendar" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Start Date :
                                        {{
                                          data.start_date | moment("DD-MM-YYYY")
                                        }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="media">
                                    <feather type="credit-card" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Wage Calculation Type : {{ wage_calculation_type }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="media">
                                    <feather type="credit-card" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        Wage Unit Price : {{ data.wage_unit_price }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div class="media" v-if="data.end_date">
                                    <feather type="calendar" class="icon-space"></feather>
                                    <div class="media-body">
                                      <h6>
                                        End Date :
                                        {{
                                          data.end_date | moment("DD-MM-YYYY")
                                        }}
                                      </h6>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="fade tab-pane" v-bind:class="activeclass === 'pills_eleaves' ? 'active show' : ''
                        " id="pills-eleaves" role="tabpanel" aria-labelledby="pills-eleaves-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0">Leaves</h5>
                        </div>

                        <div class="card" v-if="changeChild == 0">
                          <div class="card-body">
                            <b-row>
                              <b-col xl="6">

                                <b-button id="default-primary" variant="primary" @click="changeChild = 1">Apply Leave
                                </b-button>

                              </b-col>
                              <b-col xl="6">
                                <b-button id="default-primary" variant="primary" v-b-modal.modal-holidays
                                  class="pull-right" style="margin-left:10px">Holidays
                                </b-button>


                                <b-button id="default-primary" variant="primary" v-b-modal.modal-5
                                  class="pull-right">Leave Balance
                                </b-button>

                              </b-col>
                            </b-row>
                            <br />

                            <b-row>
                              <b-col xl="6">
                                <b-input-group class="datatable-btn">
                                  <b-form-input v-model="filter" placeholder="Search"></b-form-input>
                                  <b-input-group-append>
                                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>

                              <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                  <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                              <b-table show-empty stacked="md" :items="items_leaves" :fields="tablefields_leaves"
                                :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                <template #cell(create_at)="rows">
                                  <span>
                                    {{ rows.item.create_at ? rows.item.create_at.split("T")[0] : "" }}
                                  </span>
                                </template>
                                <template #cell(start_date)="rows">
                                  <span>
                                    {{ rows.item.start_date ? rows.item.start_date.split("T")[0] : "" }}
                                  </span>
                                </template>
                                <template #cell(end_date)="rows">
                                  <span>
                                    {{ rows.item.end_date ? rows.item.end_date.split("T")[0] : "" }}
                                  </span>
                                </template>
                                <template #cell(status)="rows">
                                  <b-badge :variant="statusVariant(rows.item.status)">
                                    <span v-if="rows.item.status == 1">
                                      Pending
                                    </span>
                                    <span v-else-if="rows.item.status == 2">
                                      Acknowledged
                                    </span>
                                    <span v-else-if="rows.item.status == 3">
                                      Approved
                                    </span>
                                    <span v-else-if="rows.item.status == 4">
                                      Canceled
                                    </span>
                                    <span v-else-if="rows.item.status == 5">
                                      Rejected
                                    </span>
                                  </b-badge>
                                </template>

                                <template #cell(actions)="rows">

                                  <span v-if="rows.item.status == 1 && new Date(rows.item.start_date) > new Date()"
                                    title="Cancel">
                                    <feather type="trash-2" @click="deleteLeave(rows.item)"></feather>
                                  </span>
                                  <span v-if="rows.item.status == 2 && new Date(rows.item.start_date) > new Date()"
                                    title="Cancel">
                                    <feather type="trash-2" @click="deleteLeave(rows.item)"></feather>
                                  </span>

                                  <!-- <span v-if="rows.item.status == 1" title="Cancel">
                                    <feather type="trash-2" @click="deleteLeave(rows.item)"></feather> 
                                  </span>
                                  <span v-if="rows.item.status == 2" title="Cancel">
                                    <feather type="trash-2" @click="deleteLeave(rows.item)"></feather>
                                  </span> -->

                                  <span v-if="rows.item.status != 4 && rows.item.status != 5 && rows.item.status != 3">
                                    <span v-if="rows.item.leave_type_name == 'Sick Leave'"
                                      @click="$bvModal.show('bv-modal-doc')"
                                      v-on:click="getId(rows.item.employee_leave_id)">

                                      <feather type="edit" title="Edit Document"></feather>

                                    </span>
                                  </span>

                                  <span v-if="rows.item.file_name != null">
                                    <a :href="rows.item.file_name" target="_blank" download>
                                      <feather type="download">Download</feather>
                                    </a>
                                  </span>
                                </template>
                              </b-table>

                              <b-modal id="bv-modal-doc" centered size="lg" hide-footer>
                                <template #modal-title>
                                  Upload Medical document
                                </template>
                                <div class="modal-body">
                                  <b-form class="needs-validation">


                                    <div class="form-row">
                                      <div class="col-md-3 mb-3">
                                        <label>Upload File</label>
                                      </div>

                                      <div class="col-md-3 mb-3">

                                        <input type="file" @change="onDocChange" />
                                        <b-form-valid-feedback>
                                        </b-form-valid-feedback>
                                      </div>
                                    </div>

                                    <input id="index_var" type="hidden" value="6" />

                                    <button v-if="filedoc" class="btn btn-secondary" id="Bookmark" type="button"
                                      @click="onDocSubmit()">
                                      Save
                                    </button>

                                    <button disabled class="btn btn-primary el" v-else>
                                      Loading..
                                    </button>

                                  </b-form>
                                </div>
                              </b-modal>

                            </div>
                            <b-col md="6" class="my-1 p-0">
                              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                class="my-0"></b-pagination>
                            </b-col>
                          </div>
                        </div>
                        <div v-if="changeChild == 1">
                          <add-leave v-if="changeChild" :childVal.sync='changeChild' @callTest="getLeavesByUser" />
                        </div>
                      </div>
                    </div>

                    <div class="fade tab-pane" v-bind:class="activeclass === 'pills_document' ? 'active show' : ''
                      " id="pills-document" role="tabpanel" aria-labelledby="pills-document-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0">User Documents</h5>
                        </div>

                        <div class="card">
                          <div class="card-body">
                            <b-row>
                              <b-col xl="12">
                                <b-button id="default-primary" variant="primary"
                                  @click="$bvModal.show('bv-modal-document')">Upload Document
                                </b-button>
                              </b-col>
                            </b-row>
                            <br />

                            <b-row>
                              <b-col xl="6">
                                <b-input-group class="datatable-btn">
                                  <b-form-input v-model="filter" placeholder="Search"></b-form-input>
                                  <b-input-group-append>
                                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>

                              <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                  <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                              <b-modal id="bv-modal-document" centered size="lg" hide-footer>
                                <template #modal-title>
                                  Upload document
                                </template>
                                <div class="modal-body">
                                  <b-form class="needs-validation" @submit="onDocumentSubmit">
                                    <div class="form-row">
                                      <div class="col-md-6 mb-3">
                                        <label>Document Name</label>
                                      </div>

                                      <div class="col-md-6 mb-3">
                                        <b-form-input type="text" v-model="document_name" placeholder="Name">
                                        </b-form-input>
                                        <b-form-valid-feedback>
                                        </b-form-valid-feedback>
                                      </div>
                                    </div>

                                    <div class="form-row">
                                      <div class="col-md-6 mb-3">
                                        <label>Upload File</label>
                                      </div>

                                      <div class="col-md-6 mb-3">
                                        <!-- <b-form-input
                                          type="file"
                                          @change="onFileChange"
                                        >
                                        </b-form-input> -->
                                        <input type="file" @change="onFileChange" />
                                        <b-form-valid-feedback>
                                        </b-form-valid-feedback>
                                      </div>
                                    </div>

                                    <input id="index_var" type="hidden" value="6" />

                                    <button v-if="file" class="btn btn-secondary" id="Bookmark" type="submit">
                                      Save
                                    </button>

                                    <button disabled class="btn btn-primary el" v-else>
                                      Loading..
                                    </button>

                                    <!-- <button
                                      class="btn btn-primary ml-2"
                                      type="button"
                                      data-dismiss="modal"
                                    >
                                      Cancel
                                    </button> -->
                                  </b-form>
                                </div>
                              </b-modal>

                              <b-table show-empty stacked="md" :items="items_docs" :fields="tablefields_docs"
                                :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                <template #cell(actions)="rows">
                                  <a :href="rows.item.file_name" target="_blank" download>
                                    <feather type="download"></feather>
                                  </a>
                                  <span title="Cancel">
                                    <feather type="trash-2" @click="deleteDoc(rows.item)"></feather>
                                  </span>

                                  <!-- <a
                                    href="#"
                                    @click.prevent="
                                      downloadDoc({
                                        url: rows.item.file_name,
                                        label: rows.item.doc_name+'_document',
                                      })
                                    "
                                  > -->

                                  <!-- </a> -->
                                </template>
                              </b-table>
                            </div>
                            <b-col md="6" class="my-1 p-0">
                              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                class="my-0"></b-pagination>
                            </b-col>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="fade tab-pane" v-bind:class="activeclass === 'pills_project' ? 'active show' : ''
                      " id="pills-project" role="tabpanel" aria-labelledby="pills-project-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0">Employee Projects</h5>
                        </div>

                        <div class="card">
                          <div class="card-body">
                            <!-- <b-row>
                              <b-col xl="12">
                                <b-button id="default-primary" variant="primary"
                                  >Apply Leave
                                </b-button>
                              </b-col>
                            </b-row>
                            <br /> -->

                            <b-row>
                              <b-col xl="6">
                                <b-input-group class="datatable-btn">
                                  <b-form-input v-model="filter" placeholder="Search"></b-form-input>
                                  <b-input-group-append>
                                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>

                              <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                  <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                              <b-table show-empty stacked="md" :items="items_projects" :fields="tablefields_projects"
                                :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                <template #cell(start_date)="rows">
                                  <span>
                                    {{ rows.item.start_date ? rows.item.start_date.split("T")[0] : "" }}
                                  </span>
                                </template>
                                <template #cell(end_date)="rows">
                                  <span>
                                    {{ rows.item.end_date ? rows.item.end_date.split("T")[0] : "" }}
                                  </span>
                                </template>

                                <template #cell(status)="rows">
                                  <b-badge :variant="statusVariant2(rows.item.status)">
                                    <span v-if="rows.item.status == 1">
                                      Ongoing
                                    </span>
                                    <span v-else-if="rows.item.status == 2">
                                      Completed
                                    </span>
                                    <span v-else>
                                      Hold
                                    </span>
                                  </b-badge>
                                </template>

                                <template #cell(actions)="rows">
                                  <span v-if="loginData.designation.level.key == 'MANAGER'">
                                    <router-link :to="'/home/emp-project/' + rows.item.id">
                                      <feather type="eye"></feather>
                                    </router-link>
                                  </span>
                                  <span
                                    v-else-if="loginData.designation.level.key == 'EMPLOYEE' || loginData.designation.level.key == 'HR' && rows.item.status == 1">
                                    <router-link :to="'/home/emp-project/' + rows.item.id">
                                      <feather type="eye"></feather>
                                    </router-link>
                                  </span>
                                </template>

                              </b-table>

                              <b-modal id="bv-modal-viewproject" centered size="lg" hide-footer>
                                <template #modal-title>
                                  Project : {{ proj_name }}
                                </template>
                                <div class="modal-body">
                                  <b-form class="needs-validation">
                                    <div class="form-row">
                                      <div class="col-md-6 mb-3">
                                        <label>Description</label>
                                      </div>

                                      <div class="col-md-6 mb-3">
                                        <b-form-input type="text" v-model="proj_desc" placeholder="" disabled>
                                        </b-form-input>
                                      </div>

                                      <div class="col-md-6 mb-3">
                                        <label>Client</label>
                                      </div>

                                      <div class="col-md-6 mb-3">
                                        <b-form-input type="text" v-model="proj_name" placeholder="" disabled>
                                        </b-form-input>
                                      </div>
                                    </div>

                                    <div class="form-row">
                                      <div class="col-md-6 mb-3">
                                        <label>Calculation type</label>
                                      </div>

                                      <div class="col-md-6 mb-3" v-if="calc_type == 1">
                                        <b-form-input type="text" placeholder="Hourly" disabled>Hourly
                                        </b-form-input>
                                      </div>
                                      <div class="col-md-6 mb-3" v-else-if="calc_type == 0">
                                        <b-form-input type="text" placeholder="Day wise" disabled>Day wise
                                        </b-form-input>
                                      </div>
                                      <div class="col-md-6 mb-3" v-else>
                                        <b-form-input type="text" placeholder="Day wise" disabled>Day wise
                                        </b-form-input>
                                      </div>

                                      <div class="col-md-6 mb-3">
                                        <label>Unit Cost</label>
                                      </div>

                                      <div class="col-md-6 mb-3">
                                        <b-form-input type="text" v-model="unit_cost" placeholder="" disabled>
                                        </b-form-input>
                                      </div>
                                    </div>

                                    <div class="form-row">
                                      <div class="col-md-6 mb-3">
                                        <label>Total Cost</label>
                                      </div>

                                      <div class="col-md-6 mb-3">
                                        <b-form-input type="text" v-model="total_cost" placeholder="" disabled>
                                        </b-form-input>
                                      </div>

                                      <div class="col-md-6 mb-3">
                                        <label>Lead Name</label>
                                      </div>

                                      <div class="col-md-6 mb-3">
                                        <b-form-input type="text" v-model="lead_name" placeholder="" disabled>
                                        </b-form-input>
                                      </div>
                                    </div>

                                    <div class="form-row">
                                      <div class="col-md-6 mb-3">
                                        <label>Duration</label>
                                      </div>

                                      <div class="col-md-6 mb-3">
                                        <b-form-input type="text" v-model="duration" placeholder="" disabled>
                                        </b-form-input>
                                      </div>

                                      <div class="col-md-6 mb-3" v-if="proj_file">
                                        <label>Download File</label>
                                      </div>

                                      <div v-if="proj_file" class="col-md-6 mb-3">
                                        <a :href="proj_file" target="_blank" download>
                                          <feather type="download"></feather>
                                        </a>

                                      </div>
                                    </div>

                                  </b-form>
                                </div>
                              </b-modal>
                            </div>
                            <b-col md="6" class="my-1 p-0">
                              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                class="my-0"></b-pagination>
                            </b-col>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="fade tab-pane" v-bind:class="activeclass === 'pills_project_tasks' ? 'active show' : ''
                      " id="pills-project_tasks" role="tabpanel" aria-labelledby="pills-project_tasks-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0"> Project Tasks</h5>
                        </div>

                        <task-list v-if="projectId" :project_id='projectId' />

                      </div>
                    </div>

                    <div class="fade tab-pane" v-bind:class="activeclass === 'pills_intimation' ? 'active show' : ''
                      " id="pills-project_tasks" role="tabpanel" aria-labelledby="pills-project_tasks-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0"> Intimations</h5>
                        </div>
                        <!-- <user-intimation v-if="this.$route.params.id" :emp_det='this.$route.params.id'/> -->
                        <messages />
                      </div>
                    </div>

                    <div class="fade tab-pane" v-bind:class="activeclass === 'pills_reports' ? 'active show' : ''
                      " id="pills-project_tasks" role="tabpanel" aria-labelledby="pills-project_tasks-tab">
                      <div class="card mb-0">
                        <div class="card-header d-flex">
                          <h5 class="mb-0"> Reports</h5>
                        </div>
                        <!-- <user-intimation v-if="this.$route.params.id" :emp_det='this.$route.params.id'/> -->
                        <div class="container-fluid">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="card">
                                <div class="card-body">
                                  <b-row>
                                    <b-col xl="3">
                                      <!-- <b-form-input type="month" value="2015-01" v-model="month"></b-form-input> -->
                                      <datepicker :minimumView="'month'" input-class="datepicker-here form-control digits"
                                        v-model="month"></datepicker>
                                    </b-col>
                                    <b-col xl="3" class="load-style">
                                      <b-button id="default-primary" v-if="isLoading" variant="primary"
                                        @click="generateReport">
                                        View
                                      </b-button>
                                      <div class="loader-box " v-else>
                                        <div class="loader-15"></div>
                                      </div>
                                    </b-col>
                                  </b-row>

                                  <br>
                                  <div class="table-responsive" id="datatable" v-if="result_arr[0]">
                                    <table class="table table-condensed table-bordered table-striped"
                                      id="datatable-content">
                                      <thead class="datatable-head" id="datatable-head">
                                        <tr>

                                          <th v-for="value1 in result_arr[0].day_arr">
                                            <center> {{ value1.day }}</center>
                                        <tr
                                          v-if="value1.day != 'Total Late Punch-in duration' && value1.day != 'Total Work Duration' && value1.day != 'Total OT Duration' && value1.day != 'Total OD Duration' && value1.day != 'Total Early Punch-out duration'">
                                          <th>Start Time</th>
                                          <th>End Time</th>
                                          <th>Work Duration</th>
                                          <th>Late Punch-in Duration</th>
                                          <th>Early Punch-out Duration</th>
                                          <th>Overtime</th>
                                          <th>Onsite</th>
                                          <th>Leave Info</th>
                                        </tr>
                                        </th>
                                        </tr>
                                      </thead>

                                      <tbody class="datatable-body" id="datatable-body">

                                        <tr>

                                          <td v-for="value3 in result_arr[0].day_arr">
                                            <div v-for="value2 in value3.time_details">

                                        <tr v-if="'end_time' in value2" @click="viewDetails(value3.day)"
                                          v-b-modal.modal-4>
                                          <td> {{ value2.start_time }} </td>
                                          <td>{{ value2.end_time }}</td>
                                          <td>{{ value2.value }}</td>
                                          <td>{{ value2.late }}</td>
                                          <td>{{ value2.eve_late }}</td>
                                          <td>{{ value2.overtime_day }}</td>
                                          <td>{{ value2.onsite_day }}</td>
                                          <td>{{ value2.lInfo }}</td>
                                        </tr>
                                        <tr v-else>
                                          <td class="leave-col">{{ value2.start_time }}</td>
                                        </tr>
                                  </div>
                                  </td>
                                  </tr>
                                  </tbody>

                                  </table>
                                </div>
                              </div>

                            </div>
                          </div>

                        </div>

                        <!-- modal for punching activity start -->

                        <b-modal id="modal-4" v-model="modalShow" hide-footer>
                          <div class="row">
                            <h5 class="text-center" style="width: 100%;">Punching Activities</h5>
                            <h5 class="text-center" style="width: 100%;">{{ curr_day }}</h5>
                          </div>
                          <div class="row padz">
                            <table class="table table-condensed table-bordered table-striped" id="datatable-content"
                              v-if="activity.length > 0">
                              <thead class="datatable-head" id="datatable-head">
                                <tr>
                                  <th>
                                    IN Time
                                  </th>
                                  <th>
                                    OUT Time
                                  </th>
                                  <th>
                                    Status
                                  </th>
                                  <th>
                                    Reason
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="datatable-body" id="datatable-body">
                                <tr v-for="value3 in activity">
                                  <td>
                                    {{ value3.in_time ? value3.in_time : '' }}
                                  </td>
                                  <td>
                                    {{ value3.out_time ? value3.out_time : '' }}
                                  </td>
                                  <td>
                                    {{ getStatus(value3.status) }}
                                  </td>
                                  <td>
                                    {{ value3.text }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <b-button class="mt-3 " variant="danger" @click="hideModal">Close</b-button>
                            </div>
                          </div>
                        </b-modal>

                        <!-- modal for punching activity end -->

                      </div>
                    </div>

                    <!-- modal for leave balance start -->

                    <b-modal id="modal-5" v-model="modal2Show" hide-footer>
                      <div class="table-responsive datatable-vue">
                        <b-table show-empty stacked="md" :items="items" :fields="tablefields">
                        </b-table>
                        <b-modal id="bv-modal-example" centered size="lg" hide-footer hide-header>
                          <h5> Modify Leave Balance</h5>
                          <hr>
                          <div class="modal-body" v-if="items != ''">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                              <div class="form-row" v-for="(item, index) in items" :key="index">
                                <div class="col-md-6 mb-3">
                                  <label for="c_form_name">{{
                                    item.leave_type_name
                                  }}</label>
                                </div>
                                <div class="col-md-6 mb-3">
                                  <b-form-input type="text" id="c_form_count" v-model.lazy="item.count"
                                    :state="c_form_result.count" placeholder="Count" @change="handleCountChange(
                                      item.count,
                                      item.leave_type_name,
                                      item.leave_type_count,
                                      item.validation_status
                                    )
                                      ">
                                  </b-form-input>
                                  <b-form-valid-feedback :state="c_form_result.count">
                                  </b-form-valid-feedback>
                                </div>
                              </div>
                              <input id="index_var" type="hidden" value="6" />
                              <button class="btn btn-secondary" id="Bookmark" type="submit">
                                Save
                              </button>
                              <button class="btn btn-primary ml-2" type="button" data-dismiss="modal"
                                v-on:click="getLeaveCountByUser()" @click="$bvModal.hide('bv-modal-example')">
                                Cancel
                              </button>
                            </b-form>
                          </div>
                          <div class="modal-body" v-else>
                            <h6>No Leave Balance</h6>
                          </div>
                        </b-modal>
                      </div>
                    </b-modal>


                    <!-- modal for leave balance end -->




                  </div>


                  <b-modal id="modal-holidays" v-model="modalHoliday" hide-footer>
                    <template #modal-title>
                      Holidays
                    </template>

                    <div class="table-responsive datatable-vue">
                      <b-table show-empty stacked="md" :items="items_holidays" :fields="tablefields_holidays">

                        <template #cell(date)="rows">
                          <span>
                            {{ dateConvert(rows.item.date) }}
                          </span>
                        </template>

                      </b-table>
                    </div>
                  </b-modal>


                  <div class="fade tab-pane" v-bind:class="activeclass === 'pills_comeing_reports' ? 'active show' : ''
                    " id="pills-comeing-reports-tab" role="tabpanel" aria-labelledby="pills-comeing-reports-tab">
                    <div class="card mb-0">
                      <div class="card-header d-flex">
                        <h5 class="mb-0">Late Coming</h5>
                      </div>

                      <Late-Comeing v-if="emid" :id='emid' />

                    </div>
                  </div>

                  <div class="fade tab-pane" v-bind:class="activeclass === 'pills_onsite_history' ? 'active show' : ''
                    " id="pills-onsite-history" role="tabpanel" aria-labelledby="pills-onsite-history-tab">
                    <div class="card mb-0">
                      <div class="card-header d-flex">
                        <h5 class="mb-0">Onsite History</h5>
                      </div>

                      <div class="card" v-if="changeChild == 0">
                        <div class="card-body">
                          <b-row>
                            <b-col xl="9"></b-col>
                            <b-col xl="3">

                              <b-button id="default-primary" variant="primary" @click="changeChild = 1">Apply Onsite
                                Request
                              </b-button>

                            </b-col>

                          </b-row>
                          <br /><br>

                          <b-row>
                            <b-col xl="6">
                              <b-input-group class="datatable-btn">
                                <b-form-input v-model="filter" placeholder="Search"></b-form-input>
                                <b-input-group-append>
                                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-col>

                            <b-col xl="6">
                              <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                              </b-form-group>
                            </b-col>
                          </b-row>

                          <div class="table-responsive datatable-vue">
                            <b-table show-empty stacked="md" :items="items_onsite_history"
                              :fields="tablefields_onsite_history" :filter="filter" :current-page="currentPage"
                              :per-page="perPage" @filtered="onFiltered">
                              <template #cell(create_at)="rows">
                                <span>
                                  {{ rows.item.create_at ? rows.item.create_at.split("T")[0] : "" }}
                                </span>
                              </template>
                              <template #cell(start_date)="rows">
                                <span>
                                  {{ rows.item.start_date ? rows.item.start_date.split("T")[0] : "" }}
                                </span>
                              </template>
                              <template #cell(end_date)="rows">
                                <span>
                                  {{ rows.item.end_date ? rows.item.end_date.split("T")[0] : "" }}
                                </span>
                              </template>
                              <template #cell(no_days)="rows">
                                <span>
                                  {{ formateTime(rows.item.no_days) }}
                                </span>
                              </template>
                              <template #cell(status)="rows">
                                <b-badge :variant="statusVariant(rows.item.status)">
                                  <span v-if="rows.item.status == 1">
                                    Pending
                                  </span>
                                  <span v-else-if="rows.item.status == 2">
                                    Acknowledged
                                  </span>
                                  <span v-else-if="rows.item.status == 3">
                                    Approved
                                  </span>
                                  <span v-else-if="rows.item.status == 4">
                                    Canceled
                                  </span>
                                  <span v-else-if="rows.item.status == 5">
                                    Rejected
                                  </span>
                                </b-badge>
                              </template>

                              <template #cell(actions)="rows">

                                <span v-if="rows.item.status == 1" title="Cancel">
                                  <feather type="trash-2" @click="deleteOnsite(rows.item)"></feather>
                                </span>
                                <span v-if="rows.item.status == 2" title="Cancel">
                                  <feather type="trash-2" @click="deleteOnsite(rows.item)"></feather>
                                </span>

                              </template>
                            </b-table>

                          </div>
                          <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                              class="my-0"></b-pagination>
                          </b-col>
                        </div>
                      </div>
                      <div v-if="changeChild == 1">
                        <add-onsite v-if="changeChild" :childVal.sync='changeChild' @callTest="getOnsiteHistoryByUser" />
                      </div>
                    </div>
                  </div>

                  <div class="fade tab-pane" v-bind:class="activeclass === 'pills_overtime_history' ? 'active show' : ''
                    " id="pills-overtime-history" role="tabpanel" aria-labelledby="pills-overtime-history-tab">
                    <div class="card mb-0">
                      <div class="card-header d-flex">
                        <h5 class="mb-0">Overtime History</h5>
                      </div>

                      <div class="card" v-if="changeChild2 == 0">
                        <div class="card-body">
                          <b-row>
                            <b-col xl="9"></b-col>
                            <b-col xl="3">
                              <b-button id="default-primary" variant="primary" @click="changeChild2 = 1">Apply Overtime
                                Request
                              </b-button>
                            </b-col>
                          </b-row>
                          <br /><br>

                          <b-row>
                            <b-col xl="6">
                              <b-input-group class="datatable-btn">
                                <b-form-input v-model="filter" placeholder="Search"></b-form-input>
                                <b-input-group-append>
                                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-col>

                            <b-col xl="6">
                              <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                              </b-form-group>
                            </b-col>
                          </b-row>

                          <div class="table-responsive datatable-vue">
                            <b-table show-empty stacked="md" :items="items_overtime_history"
                              :fields="tablefields_onsite_history" :filter="filter" :current-page="currentPage"
                              :per-page="perPage" @filtered="onFiltered">
                              <template #cell(create_at)="rows">
                                <span>
                                  {{ rows.item.create_at ? rows.item.create_at.split("T")[0] : "" }}
                                </span>
                              </template>
                              <template #cell(start_date)="rows">
                                <span>
                                  {{ rows.item.start_date ? rows.item.start_date.split("T")[0] : "" }}
                                </span>
                              </template>
                              <template #cell(end_date)="rows">
                                <span>
                                  {{ rows.item.end_date ? rows.item.end_date.split("T")[0] : "" }}
                                </span>
                              </template>
                              <template #cell(no_days)="rows">
                                <span>
                                  {{ formateTime(rows.item.no_days) }}
                                </span>
                              </template>
                              <template #cell(status)="rows">
                                <b-badge :variant="statusVariant(rows.item.status)">
                                  <span v-if="rows.item.status == 1">
                                    Pending
                                  </span>
                                  <span v-else-if="rows.item.status == 2">
                                    Acknowledged
                                  </span>
                                  <span v-else-if="rows.item.status == 3">
                                    Approved
                                  </span>
                                  <span v-else-if="rows.item.status == 4">
                                    Canceled
                                  </span>
                                  <span v-else-if="rows.item.status == 5">
                                    Rejected
                                  </span>
                                </b-badge>
                              </template>

                              <template #cell(actions)="rows">

                                <span v-if="rows.item.status == 1" title="Cancel">
                                  <feather type="trash-2" @click="deleteOnsite(rows.item)"></feather>
                                </span>
                                <span v-if="rows.item.status == 2" title="Cancel">
                                  <feather type="trash-2" @click="deleteOnsite(rows.item)"></feather>
                                </span>

                              </template>
                            </b-table>

                          </div>
                          <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                              class="my-0"></b-pagination>
                          </b-col>
                        </div>
                      </div>
                      <div v-if="changeChild2 == 1">
                        <add-overtime v-if="changeChild2" :childVal2.sync='changeChild2'
                          @callTest="getOnsiteHistoryByUser" />
                      </div>
                    </div>
                  </div>

                  <div class="fade tab-pane" v-bind:class="activeclass === 'pills_recruitment' ? 'active show' : ''
                    " id="pills-recruitment" role="tabpanel" aria-labelledby="pills-recruitment-tab">
                    <div class="card mb-0">
                      <div class="card-header d-flex">
                        <h5 class="mb-0">Recruitment Reviews</h5>
                      </div>

                      <div class="card">
                        <div class="card-body">

                          <br />

                          <b-row>
                            <b-col xl="6">
                              <b-input-group class="datatable-btn">
                                <b-form-input v-model="filter" placeholder="Search"></b-form-input>
                                <b-input-group-append>
                                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-col>

                            <b-col xl="6">
                              <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                              </b-form-group>
                            </b-col>
                          </b-row>

                          <div class="table-responsive datatable-vue">

                            <b-table show-empty stacked="md" :items="items_reviews" :fields="tablefields_recruitments"
                              :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                              <template #cell(description)="row">
                                <span>
                                  <textarea v-if="row.item.description" rows="1" v-model="row.item.description"
                                    readonly></textarea>
                                </span>

                              </template>
                              <template #cell(review)="row">
                                <span>
                                  <textarea v-if="row.item.review" rows="1" v-model="row.item.review" readonly></textarea>
                                </span>

                              </template>

                              <template #cell(action)="row">
                                <span v-if="checkInterViewInToday(row.item.interViewDate)"
                                  @click="editReview(row.item.transaction_id, row.item.applied_vacancy_id, row.item.resume_status)">

                                  <feather type="edit" title="Edit Review"></feather>

                                </span>

                                <span @click="viewResume(row.item.resume_id)">
                                  <!-- <router-link :to="'/home/resume-view/' + row.item.resume_id">
                                        <feather type="eye"></feather>
                                    </router-link> -->

                                  <a class="show" id="pills-resume-tab" data-toggle="pill" href="javascript:void(0)"
                                    role="tab" aria-controls="pills-resume" aria-selected="false"
                                    @click="changeComp"><span class="title" v-on:click="say('pills_resume')">
                                      <feather type="eye" title="View Resume"></feather>
                                    </span></a>

                                </span>

                              </template>

                            </b-table>

                            <b-modal id="bv-modal-review" centered size="lg" hide-footer>
                              <template #modal-title>
                                Review : {{ resume_status }}
                              </template>


                              <div class="modal-body">
                                <b-form class="needs-validation" @submit="onReviewSubmit">
                                  <div class="form-row">

                                    <div class="table-responsive datatable-vue">
                                      <b-table show-empty stacked="md" :items="items_transaction"
                                        :fields="tablefields_transaction">

                                        <template #cell(description)="row">
                                          <span>
                                            <textarea v-if="row.item.description" rows="1" v-model="row.item.description"
                                              readonly></textarea>
                                          </span>

                                        </template>
                                        <template #cell(review)="row">
                                          <span>
                                            <textarea v-if="row.item.review" rows="1" v-model="row.item.review"
                                              readonly></textarea>
                                          </span>

                                        </template>
                                      </b-table>
                                    </div>

                                    <div class="col-md-2 mb-3">
                                      <label>
                                        <h6>Type Review : </h6>
                                      </label>
                                    </div>

                                    <div class="col-md-10 mb-3">
                                      <!-- <textarea rows="4" v-model="assign_review"></textarea> -->
                                      <b-form-textarea rows="4" v-model="assign_review"
                                        placeholder="Enter Review"></b-form-textarea>

                                    </div>
                                  </div>

                                  <button v-if="assign_review" class="btn btn-secondary" id="Bookmark" type="submit">
                                    Save
                                  </button>

                                </b-form>
                              </div>


                            </b-modal>

                          </div>
                          <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                              class="my-0"></b-pagination>
                          </b-col>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="fade tab-pane" v-bind:class="activeclass === 'pills_salarySum' ? 'active show' : ''
                    " id="pills-salarySum" role="tabpanel" aria-labelledby="pills-salarySum">
                    <div class="card mb-0">
                      <div class="card-header d-flex">
                        <h5 class="mb-0">Salary summary report</h5>
                      </div>
                      <div class="card">
                        <div class="card-body">
                          <br>
                          <div>
                            <payroll-report />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="fade tab-pane" v-bind:class="activeclass === 'pills_payslip' ? 'active show' : ''
                    " id="pills-payslip" role="tabpanel" aria-labelledby="pills-payslip">
                    <div class="card mb-0">
                      <div class="card-header d-flex">
                        <h5 class="mb-0">Employee payslip</h5>
                      </div>
                      <div class="card">
                        <div class="card-body">
                          <br>
                          <div>
                            <user-payslip />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="fade tab-pane" v-bind:class="activeclass === 'pills_notification' ? 'active show' : ''
                    " id="pills-notification" role="tabpanel" aria-labelledby="pills-notification">
                    <div class="card mb-0">
                      <div class="card-header d-flex">
                        <h5 class="mb-0">Notifications</h5>
                      </div>
                      <div class="card">
                        <div class="card-body" style="padding-top: 0px !important;">
                          <br>
                          <div>
                            <emp-notify />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  
                  <div class="fade tab-pane" v-bind:class="activeclass === 'pills_activities' ? 'active show' : ''
                    " id="pills-activities" role="tabpanel" aria-labelledby="pills-activities">
                    <div class="card mb-0">
                      <div class="card-header d-flex">
                        <h5 class="mb-0">Activities</h5>
                      </div>
                      <div class="card">
                        <div class="card-body" style="padding-top: 0px !important;">
                          <br>
                          <div>
                            <amp-activity />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="fade tab-pane" v-bind:class="activeclass === 'pills_resume' ? 'active show' : ''
                    " id="pills-resume" role="tabpanel" aria-labelledby="pills-resume-tab">
                    <div class="card mb-0">
                      <div class="card-header d-flex">
                        <h5 class="mb-0">Resume Details</h5>
                      </div>

                      <div class="card">
                        <div class="card-body">

                          <br />




                          <div class="user-image">
                            <div class="row">

                              <div class="col-md-3">
                                <div class="ttl-info text-left">
                                  <!-- <h6><i class="fa fa-user"></i> Name </h6> -->
                                  <img v-if="this.image_name" class="mr-3 rounded-circle" :src="this.image_name"
                                    style="height:80px;width:80px;" alt="" />

                                  <img v-else class="mr-3 rounded-circle" src="../../assets/images/user/user.png"
                                    style="height:80px;width:80px;" alt="" />

                                </div>
                              </div>

                              <div class="">
                                <br>
                                <div class="row">

                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-user"></i> Name </h6><span>{{
                                        this.name
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-user"></i> Email </h6><span>{{
                                        this.email
                                      }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-phone"></i> Phone </h6><span>{{
                                        this.phone
                                      }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-phone"></i> Alternate Phone </h6><span>{{
                                        this.alt_phone
                                      }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-file"></i> File </h6>

                                      <a :href="this.file_name" target="_blank" download>
                                        <feather type="download"></feather>
                                      </a>

                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-user"></i> Gender </h6>
                                      <span v-if="this.gender == 'F'">
                                        Female
                                      </span>
                                      <span v-if="this.gender == 'M'">
                                        Male
                                      </span>
                                      <span v-if="this.gender == 'O'">
                                        Other
                                      </span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-heart"></i> Marital Status </h6>

                                      <span v-if="this.marital_status == 'M'">
                                        Married
                                      </span>
                                      <span v-if="this.marital_status == 'S'">
                                        Single
                                      </span>
                                      <span v-if="this.marital_status == 'D'">
                                        Divorced
                                      </span>
                                      <span v-if="this.marital_status == 'W'">
                                        Widowed
                                      </span>
                                      <span v-if="this.marital_status == 'O'">
                                        Other
                                      </span>
                                    </div>
                                  </div>

                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-file"></i> Current Address </h6><span>{{
                                        this.current_address
                                      }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-comment"></i> Current State </h6><span>{{
                                        this.current_state
                                      }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-comment"></i> Current Country </h6><span>{{
                                        this.current_country
                                      }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-comment"></i> Current Pin </h6><span>{{
                                        this.current_pin
                                      }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-file"></i> Permanent Address </h6><span>{{
                                        this.permanent_address
                                      }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-comment"></i> Permanent State </h6><span>{{
                                        this.permanent_state
                                      }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-comment"></i> Permanent Country </h6><span>{{
                                        this.permanent_country
                                      }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-comment"></i> Permanent Pin </h6><span>{{
                                        this.permanent_pin
                                      }}</span>
                                    </div>
                                  </div>


                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-comment"></i> Current Salary </h6><span>{{
                                        this.current_salary
                                      }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-comment"></i> Expected Salary </h6><span>{{
                                        this.expected_salary
                                      }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-comment"></i> Notice Period </h6><span>{{
                                        this.notice_period_in_days
                                      }} days</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="ttl-info text-left">
                                      <h6><i class="fa fa-comment"></i> Notes </h6><span>{{
                                        this.notes
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                                <hr>



                                <div class="row">
                                  <div class="col">

                                    <h6 class="mb-0"><b>Academic Details</b></h6> <br>

                                    <div class="table-responsive datatable-vue">
                                      <b-table show-empty stacked="md" :items="academic_detail"
                                        :fields="tablefields_academics">
                                      </b-table>
                                    </div>
                                  </div>



                                  <div class="col">

                                    <h6 class="mb-0"><b>Work Experience</b></h6> <br>

                                    <div class="table-responsive datatable-vue">
                                      <b-table show-empty stacked="md" :items="work_exp_details"
                                        :fields="tablefields_workexp">

                                      </b-table>
                                    </div>

                                  </div>
                                </div>


                              </div>
                            </div>
                          </div>





                        </div>
                      </div>
                    </div>
                  </div>
                  <!--tab div end-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Service from "@/services/Service.js";
import ServiceApi from "@/services/ServiceApi.js";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import S3 from "aws-s3";
import TaskList from "../Tasks/task-list.vue";
import LateComeing from "../Reports/user-by-id-late-comers.vue";
import moment from 'moment'
// import UserIntimation from "./user-intimation.vue";
import messages from './user-messages.vue';
import AddLeave from '../Leave/add.vue';
import AddOnsite from '../Onsite/add.vue';
import AddOvertime from '../Overtime/add.vue';
import Datepicker from 'vuejs-datepicker';
import userPayslip from './user-payslip.vue';
import payrollReport from './payroll-report.vue';
import empNotify from './notifications.vue';
import ampActivity from '../Activity/activities.vue';

export default {
  name: "bookmark",
  components: {
    vSelect,
    TaskList,
    messages,
    AddLeave,
    AddOnsite,
    AddOvertime,
    LateComeing,
    Datepicker,
    userPayslip,
    payrollReport,
    empNotify,
    ampActivity
  },
  data() {
    return {
      modal2Show: false,
      modalHoliday: false,
      isLoading: true,
      emid: this.$route.params.id,
      success: false,
      changecount: null,
      leavecount: null,
      leave_type_name: null,
      gender: null,
      employee_status: null,
      wage_calculation_type: null,
      liststyle: false,
      data: [],
      leave_data: [],
      academic_detail: [],
      work_exp_details: [],
      document_name: null,
      activeclass: "pills_created",
      favourite: [],
      tablefields: [
        { key: "leave_type_name", label: "Leave Type", sortable: true },
        { key: "count", label: "Leave Balance", sortable: true },
      ],
      items: [],
      items_holidays: [],
      tablefields_holidays: [
        { key: "name", label: "Holiday", sortable: true },
        { key: "date", label: "Date", sortable: true },
      ],
      tablefields_leaves: [
        { key: "create_at", label: "Applied Date", sortable: true },
        { key: "leave_type_name", label: "Leave Type", sortable: true },
        { key: "reason", label: "Reason", sortable: true },
        { key: "start_date", label: "Start Date", sortable: true },
        { key: "end_date", label: "End Date", sortable: true },
        { key: "no_days", label: "No. of days", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "actions", label: "Actions", sortable: true },
      ],
      items_leaves: [],

      tablefields_onsite_history: [
        { key: "create_at", label: "Applied Date", sortable: true },
        { key: "reason", label: "Reason", sortable: true },
        { key: "project", label: "Project", sortable: true },
        { key: "start_date", label: "Start Date and Time", sortable: true },
        { key: "end_date", label: "End Date and Time", sortable: true },
        { key: "no_days", label: "Duration", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "actions", label: "Actions", sortable: true },
      ],
      items_onsite_history: [],
      items_overtime_history: [],
      totalRows2: null,
      tablefields_docs: [
        { key: "doc_name", label: "Document Name", sortable: true },
        { key: "actions", label: "Actions", sortable: true },
      ],
      items_docs: [],
      tablefields_projects: [
        { key: "project_name", label: "Project Name", sortable: true },
        { key: "client_name", label: "Client Name", sortable: true },
        { key: "start_date", label: "Start Date", sortable: true },
        { key: "end_date", label: "End Date", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "actions", label: "Actions", sortable: true },
      ],
      tablefields_recruitments: [
        { key: 'applied_user', label: 'Applied user', sortable: true, },
        { key: 'vacancy_name', label: 'Vacancy', sortable: true, },
        { key: 'resume_status', label: 'Status', sortable: true, },
        { key: 'assign_review', label: 'Review', sortable: true, },
        { key: 'description', label: 'Description', sortable: true, },
        { key: 'interViewDate', label: 'Interview Date', sortable: true, },
        { key: 'date', label: 'Date', sortable: true, },
        { key: 'action', label: 'Action', sortable: true, },
      ],

      tablefields_transaction: [
        { key: 'resume_status', label: 'Status', sortable: true, },
        { key: 'assign_user', label: 'Assigned User', sortable: true, },
        { key: 'assign_review', label: 'Review', sortable: true, },
        { key: 'description', label: 'Description', sortable: true, },
        { key: 'interDate', label: 'Interview Date', sortable: true, },

      ],

      name: '',
      email: '',
      phone: '',
      alt_phone: '',
      gender: '',
      marital_status: '',
      image_name: '',
      current_address: '',
      current_state: '',
      current_country: '',
      current_pin: '',
      permanent_address: '',
      permanent_state: '',
      permanent_country: '',
      permanent_pin: '',
      file_name: '',
      current_salary: '',
      expected_salary: '',
      notice_period_in_days: '',
      notes: '',
      resume_status: '',
      academic_detail: [],
      work_exp_details: [],
      applied_vacancies: [],

      tablefields_academics: [
        { key: "institute", label: "Institute", sortable: true },
        { key: "course", label: "Course", sortable: true },
        { key: "joining_year", label: "Joining Year", sortable: true },
        { key: "pass_out_year", label: "Pass Out Year", sortable: true },
      ],
      tablefields_workexp: [
        { key: "organization", label: "Organization", sortable: true },
        { key: "designation", label: "Designation", sortable: true },
        { key: "start_date", label: "Start Date", sortable: true },
        { key: "end_date", label: "End Date", sortable: true },
      ],
      items_transaction: [],
      items_projects: [],
      leaveId: null,
      projectId: null,
      proj_desc: null,
      proj_name: null,
      calc_type: null,
      unit_cost: null,
      total_cost: null,
      lead_name: null,
      duration: null,
      proj_file: null,
      project_start: null,
      project_end: null,
      projectstatus: null,
      file_name: null,
      filter: null,
      file: null,
      filedoc: null,
      holidays: [],
      holidays_arr: [],
      employeecode: null,
      upload_status: null,
      assign_review: null,
      selectedId: null,
      items_reviews: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],
      curr_day: '',

      c_form: {
        count: "",
      },
      c_form_result: {
        count: null,
      },
      month: "",
      result_arr: [],
      heading: [],
      modalShow: false,
      activity: [],
      loginData: JSON.parse(localStorage.getItem('loginDetails')),
      changeChild: 0,
      changeChild2: 0,
      curr_user_id: null,
    };
  },

  computed: {
    statusVariant() {
      const statusColor = {
        1: "light-warning",
        2: "light-warning",
        3: "light-success",
        4: "light-danger",
        5: "light-danger",
      };

      return (status) => statusColor[status];
    },
    statusVariant2() {
      const statusColor = {
        1: "light-info",
        2: "light-success",
        3: "light-warning"
      };

      return (status) => statusColor[status];
    },
    statusVariant3() {
      const statusColor = {
        1: "light-danger",
        2: "light-warning",
        3: "light-success",
      };

      return (status) => statusColor[status];
    },

    sortOptions() {
      // Create an options list from our fields
      return this.tablefields_leaves
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // alert(this.$route.params.view);
    if (this.$route.params.view == '1') {
      this.say('pills_project');
    } else if (this.$route.params.view == '2') {
      this.say('pills_eleaves');
    } else if (this.$route.params.view == '3') {
      this.say('pills_intimation');
    } else if (this.$route.params.view == '4') {
      this.say('pills_reports');
    } else if (this.$route.params.view == '5') {
      this.say('pills_created');
    } else if (this.$route.params.view == '6') {
      this.say('pills_contact');
    } else if (this.$route.params.view == '7') {
      this.say('pills_company');
    } else if (this.$route.params.view == '8') {
      this.say('pills_document');
    } else if (this.$route.params.view == '9') {
      this.say('pills_notification');
    }
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    if (currentMonth / 10 >= 1) {
      var make_month = currentMonth;
    } else {
      var make_month = "0" + currentMonth;
    }
    this.month = currentYear + "-" + make_month;
    this.getUserById();
    this.getLeaveCountByUser();
    this.getLeavesByUser();
    this.getOnsiteHistoryByUser();
    this.getDocumentsByUser();
    this.getAwsS3Config();
    this.getProjectsByUser();
    this.getHolidays();
    this.getAssignedTransactions();
    // this.generateReport();
  },
  methods: {
    formateTime(time) {
      var hours = Math.floor(time / 3600);
      var minutes = Math.floor((time - (hours * 3600)) / 60);
      var remainingSeconds = time - (hours * 3600) - (minutes * 60);

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (remainingSeconds < 10) {
        remainingSeconds = "0" + remainingSeconds;
      }

      return hours + ":" + minutes + ":" + remainingSeconds;
    },
    getImgUrl(path) { },
    async getAwsS3Config() {
      const response = await Service.getAwsS3Configs();
      if (response.code == "200") {
        this.s3 = response.data;
      }
    },
    customFormatter(date) {
      return moment(date).format('Y-MM-DD');
    },
    async getUserById() {
      const ReqData = {
        id: this.$route.params.id,
      };

      const response = await ServiceApi.GetUserById(ReqData);

      if (response.code == "200") {
        this.curr_user_id = response.data.id
        this.data = response.data;
        this.employeecode = this.data.emp_ref_no;
        if (response.data.gender == "M") {
          this.gender = "Male";
        } else if (response.data.gender == "F") {
          this.gender = "Female";
        } else if (response.data.gender == "O") {
          this.gender = "Other";
        }

        if (response.data.employee_status == "1") {
          this.employee_status = "Probation";
        } else if (response.data.employee_status == "2") {
          this.employee_status = "Permanent";
        } else if (response.data.employee_status == "3") {
          this.employee_status = "Contract";
        } else if (response.data.employee_status == "4") {
          this.employee_status = "Intern";
        } else if (response.data.employee_status == "5") {
          this.employee_status = "Freelance";
        } else if (response.data.employee_status == "5") {
          this.employee_status = "Notice Period";
        }

        if (response.data.calculation_type == "1") {
          this.wage_calculation_type = "Hourly";
        } else if (response.data.calculation_type == "2") {
          this.wage_calculation_type = "Daily";
        } else if (response.data.calculation_type == "3") {
          this.wage_calculation_type = "Weekly";
        } else if (response.data.calculation_type == "4") {
          this.wage_calculation_type = "Monthly";
        }

      }
    },
    async getHolidays() {
      const response = await ServiceApi.GetHolidays();
      if (response.code == '200') {

        this.items_holidays = response.data;

        var date_obj = {};
        var holiday_arr = [];

        response.data.forEach(element => {

          date_obj = {

            date: element.date,
          }
          holiday_arr.push(date_obj);

        });
        this.holidays = holiday_arr;

      }
    },


    async getDocumentsByUser() {
      const ReqData = {
        id: this.$route.params.id,
      };

      const response = await ServiceApi.GetDocumentsByUser(ReqData);

      if (response.code == "200") {
        this.items_docs = response.data;
        this.totalRows = response.data.length;
      }
      else {
        this.items_docs = [];
      }
    },
    getId(id) {
      this.leaveId = id;

    },
    onDocChange(e) {

      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      let file = files[0];
      const now = Date.now();
      const config = {
        bucketName: this.s3.bucketName,
        dirName: "leave_docs" /* optional */,
        region: this.s3.region,
        accessKeyId: this.s3.accessKeyId,
        secretAccessKey: this.s3.secretAccessKey,
        s3Url: "https://bktqaptive.s3.amazonaws.com" /* optional */,
      };

      const S3Client = new S3(config);
      S3Client.uploadFile(file, this.employeecode + now).then((response) => {
        this.filedoc = response.location;
      });

    },
    onDocSubmit() {

      //evt.preventDefault();

      const credentials = {
        id: this.leaveId,
        file: this.filedoc,
      };

      this.editLeaveDocument(credentials);

      this.$bvModal.hide("bv-modal-doc");
      this.getLeavesByUser();
    },

    async editLeaveDocument(credentials) {

      const response = await ServiceApi.UpdateLeaveDocument(credentials);
      if (response.code == "200") {
        this.$toasted.show(" Leave document updated successfully ", {
          theme: "outline",
          position: "top-right",
          type: "success",
          duration: 2000,
        });
        this.filedoc = null;
        this.employeecode = null;
        this.getLeavesByUser();
      }
      // else if (response.code == "403") {
      //   this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
      //   localStorage.removeItem('loginDetails')
      //   localStorage.removeItem('menuData')
      //   this.$router.push('/');
      // }
    },
    onFileChange(e) {
      if (this.document_name == null) {
        this.$toasted.show("Document name is mandatory field ", {
          theme: "outline",
          position: "top-right",
          type: "error",
          duration: 2000,
        });
        e.target.value = null;
        return;
      } else {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
          return;
        }

        let file = files[0];
        const now = Date.now();
        const config = {
          bucketName: this.s3.bucketName,
          dirName: "user_doc" /* optional */,
          region: this.s3.region,
          accessKeyId: this.s3.accessKeyId,
          secretAccessKey: this.s3.secretAccessKey,
          s3Url: "https://bktqaptive.s3.amazonaws.com" /* optional */,
        };

        const S3Client = new S3(config);
        S3Client.uploadFile(file, this.document_name + now).then((response) => {
          this.file = response.location;
        });
      }
    },

    async getLeaveCountByUser() {
      const ReqData = {
        id: this.$route.params.id,
      };

      const response = await ServiceApi.GetLeaveCountByUser(ReqData);

      if (response.code == "200") {
        this.items = response.data;

        this.totalRows = response.data.length;
      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },
    handleCountChange(value, leave_type_name, count, validation_status) {


      if (validation_status != 2) {
        // 1 : yes, 2 : no
        if (value <= count) {
          this.success = true;
        } else {
          this.$toasted.show(
            "Maximum leave count for " + leave_type_name + " is " + count,
            {
              theme: "outline",
              position: "top-right",
              type: "error",
              duration: 2000,
            }
          );
          this.success = false;
          this.$bvModal.show("bv-modal-example");
        }
      } else {
        this.success = true;
        this.$bvModal.show("bv-modal-example");
      }
    },
    onCustomStyleSubmit(evt) {
      evt.preventDefault();

      if (this.success == true) {
        this.saveLeaveCount();
        this.$bvModal.hide("bv-modal-example");
      }
      this.getLeaveCountByUser();
    },
    onDocumentSubmit(evt) {
      evt.preventDefault();

      if (this.document_name == null) {
        this.$toasted.show("Document name is mandatory field ", {
          theme: "outline",
          position: "top-right",
          type: "error",
          duration: 2000,
        });
        this.$bvModal.show("bv-modal-document");
      } else {
        const credentials = {
          user_id: this.$route.params.id,
          document_name: this.document_name,
          file: this.file,
        };

        this.AddDocument(credentials);
      }

      this.$bvModal.hide("bv-modal-document");
      this.getDocumentsByUser();
    },

    async AddDocument(credentials) {
      const response = await ServiceApi.AddDocument(credentials);
      if (response.code == "200") {
        this.$toasted.show(" Document uploaded successfully ", {
          theme: "outline",
          position: "top-right",
          type: "success",
          duration: 2000,
        });
        this.file = null;
        this.document_name = null;
        this.getDocumentsByUser();
      }
      else if (response.code == '400') {
        //message = response.
        this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }
    },

    async getProjectsByUser() {
      const ReqData = {
        id: this.$route.params.id,
      };

      const response = await ServiceApi.GetProjectsByUser(ReqData);

      if (response.code == "200") {
        this.items_projects = response.data;
        this.totalRows = response.data.length;
      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },

    viewProject(
      proj_name,
      proj_desc,
      calc_type,
      unit_cost,
      total_cost,
      lead_name,
      duration,
      proj_file,
      project_start,
      project_end,
      projectstatus
    ) {
      this.proj_name = proj_name;
      this.proj_desc = proj_desc;
      this.calc_type = calc_type;
      this.unit_cost = unit_cost;
      this.total_cost = total_cost;
      this.lead_name = lead_name;
      this.duration = duration;
      this.proj_file = proj_file;
      this.project_start = project_start;
      this.project_end = project_end;
      this.projectstatus = projectstatus;

      this.$bvModal.show("bv-modal-viewproject");
    },


    saveLeaveCount() {
      if (this.success == true) {
        const count_data = this.items;
        this.updateLeaveCount(count_data);
      }
    },

    async updateLeaveCount(count_data) {
      const response = await ServiceApi.UpdateUserLeave(count_data);
      if (response.code == "200") {
        this.$toasted.show(" Leave count updated successfully ", {
          theme: "outline",
          position: "top-right",
          type: "success",
          duration: 2000,
        });
        this.getLeaveCountByUser();
      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },
    async getLeavesByUser() {
      const ReqData = {
        id: this.$route.params.id,
      };

      const response = await ServiceApi.GetLeavesByUser(ReqData);

      if (response.code == "200") {
        this.items_leaves = response.data;

        this.totalRows = response.data.length;
      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },
    async getOnsiteHistoryByUser() {
      this.items_onsite_history = [];
      this.items_overtime_history = [];
      const ReqData = {
        id: this.$route.params.id,
      };

      const response = await ServiceApi.GetOnsiteHistoryByUser(ReqData);

      if (response.code == "200") {
        response.data.forEach(element => {
          if (element.type == 1) {
            this.items_onsite_history.push(element);
          } else if (element.type == 2) {
            this.items_overtime_history.push(element);
          }
        });
        // this.items_onsite_history = response.data;
        this.totalRows = this.items_onsite_history.length;
        this.totalRows2 = this.items_overtime_history.length;
      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },

    async getAssignedTransactions() {

      const ReqData = {
        assign_user_id: this.$route.params.id,
      };

      const response = await ServiceApi.GetAssignedTransactions(ReqData);
      if (response.code == "200") {
        this.items_reviews = response.data;

        this.totalRows = response.data.length;

      }

    },

    checkInterViewInToday(intDate) {
      const now = Date.now();
      const today = new Date(now);

      // Extracting the components of today's date
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, '0');
      const day = today.getDate().toString().padStart(2, '0');

      // Formatting the date as "yyyy-mm-dd"
      const formattedDate = `${year}-${month}-${day}`;
      if (intDate === formattedDate) {
        return true;
      } else {
        return false;
      }

    },

    async getTransactionsByAppliedVacancy(applied_vacancy_id) {
      const ReqData = {
        id: applied_vacancy_id,
      };
      const response = await ServiceApi.GetTransactionsByAppliedVacancy(ReqData);
      if (response.code == "200") {
        this.items_transaction = response.data.transaction_data;
        this.totalRows = response.data.length;

      }

    },

    editReview(id, applied_vacancy_id, resume_status) {
      this.selectedId = id;
      this.resume_status = resume_status;
      this.getTransactionsByAppliedVacancy(applied_vacancy_id);
      this.$bvModal.show('bv-modal-review');
    },
    viewResume(resume_id) {
      this.retrieveResumeDetails(resume_id);

    },

    async retrieveResumeDetails(resume_id) {
      const ReqData = {
        id: resume_id,
      };
      const response = await Service.GetResumesViewById(ReqData);
      if (response.code == "200") {
        this.name = response.data.name;
        this.email = response.data.email;
        this.phone = response.data.phone;
        this.alt_phone = response.data.alt_phone;
        this.gender = response.data.gender;
        this.marital_status = response.data.marital_status;
        this.image_name = response.data.image_name;
        this.current_address = response.data.current_address;
        this.current_state = response.data.current_state;
        this.current_country = response.data.current_country;
        this.current_pin = response.data.current_pin;
        this.permanent_address = response.data.permanent_address;
        this.permanent_state = response.data.permanent_state;
        this.permanent_country = response.data.permanent_country;
        this.permanent_pin = response.data.permanent_pin;
        this.file_name = response.data.file_name;
        this.current_salary = response.data.current_salary;
        this.expected_salary = response.data.expected_salary;
        this.notice_period_in_days = response.data.notice_period_in_days;
        this.notes = response.data.notes;
        this.academic_detail = response.data.academic_detail;
        this.work_exp_details = response.data.work_exp_details;
        this.applied_vacancies = response.data.applied_vacancies;
      }
    },
    onReviewSubmit(evt) {
      evt.preventDefault();

      if (this.assign_review == null) {
        this.$toasted.show("Please enter Review", {
          theme: "outline",
          position: "top-right",
          type: "error",
          duration: 2000,
        });
        this.$bvModal.show("bv-modal-review");
      } else {
        const credentials = {
          transaction_id: this.selectedId,
          assign_review: this.assign_review,
        };

        this.AddReview(credentials);
      }

      this.$bvModal.hide("bv-modal-review");
      this.getAssignedTransactions();
    },

    async AddReview(credentials) {
      const response = await ServiceApi.AddReview(credentials);
      if (response.code == "200") {
        this.$toasted.show(" review added successfully ", {
          theme: "outline",
          position: "top-right",
          type: "success",
          duration: 2000,
        });

        this.getAssignedTransactions();
      }
      else if (response.code == '400') {
        //message = response.
        this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    deleteLeave(data) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to cancel this Leave!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel!",
      }).then((result) => {
        if (result.isConfirmed) {

          //month array calc

          this.holidays.forEach(dates => {
            var day = moment(dates.date)
            this.holidays_arr.push(day.format('MM/DD/YYYY'));

          });

          var leave_start_date = data.start_date.split("T")[0];
          var leave_end_date = data.end_date.split("T")[0];

          var session1 = data.start_session;
          var session2 = data.end_session

          var date1 = new Date(this.customFormatter(leave_start_date));
          var date2 = new Date(this.customFormatter(leave_end_date));

          var getDaysBetweenDates = function (startDate, endDate) {
            var now = startDate.clone(), dates = [];

            while (now.isSameOrBefore(endDate)) {
              dates.push(now.format('MM/DD/YYYY'));
              now.add(1, 'days');
            }
            return dates;
          };

          var startDate = moment(date1);
          var endDate = moment(date2);

          var dateList = getDaysBetweenDates(startDate, endDate);

          var date_arr1 = []
          var date_arrr = []

          dateList.forEach(dates => {
            let date = new Date(dates);
            let day = date.toLocaleString('en-us', { weekday: 'long' });
            if ((day != 'Saturday') && (day != 'Sunday')) {
              date_arr1.push(dates);
            }

          });
          date_arrr = date_arr1.filter(val => !this.holidays_arr.includes(val));

          var result_obj = this.getDateDetails(dateList, leave_start_date, leave_end_date, session1, session2)
          var result_obj2 = this.getDateDetails(date_arrr, leave_start_date, leave_end_date, session1, session2)

          //month array end

          const credentials = {
            employee_id: data.employee_id,
            employee_leave_id: data.employee_leave_id,
            leave_type_id: data.leave_type_id,
            leave_type_key: data.leave_type_key,
            no_days: data.no_days,
            month_array: result_obj.month_arr,
            month_array_without: result_obj2.month_arr,
            start: leave_start_date,
            end: leave_end_date
          };


          this.cancelLeave(credentials);
        }
      });
    },
    async cancelLeave(credentials) {
      const response = await ServiceApi.CancelLeave(credentials);
      if (response.code == "200") {
        Swal.fire("Canceled!", "Leave has been canceled.", "success");
        this.getLeavesByUser();
        this.getLeaveCountByUser();
      }
      else if (response.code == '400') {
        //message = response.
        this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
      }
      else if (response.code == '201') {
        //message = response.
        this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 5000 });
      }
      else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },

    deleteDoc(data) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this Document!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          const credentials = {
            document_id: data.id,
            file_name: data.file_name,

          };
          this.deleteDocument(credentials);
        }
      });
    },
    async deleteDocument(credentials) {
      const response = await ServiceApi.DeleteDocument(credentials);
      if (response.code == "200") {
        Swal.fire("Deleted!", "Document has been deleted.", "success");
        this.file = null;
        this.document_name = null;
        this.getDocumentsByUser();

      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },
    getDateDetails(dateList, start_date, end_date, session1, session2) {

      var date1 = new Date(this.customFormatter(start_date));
      var date2 = new Date(this.customFormatter(end_date));
      var Difference_In_Time = date2.getTime() - date1.getTime();

      var month_arr = [];
      var month;
      var year;
      var x = 0;
      var result_arr = [];
      dateList.forEach(dates => {
        month = Number(dates.split('/')[0]);
        year = dates.split('/')[2]
        // month_arr.push(month);
        month_arr.push({ month_val: month, year_val: year });

        if (x != dates.split('/')[0]) {
          result_arr.push(dates.split('/')[0]);
          x = dates.split('/')[0];
        } else {

        }
      });

      var month_array = []

      month_arr.sort();

      var current = null;
      var cnt = 0;
      for (var i = 0; i < month_arr.length; i++) {
        if (month_arr[i].month_val != current) {

          if (cnt > 0) {
            month_array.push({ month: current, value: cnt, year: month_arr[0].year_val });
          }
          current = month_arr[i].month_val;
          cnt = 1;
        } else {
          cnt++;
        }
      }
      if (cnt > 0) {
        month_array.push({ month: current, value: cnt, year: month_arr[month_arr.length - 1].year_val });
      }

      var date_arr = []
      dateList.forEach(dates => {
        let date = new Date(dates);
        let day = date.toLocaleString('en-us', { weekday: 'long' });
        if ((day != 'Saturday') && (day != 'Sunday')) {
          date_arr.push(day);
        }

      });

      if (session1 && session2) {
        var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24));
        var date_arr_length = date_arr.length - 1;
        if (result_arr.length == 2) {
          month_array[0].value = month_array[0].value - .5;
          month_array[1].value = month_array[1].value - .5;
        } else if (result_arr.length == 1) {
          month_array[0].value = month_array[0].value - 1;
        }
      } else if (session1 || session2) {
        var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24)) + .5;
        var date_arr_length = date_arr.length - .5;
        if (session1) {
          month_array[0].value = month_array[0].value - .5;
        } else if (session2) {
          if (result_arr.length == 2) {
            month_array[1].value = month_array[1].value - .5;
          } else if (result_arr.length == 1) {
            month_array[0].value = month_array[0].value - .5;
          }
        }
      } else {
        var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24)) + 1;
        var date_arr_length = date_arr.length;
      }
      var obj = { 'date_arr_length': date_arr_length, 'diff_in_days': Difference_In_Days, 'month_arr': month_array };
      return obj;
    },

    deleteOnsite(data) {
      Swal.fire({
        title: "Are you sure?",
        // text: "Are you sure !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Sure!",
      }).then((result) => {
        if (result.isConfirmed) {

          const ReqData = {
            id: data.employee_onsite_id,
            status_to: 4,
            type: data.type,
            start: data.start_date,
            end: data.end_date
          };
          this.updateOnsite(ReqData);
        }
      });
    },
    async updateOnsite(ReqData) {
      const response = await ServiceApi.ChangeOnsiteStatus(ReqData);
      if (response.code == "200") {
        this.$toasted.show(' Status updated successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        this.getOnsiteHistoryByUser();
      }
      else if (response.code == '400') {
        this.loader = false;
        this.$toasted.show(response.message + ' ' + response.data, { theme: 'outline', position: 'top-right', type: 'error', duration: 3000 });
      }
      else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },

    async generateReport() {
      this.isLoading = false;

      const dateObj = new Date(this.month);
      const year = dateObj.getUTCFullYear();
      const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
      const formattedDate = `${year}-${month}`;
      this.month = formattedDate;

      const reqData = {
        month: this.month,
        users: [{
          id: this.data.id,
          name: this.data.name,
          join: this.data.joined_date.split('+')[0],
          relieved_date: this.data.relieved_date
        }],
      }
      const response = await Service.GetReports(reqData);
      if (response.code == "200") {
        this.result_arr = response.data.data
        this.isLoading = true;
      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
        this.isLoading = true;
      }
      this.isLoading = true;
    },
    getTimeFormate(time_sec) {
      var sec_num = time_sec
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours < 10) { hours = "0" + hours; }
      if (minutes < 10) { minutes = "0" + minutes; }
      if (seconds < 10) { seconds = "0" + seconds; }
      return hours + ':' + minutes + ':' + seconds;
    },
    dateConvert(dateString) {
      const date = new Date(dateString);

      const options = {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      };

      const formattedDate = date.toLocaleDateString('en-US', options);
      return formattedDate;

    },
    hideModal() {
      this.modalShow = false;
    },
    async viewDetails(day) {
      var id = this.loginData.id;
      this.curr_day = day;
      var req_data = {
        'user': id,
        'day': day
      }
      const response = await Service.GetActivity(req_data);
      if (response.code == "200") {

        this.activity = response.data;
      } else if (response.code == "403") {
        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
        localStorage.removeItem('loginDetails')
        localStorage.removeItem('menuData')
        this.$router.push('/');
      }
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "IN";
        case 2:
          return "OUT";
        case 3:
          return "BREAK";
        case 4:
          return "RESUME";
        case 5:
          return "SYSTEM END";
      }
    },
    say: function (message) {

      this.activeclass = message;
      // alert(this.activeclass);
    },
    changeComp() {
      this.changeChild = 0;
      this.changeChild2 = 0;
    }
  },
};
</script>
<style lang="scss">
@import "vue-select/dist/vue-select.css";

.icon-space {
  margin-top: 8px;
  margin-right: 7px;
}

.table thead th {
  border-bottom: 2px solid #c9c9c9 !important;
}

.table-bordered td,
.table-bordered th {
  border-color: #bbbbbb;
}

.mt25 {
  margin-top: 25px;
}

.user-img {
  margin-top: 0px;
  width: 50px;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px !important;
  border-radius: 15px !important;
  text-align: center !important;
  font-size: 12px !important;
  line-height: 1.42857 !important;
}

.load-style .loader-box {

  height: 38px;
  text-align: left !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: left !important;
  -ms-flex-align: left !important;
  align-items: left !important;
  vertical-align: left !important;
  -webkit-box-pack: left !important;
  -ms-flex-pack: left !important;
  justify-content: left !important;
  -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
  transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
  transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
  transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;

}
p {
    line-height: 1 !important;
}
</style>