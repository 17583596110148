<template>
    <div>
        <Breadcrumbs main="Applied Vacancies" title="Apply Job" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
          
            <div v-if="loader == true" class="loader-box">
            <div class="loader-15"></div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="card">
									<div class="card-header">
										<h4>Basic Details</h4>
									</div>

									<div class="card-body">

										<div class="row">

                                            <div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label">Select Job Vacancy <span style="color:red">*</span></label>
                                                    <v-select v-model="vacancy" label="title" :options="this.vacancies" />

                                                    <!-- <b-form-select v-model="vacancy_id" label="vacancy_id"
                                                    :options="this.vacancies">
                                                    </b-form-select> -->
												</div>
											</div>

											<div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label"> Name <span style="color:red">*</span></label>
													<input type="text" class="form-control pass-input" id="name"
														v-model="name" placeholder="Enter Name">
												</div>
											</div>
											


										</div>
										<div class="row">
											<div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label">Email <span style="color:red">*</span></label>
													<input type="text" class="form-control pass-input" id="name"
														v-model="email" placeholder="Enter Email">
												</div>
											</div>

                                            <div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label">Phone Number <span style="color:red">*</span></label>

                                                    <VuePhoneNumberInput v-model="phone" default-country-code="IN"
                                                    @update="onUpdatePhoneNumber" />

												</div>
											</div>

											

										</div>
										<div class="row">
											<div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label">Date Of Birth <span style="color:red">*</span></label>
                                                    <datepicker :format="customFormatter"
                                                    input-class="datepicker-here form-control digits" v-model="dob" :disabled-dates="isDateDisabled">
                                                    </datepicker>
												</div>
											</div>
                                            <div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label">Alternate Number</label>
                                                    <VuePhoneNumberInput v-model="alt_phone" default-country-code="IN"
                                                     @update="onUpdateAltPhoneNumber" />
												</div>
											</div>

											

										</div>
										<div class="row">
                                            <div class=" col-md-6">
												<div class="form-group">
													<label class="col-form-label">Gender <span style="color:red">*</span></label>
                                                    <b-form-select v-model="gender" label="gender" :options="genders">
                                                    </b-form-select>
												</div>
											</div>

											<div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label">Marital Status <span style="color:red">*</span></label>
                                                    <b-form-select v-model="marital_status" label="marital_status"
                                                    :options="marital_statuses">
                                                    </b-form-select>
												</div>
											</div>
										

										</div>

                                        <div class="row">
                                            
											<div class="col-md-6">
												<div class="form-group">
                                                    <label for="c_form_name">Photo Upload</label>
                                                    <input type="file" @change="onImageChange" />
                                                    <span> <label> Max file size : 10 MB </label></span>
                                                    <div id="preview">
                                                    <img v-if="preview_image" :src="preview_image" />
                                                    </div>
												
												</div>

											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label for="c_form_name">Resume Upload</label>
                                                    <input type="file" @change="onFileChange" />
                                                    <div>
                                                    <a v-if="file_name" :href="this.file_name" target="_blank" download>
                                                        <feather type="download"></feather>
                                                    </a>
                                                    <div v-if="previewFile == true">
													<p>Resume  uploaded</p>
													</div>
                                                    </div>
												</div>
											</div>
                                        </div>

									</div>
								</div>

								<div class="card">
									<div class="card-body">

										<div class="row">
											<div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label">Permanent City </label>
													<input type="text" class="form-control pass-input"
														id="permanent_city" v-model="permanent_city"
														placeholder="Enter Permanent City">
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">

													<label class="col-form-label">Current City </label>
													<input type="text" class="form-control pass-input" id="current_city"
														v-model="current_city" placeholder="Enter Current City">
												</div>
											</div>

										</div>

										<div class="row">
											<div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label">Permanent Address</label>
                                                    <b-form-textarea rows="1" v-model="permanent_address"></b-form-textarea>
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label">Current Address</label>
                                                    <b-form-textarea rows="1" v-model="current_address"></b-form-textarea>
												</div>
											</div>

										</div>
										<div class="row">
											<div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label">Permanent State </label>
													<input type="text" class="form-control pass-input" id="permanent_state"
														v-model="permanent_state" placeholder="Enter Permanent State">
												</div>
											</div>

											<div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label">Current State </label>
													<input type="text" class="form-control pass-input" id="current_state"
														v-model="current_state" placeholder="Enter State">
												</div>
											</div>

										</div>

										<div class="row">

											<div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label">Permanent Country </label>
													<input type="text" class="form-control pass-input"
														id="permanent_country" v-model="permanent_country"
														placeholder="Enter Permanent Country">
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label">Current Country </label>
													<input type="text" class="form-control pass-input" id="current_country"
														v-model="current_country" placeholder="Enter Current Country">
												</div>
											</div>



										</div>

										<div class="row">

											<div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label">Permanent PIN </label>
													<input type="text" class="form-control pass-input" id="permanent_pin"
														v-model="permanent_pin" placeholder="Enter Permanent Pin">
												</div>
											</div>



											<div class="col-md-6">
												<div class="form-group">
													<label class="col-form-label">Current PIN</label>
													<input type="text" class="form-control pass-input" id="current_pin"
														v-model="current_pin" placeholder="Enter Current Pin">
												</div>
											</div>


										</div>
										<div class="row">
											<div class="form-check">
												<label class="form-check-label" for="copyCheckbox">Copy Permanent Address to
													Current Address</label>
												<input id="checkbox1" type="checkbox" v-model="copyPermanent"
													style="min-height: 0px; margin-left: 10px;" />
											</div>


										</div>

									</div>
								</div>

								<div class="card">
									<div class="card-header">
										<h4>Academic Details</h4>
									</div>
									<div class="card-body">

										<div class="row" v-for="(item, index) in items" :key="index">
											<div class="col-md-3">
												<div class="form-group">
													<label class="col-form-label"> Institute <span style="color:red">*</span></label>
													<input type="text" class="form-control pass-input" id="name"
														v-model="item.institute" placeholder="Enter Institute">
												</div>
											</div>
											<div class="col-md-3">
												<div class="form-group">
													<label class="col-form-label">Course <span style="color:red">*</span></label>
													<input type="text" class="form-control pass-input" id="name"
														v-model="item.course" placeholder="Enter Course">
												</div>
											</div>
											<div class="col-md-3">
												<div class="form-group">
													<label class="col-form-label">Joining Year <span style="color:red">*</span></label>

                                                    <b-form-select v-model="item.joining_year" :options="yearOptions"
                                                     ></b-form-select>
												</div>
											</div>
											<div class="col-md-3">
												<div class="form-group">
													<label class="col-form-label">Pass out Year <span style="color:red">*</span></label>
                                                    <b-form-select v-model="item.pass_out_year" :options="yearOptions"
                                                    ></b-form-select>
												</div>
											</div>

										</div>
									</div>
									<div class="row">
										<div class="col-md-12">
											<p class="text-center">
												<button @click="addItem" class="addbtn  mt-2">+</button>
												<button @click="removeItem" class="addbtn mt-2">-</button>
											</p>
										</div>

									</div>

								</div>

								<div class="card">
									<div class="card-header">
										<h4>Work Experience Details</h4>
									</div>
									<div class="card-body">

										<div class="row" v-for="(item, index) in items_work" :key="index">
											<div class="col-md-3">
												<div class="form-group">
													<label class="col-form-label"> Organization <span style="color:red">*</span></label>
													<input type="text" class="form-control pass-input" id="name"
														v-model="item.organization" placeholder="Enter Organization">
												</div>
											</div>
											<div class="col-md-3">
												<div class="form-group">
													<label class="col-form-label">Designation <span style="color:red">*</span></label>
													<input type="text" class="form-control pass-input" id="name"
														v-model="item.designation" placeholder="Enter Designation">
												</div>
											</div>
											<div class="col-md-3">
												<div class="form-group">
													<label class="col-form-label">From Date <span style="color:red">*</span></label>
                                                    <datepicker :format="customFormatter" 
                                                    input-class="datepicker-here form-control digits" v-model="item.start_date" :disabled-dates="isDateDisable">
                                                    </datepicker>
												</div>
											</div>
											<div class="col-md-3">
												<div class="form-group">
													<label class="col-form-label">To Date <span style="color:red">*</span></label>
                                                    <datepicker :format="customFormatter"
                                                    input-class="datepicker-here form-control digits" v-model="item.end_date" :disabled-dates="isDateDisable">
                                                    </datepicker>
												</div>
											</div>

										</div>
									</div>

									<div class="row">
										<div class="col-md-12">
											<p class="text-center">
												<button @click="addItemWork" class="addbtn  mt-2">+</button>
												<button @click="removeItemWork" class="addbtn mt-2">-</button>
											</p>
										</div>

									</div>

								</div>

								<div class="card">
									<div class="card-body">

										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label class="col-form-label">Current Salary <span style="color:red"></span></label>
													<input type="text" class="form-control pass-input" id="current_salary"
														v-model="current_salary" placeholder="Enter Current Salary">
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="col-form-label">Expected Salary <span style="color:red"></span></label>
													<input type="text" class="form-control pass-input" id="expected_salary"
														v-model="expected_salary" placeholder="Enter Expected Salary">
												</div>
											</div>

											<div class="col-md-4">
												<div class="form-group">
													<label class="col-form-label">Notice Period In Days
														<span style="color:red"></span></label>
													<input type="number" class="form-control pass-input"
														id="notice_period_in_days" v-model="notice_period_in_days"
														placeholder="Enter Notice Period In Days">
												</div>
											</div>

										</div>
										<div class="row">

											<div class="col-md-4">
												<div class="form-group">
													<label class="col-form-label">Is Negotiable <span style="color:red"></span></label>
                                                    <v-select v-model="is_negotiable" label="text" :options="types" />
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="col-form-label">Are you willing to Relocate? <span style="color:red"></span></label>
                                                    <v-select v-model="re_location" label="text" :options="types" />
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="col-form-label">Notes <span style="color:red"></span></label>
													<textarea class="form-control pass-input" rows="2" v-model="notes"
														placeholder="Enter notes"></textarea>

												</div>
											</div>

										</div>

									</div>
								</div>

                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from '@/services/ServiceApi.js';
import vSelect from "vue-select";
import Swal from "sweetalert2";
import S3 from "aws-s3";
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';
import VuePhoneNumberInput from 'vue-phone-number-input';

export default {

    components: {
        VuePhoneNumberInput,
        vSelect,
        Datepicker
    },

    data() {
        return {
            success: false,
            items: [
				{
					institute: '',
					course: '',
					joining_year: '',
					pass_out_year: '',
				},
			],
			items_work: [
				{
					organization: '',
					designation: '',
					start_date: '',
					end_date: '',
				},
			],
			//items_work: [],
			title: null,
			department: null,
			job_type_name: null,
			name: '',
			email: '',
			phone: null,
			dob: null,
			alt_phone: null,
			gender: '',
			marital_status: '',
			image_name: '',
			is_negotiable: null,
			current_address: '',
			current_state: '',
			current_country: '',
			current_pin: '',
			current_city: '',
			permanent_city: '',
			permanent_address: '',
			permanent_state: '',
			permanent_country: '',
			permanent_pin: '',
			file_name: '',
			current_salary: '',
			expected_salary: '',
			notice_period_in_days: '',
			notes: '',
			image_link: null,
			is_negotiable_name: '',
			re_location:null,
			file: null,
			file_image: null,
			image: null,
			photo: null,
			institute: '',
			course: '',
			joining_year: '',
			pass_out_year: '',
			loadimage: null,
            preview_image:null,
			copyPermanent: null,
			previewImage: null,
			previewFile:false,
			yearOptions: [],
			// flatpickrConfig2: {
			// 	maxDate: 'today', // Set maximum date to today
			// },
			types: [

				{ value: "1", text: "YES" },
				{ value: "2", text: "NO" },
			],
			genders: [
				{ value: 'M', text: 'Male' },
				{ value: 'F', text: 'Female' },
				{ value: 'O', text: 'Other' },
			],
			marital_statuses: [
				{ value: 'S', text: 'Single' },
				{ value: 'M', text: 'Married' },
				{ value: 'W', text: 'Widowed' },
				{ value: 'D', text: 'Divorced' },
				{ value: 'O', text: 'Other' },
			],
			submitted: false,
			isLoading: false,
			loader: false,
			showLoadMore: true,
            vacancies:[],
            vacancy_id:null,
            vacancy:null,
             maxDate: new Date(new Date().setFullYear(new Date().getFullYear() - 15)).toISOString().split('T')[0],

        };
    },
    computed: {
        isDateDisabled(date) {
            const currentDate = new Date();
            const maxDate = new Date(
            currentDate.getFullYear() - 15,
            currentDate.getMonth(),
            currentDate.getDate()
            );
            return {
            from: maxDate, // disable all dates before the maxDate
            };
        },
        isDateDisable(date) {
            return {
                from: new Date(), // disable all dates after today
            };
        },

    },
    watch: {
		copyPermanent(value) {
			if (value) {
				this.current_address = this.permanent_address;
				this.current_state = this.permanent_state;
				this.current_country = this.permanent_country;
				this.current_pin = this.permanent_pin;
				this.current_city = this.permanent_city;


			} else {
				this.current_address = '';
				this.current_state = '';
				this.current_country = '';
				this.current_pin = '';
				this.current_city = '';
			}
		}
	},
	mounted() {
        this.getAwsS3Config();
        const currentYear = new Date().getFullYear();
        for (let year = currentYear; year >= 1900; year--) {
        this.yearOptions.push({ value: year, text: year.toString() });
        }
        this.getVacancyDetails()

	},
    methods: {
       
        
        async getVacancyDetails() {

            const response = await ServiceApi.GetJobVacancies();
            if (response.code == '200') {
                this.vacancies = response.data;

            }
           
            else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }

        },
		addItem() {
			this.items.push({
				institute: '',
				course: '',
				joining_year: '',
				pass_out_year: '',
			});
		},

		removeItem() {
			this.items.pop({
				institute: '',
				course: '',
				joining_year: '',
				pass_out_year: '',
			});
		},
		addItemWork() {
			this.items_work.push({
				organization: '',
				designation: '',
				start_date: '',
				end_date: '',
			});
		},

		removeItemWork() {
			this.items_work.pop({
				organization: '',
				designation: '',
				start_date: '',
				end_date: '',
			});
		},


		dateConvert(dateString) {
			const date = new Date(dateString);

			const options = {
				day: 'numeric',
				month: 'short',
				year: 'numeric'
			};

			const formattedDate = date.toLocaleDateString('en-US', options);
			return formattedDate;

		},

        onUpdatePhoneNumber(payload) {
        this.PhoneNumberValue = payload.formattedNumber;
        },
        onUpdateAltPhoneNumber(payload) {
        this.AltNumberValue = payload.formattedNumber;
        },

        customFormatter(date) {
        return moment(date).format('Y-MM-DD');
        },
        async getAwsS3Config() {
        const response = await Service.getAwsS3Configs();
        if (response.code == "200") {
            this.s3 = response.data;
        }
        },

        onFileChange(e) {

        var files = e.target.files || e.dataTransfer.files;

        if (!files.length) {
        return;
        }
        const now = Date.now();

        let file = files[0];
        if (file.type != 'application/pdf') {
        this.$toasted.show(' Please upload file with pdf format', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

        }
        else {
        this.file_name = URL.createObjectURL(file);
        const config = {
            bucketName: this.s3.bucketName,
            dirName: "resume_docs" /* optional */,
            region: this.s3.region,
            accessKeyId: this.s3.accessKeyId,
            secretAccessKey: this.s3.secretAccessKey,
            s3Url: "https://bktqaptive.s3.amazonaws.com" /* optional */,
        };

        const S3Client = new S3(config);

        S3Client.uploadFile(file, now).then((response) => {
            this.file = response.location;
        });

        }

        },

        onImageChange(e) {

        var files = e.target.files || e.dataTransfer.files;

        if (!files.length) {
        return;
        }
        const now = Date.now();

        let file = files[0];

        if (!file.type.startsWith('image/')) {
        this.$toasted.show('Please upload an image file', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
        return;
        }
        const fileSize = file.size;
        const maxSizeInBytes = 10 * 1024 * 1024; // Example: maximum file size of 10MB
        if (fileSize > maxSizeInBytes) {
            this.$toasted.show('Image  size exceeds the allowed limit', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            return;
        }

        this.preview_image = URL.createObjectURL(file);
        const config = {
        bucketName: this.s3.bucketName,
        dirName: "resume_photos" /* optional */,
        region: this.s3.region,
        accessKeyId: this.s3.accessKeyId,
        secretAccessKey: this.s3.secretAccessKey,
        s3Url: "https://bktqaptive.s3.amazonaws.com" /* optional */,
        };

        const S3Client = new S3(config);

        S3Client.uploadFile(file, now).then((response) => {
        this.file_image = response.location;
        });

        },

		onCustomStyleSubmit(evt) {
			evt.preventDefault();

			const filteredArray = this.items.filter(item => {
			return Object.values(item).some(value => value !== '');
			});

			const hasValues = filteredArray.length > 0;

			const filteredArrayWork = this.items_work.filter(item => {
			return Object.values(item).some(value => value !== '');
			});

			const hasValuesWork = filteredArrayWork.length > 0;


			if (this.name == '' || this.name == null) {
				this.success = false;
                this.$toasted.show(' Please enter name', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
			}
			else if (this.email == '' || this.email == null) {
				this.success = false;
                this.$toasted.show(' Please enter email', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

			}
            else if (this.phone == '') {
                this.success = false;
                this.$toasted.show(' Please enter phone', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
			else if (this.dob == '' || this.dob == null) {
				this.success = false;
                this.$toasted.show(' Please select  date of birth', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });


			}
			else if (this.gender == '' || this.gender == null) {
				this.success = false;
                this.$toasted.show(' Please select gender', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });


			}
			else if (this.marital_status == '' || this.marital_status == null) {
				this.success = false;
                this.$toasted.show(' Please select marital status', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });


			}
			
			// else if (this.file == '' || this.file == null) {
			// 	this.success = false;
            //     this.$toasted.show(' Please upload your resume', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

			// }
			
			else if (!hasValues) {
				this.success = false;
                this.$toasted.show('Please Enter Academic details', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
			}

			else if (!hasValuesWork) {

				this.success = false;
                this.$toasted.show('Please Enter Work Experience details', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

			}
			
			// else if (this.re_location == '' || this.re_location == null) {
			// 	this.success = false;
			// 	createToast('Please Select Re location status', { type: 'danger' })

			// }

			else if (this.items_work.length > 0) {

			for (const item of this.items_work) {
              
				if (!item.start_date || !item.end_date) {
					this.success = false;
                    this.$toasted.show('Please Select Start and End Date for Work Experience', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
				}
				else if (item.start_date > item.end_date) {
					this.success = false;
                    this.$toasted.show('End date should be greater than Start Date', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

				}
				else{
					this.success = true;
				}
			}

			}
			else if (this.email != '') {
				const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				//const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

				if (!emailRegex.test(this.email)) {
					this.success = false;
                    this.$toasted.show('Please Enter Valid Email', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
				}
				else {
					this.success = true;

				}

			}

			else {
				this.success = true;
			}

			this.saveResumeDetails();
		},
		saveResumeDetails() {

			if (this.items.length > 0) {

				this.academicSuccess = true; 

				for (const item of this.items) {
				if (item.joining_year > item.pass_out_year) {
					this.academicSuccess = false;
                    this.$toasted.show('Pass out year should be greater than Joining Year', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });

				break; 
				}
				}

			}
			if (this.success == true && this.academicSuccess == true) {

				this.isLoading = true;
                this.loader = true;

                var dob = null;

                if (this.dob != null) {
                dob = this.customFormatter(this.dob);
                }

                if (this.file != null) {

                var file_name = this.file;

                }
                else {
                var file_name = "";
                }

                if (this.file_image != null) {

                var image_name = this.file_image;

                }
                else {
                var image_name = "";
                }


                if (this.phone == undefined || this.phone == "" || this.phone == null) {
                var phone = "";
                }
                else {
                var phone = this.phone;
                }

                if (this.alt_phone == undefined || this.alt_phone == "" || this.alt_phone == null) {
                var alt_phone = "";
                }
                else {
                var alt_phone = this.alt_phone;
                }


				const resume_data = {
					email: this.email,
					name: this.name,
					phone: phone,
					alt_phone: alt_phone,
					gender: this.gender,
					marital_status: this.marital_status,
                    image_name: image_name,
					file_name: file_name,
					notes: this.notes,
					current_city: this.current_city,
					permanent_city: this.permanent_city,
					current_address: this.current_address,
					current_pin: this.current_pin,
					current_state: this.current_state,
					current_country: this.current_country,
					permanent_address: this.permanent_address,
					permanent_pin: this.permanent_pin,
					permanent_state: this.permanent_state,
					permanent_country: this.permanent_country,
					expected_salary: this.expected_salary,
					current_salary: this.current_salary,
					notice_period_in_days: this.notice_period_in_days,
					is_negotiable: this.is_negotiable,
					re_location: this.re_location,
					dob: dob,
					work_experience: this.items_work,
					academics: this.items,
					applied_vacancy_id: this.vacancy.vacancy_id,
				}

				this.addResume(resume_data)
			}
		},
		async addResume(resume_data) {
			const response = await ServiceApi.AddResume(resume_data);
			if (response.code == '200') {

				this.isLoading = false;

                this.loader = false;

                this.$toasted.show('Thank you for applying', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/applied_vacancies');

			}
			
			else if (response.code == '400') {
				this.isLoading = false;
                this.loader = false;

                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });


			} else if (response.code == "403") {
				this.isLoading = false;
                this.loader = false;

				this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
			}
		},



	},
};
</script>

<style>
.loader-box {
	height: 50px;
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	vertical-align: middle;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
	transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
	transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
	transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;
}

.vaciencies-section.vaciencies-eight {
	background: rgba(193, 0, 55, 0.03);
	padding: 141px 0 !important;
	position: relative;
}

.addbtn {
	background: #605555;
	color: #fff;
	border: none;
	padding: 4px 11px;
	font-size: 25px;
	border-radius: 6px;
	margin-right: 7px;
}

.addbtn:hover {

	background: #000;
}

.sub-btn {
	background: #c10037 !important;
	color: #fff !important;
	padding: 10px 40px !important;
	border-radius: 12px !important;
	border: none;
}

.sub-btn:hover {
	background: #000 !important;
}

.check-align {
	display: flex !important;
	align-items: baseline !important;
}

.iti {
	width: 100% !important;
}

.form-select {
	line-height: 2.5 !important;
}

.settings-upload-btn {
	position: relative;
	display: inline-block;
	margin: 35px 0 0 20px !important;
	/* background: #605555;
	color: #fff; */
}
</style>
