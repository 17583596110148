<template>
    <div><br>
        <Breadcrumbs main="Report" title="Leave report" />
        <!-- {{ id }} -->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <b-col xl="3">
                                    <datepicker :minimumView="'month'" input-class="datepicker-here form-control digits"
                                        v-model="date"></datepicker>
                                </b-col>
                                <b-col xl="3">
                                    <v-select v-model="to_users" label="name" placeholder="Users" :options="users"
                                        :multiple="true" />
                                </b-col>
                                <b-col xl="3" class="load-style">
                                    <b-button id="default-primary" v-if="!isLoading" variant="primary" @click="Generate()">
                                        View
                                    </b-button>
                                    <div class="loader-box " v-else>
                                        <div class="loader-15"></div>
                                    </div>
                                </b-col>
                                <b-col xl="3" v-if="downFlag">

                                    <b-button id="default-primary" variant="primary" @click="downloadExcel"
                                        class="pull-right">
                                        Download
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>

                    </div>

                    <!-- <div class="card">
                        <div class="card-body">


                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                </b-table>
                            </div>
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    class="my-0"></b-pagination>
                            </b-col>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive mt25 table-container" id="datatable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>User</th>
                                            <th>Leave Type</th>
                                            <th>Count</th>
                                            <th>Remaining</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in items" :key="index">
                                            <td>{{ item.user }}</td>
                                            <td v-for="(leave, i) in item.leave" :key="i">{{ leave.leave_type }}</td>
                                            <td v-for="(leave, i) in item.leave" :key="i">{{ leave.count }}</td>
                                            <td v-for="(leave, i) in item.leave" :key="i">{{ getRemaining(index, i) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> -->
                </div>

            </div>
        </div>


    </div>
</template>
  
<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'
import Service from '@/services/Service.js';
import vSelect from 'vue-select'

export default {
    name: 'app',
    components: {
        Datepicker,
        vSelect
    },
    data() {
        return {
            date: moment('01-' + moment().format('MMM-Y')).format('Y-MM-DD'),
            tablefields: [
                { key: 'user', label: 'Employee', sortable: true, },
                { key: 'leave_type', label: 'Leave type', sortable: true, },
                { key: 'no_leaves', label: 'Number of leaves', sortable: true, },
                { key: 'balance', label: 'Balance', sortable: true, },
                { key: 'remaining', label: 'Remaining Leaves', sortable: true, },
            ],

            items: [],
            filter: null,
            downFlag: false,
            to_users: null,
            users: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            isLoading: false,
        };
    },
    mounted() {
        this.retrieveEmployees();
        var users;

        // if (this.to_users == null) {
        //     users = [];
        // } else {
        //     users = this.to_users;
        // }
        // var req = {

        //     users_id: users,
        //     date: moment(this.date).format('Y-MM-DD')

        // }
        // this.getLeaveReport(req)
    },
    methods: {
        downloadExcel() {
            console.log(this.items);
            // window.location.href = this.items;
            window.open(this.items, '_blank');
        },
        getRemaining(userIndex, leaveIndex) {
            let count = this.items[userIndex].leave[leaveIndex].count;
            let remaining = this.items[userIndex].leave[leaveIndex].remaining;
            return parseFloat(remaining) + parseFloat(count);
        },
        async retrieveEmployees() {
            const response = await Service.GetEmployeesForReport();
            if (response.code == "200") {
                this.users = response.data

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        Generate() {
            this.isLoading = true;
            var users;

            if (this.to_users == null) {
                users = [];
            } else {
                users = this.to_users;
            }
            var req = {

                users_id: users,
                date: moment(this.date).format('Y-MM-DD')

            }
            this.getLeaveReport(req)
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getLeaveReport(req) {
            this.downFlag = false;
            const response = await Service.GetLeaveReport(req);
            if (response.code == '200') {
                this.isLoading = false;
                this.items = response.data;
                this.downFlag = true;
                this.totalRows = response.data.length;
            } else if (response.code == "403") {
                this.isLoading = false;
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
            this.isLoading = false;
        },
    }

}
</script>
  
<style lang="scss" scoped>
table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    text-align: left;
    padding: 8px;
}

th {
    background-color: #ddd;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

.load-style .loader-box  {

height: 38px;
text-align: left !important;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: left !important;
-ms-flex-align: left !important;
align-items: left !important;
vertical-align: left !important;
-webkit-box-pack: left !important;
-ms-flex-pack: left !important;
justify-content: left !important;
-webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;

}
</style>
  