<template>
    <div><br>
        <Breadcrumbs main="Reports" title="Interview Report" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <b-col xl="3">

                                    <datepicker :minimumView="'day'" input-class="datepicker-here form-control digits"
                                        :format="customFormatter" v-model="month"></datepicker>
                                </b-col>

                                <b-col xl="3" class="load-style">
                                    <b-button id="default-primary" v-if="!isLoading" variant="primary"
                                        @click="GenerateByDate()">
                                        View
                                    </b-button>
                                    <div class="loader-box " v-else>
                                        <div class="loader-15"></div>
                                    </div>
                                </b-col>

                            </b-row><br><br>
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="result_arr" :fields="tablefields" :filter="filter"
                                    :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">

                                </b-table>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>
</template>

<script>
import Service from '@/services/Service.js';
import Swal from "sweetalert2";
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'

export default {
    components: {
        vSelect,
        Datepicker
    },
    data() {
        return {
            month: null,
            result_arr: [],
            heading: [],
            // users: [],
            activity: [],
            to_users: null,
            modalShow: false,
            curr_day: '',
            isLoading: false,
            link: null,
            isLoading1: false,
            filter: null,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            tablefields: [
                { key: 'applicant', label: 'Applicant Name', sortable: true, },
                { key: 'appliEmail', label: 'Email', sortable: true, },
                { key: 'appliPhone', label: 'Phone', sortable: true, },
                { key: 'vacancy', label: 'Applied position', sortable: true, },
                { key: 'status', label: 'Status', sortable: true, },
                { key: 'assign_user', label: 'Assigned Employee', sortable: true, },
            ],
        };
    },
    computed: {

    },
    mounted() {
        const currentDate = new Date();

        // Get the individual date components
        const day = String(currentDate.getDate()).padStart(2, '0'); // Day (with leading zero if necessary)
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month (with leading zero if necessary)
        const year = currentDate.getFullYear(); // Full year
        this.month = `${year}-${month}-${day}`;

        this.isLoading = true;
        this.generateReport(this.month);
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        customFormatter(date) {
            return moment(date).format('Y-MM-DD');
        },
        async GenerateByDate() {
            this.isLoading = true;
            // Get the individual date components

            if (typeof (this.month) != 'string') {
                const day = String(this.month.getDate()).padStart(2, '0'); // Day (with leading zero if necessary)
                const month = String(this.month.getMonth() + 1).padStart(2, '0'); // Month (with leading zero if necessary)
                const year = this.month.getFullYear(); // Full year

                // Combine the date components into the desired format
                this.month = `${year}-${month}-${day}`;
            }
            const reqData = {
                month: this.month,
            }
            const response = await Service.GetInterviewReportByDate(reqData);
            if (response.code == "200") {
                this.isLoading = false;
                this.result_arr = response.data;
            } else if (response.code == "403") {
                this.isLoading = false;
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
            this.isLoading = false;
            // this.generateReport(this.month);
        },
        async generateReport(date) {

            const reqData = {
                month: date,
            }
            const response = await Service.GetInterviewReports(reqData);
            if (response.code == "200") {
                this.isLoading = false;
                this.result_arr = response.data;
            } else if (response.code == "403") {
                this.isLoading = false;
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
            this.isLoading = false;
        },

    }
};
</script>
<style>
.table thead th {
    border: 1px solid #c9c9c9 !important;
}

/* 
.table-bordered td,
.table-bordered th {
    border-color: #c9c9c9 !important;
   
} */

.table-bordered td,
.table-bordered th {
    border-color: #c9c9c9 !important;
    width: 640px !important;
}

.table-wd {
    border-color: #c9c9c9 !important;
    width: 640px !important;
}

.mt25 {
    margin-top: 25px;
}

.padz {
    padding: 2px 15px;
}

th.pinned,
td.pinned {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 1;
}

.table-container {
    height: 450px;
    overflow: auto;
}

.load-style .loader-box {

    height: 38px;
    text-align: left !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: left !important;
    -ms-flex-align: left !important;
    align-items: left !important;
    vertical-align: left !important;
    -webkit-box-pack: left !important;
    -ms-flex-pack: left !important;
    justify-content: left !important;
    -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;

}

.load-style1 .box12 {

    float: right;
    margin-right: 68px;
    height: 38px;
    text-align: left !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: left !important;
    -ms-flex-align: left !important;
    align-items: left !important;
    vertical-align: left !important;
    -webkit-box-pack: left !important;
    -ms-flex-pack: left !important;
    justify-content: left !important;
    -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;

}

.leave-col {
    background-color: antiquewhite;
    width: 640px !important;
    text-align: center;
}
</style>