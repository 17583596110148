<template>
    <div>
        <Breadcrumbs main="Designations" title="Add Designation" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <label for="name">Designation</label>
                                        <b-form-input type="text" id="designation" v-model="c_form.name"
                                            :state="c_form_result.name" placeholder="Designation"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.name">
                                            </b-form-valid-feedback>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="c_form_departments">Department</label>
                                        <v-select
                                            v-model="c_form.department"
                                            label="name"
                                            :options="departments" 
                                        />
                                       
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="c_form_departments">Level</label>
                                        <v-select
                                            v-model="c_form.level"
                                            label="name"
                                            :options="LevelArray" 
                                        />
                                       
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-12 mb-3">
                                        <label for="Permissions"><b>Permissions</b></label><br/>
                                        <ul>
                                            <li v-for="(item, index) in menuData">
                                                <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated"  :value="item.key" v-model="selectedMenus" :id="item.name" type="checkbox"> <b>{{item.name}}</b>
                                                </label>
                                                <ul :style="'padding-left:14px'">
                                                    <li v-for="(child, index_child) in item.children">
                                                        <label class="d-block" for="chk-ani">
                                                            <input class="checkbox_animated"  :value="child.key" v-model="selectedMenus" :id="child.name" type="checkbox"> {{child.name}}
                                                        </label>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_company">Company</label>
                                        <v-select
                                            v-model="c_form.company"
                                            label="name"
                                            :options="companies"                       
                                        />
                                       
                                    </div>
                                </div> -->
                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from '@/services/ServiceApi.js';
import vSelect from 'vue-select'

export default {

    components: {
      vSelect,
    },

    data() {
        return {
            success: false,
            email: '',
            // companies: [],
            departments: [],
            c_form: {
                name: '',
                department: '',
                level: '',
            },
            c_form_result: {
                name: null,
            },
            // LevelArray:[{id:'HR',name:"HR"},{id:'MR',name:"MANAGER"},{id:'EM',name:"EMPLOYEE"},{id:'MANAGEMENT',name:"MANAGEMENT"}]
            LevelArray:[],
            menuData: [],
            selectedMenus:[]

        };
    },
    computed: {

    },
    mounted() {
        // this.getCompanies();
        this.getDepartments();
        this.GetDesignationLevel();
        this.getMenuWithModules()
    },
    methods: {
        onCustomStyleSubmit(evt) {
            evt.preventDefault();
            // if (this.c_form.name !== '') {
            //     this.c_form_result.name = true;
            //     this.success = true;
            // } else {
            //     this.$toasted.show(' Please select Department ', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });

            //     this.success = false;
            //     this.c_form_result.name = false;
            // }
            if (this.c_form.name == '') {
                this.success = false;
                this.$toasted.show(' Please enter Designation ', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
            }

            else if (this.c_form.department == '') {
                this.success = false;
                this.$toasted.show(' Please select Department ', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.c_form.level == '') {
                this.success = false;
                this.$toasted.show(' Please select Level ', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
            } 
           
            else {
                this.success = true;
            }

            this.saveDesignation();
        },
        saveDesignation() {
            if (this.success == true) {
                const design_data = {
                    name: this.c_form.name,
                    department: this.c_form.department.department_id,
                    level: this.c_form.level.id,
                    access_permissions:this.selectedMenus
                } 
                this.addDesignation(design_data)
            }
        },
        async addDesignation(design_data) {
            const response = await Service.AddDesignation(design_data);
            if (response.code == '200') {
                this.$toasted.show(' Designation added successfully ', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/designations');
            }
            else if (response.code == '400') {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async GetDesignationLevel() {
            const response = await Service.GetDesignationLevels();
            if (response.code =='200') {
                this.LevelArray =  response.data;
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getDepartments() {
            const response = await ServiceApi.GetDepartments();
            if (response.code =='200') {
                this.departments =  response.data;
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getMenuWithModules() {
            const response = await Service.getMenuWithModules();
            if (response.code == '200') {
                var menus = response.data;
                this.menuData = menus.filter(module => module.key !== "COMPANIES" && module.key !== "CONFIGURATIONS");

            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        }
    }
};
</script>
