<template>
    <!-- <v-app> -->
    <div>
        <Breadcrumbs main="Daily Attendance" title="Daily Attendance" />


        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <b-col xl="3">

                                    <!-- <b-form-input type="month" value="2015-01" v-model="month"></b-form-input> -->
                                    <datepicker :format="customFormatter" input-class="datepicker-here form-control digits"
                                        v-model="date" :disabled-dates="state.disabledDates"></datepicker>
                                    <div v-if="pinvaliddate" class="feedbacksd">
                                        date is required
                                    </div>
                                </b-col>
                                <b-col xl="3">
                                    <v-select v-model="to_users" label="name" placeholder="Users" :options="users"
                                        :multiple="false" />
                                    <div v-if="pinvaliduser" class="feedbacksd">
                                        user is required
                                    </div>

                                </b-col>
                                <b-col xl="3" class="load-style">
                                    <b-button id="default-primary" variant="primary" @click="Generate()">
                                        View
                                    </b-button>

                                </b-col>
                            </b-row>
                        </div>

                    </div>

                </div>

                <div class="col-md-12" v-if="responseData">
                    <div class="card">
                        <div class="card-body">

                            <div class="table-responsive datatable-vue">

                                <table style="width:100%; border: 0;">
                                    <tr>
                                        <th>Date</th>
                                        <th style="width:20%;">Name</th>
                                        <th  style="width:30%;">Employee Referance No</th>
                                        <th>Designation</th>
                                    </tr>
                                    <br>
                                    <tr>
                                        <td>{{selectedDate}}</td>

                                        <td>{{responseData.user.name}}</td>
                                        <td>{{responseData.user.emp_ref_no}}</td>
                                        <td>{{responseData.user.designation.name}}</td>
                                    </tr>
                                    </table>

                                </div>



                        </div>
                    </div>
                </div>
                <div class="col-md-12" v-if="responseData">
                    <div class="card">
                        <div class="card-body">

                            <div class="table-responsive datatable-vue">
                               

<b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter"
    :current-page="currentPage" :per-page="perPage">


{{ rows }}
 
    <template #cell(punching_in_time)="rows">
                                        <span>
                                          
                                         <span v-if="items.length - 1 == rows.index && rows.item.punching_in_time" >( Login )</span>
                                       
                                         
                                         
                                         
                                         {{ rows.item.punching_in_time | moment("H:m:s") }}
                                            
                                        </span>
                                    </template>

                                    <template #cell(punching_out_time)="rows">
                                        <span>
                                            {{ rows.item.punching_out_time | moment("H:m:s") }} 
                                           
                                        </span>
                                        <span v-if="rows.index == 0 && rows.item.punching_out_time" >( Logout )</span>
                                    </template>


                                    <template #cell(actions)="row">

                                            <span v-if="row.index == 0 || items.length - 1 == row.index" @click="$bvModal.show('bv-modal-edit')" 
                                            
                                            
                                            v-on:click="setData(row.item.id, formatTime(row.item.punching_in_time) , formatTime(row.item.punching_out_time))"
                                            >

                                                <feather type="edit" title="Edit"></feather>

                                            </span>


</template>
</b-table>


</div>

<v-app>
                            <b-modal id="bv-modal-edit" centered size="lg" hide-footer>
                                <template #modal-title>
                                    Edit Punching Details ( Date : {{selectedDate}} )
                                </template>
                                <div class="modal-body">
                                    <b-form class="needs-validation">

                                        <div class="form-group">
                                            <label class="form-label">Punch Out</label>
                                            <br>
                                            <date-picker  v-model="punch_in"  valueType="format" type="time"></date-picker>
                                            <!-- <datetime format="YYYY-MM-DD H:i:s" v-model='punch_out'></datetime> -->
                                           
                                        </div>


                                        <div class="form-group">
                                            <label class="form-label">Punch Out</label>
                                            <br>
                                            <date-picker v-model="punch_out"  valueType="format" type="time"></date-picker>
                                            <!-- <datetime format="H:i:s" v-model='punch_out'></datetime> -->
                                           

                                        </div>


                                        <input id="index_var" type="hidden" value="6" />

                                        <button class="btn btn-secondary" id="Bookmark" type="button"
                                            @click="onPunchSubmit()">
                                            Save
                                        </button>



                                    </b-form>
                                </div>
                            </b-modal>
                        </v-app>


                        </div>
                    </div>
                </div>
                <div class="col-md-12" v-if="responseData == null && selectedDate">
                    <div class="card">
                        <div class="card-body">


                            <p>No data found</p>

                            </div>
                            </div>
                            </div>

            </div>
        </div>



    </div>
    <!-- </v-app> -->
</template>

<script>
import ServiceApi from '@/services/ServiceApi.js';
import Service from '@/services/Service.js';
import Swal from "sweetalert2";
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'
import Datetime from 'vuejs-datetimepicker';



export default {
    components: {
        vSelect,
        Datetime,
        Datepicker
    },

    data() {
        return {
            datalayout: JSON.parse(localStorage.getItem('loginDetails')),
            data: this.$store.state.PinVery.token,
            // data:'byg0hJfFQDVf3ygBRxXWtb6u-nqEBW70tGV2Yy7t6YHrPKOccu730X3yEiJRYwtJ',
            pinvaliduser: null,
            pinvaliddate: null,
            users: [],
            to_users: null,
            date: null,
            state: { disabledDates: { from: new Date() } },
            responseData: null,
            selectedDate: null,
            tablefields: [
               
 
                { key: 'punching_in_time', label: 'Punch In', sortable: true, },
                { key: 'punching_out_time', label: 'Punch Out', sortable: true, },
          
                { key: 'actions', label: 'Actions', sortable: true, },
            ],

            items: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            punch_out:null,
            punch_in:null,
            id:null,
        };
    },
    computed: {


    },
    mounted() {
        // if (this.data == null) {
        //     this.$router.push('/home/verify-passcode');
        // }
        this.retrieveEmployees();
    },
    methods: {
            formatDate(dateTimeString) {
            const date = new Date(dateTimeString);
            return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        },
        formatTime(dateTimeString) {
            const date = new Date(dateTimeString);
            return moment(dateTimeString).format("HH:mm:ss");
        },
        Generate() {
            this.responseData = null;
            if (this.to_users != null && this.date != null) {
                this.pinvaliduser = false;
                this.pinvaliddate = false;
                this.selectedDate = moment(this.date).format('Y-MM-DD');
                var req = {
                    date: moment(this.date).format('Y-MM-DD'),
                    id: this.to_users.id
                }
                this.getDailyAttendanceApi(req);
            }
            if (this.to_users == null) {
                this.pinvaliduser = true;
            } else {
                this.pinvaliduser = false;
            }
            if (this.date == null) {
                this.pinvaliddate = true;
            } else {
                this.pinvaliddate = false;
            }

        },
        customFormatter(date) {
            return moment(date).format('Y-MM-DD');
        },
        async retrieveEmployees() {
            const response = await Service.GetEmployeesForReport();
            if (response.code == "200") {
                this.users = response.data

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getDailyAttendanceApi(req) {
            const response = await ServiceApi.getDailyAttendanceApi(req);

            console.log(response.data);
            if (response.code == "200") {

                this.responseData = response.data


                this.items = this.responseData.data

                this.items.sort((a, b) => b.id - a.id);


                this.$toasted.show('Daily Punching Details', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            } else if (response.code == "400") {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }


            // const maxRecord = response.data.reduce((max, record) =>
            // record.id > max.id ? record : max
            // );

            // console.log(maxRecord);

        },
        setData(id,intime,out){
            this.id = id;
            this.punch_out = out;
            this.punch_in= intime ;
        },
        async onPunchSubmit(){

            console.log(this.punch_out);

            if (this.punch_out == null || this.punch_in == null) {


                this.$toasted.show('In and Out required ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });


                
            }else if (this.selectedDate+'T'+this.punch_out < this.selectedDate+'T'+this.punch_in) {

                this.$toasted.show('punch out is less than punch In', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });


                
            }else{
                
            var req = {
                    date: moment(this.date).format('Y-MM-DD'),
                    id:  this.id,
                    punch_out:this.punch_out,
                    punch_in:this.punch_in,
                    date:this.selectedDate,
                }

            const response = await ServiceApi.updateDailyAttendanceApi(req);
            this.$bvModal.hide("bv-modal-edit");
            if (response.code == "200") {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                var req = {
                    date: moment(this.date).format('Y-MM-DD'),
                    id: this.to_users.id
                }
                this.getDailyAttendanceApi(req);
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }else if (response.code == "400") {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }

            }

        }

    }
};
</script>
<style>
.feedbacksd {
    display: block !important;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
}
</style>