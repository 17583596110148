<template>
    <div>
        <Breadcrumbs main="Configurations" title="Edit Configuration" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="name">Configuration</label>
                                        <b-form-input type="text" id="config" v-model="name"
                                            :state="c_form_result.name" placeholder="Configuration"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.name">
                                            </b-form-valid-feedback>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="value">Value</label>
                                        <b-form-input type="text" id="value" v-model="value"
                                            :state="c_form_result.value" placeholder="Value"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.value">
                                            </b-form-valid-feedback>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_visibility">Visibility</label>
                                        <v-select
                                            v-model="visibility"
                                            label="text"
                                            :options="visibilityTypes" 
                                        />
                                       
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_company">Company</label>
                                        <v-select
                                            v-model="company"
                                            label="name"
                                            :options="companies"                       
                                        />
                                       
                                    </div>
                                </div>
                            
                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from '@/services/ServiceApi.js';
import vSelect from 'vue-select'

export default {

    components: {
      vSelect,
    },

    data() {
        return {
            success: false,
            visibility_name:'',
            companies: [],
            name:'',
            value:'',
            company:'',
            c_form: {
                name: '',
                value: '',
                company:'',
                
            },
            c_form_result: {
                name: null,
                value: null,
                company:null
               
            },
            visibilityTypes: [
                { value: "", text: "Please select" },
                { value: "1", text: "YES" },
                { value: "0", text: "NO" },
            ],
            visibility:[]

        };
    },
    computed: {

    },
    mounted() {
        this.getConfigurationById();
        this.getCompanies();
    },
    methods: {
        async getCompanies() {
          const response = await Service.GetCompanies();
            if (response.code =='200') {
            //   console.log(response.data);
                this.companies =  response.data;
                
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getConfigurationById(){
            const ReqData = {
                 id: this.$route.params.id,
            };
            const response = await ServiceApi.GetConfigurationById(ReqData);
            if (response.code =='200') {
                this.name = response.data.name;
                this.value = response.data.value;
                this.company = { name: response.data.company_name, company_id: response.data.company_id };

                if(response.data.visibility == 1){
                    this.visibility_name = 'YES';

                }
                else{
                    this.visibility_name = 'NO';
                }
                 this.visibility = { value: response.data.visibility, text: this.visibility_name };
             
            }else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
    
        },
        onCustomStyleSubmit(evt) {
            evt.preventDefault();
         
            if (this.name == '') {
                this.success = false;
                this.$toasted.show(' Please enter Configuration', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
            }

            else if (this.value == '') {
                this.success = false;
                this.$toasted.show(' Please enter value', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
            }
            else if (this.visibility.value == '') {
                this.success = false;
                this.$toasted.show(' Please select Visibility ', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
            } 
            else if (this.company == '') {
                this.success = false;
                this.$toasted.show(' Please select Company ', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });
            } 
            else {
                this.success = true;
            }

            this.saveConfig();
        },
        saveConfig() {
           
            if (this.success == true) {
                const config_data = {
                    id: this.$route.params.id,
                    name: this.name,
                    value: this.value,
                    visibility: this.visibility.value,
                    company_id: this.company.company_id
                    
                } 
                this.updateConfig(config_data)
            }
        },
        async updateConfig(config_data) {
            const response = await ServiceApi.UpdateConfig(config_data);
            if (response.code == '200') {
                this.$toasted.show(' Configuration updated successfully ', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/configuration');
            }
            else if (response.code == '400') {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline',position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
      
    }
};
</script>
