<template>
    <div><br>
        <Breadcrumbs main="Project" :title="projName + ' / Manage Task'" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="user-profile">
                <div class="row">
                    <!-- user profile first-style start-->
                    <div class="col-sm-12">
                        <br /><br />
                        <div class="card hovercard text-center">
                            <div class="user-image">
                                <!-- <div class="avatar"><img alt="" src="../../assets/images/user/7.jpg"></div> -->
                            </div>
                            <div class="info">

                                <br>
                                <div class="row">

                                    <!-- <div class="col-1" @click="goToProjectView()">
                                       
                                        <b-avatar class="cust-color" variant="warning" size="2.5em">

                                          
                                            <feather type="arrow-left"></feather>
                                          
                                        </b-avatar>
                                    </div> -->
                                    <div class="col-md-3">
                                        <div class="ttl-info ">
                                            <h6><i class="fa fa-file-code-o"></i> Task Name</h6>
                                            <span> {{ this.items.name }} </span>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="ttl-info">
                                            <h6><i class="fa fa-flag"></i> Task Status</h6>
                                            <span>
                                                <b-badge :variant="statusVariant(this.items.status)">

                                                    <span v-if="this.items.status == 1">
                                                        Pending
                                                    </span>
                                                    <span v-else-if="this.items.status == 2 || this.items.status == 5">
                                                        Started
                                                    </span>
                                                    <span v-else-if="this.items.status == 3">
                                                        Completed
                                                    </span>
                                                    <span v-else-if="this.items.status == 4">
                                                        Paused
                                                    </span>

                                                </b-badge>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="ttl-info">
                                            <h6><i class="fa fa-file-code-o"></i> Module</h6>
                                            <span> {{ this.items.module }} </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="ttl-info">
                                            <h6><i class="fa fa-user"></i> Assigned User</h6>
                                            <span> {{ this.items.user.user }} </span>
                                        </div>
                                    </div>

                                    <div v-if="loader == true" class="loader-box">
                                        <div class="loader-15"></div>
                                    </div>

                                </div>
                                <br>
                                <div>
                                    <div class="row">
                                        <div class="col-sm-12 col-lg-12 order-sm-0 order-xl-1">

                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div>
                                    <div class="card" v-if="LvStatus != '1'">

                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-4">
                                                    <div class="pull-left">
                                                        <h6>Total Time</h6>
                                                        <p><b>{{ getTimeAsForemated(time_seconds) }}</b></p>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <!-- <h5>Subtasks</h5> -->
                                                </div>
                                                <div class="col-4">
                                                    <b-button v-if="loader == false && project_status == 1"
                                                        id="default-xs-secondary" class="btn-pill pull-right" size="md"
                                                        variant="outline-primary" @click="modalShow3 = true">Add Subtask
                                                    </b-button>
                                                </div>
                                            </div>

                                            <hr>
                                            <b-row>
                                                <b-col xl="6">

                                                    <b-input-group class="datatable-btn">
                                                        <b-form-input v-model="filter"
                                                            placeholder="Type to Search"></b-form-input>
                                                        <b-input-group-append>
                                                            <b-button :disabled="!filter"
                                                                @click="filter = ''">Clear</b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>

                                                </b-col>

                                                <b-col xl="6">
                                                    <b-form-group label-cols="2" label="Per page"
                                                        class="mb-0 datatable-select">
                                                        <b-form-select v-model="perPage"
                                                            :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <div class="table-responsive datatable-vue">
                                                <b-table show-empty stacked="md" :items="desc_data" :fields="tablefields"
                                                    :filter="filter" :current-page="currentPage" :per-page="perPage"
                                                    @filtered="onFiltered">

                                                    <template #cell(duration)="rows">
                                                        <span>
                                                            <p>{{ getTimeAsForemated(rows.item.duration) }}</p>
                                                        </span>
                                                    </template>

                                                    <template #cell(start_time)="rows">
                                                        <span>
                                                            <p>{{ foremateDateTime(rows.item.start_time) }}</p>
                                                        </span>
                                                    </template>
                                                    <template #cell(end_time)="rows">
                                                        <span>
                                                            <p>{{ foremateDateTime(rows.item.end_time) }}</p>
                                                        </span>
                                                    </template>

                                                    <template #cell(desc)="rows">
                                                        <span v-b-modal.modal-4 @click="describeView(rows.item.desc)">
                                                            <p class="p-dot">{{ rows.item.desc }}</p>
                                                        </span>
                                                    </template>
                                                    <template #cell(task_status)="rows">
                                                        <span>
                                                            <b-badge :variant="statusVariant(rows.item.task_status)">
                                                                <span v-if="rows.item.task_status == 1">
                                                                    Pending
                                                                </span>
                                                                <span
                                                                    v-else-if="rows.item.task_status == 2 || rows.item.task_status == 5">
                                                                    Started
                                                                </span>
                                                                <span v-else-if="rows.item.task_status == 3">
                                                                    Completed
                                                                </span>
                                                                <span v-else-if="rows.item.task_status == 4">
                                                                    Paused
                                                                </span>
                                                            </b-badge>
                                                            <!-- <p class="p-dot">{{ statusVariant(rows.item.task_status) }}</p> -->
                                                        </span>
                                                    </template>

                                                    <template #cell(actions)="rows">
                                                        <span>
                                                            <!-- <feather v-b-modal.modal-4 @click="describeView(rows.item.desc)"
                                                                type="eye">
                                                            </feather> -->
                                                            <feather
                                                                v-if="rows.item.task_status != 3 && checkEmpCurrentDate(rows.item.start_time)"
                                                                v-b-modal.modal-5 @click="endSubTask(rows.item)"
                                                                type="check-circle">
                                                            </feather>
                                                        </span>
                                                    </template>
                                                </b-table>
                                            </div>
                                            <b-col md="6" class="my-1 p-0">
                                                <b-pagination v-model="currentPage" :total-rows="totalRows"
                                                    :per-page="perPage" class="my-0"></b-pagination>
                                            </b-col>

                                        </div>
                                    </div>

                                </div>

                                <b-modal id="modal-3" v-model="modalShow" hide-footer>
                                    <div class="row">
                                        <div class="col-12">
                                            <form>
                                                <label class="col-form-label" for="message-text">Descriptions:</label>
                                                <b-form-textarea id="textarea" v-model="text"
                                                    placeholder="Enter Description" rows="3"></b-form-textarea>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <b-button class="mt-3 " variant="danger" @click="hideModal">Close</b-button>
                                        </div>
                                        <div class="col-6">
                                            <b-button class="mt-3 float-right" variant="primary"
                                                @click="handleSubmit()">Update
                                                Status</b-button>
                                        </div>
                                    </div>
                                </b-modal>

                                <b-modal id="modal-4" v-model="modalShow1" cancel-only>
                                    <div class="row">
                                        <div class="col-12">

                                            {{ describe_text }}

                                        </div>
                                    </div>
                                    <!-- <p> {{ describe_text }}</p> -->

                                    <!-- <div class="row">
                                        <div class="col-6">
                                            <b-button class="mt-3 " variant="danger" @click="hideModal1">Close</b-button>
                                        </div>
                                    </div> -->
                                    <template #modal-footer>
                                        <div class="w-100">
                                            <!-- <p class="float-left">Modal Footer Content</p> -->
                                            <b-button variant="danger" size="sm" class="float-right" @click="hideModal1">
                                                Close
                                            </b-button>
                                        </div>
                                    </template>
                                </b-modal>
                                <b-modal id="modal-5" v-model="modalShow5" hide-footer title="End Subtask" size="md"
                                    centered>
                                    <div>
                                        <br>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-label">Start Date and Time</label>
                                                    <b-form-input type="text" name="" id="" v-model="startDateAndTime"
                                                        readonly></b-form-input>
                                                    <!-- <date-picker v-model="startDateAndTime" valueType="format"
                                                            type="datetime" readonly></date-picker> -->
                                                    <!-- <datetime format="YYYY-MM-DD" v-model='startDate' readonly></datetime> -->
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-label">Start time</label>
                                                    <b-form-input type="text" name="" id="" v-model="startTime"
                                                        readonly></b-form-input>
                                                    
                                                </div>
                                            </div> -->
                                            <!-- </div>
                                        <div class="row"> -->
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-label">End Date and Time</label>
                                                    <date-picker v-model="endDateAndTime" valueType="format"
                                                        :type="pManager == 'MANAGER' || has_prev == true ? 'datetime' : 'time'"></date-picker>
                                                    <!-- <datetime format="YYYY-MM-DD" v-model='endDate'></datetime> -->
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-label">End time</label>
                                                    <datetime format="H:i:s" v-model='endTime'></datetime>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                            </div>
                                            <div class="col-6" v-if="loader_flag2">
                                                <b-button class="mt-3 pull-right" variant="primary"
                                                    @click="UpdateSubtask()">Update</b-button>
                                            </div>
                                            <div class="col-6" v-else>
                                                <div class="loader-box">
                                                    <div class="loader-15"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-modal>
                                <div>

                                    <b-modal id="modal-center" centered v-model="modalShow3" title="Add Subtask" size="md"
                                        hide-footer>
                                        <div>

                                            <div class="row">
                                                <div class="col-md-12 mb-3">
                                                    <label for="description">Description</label>
                                                    <textarea class="form-control" rows="2" placeholder="Enter Description"
                                                        v-model="description">
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="form-label">Start Date and Time</label>
                                                        <!-- <datetime format="YYYY-MM-DD" v-model='start_date'></datetime> -->
                                                        <date-picker v-model="start_date_and_time" valueType="format"
                                                            :type="pManager == 'MANAGER' || has_prev == true ? 'datetime' : 'time'"></date-picker>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-6" v-else>

                                                </div> -->
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="form-label">End Date and Time</label>
                                                        <!-- <datetime format="H:i:s" v-model='start_time'></datetime> -->
                                                        <date-picker v-model="end_date_and_time" valueType="format"
                                                            :type="pManager == 'MANAGER' || has_prev == true ? 'datetime' : 'time'"></date-picker>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="row">
                                                <div class="col-md-6" v-if="pManager == 'MANAGER' || has_prev == true">
                                                    <div class="form-group">
                                                        <label class="form-label">End Date</label>
                                                        <datetime format="YYYY-MM-DD" v-model='end_date'></datetime>

                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="form-label">End time</label>
                                                        <datetime format="H:i:s" v-model='end_time'></datetime>

                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="row">
                                                <div class="col-6">

                                                </div>
                                                <div class="col-6" v-if="loader_flag1">
                                                    <b-button class="mt-3 pull-right" variant="primary"
                                                        @click="addSubtask()">Add Subtask
                                                    </b-button>
                                                </div>
                                                <div class="col-6" v-else>
                                                    <div class="loader-box">
                                                        <div class="loader-15"></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </b-modal>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import Swal from "sweetalert2";
import Datetime from 'vuejs-datetimepicker';

export default {

    components: {
        vSelect,
        Datetime
    },

    data() {
        return {
            tablefields: [
                { key: 'user', label: 'Name', sortable: true, },
                { key: 'start_time', label: 'Start Time', sortable: true, },
                { key: 'end_time', label: 'End Time', sortable: true, },
                { key: 'duration', label: 'Duration', sortable: true, },
                { key: 'desc', label: 'Description', sortable: true, },
                { key: 'task_status', label: 'Status', sortable: true, },
                { key: 'actions', label: 'Actions', sortable: true, },
            ],
            filter: null,
            loader_flag1: true,
            loader_flag2: true,
            start_time: '',
            start_date: '',
            start_date_and_time: '',
            end_date_and_time: '',
            end_time: '',
            end_date: '',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            items: [],
            LvStatus: null,
            employees: [],
            employee: null,
            text: '',
            modalShow1: false,
            modalShow3: false,
            modalShow5: false,
            describe_text: null,
            modalShow: false,
            changeStatus: null,
            teamLead: false,
            currUser: null,
            pManager: null,
            loginData: JSON.parse(localStorage.getItem('loginDetails')),
            historyId: null,
            time_seconds: null,
            desc_data: [],
            loader: false,
            description: '',
            subTaskDet: [],
            startDate: '',
            startDateAndTime: '',
            endDateAndTime: '',
            endDate: '',
            startTime: '',
            endTime: '',
            projName: null,
            project_status: null,
            has_prev: false,
        };
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-warning",
                2: "light-info",
                3: "light-success",
                4: "light-info",
                5: "light-info",
            };
            return (status) => statusColor[status];
        },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        },
    },
    mounted() {
        // this.heading = head;
        this.retrieveTask(this.$route.params.id);
        this.pManager = this.loginData.designation.level.key;
    },
    methods: {
        goToProjectView() {
            // console.log(this.items.project_id);
            this.$router.push('/home/emp-project/' + this.items.project_id);
        },
        checkEmpCurrentDate(date) {
            var dateString = date.split(' ')[0];
            var date = new Date(dateString);
            var currentDate = new Date();

            if (date.toDateString() === currentDate.toDateString()) {
                return true;
            } else {
                if (this.pManager == 'MANAGER' || this.has_prev == true) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        UpdateSubtask() {
            this.loader_flag2 = false;
            if (this.pManager == 'MANAGER' || this.has_prev == true) {
                if (this.startDateAndTime == '') {
                    this.$toasted.show('Enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                    this.loader_flag2 = true;
                } else if (this.endDateAndTime <= this.startDateAndTime) {
                    this.$toasted.show('Enter correct Date and Time ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                    this.loader_flag2 = true;
                    // } else {
                    //     var taskData = {
                    //         id: this.subTaskDet.id,
                    //         user: this.items.user.user_id,
                    //         task: this.$route.params.id,
                    //         dateTimeEnd: this.makeDateForMngr(this.endDate, this.endTime)
                    //     }
                    //     // console.log(subTaskData);
                    //     this.stopSubtask(taskData);
                } else {
                    var taskData = {
                        id: this.subTaskDet.id,
                        user: this.items.user.user_id,
                        task: this.$route.params.id,
                        dateTimeEnd: this.makeDateForMngr(this.endDateAndTime)
                    }
                    // console.log(subTaskData);
                    this.stopSubtask(taskData);
                }
            } else {
                // console.log(this.endDateAndTime, String(this.startDateAndTime).split(' ')[1]);
                if (this.startDateAndTime == '') {
                    this.$toasted.show('Enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                    this.loader_flag2 = true;
                } else if (this.endDateAndTime <= String(this.startDateAndTime).split(' ')[1]) {
                    this.$toasted.show('Enter correct Date and Time ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                    this.loader_flag2 = true;
                } else {
                    var taskData = {
                        id: this.subTaskDet.id,
                        user: this.items.user.user_id,
                        task: this.$route.params.id,
                        dateTimeEnd: this.makeDateForEmp(this.endDateAndTime)
                    }
                    // console.log(subTaskData);
                    this.stopSubtask(taskData);
                }
            }
        },
        endSubTask(taskData) {
            this.subTaskDet = [];
            this.subTaskDet = taskData;
            this.startDateAndTime = String(taskData.start_time).split(' ')[0] + ' ' + (String(taskData.start_time).split(' ')[1]);
            // this.startDate = String(taskData.start_time).split(' ')[0];
            // this.startTime = (String(taskData.start_time).split(' ')[1]);
            this.modalShow5 = true;
            // console.log(taskData);
        },
        async stopSubtask(tskData) {
            const response = await Service.StopSubtask(tskData);
            if (response.code == "200") {
                this.$toasted.show('Subtask Completed ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.loader_flag2 = true;
                this.retrieveTask(this.$route.params.id);
                this.modalShow5 = false;

            } else {
                this.loader_flag2 = true;
            }
        },
        makeDateForMngr(date) {
            var combinedStr = String(date).split(' ')[0] + 'T' + String(date).split(' ')[1];
            // Create a Date object using the combined string
            var dateTime = new Date(combinedStr);

            var year = dateTime.getFullYear();
            var month = String(dateTime.getMonth() + 1).padStart(2, '0');
            var day = String(dateTime.getDate()).padStart(2, '0');
            var hours = String(dateTime.getHours()).padStart(2, '0');
            var minutes = String(dateTime.getMinutes()).padStart(2, '0');
            var seconds = String(dateTime.getSeconds()).padStart(2, '0');

            // var formattedDateTime = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day) + ' ' + (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds);
            var formattedDateTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
            return formattedDateTime;
        },
        makeDateForEmp(time) {
            
            var timeStr = time;

            // Get the current date
            var today = new Date();

            // Extract the current date components
            var year = today.getFullYear();
            var month = String(Number(today.getMonth()) + 1);
            var day = today.getDate();

            // Split the time string into hours, minutes, and seconds
            var timeParts = timeStr.split(':');
            var hours = parseInt(timeParts[0], 10);
            var minutes = parseInt(timeParts[1], 10);
            var seconds = parseInt(timeParts[2], 10);
            // Create a new Date object using the current date and the specified time
            // var dateTime = new Date(year, month, day, hours, minutes, seconds);
            var dateTime = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day) + ' ' + (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds);
            // var dateTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;

            return dateTime;
        },
        addSubtask() {
            this.loader_flag1 = false;
            // console.log(this.start_date_and_time, this.end_date);
            if (this.pManager == 'MANAGER' || this.has_prev == true) {
                if (this.description == '' || this.start_date_and_time == '') {
                    this.$toasted.show('Enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                    this.loader_flag1 = true;
                } else {
                    var endDateTime = '';
                    if (this.end_date_and_time != '') {
                        // if (this.end_date_and_time == this.start_date_and_time) {
                        if (this.end_date_and_time <= this.start_date_and_time) {
                            this.$toasted.show('Enter correct Date and Time ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                            this.loader_flag1 = true;
                        } else {
                            endDateTime = this.makeDateForMngr(this.end_date_and_time);
                            var subTaskData = {
                                user: Object.keys(this.items.user).length > 0 ? this.items.user.user_id : null,
                                task: this.$route.params.id,
                                description: this.description,
                                dateTime: this.makeDateForMngr(this.start_date_and_time),
                                dateTimeEnd: endDateTime,
                                previlege: this.has_prev,
                            }
                            // console.log(subTaskData);
                            this.addSubtasks(subTaskData);
                        }
                        // } else if (this.end_date < this.start_date) {
                        //     this.$toasted.show('Enter correct Date and Time ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                        //     this.loader_flag1 = true;
                        // } else {
                        //     endDateTime = this.makeDateForMngr(this.end_date_and_time);
                        //     var subTaskData = {
                        //         user: Object.keys(this.items.user).length > 0 ? this.items.user.user_id : null,
                        //         task: this.$route.params.id,
                        //         description: this.description,
                        //         dateTime: this.makeDateForMngr(this.start_date_and_time),
                        //         dateTimeEnd: endDateTime,
                        //         previlege: this.has_prev,
                        //     }

                        //     this.addSubtasks(subTaskData);
                        // }
                    } else {
                        var subTaskData = {
                            user: Object.keys(this.items.user).length > 0 ? this.items.user.user_id : null,
                            task: this.$route.params.id,
                            description: this.description,
                            dateTime: this.makeDateForMngr(this.start_date_and_time),
                            dateTimeEnd: endDateTime,
                            previlege: this.has_prev,
                        }
                        // console.log(subTaskData);
                        this.addSubtasks(subTaskData);
                    }
                }
            } else {
                if (this.description == '' || this.start_date_and_time == '') {
                    this.$toasted.show('Enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                    this.loader_flag1 = true;
                } else {
                    var endDateTime = '';
                    if (this.end_date_and_time != '') {
                        if (this.end_date_and_time <= this.start_date_and_time) {
                            this.$toasted.show('Enter correct Date and Time ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                            this.loader_flag1 = true;
                        } else {
                            endDateTime = this.makeDateForEmp(this.end_date_and_time);
                            var subTaskData = {
                                user: Object.keys(this.items.user).length > 0 ? this.items.user.user_id : null,
                                task: this.$route.params.id,
                                description: this.description,
                                dateTime: this.makeDateForEmp(this.start_date_and_time),
                                dateTimeEnd: endDateTime,
                                previlege: this.has_prev,
                            }
                            // console.log(subTaskData);
                            this.addSubtasks(subTaskData);
                        }
                    } else {

                        var subTaskData = {
                            user: Object.keys(this.items.user).length > 0 ? this.items.user.user_id : null,
                            task: this.$route.params.id,
                            description: this.description,
                            dateTime: this.makeDateForEmp(this.start_date_and_time),
                            dateTimeEnd: endDateTime,
                            previlege: this.has_prev,
                        }
                        // console.log(subTaskData);
                        this.addSubtasks(subTaskData);
                    }
                }
            }

        },
        async addSubtasks(subData) {
            // console.log(subData);
            const response = await Service.addNewSubtask(subData);
            if (response.code == "200") {
                this.$toasted.show('Subtask added ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.loader_flag1 = true;
                this.retrieveTask(this.$route.params.id);
                this.modalShow3 = false;

                this.description = '';
                this.start_date = '';
                this.start_time = '';
                this.end_date = '';
                this.end_time = '';

                this.start_date_and_time = '';
                this.end_date_and_time = '';
            } else {
                this.loader_flag1 = true;
            }
        },
        foremateDateTime(dt) {
            if (dt != '') {
                return dt.split(' ')[0] + ' ' + dt.split(' ')[1]
            } else {
                return '';
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        describeView(text) {
            this.describe_text = text;
        },
        hideModal() {
            this.modalShow = false;
        },
        hideModal1() {
            this.modalShow1 = false;
        },
        async retrieveTask(task) {
            const ReqData = {
                id: task,
            };
            const response = await Service.GetEmpTaskById(ReqData);
            if (response.code == "200") {
                this.projName = response.data.proj_name;
                this.items = response.data;
                this.project_status = response.data.project_status;
                this.getUsers(this.items.project_id);
                this.getPhaseById(this.items.project_id);
                this.getUserAssign(this.items.id);
            }
        },
        async getPhaseById(project) {
            const reqDatas = {
                project_id: project
            }
            const response = await Service.GetProjectPhase(reqDatas);
            if (response.code == "200") {

                response.data.forEach(element => {
                    element.mem.forEach(element1 => {
                        if (element1.id == this.loginData.id) {
                            this.has_prev = true;
                        }
                    });
                });
            }
        },
        async getUsers(pid) {
            const reqDataUser = {
                id: pid,
            }

            const response = await Service.GetProjectDetails(reqDataUser);
            if (response.code == '200') {

                // this.teamLead = response.data.all.lead_id;

                this.pManager = this.loginData.designation.level.key;
                this.currUser = this.loginData.id;
                // if (this.LvStatus != 3) {
                //     this.employees = response.data.all.members;
                // } else {
                //     this.employees = [];
                // }
                // response.data.all.allMem.forEach(element => {
                //     element.mem.forEach(element1 => {
                //         if (element1.role == 1) {
                //             if (this.currUser == element1.user_id) {
                //                 this.teamLead = true;
                //                 this.employees = element.mem;
                //             }
                //         }
                //     });
                // });
            }
        },
        async getUserAssign(task) {
            this.desc_data = []
            const reqDataTask = {
                task_id: task,
            }
            const response = await Service.GetUserAssignTask(reqDataTask);
            if (response.code == '200') {
                this.desc_data = response.data.subDetails;
                this.totalRows = response.data.subDetails.length;
                this.time_seconds = response.data.total_time;
                // console.log(this.time_seconds);
                // var sec_num = response.data.total_time;
                // var hours = Math.floor(sec_num / 3600);
                // var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
                // var seconds = sec_num - (hours * 3600) - (minutes * 60);

                // if (hours < 10) { hours = "0" + hours; }
                // if (minutes < 10) { minutes = "0" + minutes; }
                // if (seconds < 10) { seconds = "0" + seconds; }
                // this.time_seconds = hours + ':' + minutes + ':' + seconds;
                // this.employee = response.data[0];
                // this.LvStatus = response.data[0].task_status;
                // this.historyId = response.data[0].id;
                // this.getTaskHistory(response.data[0], task);
            }
        },
        getTimeAsForemated(time) {
            var sec_num = time;
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours < 10) { hours = "0" + hours; }
            if (minutes < 10) { minutes = "0" + minutes; }
            if (seconds < 10) { seconds = "0" + seconds; }
            return hours + ':' + minutes + ':' + seconds;
        },
        changeUser(emp) {

            Swal.fire({
                title: "Are you sure?",
                text: "Are you sure you want to Change Employee!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Change!",
            }).then((result) => {
                if (result.isConfirmed) {
                    const reqDataTask = {
                        id: this.$route.params.id,
                        user: emp,
                        stat: this.LvStatus,
                    }
                    this.updateEmly(reqDataTask);

                }
            });


        },

        async updateEmly(reqDataTask) {
            this.loader = true;
            const response = await Service.UpdateUserInTaskHistory(reqDataTask);

            if (response.code == '200') {
                this.loader = false;
                this.retrieveTask(this.$route.params.id);

                this.$toasted.show('Task updated successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                // this.taskId.val_view = 1;
                // this.$router.push('/home/task-view/' + this.$route.params.id);
            }


        },
        async handleSubmit() {
            this.modalShow = false;

            const ReqData = {
                id: this.$route.params.id,
                status: this.changeStatus,
                desc: this.text,
                user: this.employee.user_id,
                taskHistory: this.historyId,
            };
            const response = await Service.UpdateStatusInTask(ReqData);

            if (response.code == '200') {
                this.text = '';
                this.retrieveTask(this.$route.params.id);
                // this.taskId.val_view = 1;
                // this.$emit('update:taskId', this.taskId);
                // this.$router.push('/home/task-view/' + this.$route.params.id);
            }

        },
        async getTaskHistory(taskDetails, task) {
            const ReqData = {
                proj_task: task,
                emp: taskDetails.user_id,
            };

            const response = await Service.getTaskHistoryDetails(ReqData);
            if (response.code == '200') {
                // this.time_seconds = response.data;
                this.desc_data = [];
                var sec_num = response.data.time_arr
                var hours = Math.floor(sec_num / 3600);
                var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
                var seconds = sec_num - (hours * 3600) - (minutes * 60);

                if (hours < 10) { hours = "0" + hours; }
                if (minutes < 10) { minutes = "0" + minutes; }
                if (seconds < 10) { seconds = "0" + seconds; }
                this.time_seconds = hours + ':' + minutes + ':' + seconds;

                response.data.desc_arr.forEach(arr_val => {
                    arr_val.forEach(inn_arr => {
                        this.desc_data.push(inn_arr)
                    });
                });
                this.totalRows = this.desc_data.length;

            }
        }

    }
};

</script>
<style>
.p-dot {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

ul {
    padding-left: 0px;
    list-style-type: unset;
    margin-bottom: 0;
}

.modal-backdrop {
    opacity: 0.8 !important;
}

.cust-color:hover {
    background-color: #cac701 !important;
}
</style>