<template>
    <div>
        <Breadcrumbs main="Departments" title="Add Department" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="c_form_name">Name</label>
                                        <b-form-input type="text" id="c_form_name" v-model="c_form.name"
                                            :state="c_form_result.name" placeholder="Name"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.name">
                                        </b-form-valid-feedback>
                                    </div>
                                </div>
                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import ServiceApi from '@/services/ServiceApi.js';
import vSelect from 'vue-select'

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            success: false,
            c_form: {
                name: '',
            },
            c_form_result: {
                name: null,
            },
        };
    },
    computed: {

    },
    mounted() {

    },
    methods: {

        onCustomStyleSubmit(evt) {
            evt.preventDefault();

            if (this.c_form.name !== '') {
                if (/^\d+$/.test(this.c_form.name)) {
                    this.c_form_result.name = false;
                    this.success = false;
                    this.$toasted.show(' Please enter a valid name ', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });

                } else {
                    this.success = true;
                    this.c_form_result.name = true;
                }
            } else {
                this.c_form_result.name = false;
                this.success = false;
                this.$toasted.show(' Please enter Department ', { theme: 'outline',position: 'top-right', type: 'error', duration: 2000 });

            }
            this.saveDepartment();
        },
        saveDepartment() {
            if (this.success == true) {
                const dept_data = {
                    name: this.c_form.name,
                }
                this.addDepartment(dept_data)
            }
        },
        async addDepartment(dept_data) {
            const response = await ServiceApi.AddDepartment(dept_data);
            if (response.code == '200') {
                this.$toasted.show(' Department added successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/department');
            } else if (response.code == '400') {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'danger', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        }
    }
};
</script>
<style lang="scss">
@import "vue-select/dist/vue-select.css";
</style>