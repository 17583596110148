<template>
    <div>
        <Breadcrumbs main="Companies" title="Add Company" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onCustomStyleSubmit">
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="name">Company Name</label>
                                        <b-form-input type="text" id="name" v-model="c_form.name"
                                            :state="c_form_result.name" placeholder="Comapany name"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.first_name">
                                        </b-form-valid-feedback>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="address">Address</label>
                                        <b-form-input type="text" id="address" v-model="c_form.address"
                                            :state="c_form_result.address" placeholder="Address"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.address">
                                        </b-form-valid-feedback>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-6 mb-3">
                                        <label for="email">Email</label>
                                        <b-form-input type="email" id="email" v-model="c_form.email"
                                            :state="c_form_result.email" placeholder="Email"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.email">
                                        </b-form-valid-feedback>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="address">Phone</label>
                                        <b-form-input type="text" id="phone" v-model="c_form.phone"
                                            :state="c_form_result.phone" placeholder="Phone"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.phone">
                                        </b-form-valid-feedback>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="name">Website Link</label>
                                        <b-form-input type="text" id="name" v-model="c_form.website_link"
                                            :state="c_form_result.website_link" placeholder="Website Link"></b-form-input>
                                        <b-form-valid-feedback :state="c_form_result.website_link">
                                        </b-form-valid-feedback>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                    <label for="c_form_name"> Upload Logo </label>
                                    <input type="file" @change="onFileChange" />
                                    <div id="preview">
                                        <img v-if="logo_name" :src="logo_name" />
                                    </div>
                                </div>
                                </div>
                             

                                <div class="form-row">
                                    <div class="col-md-12 mb-3">
                                        <label for="Permissions"><b>Permissions</b></label><br />
                                        <ul>
                                            <li v-for="(item, index) in menuData">
                                                <label class="d-block" for="chk-ani">
                                                    <input class="checkbox_animated" :value="item.key"
                                                        v-model="selectedMenus" :id="item.name" type="checkbox">
                                                    <b>{{ item.name }}</b>
                                                </label>
                                                <ul :style="'padding-left:14px'">
                                                    <li v-for="(child, index_child) in item.children">
                                                        <label class="d-block" for="chk-ani">
                                                            <input class="checkbox_animated" :value="child.key"
                                                                v-model="selectedMenus" :id="child.name" type="checkbox">
                                                            {{ child.name }}
                                                        </label>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import S3 from "aws-s3";

export default {

    data() {
        return {
            success: false,
            email: '',
            c_form: {
                name: '',
                address: '',
                email: '',
                phone: '',
                website_link:''
            },
            c_form_result: {
                name: null,
                address: null,
                email: null,
                phone: null,
                website_link:null
            },
            file_logo: null,
            logo_name: null,
            menuData: [],
            selectedMenus: []

        };
    },
    mounted: function () {
        this.getMenuWithModules()
        this.getAwsS3Config();

    },
    computed: {

    },
    methods: {
        async getAwsS3Config() {
            const response = await Service.getAwsS3Configs();
            if (response.code == "200") {
                this.s3 = response.data;
            }
        },

        onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;

        if (!files.length) {
            return;
        }

        const now = Date.now();
        let file = files[0];

        if (!file.type.startsWith('image/')) {
            this.$toasted.show('Please upload an image file', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            return;
        }

        const img = new Image();
        img.onload = () => {
            if (img.width !== 140 || img.height !== 40) {
            this.$toasted.show('Please upload an image with dimensions 140x40', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            return;
            }

            this.logo_name = URL.createObjectURL(file);

            const config = {
            bucketName: this.s3.bucketName,
            dirName: "company_logos" /* optional */,
            region: this.s3.region,
            accessKeyId: this.s3.accessKeyId,
            secretAccessKey: this.s3.secretAccessKey,
            s3Url: "https://bktqaptive.s3.amazonaws.com" /* optional */,
            };

            const S3Client = new S3(config);

            S3Client.uploadFile(file, now).then((response) => {
            this.file_logo = response.location;
            });
        };

        img.src = URL.createObjectURL(file);
        },

        onCustomStyleSubmit(evt) {
            evt.preventDefault();
            if (this.c_form.name !== '') {
                this.c_form_result.name = true;
                this.success = true;
            } else {
                this.success = false;
                this.c_form_result.name = false;
            }

            if (this.c_form.address !== '') {
                this.success = true;
                this.c_form_result.address = true;
            } else {
                this.success = false;
                this.c_form_result.address = false;
            }

            if (this.c_form.email !== '') {
                this.success = true;
                this.c_form_result.email = true;
            } else {
                this.success = false;
                this.c_form_result.email = false;
            }

            if (this.c_form.phone !== '') {
                this.success = true;
                this.c_form_result.phone = true;
            } else {
                this.success = false;
                this.c_form_result.phone = false;
            }
            if (this.c_form.website_link != '' && this.c_form.website_link != null) {

                const regExp = /^https?:\/\/[^\s/$.?#].[^\s]*$/;

                if (this.c_form.website_link.match(regExp)) {
                    this.success = true;
                }
                else{
                    this.success = false;
                    this.$toasted.show(' Please enter valid link', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                }


            }
            else{
                this.success = true;
            }

            this.saveCompany();
        },
        saveCompany() {

            if (this.success == true) {
                const comp_data = {
                    name: this.c_form.name,
                    address: this.c_form.address,
                    phone: this.c_form.phone,
                    email: this.c_form.email,
                    selectedMenus: this.selectedMenus,
                    logo_name:this.file_logo,
                    website_link: this.c_form.website_link,
                }
                this.addCompany(comp_data)
            }
        },
        async addCompany(comp_data) {
            const response = await Service.AddCompanyData(comp_data);
            if (response.code == '200') {
                this.$toast.success('Company created successfully', { icon: false });
                this.$router.push('/home/company');
            }
            else if (response.code == '400') {
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getMenuWithModules() {
            const response = await Service.getMenuWithModules();
            if (response.code == '200') {
                this.menuData = response.data;
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        }
    }
};
</script>
