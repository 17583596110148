<template>
    <div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="user-profile">
                <div class="row">
                    <!-- user profile first-style start-->
                    <div class="col-sm-12">

                        <br /><br />

                        <div class="card hovercard text-center"><br>
                            <div>
                                <h5> {{ this.title }}</h5>
                            </div>

                            <div class="info">
                                <div class="row">
                                   
                                    <div class="col-md-3">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-database"></i> Department </h6>
                                            <span>
                                                {{ this.department }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-check"></i> No. of Vacancies </h6>
                                            <span>
                                                {{ this.no_of_vacancies }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-money"></i> Package </h6>
                                            <span>
                                                {{ this.package }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div class="ttl-info text-left">
                                           <h6><i class="fa fa-flag"></i> Priority </h6>
                                           <span>
                                               {{ this.priority_name }}
                                           </span>
                                       </div>
                                   </div>
                                   
                                </div>
                                <br> <br>
                                <div class="row">
                                    <div class="col-md-3">
                                       <div class="ttl-info text-left">
                                           <h6><i class="fa fa-map-marker"></i> Location </h6>
                                           <span>
                                               {{ this.location }}
                                           </span>
                                       </div>
                                   </div>

                                   <div class="col-md-3">
                                       <div class="ttl-info text-left">
                                           <h6><i class="fa fa-calendar"></i> Start Date </h6>
                                           <span>
                                               {{ this.start_date }}
                                           </span>
                                       </div>
                                   </div>
                                   <div class="col-md-3">
                                       <div class="ttl-info text-left">
                                           <h6><i class="fa fa-calendar"></i> End Date </h6>
                                           <span>
                                               {{ this.end_date }}
                                           </span>
                                       </div>
                                   </div>
                                   <div class="col-md-3">
                                       <div class="ttl-info text-left">
                                           <h6><i class="fa fa-clock-o"></i> Job Type </h6>
                                           <span>
                                               {{ this.job_type_name }}
                                           </span>
                                       </div>
                                   </div>
                               </div> 
                               <hr>
                               <div class="row">
                                    <div class="col-md-3">
                                       <div class="ttl-info text-left">
                                           <h6><i class="fa fa-map-marker"></i> Pass Mark </h6>
                                           <span>
                                               {{ this.pass_mark }}
                                           </span>
                                       </div>
                                   </div>
                                   <div class="col-md-9">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-key"></i> Keywords </h6>
                                            <span v-if="this.keywords && Object.keys(this.keywords).length > 0">
                                               
                                                    <span v-for="(mark, keyword) in this.keywords" :key="keyword">
                                                        {{ keyword }}: {{ mark }}
                                                    </span>
                                              
                                            </span>
                                            <span v-else>
                                                No keywords available
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                  
                                    <div class="col-md-12">
                                        <div class="ttl-info text-left">
                                            <h6><i class="fa fa-list-alt"></i> Short Description</h6>
                                            <span>
                                                {{ this.short_description }}
                                            </span>
                                        </div>
                                    </div>
                                   
                                </div>
                                <hr>
                                <div class="row">
                                  
                                  <div class="col-md-12">
                                      <div class="ttl-info text-left">
                                          <h6><i class="fa fa-list-alt"></i> Description</h6>
                                          <p :style="'margin: 27px;'" v-html="this.description"></p>
                                      </div>
                                  </div>
                                  
                                 
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ServiceApi from '@/services/ServiceApi.js';
import vSelect from 'vue-select'

export default {

    components: {
        vSelect,
    },

    data() {
        return {
            title: '',
            //qualification: '',
            description: '',
            short_description: '',
            no_of_vacancies: '',
            package: '',
            priority: '',
            priority_name: '',
            job_type:'',
            job_type_name:'',
            department: '',
            location: '',
            start_date: '',
            end_date: '',
            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50],
            items: [],
            pass_mark:'',
            keywords:'',

        };
    },
    props: ['taskId', 'heading'],
    computed: {
        statusVariant() {
            const statusColor = {
                1: "light-warning",
                2: "light-info",
                3: "light-success",
                4: "light-info",
                5: "light-info",
            };

            return (status) => statusColor[status];
        },
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key };
                });
        },

    },

    mounted() {
        this.getVacancyById();

    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        async getVacancyById() {
            const ReqData = {
                id: this.$route.params.id,
            };
            const response = await ServiceApi.GetVacancyById(ReqData);
            if (response.code == '200') {
                this.title = response.data.title;
                //this.qualification = response.data.qualification;
                this.short_description = response.data.short_description;
                this.description = response.data.description;
                this.package = response.data.package;
                this.no_of_vacancies = response.data.no_of_vacancies;
                this.start_date = response.data.start_date;
                this.end_date = response.data.end_date;
                this.location = response.data.location;
                this.department = response.data.department;
                this.pass_mark = response.data.pass_mark;
                this.keywords = response.data.keywords;
                if (response.data.priority == 1) {
                    this.priority_name = 'Immediate';

                }
                else {
                    this.priority_name = 'Normal';
                }
                this.priority = { value: response.data.priority, text: this.priority_name };

                if (response.data.job_type == 1) {
                    this.job_type_name = 'Full Time';

                }
                else {
                    this.job_type_name = 'Part Time';
                }
                this.job_type = { value: response.data.job_type, text: this.job_type_name };

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }

        },


    }
};

</script>
<style>
.p-dot {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

ul {
    padding-left: 0px;
    list-style-type: unset;
    margin-bottom: 0;
}</style>