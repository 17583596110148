<template>
    <div>
        <br>
        <Breadcrumbs main="Report" title="Monthly Report" />

        <div class="container-fluid">
            
            <div class="row">
                <div class="confetti-container" v-if="holidayefft">
      <div class="confetti">
        <i style="--speed: 10; --bg: yellow" class="square"></i>
        <i style="--speed: 18; --bg: white" class="pentagram"></i>
        <i style="--speed: 29; --bg: green" class="rectangle"></i>
        <i style="--speed: 17; --bg: blue" class="hexagram"></i>
        <i style="--speed: 33; --bg: red" class="pentagram"></i>
        <i style="--speed: 26; --bg: yellow" class="dodecagram"></i>
        <i style="--speed: 24; --bg: pink" class="wavy-line"> </i>
        <i style="--speed: 5; --bg: blue" class="wavy-line"></i>
        <i style="--speed: 40; --bg: white" class="square"></i>
        <i style="--speed: 17; --bg: green" class="rectangle"></i>
        <i style="--speed: 25; --bg: white" class="square"></i>
        <i style="--speed: 18; --bg: green" class="rectangle"></i>
        <i style="--speed: 15; --bg: yellow" class="wavy-line"> </i>
        <i style="--speed: 32; --bg: yellow" class="pentagram"></i>
        <i style="--speed: 25; --bg: white" class="square"></i>
        <i style="--speed: 18; --bg: green" class="rectangle"></i>
        <i style="--speed: 37; --bg: yellow" class="dodecagram"></i>
        <i style="--speed: 23; --bg: pink" class="wavy-line"></i>
        <i style="--speed: 37; --bg: red" class="dodecagram"></i>
        <i style="--speed: 37; --bg: pink" class="wavy-line"></i>
        <i style="--speed: 36; --bg: white" class="hexagram"></i>
        <i style="--speed: 32; --bg: green" class="wavy-line"></i>
        <i style="--speed: 32; --bg: yellow" class="pentagram"></i>
        <i style="--speed: 29; --bg: white" class="square"></i>
        <i style="--speed: 18; --bg: green" class="rectangle"></i>
        <i style="--speed: 37; --bg: red" class="dodecagram"></i>
        <i style="--speed: 23; --bg: pink" class="wavy-line"> </i>
        <i style="--speed: 30; --bg: pink" class="rectangle"></i>
        <i style="--speed: 30; --bg: red" class="square"></i>
        <i style="--speed: 18; --bg: red" class="pentagram"></i>
        <i style="--speed: 19; --bg: green" class="rectangle"></i>
        <i style="--speed: 16; --bg: blue" class="hexagram"></i>
        <i style="--speed: 23; --bg: red" class="pentagram"></i>
        <i style="--speed: 34; --bg: yellow" class="dodecagram"></i>
        <i style="--speed: 39; --bg: pink" class="wavy-line"></i>
        <i style="--speed: 40; --bg: purple" class="square"></i>
        <i style="--speed: 21; --bg: green" class="rectangle"></i>
        <i style="--speed: 14; --bg: white" class="square"></i>
        <i style="--speed: 38; --bg: green" class="rectangle"></i>
        <i style="--speed: 19; --bg: red" class="dodecagram"></i>
        <i style="--speed: 29; --bg: pink" class="wavy-line"> </i>
        <i style="--speed: 21; --bg: white" class="hexagram"></i>
        <i style="--speed: 17; --bg: purple" class="wavy-line"></i>
        <i style="--speed: 32; --bg: yellow" class="pentagram"></i>
        <i style="--speed: 23; --bg: white" class="square"></i>
        <i style="--speed: 18; --bg: green" class="rectangle"></i>
        <i style="--speed: 37; --bg: red" class="dodecagram"></i>
        <i style="--speed: 48; --bg: pink" class="wavy-line"> </i>
        <i style="--speed: 38; --bg: pink" class="rectangle"></i>
        <i style="--speed: 13; --bg: red" class="pentagram"></i>
        <i style="--speed: 49; --bg: yellow" class="dodecagram"></i>
        <i style="--speed: 19; --bg: cyan" class="wavy-line"></i>
        <i style="--speed: 15; --bg: steelblue" class="square"></i>
        <i style="--speed: 10; --bg: yellow" class="square"></i>
        <i style="--speed: 18; --bg: white" class="pentagram"></i>
        <i style="--speed: 29; --bg: green" class="rectangle"></i>
        <i style="--speed: 17; --bg: blue" class="hexagram"></i>
        <i style="--speed: 33; --bg: red" class="pentagram"></i>
        <i style="--speed: 26; --bg: yellow" class="dodecagram"></i>
        <i style="--speed: 24; --bg: pink" class="wavy-line"> </i>
        <i style="--speed: 5; --bg: white" class="wavy-line"></i>
        <i style="--speed: 40; --bg: purple" class="square"></i>
        <i style="--speed: 17; --bg: green" class="rectangle"></i>
        <i style="--speed: 25; --bg: white" class="square"></i>
        <i style="--speed: 18; --bg: green" class="rectangle"></i>
        <i style="--speed: 15; --bg: cyan" class="wavy-line"> </i>
        <i style="--speed: 32; --bg: yellow" class="pentagram"></i>
        <i style="--speed: 45; --bg: white" class="square"></i>
        <i style="--speed: 18; --bg: green" class="rectangle"></i>
        <i style="--speed: 37; --bg: red" class="dodecagram"></i>
        <i style="--speed: 23; --bg: pink" class="wavy-line"> </i>
        <i style="--speed: 37; --bg: red" class="dodecagram"></i>
        <i style="--speed: 37; --bg: pink" class="wavy-line"> </i>
        <i style="--speed: 26; --bg: white" class="hexagram"></i>
        <i style="--speed: 32; --bg: cyan" class="wavy-line"></i>
        <i style="--speed: 32; --bg: yellow" class="pentagram"></i>
        <i style="--speed: 45; --bg: white" class="square"></i>
        <i style="--speed: 18; --bg: green" class="rectangle"></i>
        <i style="--speed: 37; --bg: red" class="dodecagram"></i>
        <i style="--speed: 23; --bg: pink" class="wavy-line"> </i>
        <i style="--speed: 50; --bg: pink" class="rectangle"></i>
        <i style="--speed: 30; --bg: red" class="square"></i>
        <i style="--speed: 18; --bg: red" class="pentagram"></i>
        <i style="--speed: 19; --bg: green" class="rectangle"></i>
        <i style="--speed: 16; --bg: blue" class="hexagram"></i>
        <i style="--speed: 23; --bg: red" class="pentagram"></i>
        <i style="--speed: 33; --bg: yellow" class="dodecagram"></i>
        <i style="--speed: 39; --bg: white" class="wavy-line"></i>
        <i style="--speed: 40; --bg: orange" class="square"></i>
        <i style="--speed: 21; --bg: green" class="rectangle"></i>
        <i style="--speed: 14; --bg: white" class="square"></i>
        <i style="--speed: 38; --bg: green" class="rectangle"></i>
        <i style="--speed: 19; --bg: red" class="dodecagram"></i>
        <i style="--speed: 29; --bg: pink" class="wavy-line"> </i>
        <i style="--speed: 34; --bg: white" class="hexagram"></i>
        <i style="--speed: 17; --bg: indigo" class="wavy-line"></i>
        <i style="--speed: 32; --bg: yellow" class="pentagram"></i>
        <i style="--speed: 23; --bg: white" class="square"></i>
        <i style="--speed: 18; --bg: green" class="rectangle"></i>
        <i style="--speed: 37; --bg: red" class="dodecagram"></i>
        <i style="--speed: 48; --bg: pink" class="wavy-line"> </i>
        <i style="--speed: 38; --bg: pink" class="rectangle"></i>
        <i style="--speed: 13; --bg: red" class="pentagram"></i>
        <i style="--speed: 49; --bg: yellow" class="dodecagram"></i>
        <i style="--speed: 19; --bg: purple" class="wavy-line"></i>
        <i style="--speed: 15; --bg: cyan" class="square"></i>
      </div>
    </div>
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <!-- <p  @click="goBack()">
                                <b-avatar class="cust-color" variant="warning" size="2.5em">
                                                                    <feather type="arrow-left"></feather>
                                                                </b-avatar></p> -->
                                
                                <b-col xl="3">
                                    <!-- <b-form-input type="month" value="2015-01" v-model="month"></b-form-input> -->
                                    <datepicker  :minimumView="'month'" input-class="datepicker-here form-control digits"
                                        v-model="month"></datepicker>
                                </b-col>
                                <b-col xl="3">
                                    <v-select v-model="to_users" label="name" placeholder="Users" :options="users"
                                        :multiple="false" />
                                </b-col>
                                <b-col xl="3" class="load-style">
                                    <b-button id="default-primary" v-if="!isLoading" variant="primary" @click="getTimeReport()">
                                        View
                                    </b-button>
                                    <div class="loader-box " v-else>
                                        <div class="loader-15"></div>
                                    </div>
                                </b-col>
                                <!-- <b-col xl="3" class="load-style1">

                                    <b-button v-if="!isLoading1" id="default-primary" variant="primary"
                                        @click="downloadExcel()" class="pull-right">
                                        Download
                                    </b-button>
                                    <div class="loader-box box12" v-else>
                                        <div class="loader-15"></div>
                                    </div>
                                </b-col> -->
                            </b-row>

                        </div>


                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <b-col xl="8">
                                    <FullCalendar ref="fullCalendar" :options="calendarOptions" />

                                </b-col>
                                <b-col xl="4">
                                    <br>
                                    <br>
                                    <br>
                                    <!-- {{ selectMonth }} -->

                                    <!-- {{ MonthlySummary }} -->
                                    <div class="card" v-if="selectMonth ">
                                        <div class="card-header">
                                            <h5 class="">Punching Details ( {{selectMonth}} )</h5>
                                        </div>
                                        <div class="card-body" v-if="selectData.length > 0 && selectData[0].data">

                                            <div class="appointment-table table-responsive">
                                                <table class="table table-bordernone">
                                                    <tbody>
                                                        <tr>
                                                            <td class="img-content-box">Punch-in</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">{{selectData[0].data.in}}</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="img-content-box">Punch-out</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">{{selectData[0].data.out}}</p>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="selectData[0].data.late_in > 0" >
                                                            <td class="img-content-box">Late Punch-in duration</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">{{secondsToHMS(selectData[0].data.late_in)}}</p>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="selectData[0].data.late_in == 0" >
                                                            <td class="img-content-box">Late Punch-in duration</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">00H 00M 00S</p>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="selectData[0].data.early_out > 0">
                                                            <td class="img-content-box">Early Punch-out duration</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">{{secondsToHMS(selectData[0].data.early_out)}}</p>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="selectData[0].data.early_out  ==  0">
                                                            <td class="img-content-box">Early Punch-out duration</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">00H 00M 00S</p>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="selectData[0].data.in && selectData[0].data.out">
                                                            <td class="img-content-box">Work duration</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">{{calculateTimeDuration(selectData[0].data.in,selectData[0].data.out)}}</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <div v-if="selectData.length > 0 && selectData[0].data && selectData[0].tag == true" class="card-footer">
                                            <span>If you arrive late or leave early, your half-day will be automatically designated as LOP (Loss of Pay) since it exceeds the buffer time.</span>
                                        </div>

                                        <div class="card-body" v-if="selectData.length > 0 && selectData[0].title == 'WO'">
                                            <h5 class="text-center">Week Off</h5>
                                        </div>
                                        <div class="card-body" v-if="(selectData && selectData.length > 0) && (selectData[0].title == 'OS' || selectData[0].title == 'OT')">
                                            <h5 v-if="selectData[0].title == 'OS'" class="text-center">Full day on site </h5>
                                            <h5 v-if="selectData[0].title == 'OT'" class="text-center">Full day over time </h5>
                                        </div>
                                        <div class="card-body" v-if="(selectData && selectData.length > 0) && ( selectData[0].title == 'OS/WO' || selectData[0].title == 'OT/WO')">
                                            <h5 v-if="selectData[0].title == 'OS/WO'" class="text-center">Week off and on site </h5>
                                            <h5 v-if="selectData[0].title == 'OT/WO'" class="text-center">Week off and over time </h5>
                                        </div>
                                        <div class="card-body" v-if="(selectData && selectData.length > 0) && selectData[0].title == 'HD'">
         
                                            <h5 class="text-center">{{ selectData[0].name }}</h5>
                                        </div>
                                        

                                    </div>
                                    <div class="card" v-if="MonthlySummary.data == true">
                                        <div class="card-header">
                                            <h5 class="">Monthly Summary</h5>
                                        </div>
                                        <div class="card-body">

                                            <div class="appointment-table table-responsive">
                                                <table class="table table-bordernone">
                                                    <tbody>
                                                        <tr>
                                                            <td class="img-content-box">Total Late Punch-in duration</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">{{MonthlySummary.late}}</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="img-content-box">Total Early Punch-out duration</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">{{MonthlySummary.early}}</p>
                                                            </td>
                                                        </tr>
                                                        <!-- <tr>
                                                            <td class="img-content-box">Total OD Duration</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">{{MonthlySummary.od}}</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="img-content-box">Total OT Duration</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">{{MonthlySummary.ot}}</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="img-content-box">Total Work Duration</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">{{MonthlySummary.work}}</p>
                                                            </td>
                                                        </tr> -->

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="card">
                                        <div class="card-body">

                                            <div class="appointment-table table-responsive">
                                                <table class="table table-bordernone">
                                                    <tbody>
                                                        <tr>
                                                            <td class="img-content-box">WO</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">WEEK OFF</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="img-content-box">P</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">PRESENT</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="img-content-box">OS</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">PRESENT</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="img-content-box">OT</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">PRESENT</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="img-content-box">HP</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">HALF-DAY PRESENT</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="img-content-box">HD</td>
                                                            <td class="text-end">
                                                                <p class="m-0 font-success">HOLIDAY</p>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="card-body pt-0">

                                    </div>

                                </b-col>
                            </b-row>


                        </div>
                    </div>
                </div>


            </div>
        </div>


    </div>
</template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';


import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'


export default {
    components: {
        vSelect,
        Datepicker,
        FullCalendar
    },
    data() {

        return {
            holidayefft:false,

            month: "",
            selectMonth: '',
            users: [],
            isLoading1: false,
            to_users: null,
            isLoading: false,
            selectData:[],
            MonthlySummary: {
                data: true,
                late: '00 H:00 M:00 S',
                early: '00 H:00 M:00 S',
                od: '00:00:00',
                ot: '00:00:00',
                work: '00:00:00',
            },

            calendarOptions: {
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin // needed for dateClick
                ],
                headerToolbar: {
                    left: '',
                    center: 'title',
                    right: ''
                },
                initialView: 'dayGridMonth',
                editable: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                weekends: true,
                select: this.handleDateSelect,
                eventClick: this.handleEventClick,
                events: [],
                validRange: {},
                eventContent: this.renderEventContent,
            },


        }
    },

    watch: {
        month(newMonth) {


            const currentMonth = new Date(newMonth)

            // Format the date to "YYYY-MM-DD"
            const formattedDate = `${currentMonth.getFullYear()}-${String(currentMonth.getMonth() + 1).padStart(2, '0')}-${String(currentMonth.getDate()).padStart(2, '0')}`;


            const currentMonths = new Date(formattedDate);

            // Calculate the first day of the next month
            const nextMonth = new Date(currentMonths.getFullYear(), currentMonths.getMonth() + 1, 1);

            // Format the next month as a string (if needed)
            const nextMonthString = `${nextMonth.getFullYear()}-${(nextMonth.getMonth() + 1).toString().padStart(2, '0')}-01`;



            this.handleDateChange(formattedDate, nextMonthString);

        },
    },

    mounted() {
        // this.isLoading = true;
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        if (currentMonth / 10 >= 1) {
            var make_month = currentMonth;
        } else {
            var make_month = "0" + currentMonth;
        }
        this.month = currentYear + "-" + make_month;
        // console.log(this.month);
        // this.generateReport();
        this.retrieveEmployees();


        const currentMonths = new Date(this.month);

        // Calculate the first day of the next month
        const nextMonth = new Date(currentMonths.getFullYear(), currentMonths.getMonth() + 1, 1);

        // Format the next month as a string (if needed)
        const nextMonthString = `${nextMonth.getFullYear()}-${(nextMonth.getMonth() + 1).toString().padStart(2, '0')}-01`;

        this.calendarOptions.validRange = {
            start: this.month + '-01',
            end: nextMonthString
        };

        this.fetchData();



    },
    methods: {
        calculateTimeDuration(dateString1,dateString2) {
            // Convert strings to Date objects
            var date1 = new Date(dateString1);
            var date2 = new Date(dateString2);

            // Calculate the time duration in milliseconds
            var durationInMilliseconds = date2 - date1;

            // Convert the duration to hours, minutes, and seconds
            var millisecondsInSecond = 1000;
            var millisecondsInMinute = millisecondsInSecond * 60;
            var millisecondsInHour = millisecondsInMinute * 60;

            var hours = Math.floor(durationInMilliseconds / millisecondsInHour);
            var minutes = Math.floor((durationInMilliseconds % millisecondsInHour) / millisecondsInMinute);
            var seconds = Math.floor((durationInMilliseconds % millisecondsInMinute) / millisecondsInSecond);


            return hours + " hours, " + minutes + " minutes, " + seconds + " seconds";

            },
        async retrieveEmployees() {
            const response = await Service.GetEmployeesForReport();
            if (response.code == "200") {
                this.users = response.data

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getTimeReport() {
            this.isLoading = true;
            this.selectData = [];
            console.log(this.selectData);
            var users;

            if (this.to_users == null) {
                this.$toasted.show('Please select employee', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                return false;
            } else {
                users = this.to_users.id;
            }

            const dateObj = new Date(this.month);
            const year = dateObj.getUTCFullYear();
            const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const formattedDate = `${year}-${month}`;
            this.month = formattedDate;

            const reqData = {
                month: this.month,
                users: users
            }

            const response = await Service.getTimeReportApi(reqData);
            if (response.code == "200") {
                this.calendarOptions.events = response.data

                this.isLoading = false;

                this.MonthlySummary.late = this.secondsToHMS(response.summary.late);
                this.MonthlySummary.early = this.secondsToHMS(response.summary.early);
               



            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        secondsToHMS(seconds) {
            var hours = Math.floor(seconds / 3600);
            var minutes = Math.floor((seconds % 3600) / 60);
            var remainingSeconds = seconds % 60;

            // Add leading zero if the value is less than 10
            hours = hours < 10 ? '0' + hours : hours;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            remainingSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

            return hours + ' H ' + minutes + ' M ' + remainingSeconds + ' S';
        },
        renderEventContent(arg) {

           

            const tag = arg.event.extendedProps.tag;

            if (tag != undefined) {
                return {
                html: `<div class="event-content">
                    <img class="event-tag" src="https://bktqaptive.s3.amazonaws.com/tag.png" alt="Tag Image">
                <div class="event-text">${arg.event.title}</div>
              </div>`,
            };
            }else{
                return {
                html: `<div class="event-content">
                    <div style="font-size: 15px;color: black;text-align: center;font-weight: 900;margin: 30px; cursor: default;">${arg.event.title}</div>
              </div>`,
            };
            }

            
        },

        handleDateSelect(selectInfo) {
           
            this.selectMonth = selectInfo.startStr;
         

            var data = this.calendarOptions.events.filter(item => item.start === this.selectMonth);

       

            if (data.length > 0) {
                this.selectData = data;
            }else{
                this.selectData.length = 0;
            }            


            if (this.selectData[0]) {

                if (this.selectData[0].title == 'HD') {

                this.holidayefft = true;

                }else{
                this.holidayefft = false;

                }
            }


        },
        handleDateChange(start, end) {



            this.calendarOptions.validRange = {
                start: start,
                end: end
            };

        },
        goBack() {
        if (window.history.state.back === '/reset-password') {
            this.$router.push({ name: 'Home' })
        } else {
            this.$router.go(-1)
        }
        },
            fetchData() {

            // this.calendarOptions.events = [
            //     {
            //         title: 'P',
            //         title2: 'Event 1',
            //         start: '2023-11-29',
            //         display: 'background',
            //         color: '#8fdf82'
            //     },
            //     {
            //         title: 'HP',
            //         start: '2023-11-30',
            //         display: 'background',
            //         color: '#ffdf3b'
            //     },
            // ];

        }
    }



}
</script>
<style>.fc-h-event {
    background-color: #00ffff00 !important;
    border: 0px !important;
    display: block;
}

.fc-daygrid-body td {
    height: 110px !important;
}

.event-tag {
  width: 25px; /* Adjust the width of the image */
  height: 25px; /* Adjust the height of the image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 5px;
  
}
.event-text{
    font-size: 15px;color: black;text-align: center;font-weight: 900;
}



.appointment-table table tr td p, .appointment-table table tr td .btn {
    font-weight: 700;
    font-size: 15px;
}






.confetti-container {
  user-select: none;
  z-index: 10;
}
.confetti {
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  /* width: 600px; */
  /* height: 600px; */
  /* overflow: hidden; */
}

.confetti .square {
  width: 1rem;
  height: 1rem;
  background-color: var(--bg);
  transform: rotate(-140deg);
}

.confetti .rectangle {
  width: 1rem;
  height: 0.5rem;
  background-color: var(--bg);
}

.confetti .hexagram {
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 1rem solid var(--bg);
  position: relative;
}

.confetti .hexagram:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 1rem solid var(--bg);
  position: absolute;
  top: 0.33rem;
  left: -0.5rem;
}

.confetti .pentagram {
  width: 0rem;
  height: 0rem;
  display: block;
  margin: 0.5rem 0;
  border-right: 1rem solid transparent;
  border-bottom: 0.7rem solid var(--bg);
  border-left: 1rem solid transparent;
  transform: rotate(35deg);
  position: relative;
}
.confetti .pentagram:before {
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-bottom: 0.8rem solid var(--bg);
  border-left: 0.3rem solid transparent;
  border-right: 0.3rem solid transparent;
  transform: rotate(-35deg);
  position: absolute;
  top: -0.45rem;
  left: -0.65rem;
}
.confetti .pentagram:after {
  content: "";
  width: 0rem;
  height: 0rem;
  display: block;
  border-right: 1rem solid transparent;
  border-bottom: 0.7rem solid var(--bg);
  border-left: 1rem solid transparent;
  transform: rotate(-70deg);
  position: absolute;
  top: 0.03rem;
  left: -1.05rem;
}

.confetti .dodecagram {
  background: var(--bg);
  width: 0.8rem;
  height: 0.8rem;
  position: relative;
}

.confetti .dodecagram:before {
  content: "";
  height: 0.8rem;
  width: 0.8rem;
  background: var(--bg);
  transform: rotate(30deg);
  position: absolute;
  top: 0;
  left: 0;
}
.confetti .dodecagram:after {
  content: "";
  height: 0.8rem;
  width: 0.8rem;
  background: var(--bg);
  transform: rotate(60deg);
  position: absolute;
  top: 0;
  left: 0;
}

.confetti .wavy-line {
  position: relative;
}
.confetti .wavy-line::after,
.confetti .wavy-line::before {
  content: "";
  height: 1rem;
  width: 8rem;
  background-size: 2rem 1rem;
  position: absolute;
  left: -9rem;
  transform: rotate(90deg);
}

.confetti .wavy-line::before {
  background-image: linear-gradient(
    45deg,
    transparent,
    transparent 50%,
    var(--bg) 50%,
    transparent 60%
  );
  top: 1rem;
}
.confetti .wavy-line::after {
  background-image: linear-gradient(
    -45deg,
    transparent,
    transparent 50%,
    var(--bg) 50%,
    transparent 60%
  );
}

.confetti i {
  width: 3rem;
  height: 3rem;
  margin: 0 0.2rem;
  animation-name: confetti;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: calc(70s / var(--speed));
}

.confetti i:nth-child(even) {
  transform: rotate(90deg);
}

@keyframes confetti {
  0% {
    transform: translateY(-100vh);
  }

  100% {
    transform: translateY(100vh);
  }
}


</style>