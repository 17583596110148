<template><!-- <div class="container-fluid"> -->
    <div class="row">
        <div class="col call-chat-sidebar col-sm-12">
            <div class="card">
                <div class="card-body chat-body">
                    <div class="chat-box">
                        <!-- Chat left side Start-->
                        <div class="chat-left-aside">
                            <div class="media">
                                <b-button v-b-modal.modal-6 id="default-primary" variant="primary"
                                    style="margin: 0 auto;">Add
                                    Intimation topic
                                </b-button>
                            </div>
                            <div class="people-list custom-scrollbar" id="people-list">
                                <!-- <div class="search">
                                        <form class="theme-form">
                                            <div class="form-group">
                                                <input v-model="search" v-on:keyup="" class="form-control" type="text"
                                                    placeholder="search" /><i class="fa fa-search"></i>
                                            </div>
                                        </form>
                                    </div> -->
                                <ul class="list" v-if="search == ''">
                                    <div class="clearfix chat-lists" v-for="(intimation, index) in activeIntimations"
                                        :key="intimation.id" @click="setActiveuser(intimation.id, intimation)"
                                        :class="{ 'selected': intimation.selected }">

                                        <li @click="selectItem(intimation)">
                                            <div>
                                                <img class="rounded-circle user-image" src="@/assets/group.png" alt="" />

                                                <span class="font-danger away rounded-circle">
                                                    {{ intimation.newMsg ? intimation.newMsg : '' }}
                                                </span>

                                                <div class="about">
                                                    <div class="name">{{ intimation.subject }}</div>
                                                    <!-- <div class="status">{{ intimation.text }}</div> -->
                                                </div>

                                            </div>
                                        </li>
                                    </div>
                                </ul>
                                <!-- <ul class="list" v-if="search != ''">
                                        <li class="clearfix" v-for="(user, index) in activeIntimations" :key="index"
                                            @click="setActiveuserSerch(user.id)">
                                            <img class="rounded-circle user-image" alt="" />
                                            <div class="status-circle away"></div>
                                            <div class="about">
                                                <div class="name">{{ user.subject }}</div>
                                                <div class="status">{{ user.text }}</div>
                                            </div>
                                        </li>
                                        <div v-if="!activeIntimations.length">
                                            <div class="search-not-found chat-search text-center">
                                                <div>
                                                    
                                                    <p>
                                                        Sorry, We didn't find any results matching this
                                                        search
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </ul> -->
                            </div>
                        </div>
                        <!-- Chat left side Ends-->
                    </div>
                </div>
            </div>
        </div>
        <div class="col call-chat-body">
            <div class="card">
                <div class="card-body p-0">
                    <div class="row chat-box">
                        <!-- Chat right side start-->
                        <div class="col pr-0 chat-right-aside">
                            <!-- chat start-->
                            <div class="chat">
                                <!-- chat-header start-->
                                <div class="chat-header clearfix">
                                    <!-- <img class="rounded-circle" v-if="currentchat.thumb" alt="" /> -->
                                    <div class="about">
                                        <div class="name">
                                            {{
                                                currentchat ? currentchat.sub : ''
                                            }}<!--<span class="font-primary f-12">Typing...</span>-->
                                        </div>
                                        <div class="status digits">
                                            {{ currentchat ? currentchat.body : '' }}
                                        </div>
                                    </div>
                                    <ul class="list-inline float-left float-sm-right chat-menu-icons">
                                        <!-- <li class="list-inline-item">
                                                    <a href="#"><i class="icon-search"></i></a>
                                                </li> -->
                                        <li class="list-inline-item">
                                            <a v-b-modal.mod-7 title="Add Members"><i class="fa fa-plus-square"></i></a >
                                        </li>
                                        <!-- <li class="list-inline-item">
                                                    <a href="#"><i class="icon-headphone-alt"></i></a>
                                                </li>
                                                <li class="list-inline-item">
                                                    <a href="#"><i class="icon-video-camera"></i></a>
                                                </li>-->
                                        <li class="list-inline-item toogle-bar"
                                            v-on:click="chatmenutoogle = !chatmenutoogle">
                                            <a href="#"><i class="icon-menu"></i></a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- chat-header end-->
                                <div class="chat-history chat-msg-box custom-scrollbar">
                                    <ul>
                                        <li v-for="(chat, index) in currentMsg" :key="index"
                                            v-bind:class="{ clearfix: chat.myself == true }">
                                            <div class="message" v-bind:class="{
                                                'other-message pull-right': chat.myself == true,
                                                'my-message': chat.myself != true,
                                            }">

                                                <b class="float-left" alt="" v-if="chat.myself != true">{{
                                                    chat.mem
                                                }}</b>
                                                <!-- <b class="float-right " alt=""
                                                        v-if="chat.sender == 0">{{ chat.mem }}</b> -->
                                                <div class="message-data"
                                                    v-bind:class="{ 'text-right': chat.myself == true }"><br />
                                                    <span class="message-data-time">
                                                        <!-- {{ chat.time | moment("DD-MM-YYYY HH:MM") }} -->
                                                        {{
                                                            chat.time
                                                        }}
                                                    </span>
                                                </div>
                                                {{ chat.content }}
                                            </div>
                                        </li>
                                        <!-- <div v-if="!currentMsg.length">
                                                <div class="image-not-found">
                                                    <div class="d-block start-conversion">
                                                       
                                                    </div>
                                                </div>
                                            </div> -->
                                    </ul>
                                </div>
                                <!-- end chat-history-->
                                <div class="chat-message clearfix">
                                    <div class="row">
                                        <div class="col-xl-12 d-flex">

                                            <div class="input-group text-box">
                                                <input class="form-control input-txt-bx" id="message-to-send"
                                                    v-model="chatText" v-on:keyup.enter="addChat()" type="text"
                                                    name="message-to-send" placeholder="Type a message......" />
                                                <div class="input-group-append">
                                                    <button @click="addChat()" class="btn btn-primary" type="button">
                                                        SEND
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end chat-message-->
                                <!-- chat end-->
                                <!-- Chat right side ends-->
                            </div>
                        </div>

                        <div class="col pl-0 chat-menu custom-scrollbar" :class="{ show: chatmenutoogle }" v-if="showMem">

                            <b-tabs nav-class="tabbed-card border-tab border-tab-primary custom-scrollbar">

                                <b-tab title="Members" active>

                                    <div class="people-list">
                                        <ul class="list digits custom-scrollbar">
                                            <div class="clearfix" v-for="(user, index) in members" :key="index">
                                                <hr>
                                                <li>
                                                    <img v-if="user.profilePicture"
                                                        class="rounded-circle user-image sid-img" :src='user.profilePicture'
                                                        alt="@/assets/member.png" />
                                                    <!-- <img v-else class="rounded-circle user-image sid-img" src='@/assets/member.png' alt="user" /> -->
                                                    <div class="about">
                                                        <div class="name">{{ user.name }}</div>
                                                        <div class="status">
                                                            {{
                                                                user.lastSeenDate
                                                            }}
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                        </ul>

                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-6" v-model="modalShow" hide-footer>
            <div class="row">
                <div class="col-12">
                    <form>
                        <label class="col-form-label" for="message-text">Subject:</label>
                        <input class="form-control" type="text" placeholder="Subject" v-model="subject">
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <form>
                        <label class="form-label">Members</label>
                        <v-select v-model="to_users" label="name" :options="users" :multiple="true" />
                    </form>
                </div>
            </div>
            <!-- <div class="row">
                    <div class="col-12">
                        <form>
                            <label class="col-form-label" for="message-text">Body:</label>
                            <b-form-textarea id="textarea" v-model="text" placeholder="Enter Description"
                                rows="3"></b-form-textarea>
                        </form>
                    </div>
                </div> -->
            <div class="row">
                <div class="col-6">
                    <b-button class="mt-3 " variant="danger" @click="hideModal">Close</b-button>
                </div>
                <div class="col-6">
                    <b-button class="mt-3 float-right" variant="primary" @click="handleSubmit()">Save
                        Intimation</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="mod-7" v-model="modal2Show" hide-footer>
            <div class="row">
                <!-- <h5 class="text-center" style="width: 100%;">Add members to chat</h5> -->
            </div>
            <div class="row">
                <div class="col-12">
                    <form>
                        <label class="form-label">Members</label>
                        <v-select v-model="addUsers" label="name" :options="ex_users" :multiple="true" />
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <b-button class="mt-3 " variant="danger" @click="hide2Modal">Close</b-button>
                </div>
                <div class="col-6">
                    <b-button class="mt-3 float-right" variant="primary" @click="addExtraEmp()">Add
                        Members</b-button>
                </div>
            </div>
        </b-modal>
    </div>
<!-- </div> --></template>

<script>
import Service from '@/services/Service.js';
import vSelect from 'vue-select'

export default {
    name: 'Chat',
    components: {
        vSelect,
    },
    data() {
        return {
            text: '',
            users: [],
            search: '',
            subject: '',
            addUsers: [],
            members: [],
            intervalId: '',
            chatText: '',
            to_users: [],
            ex_users: [],
            currentMsg: [],
            currentchat: [],
            modalShow: false,
            modal2Show: false,
            intimation_id: null,
            activeIntimations: [],
            chatmenutoogle: false,
            isActive: false,
            showMem: true,
        };
    },
    computed: {

    },
    mounted() {
        // var container = this.$el.querySelector('.chat-history');
        // container.scrollTop = container.scrollHeight;
    },
    created() {

        this.retrieveEmployees();
        this.getFewMsg();

        this.intervalId = setInterval(() => {
            this.getFewMsg();
        }, 15000);

    },
    beforeDestroy() {
        clearInterval(this.intervalId)
    },
    methods: {
        hideModal() {
            this.modalShow = false;
        },
        hide2Modal() {
            this.modal2Show = false;
        },
        async addExtraEmp() {
            if (this.addUsers.length == 0) {
                this.$toasted.show('Please select members', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                const paramData = {
                    users: this.addUsers,
                    inti_id: this.intimation_id,
                };

                const response = await Service.AddExtraMem(paramData);
                if (response.code == '200') {
                    this.modal2Show = false;
                    this.addUsers = [];
                    this.$toasted.show(' Members added successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                    this.retrieveEmployees();
                    this.getIntimations();
                }
            }
        },
        async handleSubmit() {
            if ((this.subject == '') || (this.to_users.length == 0)) {
                this.$toasted.show('Please enter required fields', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {
                const ReqData = {
                    subject: this.subject,
                    users: this.to_users,
                    body: this.text,
                };
                const response = await Service.AddMessage(ReqData);
                if (response.code == '200') {
                    this.modalShow = false;
                    this.subject = '';
                    this.to_users = [];
                    this.text = '';
                    this.retrieveEmployees();
                    this.getIntimations();
                } else if (response.code == '400') {
                    this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                }
            }
        },
        async retrieveEmployees() {
            const response = await Service.GetEmployeeMsg();
            if (response.code == "200") {
                this.users = response.data

            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getIntimations() {
            const response = await Service.GetIntimations();
            if (response.code == "200") {
                var newIntimation = response.data;
                newIntimation.forEach(element => {
                    this.NewMsg.forEach(element2 => {
                        if (element.id == element2.intimation.id) {
                            element.newMsg = element2.chat.length;
                        }
                    });
                });
                this.activeIntimations = newIntimation;
                this.setActiveuser(this.intimation_id);
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
        },
        async getFewMsg() {
            const response = await Service.GetMessages();
            if (response.code == "200") {
                this.NewMsg = response.data;
                this.getIntimations()
            }
        },
        async addChat() {
            if (this.chatText != '') {
                if (this.intimation_id == null) {
                    this.$toasted.show('Select a Chat Room', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                } else {
                    const req_data = {
                        inti_id: this.intimation_id,
                        msg: this.chatText,
                    }
                    const response = await Service.sendMessage(req_data);
                    if (response.code == "200") {
                        this.chatText = '';
                        var container = this.$el.querySelector('.chat-history');
                        setTimeout(function () {
                            container.scrollBy({ top: 200, behavior: 'smooth' });
                        }, 310);
                        setTimeout(function () {
                            container.scrollBy({ top: 200, behavior: 'smooth' });
                        }, 1100);
                        this.setActiveuser(response.data);
                    } else if (response.code == "403") {
                        this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                        localStorage.removeItem('loginDetails')
                        localStorage.removeItem('menuData')
                        this.$router.push('/');
                    }
                }
            } else {
                this.$toasted.show('Type a message', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            }
        },
        selectItem(item) {
            this.activeIntimations.forEach(i => {
                i.selected = false;
            });
            item.selected = true;
        },
        async setActiveuser(id, item = []) {
            var container = this.$el.querySelector('.chat-history');
            setTimeout(function () {
                container.scrollBy({ top: container.scrollHeight, behavior: 'auto' });
            }, 100);
            setTimeout(function () {
                container.scrollBy({ top: container.scrollHeight, behavior: 'auto' });
            }, 50);
            // this.isActive =  true;
            this.selectItem(item);
            const req = {
                inti_id: id,
            }
            const response = await Service.GetIntimationsById(req);
            if (response.code == "200") {
                this.members = response.data.members;
                this.currentMsg = response.data.chat;
                this.currentchat = response.data.curr_inti
                if (this.members) {
                    var forMem = [];
                    this.members.forEach(element => {
                        forMem.push({ 'id': element.id, 'name': element.name })
                    });
                    this.ex_users = this.users.filter(obj2 => !forMem.some(obj1 => obj1.name === obj2.name));
                }
            } else if (response.code == "403") {
                this.$toasted.show('Token expired please login again', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                localStorage.removeItem('loginDetails')
                localStorage.removeItem('menuData')
                this.$router.push('/');
            }
            this.intimation_id = id;
        },

    },
};
</script>

<style>
.chat-lists {
    padding: 12px 10px;
}

.selected {
    background: #e9e9e9;
    padding: 12px 10px;
}

.call-chat-sidebar {
    max-width: 265px !important;
}

.chat-box .chat-menu {
    max-width: 260px !important;
}

.sid-img {
    width: 31px !important;
    height: 31px !important;
}
</style>