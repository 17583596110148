<template>
    <div>
        <Breadcrumbs main="Leave" title="Apply Leave" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card :actions="false">
                        <div slot="with-padding">
                            <b-form class="needs-validation" @submit="onFormSubmit">
                                <div class="form-row">
                                    <div class="row">

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-label">Start date</label>
                                                <datepicker :format="customFormatter"
                                                    input-class="datepicker-here form-control digits"
                                                    v-model="start_date"></datepicker>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-label">Session</label>
                                                <v-select v-model="session1" label="name" :options="sessions" />
                                            </div>
                                        </div>
                                        <div class="col-md-4"></div>

                                    </div>

                                </div>

                                <div class="form-row">
                                    <div class="row">

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-label">End date</label>
                                                <datepicker :format="customFormatter"
                                                    input-class="datepicker-here form-control digits"
                                                    v-model="end_date"></datepicker>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-label">Session</label>
                                                <v-select v-model="session2" label="name" :options="sessions" />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                        </div>
                                    </div>

                                </div>
                                <div class="form-row">

                                    <div class="col-md-4 mb-3">
                                        <label for="c_form">Leave Type</label>
                                        <v-select v-model="leaveType" label="name" :options="leave_types"
                                            v-on:input="setname()" />
                                    </div>
                                    <div class="col-md-4" v-if="this.leave_name == 'sick'">
                                        <label for="file">File</label>
                                        <b-form-file v-model="file" :state="supported_file_state"
                                            placeholder="Choose file..." drop-placeholder="Drop file here..."
                                            type="file" @change="onFileChange"></b-form-file>

                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <label class="form-label">Reason</label>
                                        <textarea class="form-control" rows="4" placeholder="Enter leave reason"
                                            v-model="leave_reason">
                                        </textarea>
                                    </div>
                                </div>
                                <b-button type="submit" variant="primary">Submit</b-button>

                                <div v-if="loader == true" class="loader-box">
                                    <div class="loader-15"></div>
                                </div>

                            </b-form>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Service from '@/services/Service.js';
import ServiceApi from "@/services/ServiceApi.js";
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'
import S3 from "aws-s3";

export default {

    components: {
        vSelect,
        Datepicker,
    },

    data() {
        return {
            loader: false,
            success: false,
            file: null,
            file_name: null,
            fileName: null,
            leave_name: null,
            leave_reason: null,
            user_id: null,
            start_date: null,
            end_date: null,
            start_date_old: null,
            end_date_old: null,
            leaveType: null,
            leave_types: [],
            holidays: [],
            holidays_arr: [],
            duration: null,
            sessions: [
            ],
            session1: null,
            session2: null,
            session1_old: null,
            session2_old: null,
            emp_ref_no: null,
            order_arr: [],
        };
    },
    computed: {
        supported_file_state() {
            if (this.file === null) {
                return false;
            } else {
                return true;
            }
        },
    },
    mounted() {
        this.getLeaveTypes();
        this.getSessions();
        this.getAwsS3Config();
        this.getHolidays();
       

    },
    methods: {
        async getAwsS3Config() {
            const response = await Service.getAwsS3Configs();
            if (response.code == "200") {
                this.s3 = response.data;
            }
        },
        async getLeaveDetailsById(sess_arr) {
            const ReqData = {
                id: this.$route.params.id,
            };
            const response = await Service.GetLeaveById(ReqData);
            if (response.code == "200") {
                this.items = response.data;

                this.user_id = response.data.user_id;
                this.fileName = response.data.fileName
                this.start_date = response.data.start_date;
                this.end_date = response.data.end_date;
                this.leaveType = { name: response.data.type, id: response.data.type_id };
                // this.session1 = { name: response.data.start_session_name, id: response.data.start_session };
                // this.session2 = { name: response.data.end_session_name, id: response.data.end_session };
                this.leave_reason = response.data.reason;
                this.emp_ref_no = response.data.emp_ref_no

                this.start_date_old = response.data.start_date;
                this.end_date_old = response.data.end_date;
                // this.session1_old = { name: response.data.start_session_name, id: response.data.start_session };
                // this.session2_old = { name: response.data.end_session_name, id: response.data.end_session };

                sess_arr.forEach(element => {

                    if(element.id == response.data.start_session){
                        this.session1 =element
                        this.session1_old =element
                    }
                    else if(element.id == response.data.end_session){
                        this.session2 =element
                        this.session2_old =element
                    }
                  
                });
                


            }
        },
        customFormatter(date) {
            return moment(date).format('Y-MM-DD');
        },

        onFormSubmit(evt) {
            evt.preventDefault();

            if ((this.leave_reason == null) || (this.start_date == null) || (this.end_date == null) || (this.leaveType == null)) {

                this.$toasted.show(' Please enter required fields ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else if (this.customFormatter(this.end_date) < this.customFormatter(this.start_date)) {

                this.$toasted.show(' From Date should be less than To Date ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
            } else {

                var from_date = this.customFormatter(this.start_date);
                var to_date = this.customFormatter(this.end_date);



                if (from_date === to_date) {
                    if (this.session1 && this.session2) {
                        if (this.session1.id == this.session2.id) {
                            this.applyLeave();
                        } else {
                            this.$toasted.show('Both sessions should be same', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                        }
                    }
                    else {
                        this.applyLeave();
                    }
                } else {
                    var larger = Math.max(...this.order_arr)
                    var smaller = Math.min(...this.order_arr)

                    
                    if (this.session1 && this.session2) {
                        if ((this.session1.order == larger) && (this.session2.order == smaller)) {
                            this.applyLeave();
                        } else {
                            this.$toasted.show(' Please selec correct sessions ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                        }
                    } else {
                        if (this.session1) {
                            if (this.session1.order != larger) {
                                this.$toasted.show(' Please seect correct sessions ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                            } else {
                                this.applyLeave();
                            }
                        } else if (this.session2) {
                           
                            if (this.session2.order != smaller) {
                                this.$toasted.show(' Please selet correct sessions ', { theme: 'outline', position: 'top-right', type: 'error', duration: 2000 });
                            } else {
                                this.applyLeave();
                            }
                        } else {
                            this.applyLeave();
                        }

                    }
                }
            }


        },

        onFileChange(e) {

            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }

            let file = files[0];
            const now = Date.now();
            const config = {
                bucketName: this.s3.bucketName,
                dirName: "leave_docs" /* optional */,
                region: this.s3.region,
                accessKeyId: this.s3.accessKeyId,
                secretAccessKey: this.s3.secretAccessKey,
                s3Url: "https://bktqaptive.s3.amazonaws.com" /* optional */,
            };

            const S3Client = new S3(config);
            S3Client.uploadFile(file, this.emp_ref_no + now).then((response) => {
                this.file = response.location;
            });

        },

        applyLeave() {

            this.holidays.forEach(dates => {
                var day = moment(dates.date)
                this.holidays_arr.push(day.format('MM/DD/YYYY'));

            });

            // //old month array calc
         
            // var leave_start_date_old = this.start_date_old ? this.start_date_old.split("T")[0] : "";
            // var leave_end_date_old = this.endDate_old ? this.endDate_old.split("T")[0] : "";

            // var date1_old = new Date(this.customFormatter(this.start_date_old));

            // var date2_old = new Date(this.customFormatter(this.endDate_old));

            // var getDaysBetweenDates_old = function (startDate_old, endDate_old) {
            //     var now = startDate_old.clone(), dates = [];

            //     while (now.isSameOrBefore(endDate_old)) {
            //         dates.push(now.format('MM/DD/YYYY'));
            //         now.add(1, 'days');
            //     }
            //     return dates;
            // };

            // var startDate_old = moment(date1_old);
            // var endDate_old = moment(date2_old);

            // var dateList_old = getDaysBetweenDates_old(startDate_old, endDate_old);


            // var date_arr1_old = []
            // var date_arrr_old = []

            // dateList_old.forEach(dates => {
            //     let date = new Date(dates);
            //     let day = date.toLocaleString('en-us', { weekday: 'long' });
            //     if ((day != 'Saturday') && (day != 'Sunday')) {
            //         date_arr1_old.push(dates);
            //     }

            // });
            // date_arrr_old = date_arr1_old.filter(val => !this.holidays_arr.includes(val));

           

            // var result_obj_old = this.getDateDetails_old(dateList_old, leave_start_date_old, leave_end_date_old, this.session1_old, this.session2_old)
            // var result_obj2_old = this.getDateDetails_old(date_arrr_old, leave_start_date_old, leave_end_date_old, this.session1_old, this.session2_old)
          
            // //end



            var date1 = new Date(this.customFormatter(this.start_date));

            var date2 = new Date(this.customFormatter(this.end_date));

            var getDaysBetweenDates = function (startDate, endDate) {
                var now = startDate.clone(), dates = [];

                while (now.isSameOrBefore(endDate)) {
                    dates.push(now.format('MM/DD/YYYY'));
                    now.add(1, 'days');
                }
                return dates;
            };

            var startDate = moment(date1);
            var endDate = moment(date2);

            var dateList = getDaysBetweenDates(startDate, endDate);

            var date_arr1 = []
            var date_arrr = []

            dateList.forEach(dates => {
                let date = new Date(dates);
                let day = date.toLocaleString('en-us', { weekday: 'long' });
                if ((day != 'Saturday') && (day != 'Sunday')) {
                    date_arr1.push(dates);
                }

            });
            date_arrr = date_arr1.filter(val => !this.holidays_arr.includes(val));

            var result_obj = this.getDateDetails(dateList)
            var result_obj2 = this.getDateDetails(date_arrr)

            if(this.file !=null){
                    var filename = this.file;

                }
                else{
                    var filename = this.fileName;
                }


            const leave_data = {
                id: this.$route.params.id,
                employee_id: this.user_id,
                reason: this.leave_reason,
                session1: this.session1 ? this.session1.id : '',
                session2: this.session2 ? this.session2.id : '',
                no_of_days: result_obj.diff_in_days,
                type: this.leaveType.id,
                from_date: this.customFormatter(this.start_date),
                to_date: this.customFormatter(this.end_date),
                days_without_holiday: result_obj2.date_arr_length,
                file: filename,
                month_array: result_obj.month_arr,
                month_array_without: result_obj2.month_arr,

                // no_of_days_old: result_obj_old.diff_in_days,
                // month_array_old: result_obj_old.month_arr,
                // days_without_holiday_old: result_obj2_old.date_arr_length,
                // month_array_without_old: result_obj2_old.month_arr,
            }

            this.editLeave(leave_data);

        },
        getDateDetails(dateList) {
            var date1 = new Date(this.customFormatter(this.start_date));
            var date2 = new Date(this.customFormatter(this.end_date));
            var Difference_In_Time = date2.getTime() - date1.getTime();

            var month_arr = [];
            var month;
            var year;
            var x = 0;
            var result_arr = [];
            dateList.forEach(dates => {
                month = Number(dates.split('/')[0]);
                year = dates.split('/')[2]
                // month_arr.push(month);
                month_arr.push({ month_val: month, year_val: year });

                if (x != dates.split('/')[0]) {
                    result_arr.push(dates.split('/')[0]);
                    x = dates.split('/')[0];
                } else {

                }
            });

            var month_array = []

            month_arr.sort();

            var current = null;
            var cnt = 0;
            for (var i = 0; i < month_arr.length; i++) {
                if (month_arr[i].month_val != current) {

                    if (cnt > 0) {
                        month_array.push({ month: current, value: cnt, year: month_arr[0].year_val });
                    }
                    current = month_arr[i].month_val;
                    cnt = 1;
                } else {
                    cnt++;
                }
            }
            if (cnt > 0) {
                month_array.push({ month: current, value: cnt, year: month_arr[month_arr.length - 1].year_val });
            }

            var date_arr = []
            dateList.forEach(dates => {
                let date = new Date(dates);
                let day = date.toLocaleString('en-us', { weekday: 'long' });
                if ((day != 'Saturday') && (day != 'Sunday')) {
                    date_arr.push(day);
                }

            });

            if (this.session1 && this.session2) {
                var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24));
                var date_arr_length = date_arr.length - 1;
                if (result_arr.length == 2) {
                    month_array[0].value = month_array[0].value - .5;
                    month_array[1].value = month_array[1].value - .5;
                } else if (result_arr.length == 1) {
                    month_array[0].value = month_array[0].value - 1;
                }
            } else if (this.session1 || this.session2) {
                var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24)) + .5;
                var date_arr_length = date_arr.length - .5;
                if (this.session1) {
                    month_array[0].value = month_array[0].value - .5;
                } else if (this.session2) {
                    if (result_arr.length == 2) {
                        month_array[1].value = month_array[1].value - .5;
                    } else if (result_arr.length == 1) {
                        month_array[0].value = month_array[0].value - .5;
                    }
                }
            } else {
                var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24)) + 1;
                var date_arr_length = date_arr.length;
            }
            var obj = { 'date_arr_length': date_arr_length, 'diff_in_days': Difference_In_Days, 'month_arr': month_array };
            return obj;
        },

        getDateDetails_old(dateList, start_date, end_date, session1, session2) {

            var date1 = new Date(this.customFormatter(start_date));
            var date2 = new Date(this.customFormatter(end_date));
            var Difference_In_Time = date2.getTime() - date1.getTime();

            var month_arr = [];
            var month;
            var year;
            var x = 0;
            var result_arr = [];
            dateList.forEach(dates => {
                month = Number(dates.split('/')[0]);
                year = dates.split('/')[2]
                // month_arr.push(month);
                month_arr.push({ month_val: month, year_val: year });

                if (x != dates.split('/')[0]) {
                    result_arr.push(dates.split('/')[0]);
                    x = dates.split('/')[0];
                } else {

                }
            });

            var month_array = []

            month_arr.sort();

            var current = null;
            var cnt = 0;
            for (var i = 0; i < month_arr.length; i++) {
                if (month_arr[i].month_val != current) {

                    if (cnt > 0) {
                        month_array.push({ month: current, value: cnt, year: month_arr[0].year_val });
                    }
                    current = month_arr[i].month_val;
                    cnt = 1;
                } else {
                    cnt++;
                }
            }
            if (cnt > 0) {
                month_array.push({ month: current, value: cnt, year: month_arr[month_arr.length - 1].year_val });
            }

            var date_arr = []
            dateList.forEach(dates => {
                let date = new Date(dates);
                let day = date.toLocaleString('en-us', { weekday: 'long' });
                if ((day != 'Saturday') && (day != 'Sunday')) {
                    date_arr.push(day);
                }

            });

            if (session1 && session2) {
                var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24));
                var date_arr_length = date_arr.length - 1;
                if (result_arr.length == 2) {
                    month_array[0].value = month_array[0].value - .5;
                    month_array[1].value = month_array[1].value - .5;
                } else if (result_arr.length == 1) {
                    month_array[0].value = month_array[0].value - 1;
                }
            } else if (session1 || session2) {
                var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24)) + .5;
                var date_arr_length = date_arr.length - .5;
                if (session1) {
                    month_array[0].value = month_array[0].value - .5;
                } else if (session2) {
                    if (result_arr.length == 2) {
                        month_array[1].value = month_array[1].value - .5;
                    } else if (result_arr.length == 1) {
                        month_array[0].value = month_array[0].value - .5;
                    }
                }
            } else {
                var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24)) + 1;
                var date_arr_length = date_arr.length;
            }
            var obj = { 'date_arr_length': date_arr_length, 'diff_in_days': Difference_In_Days, 'month_arr': month_array };
            return obj;
        },
        async editLeave(leave_data) {
            this.loader = true;
            const response = await ServiceApi.UpdateLeave(leave_data);
            if (response.code == '200') {
                this.loader = false;
                this.$toasted.show(' Leave applied successfully ', { theme: 'outline', position: 'top-right', type: 'success', duration: 2000 });
                this.$router.push('/home/leaves/');

            } else if (response.code == '201') {
                this.loader = false;
                this.$toasted.show(response.message, { theme: 'outline', position: 'top-right', type: 'error', duration: 3000 });
            }


        },
        async getLeaveTypes() {
            const response = await Service.GetLeaveTypes();
            if (response.code == '200') {

                this.leave_types = response.data;
            }
        },
        async getHolidays() {
            const response = await ServiceApi.GetHolidays();
            if (response.code == '200') {

                var date_obj = {};
                var holiday_arr = [];

                response.data.forEach(element => {

                    date_obj = {

                        date: element.date,
                    }
                    holiday_arr.push(date_obj);

                });
                this.holidays = holiday_arr;

            }
        },
        async setname() {
            this.leave_name = this.leaveType.name;
        },
        async getSessions() {
            const response = await ServiceApi.GetSessions();
            if (response.code == '200') {
                var sess_obj = {};
                var sess_arr = [];

                var i = 1;
                response.data.forEach(element => {

                    sess_obj = {
                        id: element.id,
                        name: element.name,
                        order: i,
                    }
                    this.order_arr.push(i);
                    sess_arr.push(sess_obj);
                    i++;
                });
                this.sessions = sess_arr;

                this.getLeaveDetailsById(this.sessions);
            }
        }
    }
};
</script>

<style>
.loader-box {
    height: 50px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s opacity, 0.3s -webkit-transform;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity, 0.3s -webkit-transform;
}
</style>
